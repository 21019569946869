import { Injectable } from '@angular/core';
import { IErrorContext } from '../interceptors/error-handler.interceptor';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';

export interface ILoggingService {
    logError(error: IErrorContext): void;
}

@Injectable()
export class LoggingService implements ILoggingService {

    constructor() { }

    logError(error: IErrorContext) {
        if (error.clientError) {
            console.error(error.clientError, lodashHelper.omit(error, ['clientError']));
        } else {
            console.error(error);
        }
    }
}
