import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import * as moment from 'moment';
import { MomentHelper } from '../../../core-configuration/helpers/moment.helper';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'cp-date-range-display',
    templateUrl: 'date-range-display.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DateRangeDisplayComponent implements OnInit, OnChanges {

    @Input() start: moment.Moment;
    @Input() end?: moment.Moment;
    @Input() hideEnd = false;
    @Input() hideIcon = false;
    @Input() disableTextClasses = false;

    activeWhen: 'now' | 'past' | 'future';

    get isActive(): boolean { return this.activeWhen === 'now'; }
    get isPast(): boolean { return this.activeWhen === 'past'; }
    get isFuture(): boolean { return this.activeWhen === 'future'; }

    get dateFormat(): string { return environment.momentDateFormat; }

    get icon(): string {
        if (this.isActive) { return 'circle'; }
        if (this.isPast) { return 'caret-down'; }
        if (this.isFuture) { return 'caret-up'; }

        return '';
    }

    get iconTitle(): string {
        if (this.isActive) { return 'Currently Active'; }
        if (this.isPast) { return 'Past'; }
        if (this.isFuture) { return 'Upcoming'; }

        return '';
    }

    get endIsSame(): boolean {
        if (!this.end) { return false; }
        return this.end.isSame(this.start, 'days');
    }

    get activeClass(): Object {
        return {
            'text-success': this.isActive,
            'text-muted': !this.isActive
        };
    }

    get textClass(): Object | undefined {
        if (this.disableTextClasses) { return; }

        return this.activeClass;
    }

    private initialized = false;

    constructor(
        private momentHelper: MomentHelper
    ) { }

    ngOnInit() {
        if (!this.start) { throw new Error('start is required'); }

        this.initialized = true;
        this.calculateLabels();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.start || changes.end) {
            this.calculateLabels();
        }
    }

    private calculateLabels() {
        if (!this.initialized) { return; }

        const today = this.momentHelper.today();

        if (this.start.isAfter(today, 'days')) {
            this.activeWhen = 'future';
            return;
        }

        if (this.start.isBefore(today, 'days')) {
            if (!this.end || this.end.isSameOrAfter(today, 'days')) {
                this.activeWhen = 'now';
                return;
            }
        }

        this.activeWhen = 'past';
    }
}
