import { Injectable } from '@angular/core';
import { DateRange } from '../../../models/shared/date-range.model';
import { DateRangeOverlaps } from '../../date-range-display-dynamic/components/date-range/date-range-breaks-display.component';

@Injectable()
export class WeekMinutesHelper {

    constructor() { }

    calculateWeekMinutes(dateRange: DateRangeOverlaps, dayMinutes: number): number {
        const maxMinutesPerWeek = dayMinutes * 5;

        let total = this.minutesPerWeek(dayMinutes, maxMinutesPerWeek, dateRange);

        dateRange.overlaps.map(overlap => {
            total -= this.minutesPerWeek(dayMinutes, maxMinutesPerWeek, overlap);
        });

        return total;
    }

    private minutesPerWeek(dayMinutes: number, maxMinutesPerWeek: number, dateRange: DateRange): number {
        const dayDiff = dateRange.end.clone().add(1, 'days').diff(dateRange.start, 'days');

        const weeks = Math.floor(dayDiff / 7);
        const remainder = dayDiff % 7;
        const remainderDayMinutes = remainder * dayMinutes;

        const remainderMinutes = remainderDayMinutes > maxMinutesPerWeek ? maxMinutesPerWeek : remainderDayMinutes;

        return weeks * maxMinutesPerWeek + remainderMinutes;
    }
}
