import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DisplayHelperService } from '../../services/display-helper.service';

@Component({
    selector: 'cp-badge',
    template: `
        <span class="badge"
            [style.background-color]="color"
            [ngClass]="textClass(color)">{{ label }}</span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class BadgeComponent {

    @Input() color: string;
    @Input() label: string;

    constructor(
        private displayHelperService: DisplayHelperService
    ) {

    }

    textClass(color: string) {
        return this.displayHelperService.caculateTextColorClass(color);
    }
}
