import { NgModule } from '@angular/core';
import { ConfigurationService } from './services/configuration.service';
import { ConfigurationHttpService } from './services/http/configuration.http.service';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'angular2-moment';
import { MomentHelper } from './helpers/moment.helper';
import { RouteHelper } from './helpers/route.helper';
import { RouterModule } from '@angular/router';
import { UuidHelper } from './helpers/uuid.helper';
import { EntityListDescriptorService } from './services/metadata/entity-list-descriptor.service';
import { EntityMetadataService } from './services/metadata/entity-metadata.service';
import { EntityListSizeService } from './services/metadata/entity-list-size.service';
import { WeekMinutesHelper } from './helpers/week-minutes.helper';
import { IntervalHelper } from './helpers/interval.helper';
import { FilterBuilderHelper } from './helpers/filter-builder.helper';
import { DateRangeHelper } from './helpers/date-range.helper';

@NgModule({
    imports: [
        CommonModule,
        MomentModule,
        RouterModule
    ],
    providers: [
        ConfigurationService,
        ConfigurationHttpService,

        MomentHelper,
        RouteHelper,
        UuidHelper,
        WeekMinutesHelper,
        IntervalHelper,
        FilterBuilderHelper,
        DateRangeHelper,

        EntityListDescriptorService,
        EntityListSizeService,
        EntityMetadataService
    ],
    declarations: [],
    exports: []
})
export class CoreConfigurationModule { }
