import { Injectable } from '@angular/core';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { ResponseStudentSubEntityInclude } from '../../../models/api/response.model';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { StudentCourse } from '../../../models/student/student-course.model';
import { StudentCourseHttpService } from './http/student-course.http.service';
import { StudentSubEntityIncludeOption } from '../../../models/shared/include.model';

@Injectable()
export class StudentCourseService
    extends EntityServiceMetadataBase<StudentCourse, StudentSubEntityIncludeOption, ResponseStudentSubEntityInclude>
    implements IUpdatableService<StudentCourse> {

    protected includeOptions: StudentSubEntityIncludeOption[] = [];

    constructor(
        protected httpService: StudentCourseHttpService,
        protected apiCacheService: ApiCacheService,
        protected includeMapperService: IncludeMapperService
    ) {
        super('studentCourse', httpService, apiCacheService, includeMapperService);
    }

    protected onAfterCreate(entity: StudentCourse): void | RequestFilter[] {
        return [new RequestFilter('students', 'id', 'eq', [entity.studentId])];
    }

    protected onAfterUpdate(entity: StudentCourse): void {
        this.apiCacheService.clearCacheByEntityId('students', entity.studentId);
    }
}
