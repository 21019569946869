import { Injectable } from '@angular/core';
import { ExperienceHttpService } from './http/experience.http.service';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { Experience } from '../../../models/experience/experience.model';
import { CrudEntityServiceBase } from '../../entity-shared/base/entity-service.base';
import { ResponseNoInclude } from '../../../models/api/response.model';
import { NoIncludeOption } from '../../../models/shared/include.model';

@Injectable()
export class ExperienceService
    extends CrudEntityServiceBase<Experience, NoIncludeOption, ResponseNoInclude>
    implements IUpdatableService<Experience> {

    constructor(
        protected httpService: ExperienceHttpService
    ) {
        super(httpService);
     }
}
