<div class="alert alert-{{type}}" [class.alert-sm]="small">
    <div class="flex-shrink-1" *ngIf="!iconRight">
        <fa-icon [icon]="faIcon" size="lg"></fa-icon>
    </div>
    <div class="flex-grow-1 alert-body alert-text mt-0">
        <ng-content></ng-content>
    </div>
    <div class="flex-shrink-1" *ngIf="iconRight">
        <fa-icon [icon]="faIcon" size="lg"></fa-icon>
    </div>
</div>