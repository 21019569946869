import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ResponseFilter } from '../../../../models/api/response.model';
import { CpRequiredOnInit } from '../../../core-configuration/decorators/required-on-init.decorator';
import { InternalFilter } from '../../services/filter-query-helper.service';
import { ResponseFilterService } from '../../services/response-filter.service';
import { ConfigurationService } from '../../../core-configuration/services/configuration.service';
import { map, takeUntil } from 'rxjs/operators';
import { BaseSubscriber } from '../../../common/observables/base-subscriber';
import { EntityPropertyConfig, EntityPropertiesConfig } from '../../../../models/filters/filter.model';
import { FilterPropertyPath, FilterConfigInnerService } from '../../services/filter-config-inner.service';

@Component({
    selector: 'cp-filter-display',
    templateUrl: 'filter-display.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class FilterDisplayComponent extends BaseSubscriber implements OnInit {

    @CpRequiredOnInit() @Input() filter: ResponseFilter;

    internalFilters: InternalFilter[] = [];
    loaded = false;

    private config: EntityPropertiesConfig;

    constructor(
        private configService: ConfigurationService,
        private filterConfigInnerService: FilterConfigInnerService,
        private responseFilterHelper: ResponseFilterService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    getConditionProperty(internalFilter: InternalFilter): EntityPropertyConfig {
        return internalFilter.entityIdProperty || internalFilter.property;
    }

    getPropertyPath(internalFilter: InternalFilter): FilterPropertyPath[] {
        return this.filterConfigInnerService.getPropertyPathByPath(this.config, this.filter.type, internalFilter.path) || [];
    }

    ngOnInit() {

        // need to make this more dynamic eventually
        // changes to the filter will do nothing to override this guard
        if (this.filter.conditions.length === 0) {
            this.loaded = true;
            return;
        }

        this.configService.getFilterProperties().pipe(
            map(configResult => {
                this.config = configResult;
                this.internalFilters = this.responseFilterHelper.responseFilterToInternalFilter(this.config, this.filter);
                this.loaded = true;
                this.changeDetectorRef.markForCheck();
            }),
            takeUntil(this.ngUnsubscribe)
        ).subscribe();
    }
}
