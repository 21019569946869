<cp-date-range-display
    [start]="start"
    [end]="end">
</cp-date-range-display>
<div *ngFor="let range of ranges">
    <cp-date-range-display 
        class="small"
        property
        [start]="range.start"
        [end]="range.end">
    </cp-date-range-display>
    <cp-experience-property-display-list [experienceIds]="[range.experienceId]" [cssClass]="'ml-2'"></cp-experience-property-display-list>
</div>
