import { Injectable } from '@angular/core';
import { ResponseProviderInclude } from '../../../../models/api/response.model';
import { Provider } from '../../../../models/provider/provider.model';
import { HttpClient } from '@angular/common/http';
import { MergeApiHttpClientService } from '../../../api/services/api-http-client.service';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { ProviderIncludeOption } from '../../../../models/shared/include.model';


@Injectable()
export class ProviderHttpService
    extends MergeApiHttpClientService<Provider, ProviderIncludeOption, ResponseProviderInclude> {

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super('providers', httpClient, apiRequestHelperService);
    }
}
