var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PositionHttpService } from './http/position.http.service';
import { Position } from '../../../models/provider/position.model';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { ProviderBaseService } from './provider-base.service';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
var PositionService = /** @class */ (function (_super) {
    __extends(PositionService, _super);
    function PositionService(httpService, apiCacheService, includeMapperService) {
        var _this = _super.call(this, 'position', httpService, apiCacheService, includeMapperService) || this;
        _this.httpService = httpService;
        _this.apiCacheService = apiCacheService;
        _this.includeMapperService = includeMapperService;
        _this.includeOptions = ['sites', 'contacts'];
        _this.createEntity = function () { return new Position(); };
        return _this;
    }
    PositionService.prototype.capacity = function (start, end, options) {
        return this.httpService.capacity(start, end, options);
    };
    PositionService.prototype.onAfterCreate = function (entity) {
        this.apiCacheService.clearCacheByEntityId('providers', entity.providerId);
        return [];
    };
    PositionService.prototype.onAfterUpdate = function (entity) {
        // archivable entities need to clear master types just to be safe
        this.apiCacheService.clearCacheByType('providers');
        this.apiCacheService.clearCacheByType('positions');
        // this.apiCacheService.clearCacheByEntityId('positions', entity.id);
        return;
    };
    return PositionService;
}(ProviderBaseService));
export { PositionService };
