/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../pipes/minutes.pipe";
import * as i3 from "./minutes-summary.component";
var styles_MinutesSummaryComponent = [];
var RenderType_MinutesSummaryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MinutesSummaryComponent, data: {} });
export { RenderType_MinutesSummaryComponent as RenderType_MinutesSummaryComponent };
function View_MinutesSummaryComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "px-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), _co.value.absent)); _ck(_v, 1, 0, currVal_0); }); }
function View_MinutesSummaryComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "px-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), _co.value.attended)); _ck(_v, 1, 0, currVal_0); }); }
function View_MinutesSummaryComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "px-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), _co.unaccounted)); _ck(_v, 1, 0, currVal_0); }); }
function View_MinutesSummaryComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "border d-flex minutes-summary small text-right"]], [[1, "title", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "bg-danger-light border-danger text-danger text-truncate"]], [[4, "width", "%"]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MinutesSummaryComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "bg-success-light boder-success text-success text-truncate"]], [[4, "width", "%"]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MinutesSummaryComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "bg-white text-dark text-truncate"]], [[4, "width", "%"]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MinutesSummaryComponent_4)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.value.absent; _ck(_v, 3, 0, currVal_2); var currVal_4 = _co.value.attended; _ck(_v, 6, 0, currVal_4); var currVal_6 = _co.value.expected; _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.absentWidth; _ck(_v, 1, 0, currVal_1); var currVal_3 = _co.attendedWidth; _ck(_v, 4, 0, currVal_3); var currVal_5 = _co.unaccountedWidth; _ck(_v, 7, 0, currVal_5); }); }
export function View_MinutesSummaryComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.MinutesPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MinutesSummaryComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.value.absent || _co.value.attended) || _co.value.expected); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MinutesSummaryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-minutes-summary", [], null, null, null, View_MinutesSummaryComponent_0, RenderType_MinutesSummaryComponent)), i0.ɵdid(1, 49152, null, 0, i3.MinutesSummaryComponent, [], null, null)], null, null); }
var MinutesSummaryComponentNgFactory = i0.ɵccf("cp-minutes-summary", i3.MinutesSummaryComponent, View_MinutesSummaryComponent_Host_0, { value: "value" }, {}, []);
export { MinutesSummaryComponentNgFactory as MinutesSummaryComponentNgFactory };
