import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseNoInclude } from '../../../../models/api/response.model';
import { Report, ReportResponse, ReportRequestRunQuery, ReportRequestDownloadQuery, ReportRequestQuery } from '../../../../models/report/report.model';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ApiGetHttpClientService } from '../../../api/services/api-http-client.service';
import { Moment } from 'moment';
import { API_DATE_FORMAT } from '../../../core-configuration/helpers/moment.helper';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { NoIncludeOption } from '../../../../models/shared/include.model';

@Injectable()
export class ReportHttpService extends ApiGetHttpClientService<Report, NoIncludeOption, ResponseNoInclude> { // implements IReportHttpService {

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super('reports', httpClient, apiRequestHelperService);
    }

    run(request: ReportRequestRunQuery): Observable<ReportResponse> {

        let httpParams = this.createBaseHttpParams(request);
        httpParams = this.addRunParams(request, httpParams);

        const url = this.generateUrl({ path: ['reportActions', 'run', request.key], overrideBasePath: true, options: {
            filters: request.filters,
        }});

        return this.httpClient.get<ReportResponse>(url, {
            params: httpParams
        });
    }

    download(request: ReportRequestDownloadQuery): Observable<Blob> {

        let httpParams = this.createBaseHttpParams(request);
        httpParams = this.addDownloadParams(request, httpParams);

        const url = this.generateUrl({ path: ['reportActions', 'download', request.key], overrideBasePath: true, options: {
            filters: request.filters
        }});

        const headers = new HttpHeaders({
            'Accept': 'application/zip, text/csv'
        });

        return this.httpClient.get<Blob>(url, {
            headers: headers,
            params: httpParams,
            // unbelivable angular, this is a hack 'arraybuffer' as 'json'
            responseType: 'arraybuffer' as 'json'
        });
    }

    private createBaseHttpParams(request: ReportRequestQuery): HttpParams {
        let httpParams = new HttpParams();
        Object.keys(request.parameters)
            .map(key => ({ key: key, parameter: request.parameters[key]}))
            .filter(item =>
                // filters are handled separately
                item.parameter.type !== 'filter' &&
                // userIds are always pulled from the authentication token
                item.parameter.type !== 'userId' &&
                // only add parameters that have a value
                item.parameter.value)
            .forEach(item => {
                if (item.parameter.type === 'date') {
                    httpParams = httpParams.set(item.key, (item.parameter.value as Moment).format(API_DATE_FORMAT));
                } else {
                    httpParams = httpParams.set(item.key, item.parameter.value!.toString());
                }
            });

        // you always have to include all tables (unless it's the first run call that is)
        if (request.tables && request.tables.length > 0) {
            request.tables.forEach(t => {
                const key = `tables[${t.index}].exclude`;
                httpParams = httpParams.set(key, t.visible ? 'false' : 'true');
            });
        }

        return httpParams;
    }

    private addRunParams(request: ReportRequestRunQuery, httpParams: HttpParams): HttpParams {
        request.pagination.forEach(item => {
            const prefix = `tables[${item.index}].`;
            ['skip', 'take'].forEach(key => {
                const value = item[key];
                if (value) {
                    httpParams = httpParams.set(prefix + key, value.toString());
                }
            });
            if (item.columns) {
                httpParams = httpParams.set(prefix + 'columns', item.columns.join(','));
            }
            if (item.sort) {
                const sortString = item.sort
                    .map(i => i.descending ? '-' + i.name : i.name)
                    .join(',');

                httpParams = httpParams.set(prefix + 'sort', sortString);
            }
        });

        return httpParams;
    }

    private addDownloadParams(request: ReportRequestDownloadQuery, httpParams: HttpParams): HttpParams {
        if (request.tableColumns) {
            request.tableColumns.forEach(item => {
                const key = `tables[${item.index}].columns`;
                httpParams = httpParams.set(key, item.columns.join(','));
            });
        }

        return httpParams;
    }
}
