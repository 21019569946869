import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseModelError } from '../../../../models/api/response.model';

@Component({
    selector: 'cp-confirm-modal',
    templateUrl: 'confirm.modal.html'
})

export class ConfirmModalComponent implements OnInit {
    @Input() title = 'Confirm';
    @Input() message = 'Are you sure?';
    @Input() hideConfirm = false;
    @Input() hideDismiss = false;
    @Input() loading = false;
    @Input() error: ResponseModelError;

    constructor(private activeModal: NgbActiveModal) {

    }

    ngOnInit() {
        if (!this.title) {
            this.title = this.error ? 'Error' : 'Confirm';
        }
        if (!this.message) {
            this.message = this.error ? 'An unexpected error occurred' : 'Are you sure?';
        }
    }

    close() {
        this.activeModal.close();
    }

    dismiss() {
        this.activeModal.dismiss();
    }
}
