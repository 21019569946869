import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'translateOr'
})

export class TranslateOrPipe implements PipeTransform {

    constructor(
        private translateService: TranslateService
    ) {

    }

    // All this does is check if there is a translation is the string file, and if there is, it will return that
    // else it returns the value sent in.
    transform(value: string, keys: string[] = []): any {
        const prefix = keys.length === 0 ? '' : keys.join('.') + '.';

        const translation: string = this.translateService.instant(prefix + value);

        if (keys.length === 0) { return translation; }
        if (typeof(translation) !== 'string') { return translation; }

        return translation.startsWith(prefix) ? value : translation;
    }
}
