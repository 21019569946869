import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/locale/en-gb';
import { environment } from '../environments/environment';
import { routeConfig } from './app.routes';
import { Router } from '@angular/router';
import { AuthUserType } from './models/authentication/authentication.model';
import { MINI_PROFILER_USER_TYPES } from './modules/mini-profiler/components/mini-profiler/mini-profiler.component';
import { AuthenticationService } from './modules/authentication/services/authentication.service';
import { map, takeUntil } from 'rxjs/operators';
import { BaseSubscriber } from './modules/common/observables/base-subscriber';
import { BreadcrumbBuilderService } from './modules/breadcrumb/services/breadcrumb-builder.service';

const MOMENT_CUSTOM_LOCALE = 'en-awesome';

@Component({
    selector: 'cp-bootstrap',
    template: `
    <router-outlet></router-outlet>
    <ng-container *ngIf="isDevelopment">
        <cp-mini-profiler *ifAuthed="true" [token]="token"></cp-mini-profiler>
    </ng-container>
    <ng-container *ngIf="!isDevelopment">
        <cp-mini-profiler *ifAuthed="true;forUserType:supportUserTypes" [token]="token"></cp-mini-profiler>
    </ng-container>
    `
})

export class BootstrapPage extends BaseSubscriber implements OnInit {

    supportUserTypes: AuthUserType[] = MINI_PROFILER_USER_TYPES;
    token: string | null;
    isDevelopment = false;

    constructor(
        private authService: AuthenticationService,
        private translate: TranslateService,
        private router: Router,
        private breadcrumbBuilderService: BreadcrumbBuilderService
    ) {
        super();

        this.router.resetConfig(routeConfig);

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('default');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translate.use('default');

        // moment locale customisation
        moment.defineLocale(MOMENT_CUSTOM_LOCALE, { parentLocale: environment.momentLocale });

        // set moment locale
        moment.locale(MOMENT_CUSTOM_LOCALE);

        // initialize breadcrumb
        this.breadcrumbBuilderService.initialize();

        this.isDevelopment = environment.envType === 'local';
    }

    ngOnInit() {
        this.authService.user$.pipe(
            map(() => this.token = this.authService.getToken()),
            takeUntil(this.ngUnsubscribe)
        ).subscribe();
    }
}
