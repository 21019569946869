import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModelBulk, ResponseTimesheetInclude } from '../../../../models/api/response.model';
import { Timesheet } from '../../../../models/placement/timesheet.model';
import { ApiHttpClientService, ICrudHttpService } from '../../../api/services/api-http-client.service';
import { HttpClient } from '@angular/common/http';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { TimesheetIncludeOption } from '../../../../models/shared/include.model';

export interface ITimesheetHttpService extends ICrudHttpService<Timesheet> {
    bulk(timesheets: Timesheet[]): Observable<ResponseModelBulk<Timesheet>>;
}

@Injectable()
export class TimesheetHttpService extends ApiHttpClientService<Timesheet, TimesheetIncludeOption, ResponseTimesheetInclude> implements ITimesheetHttpService {

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super('timesheets', httpClient, apiRequestHelperService);
    }

    bulk(timesheets: Timesheet[]): Observable<ResponseModelBulk<Timesheet>> {
        const url = `${this.baseUrl}/timesheets/bulk`;

        return this.httpClient.post<ResponseModelBulk<Timesheet>>(url, timesheets);
    }
}
