import { getAllFromTypeMetadata } from '../type.helper';
import { Plan } from '../plan/plan.model';
import { Block } from '../plan/block.model';
import { Student } from '../student/student.model';
import { StudentAddress } from '../student/student-address.model';
import { StudentCourse } from '../student/student-course.model';
import { Experience } from '../experience/experience.model';
import { AbsenceType } from '../absence-type/absence-type.model';
import { Type } from '@angular/core';
import { Site } from '../provider/site.model';
import { Contact } from '../provider/contact.model';
import { ExtendedProperty } from '../extended-properties/extended-property.model';
import { Mapping } from '../placement/mapping.model';
import { SavedSearch } from '../saved-search/saved-search.model';
import { Report } from '../report/report.model';
import { DeletedEntity } from '../deleted-entity/deleted-entity.model';
import { EntityLog } from '../shared/logged.model';
import { PropertyOptionSet } from '../extended-properties/option-set.model';
import { Admin } from '../admin/admin.model';
import { Placement } from '../placement/placement.model';
import { Provider } from '../provider/provider.model';
import { Alert } from '../alert/alert.model';
import { Position } from '../provider/position.model';
import { Timesheet } from '../placement/timesheet.model';
import { Role } from '../user/role.model';
import {
    SummaryType,
    Summary,
    SummaryTypePlural,
    PlacementSummary,
    ProviderSummary,
} from '../summary/summary.model';

export type Entity =
    | Plan
    | Block
    | Student
    | StudentAddress
    | StudentCourse
    | Experience
    | Placement
    | AbsenceType
    | Provider
    | Position
    | Site
    | Contact
    | ExtendedProperty
    | Mapping
    | SavedSearch
    | Report
    | Alert
    | DeletedEntity
    | EntityLog
    | PropertyOptionSet
    | Admin;

export type EntityType =
    | 'plan'
    | 'planBlock'
    | 'student'
    | 'studentAddress'
    | 'studentCourse'
    | 'experience'
    | 'placement'
    | 'absenceType'
    | 'provider'
    | 'position'
    | 'site'
    | 'contact'
    | 'extendedProperty'
    | 'mapping'
    | 'savedSearch'
    | 'report'
    | 'alert'
    | 'deletedEntity'
    | 'entity'
    | 'propertyOptionSet'
    | 'admin';

export type EntityTypePlural =
    | 'plans'
    | 'planBlocks'
    | 'students'
    | 'studentAddresses'
    | 'studentCourses'
    | 'experiences'
    | 'placements'
    | 'absenceTypes'
    | 'providers'
    | 'positions'
    | 'sites'
    | 'contacts'
    | 'extendedProperties'
    | 'mappings'
    | 'savedSearches'
    | 'reports'
    | 'alerts'
    | 'deletedEntities'
    | 'entities'
    | 'propertyOptionSets'
    | 'admins';

export type NonEntity = Summary | Timesheet | Role;

export type NonEntityType = SummaryType | 'timesheet' | 'role';
export type NonEntityTypePlural = SummaryTypePlural | 'timesheets' | 'roles';

export const ALL_NON_ENTITY_TYPES: NonEntityType[] = [
    'placementSummary',
    'providerSummary',
    'timesheet',
    'role',
];
export const ALL_NON_ENTITY_TYPES_PLURAL: NonEntityTypePlural[] = [
    'timesheets',
    'roles',
];

export type ArchivableEntity =
    | Experience
    | AbsenceType
    | Provider
    | Site
    | Contact
    | ExtendedProperty
    | Mapping
    | SavedSearch
    | Report
    | Alert
    | PropertyOptionSet
    | Admin;

export type MetadataType = EntityType | SummaryType | NonEntityType;
export type MetadataTypePlural =
    | EntityTypePlural
    | SummaryTypePlural
    | NonEntityTypePlural;

export type MetadataExtendedKeys =
    | 'placementOverlaps'
    | 'studentCurrentCourses';
export type MetadataIncludeKeys = MetadataTypePlural | MetadataExtendedKeys;

export type MetadataEntity = Entity | NonEntity | Summary;

export type MergeableEntity = Provider | Site | Contact | Position;
export type MergeableEntityType = 'provider' | 'site' | 'contact' | 'position';

export interface IMetadataTypeDescription {
    type: Type<MetadataEntity>;
    plural: MetadataTypePlural;
    archivable?: boolean;
    mergable?: boolean;
    extendedable?: boolean;
    filterable?: boolean;
}

export const DESCRIBABLE_TYPE_METADATA: {
    [key in MetadataType]: IMetadataTypeDescription;
} = {
    plan: {
        filterable: true,
        extendedable: true,
        plural: 'plans',
        type: Plan,
    },
    planBlock: {
        filterable: true,
        extendedable: true,
        plural: 'planBlocks',
        type: Block,
    },
    student: {
        filterable: true,
        extendedable: true,
        plural: 'students',
        type: Student,
    },
    studentAddress: {
        filterable: true,
        extendedable: true,
        plural: 'studentAddresses',
        type: StudentAddress,
    },
    studentCourse: {
        filterable: true,
        extendedable: true,
        plural: 'studentCourses',
        type: StudentCourse,
    },
    experience: {
        filterable: true,
        archivable: true,
        extendedable: true,
        plural: 'experiences',
        type: Experience,
    },
    placement: {
        filterable: true,
        extendedable: true,
        plural: 'placements',
        type: Placement,
    },
    absenceType: {
        filterable: true,
        archivable: true,
        plural: 'absenceTypes',
        type: AbsenceType,
    },
    provider: {
        filterable: true,
        archivable: true,
        mergable: true,
        extendedable: true,
        plural: 'providers',
        type: Provider,
    },
    position: {
        filterable: true,
        mergable: true,
        extendedable: true,
        plural: 'positions',
        type: Position,
    },
    site: {
        filterable: true,
        archivable: true,
        mergable: true,
        extendedable: true,
        plural: 'sites',
        type: Site,
    },
    contact: {
        filterable: true,
        archivable: true,
        mergable: true,
        extendedable: true,
        plural: 'contacts',
        type: Contact,
    },
    extendedProperty: {
        archivable: true,
        plural: 'extendedProperties',
        type: ExtendedProperty,
    },
    mapping: {
        archivable: true,
        filterable: true,
        plural: 'mappings',
        type: Mapping,
    },
    savedSearch: {
        archivable: true,
        plural: 'savedSearches',
        type: SavedSearch,
    },
    report: {
        archivable: true,
        plural: 'reports',
        type: Report,
    },
    alert: {
        archivable: true,
        plural: 'alerts',
        type: Alert,
    },
    deletedEntity: {
        plural: 'deletedEntities',
        type: DeletedEntity,
    },
    entity: {
        filterable: true,
        plural: 'entities',
        type: EntityLog,
    },
    propertyOptionSet: {
        archivable: true,
        filterable: true,
        plural: 'propertyOptionSets',
        type: PropertyOptionSet,
    },
    admin: {
        filterable: true,
        archivable: true,
        plural: 'admins',
        type: Admin,
    },

    // non entities
    placementSummary: {
        type: PlacementSummary,
        plural: 'placementSummaries',
    },

    providerSummary: {
        type: ProviderSummary,
        plural: 'providerSummaries',
    },

    role: {
        filterable: true,
        type: Role,
        plural: 'roles',
    },

    timesheet: {
        filterable: true,
        type: Timesheet,
        plural: 'timesheets',
    },
};

export const ALL_METADATA_TYPES: MetadataType[] = getAllFromTypeMetadata(
    DESCRIBABLE_TYPE_METADATA
);
export const ALL_METADATA_TYPE_PLURALS: MetadataTypePlural[] = ALL_METADATA_TYPES.map(
    t => DESCRIBABLE_TYPE_METADATA[t].plural
);

export const ALL_FILTER_TYPES = ALL_METADATA_TYPES.filter(
    t => DESCRIBABLE_TYPE_METADATA[t].filterable
);
export const ALL_FILTER_TYPE_PLURALS = ALL_FILTER_TYPES.map(
    t => DESCRIBABLE_TYPE_METADATA[t].plural
);
