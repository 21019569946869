import { PipeTransform, Pipe } from '@angular/core';

export function minutesToHoursDisplay(minutes?: number): string {
    if (!minutes) { return ''; }
    const hours = minutes / 60;
    return `${Math.round(hours * 100) / 100}`;
}

@Pipe({
    name: 'minutes'
})
export class MinutesPipe implements PipeTransform {

    constructor() {}

    transform(minutes?: number, ...args: any[]): string {
        return minutesToHoursDisplay(minutes);
    }
}
