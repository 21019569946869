import { PropertyMetadata, ClassMetadata } from '../../../models/shared/metadata.model';
import { MetadataEntity } from '../../../models/entity/entity.type';

import 'reflect-metadata';

export const CLASS_METADATA_DECORATOR_KEY = 'CpClassMetadataDecoratorKey';
export const PROPERTY_METADATA_DECORATOR_KEY = 'CpPropertyMetadataDecoratorKey';


// NOTE: this will only work for instance properties
export function CpProperty(options: PropertyMetadata): PropertyDecorator {
    return (target, property) => {
        const classConstructor = target.constructor;

        const metadata = Reflect.getOwnMetadata(PROPERTY_METADATA_DECORATOR_KEY, classConstructor) || {};

        // This creates a default label using the property key, and camel case logic
        if (!options.label) {
            const spaced = (property as string).replace( /([A-Z])/g, ' $1' );
            options.label = spaced.charAt(0).toUpperCase() + spaced.slice(1);
        }

        metadata[property] = options;
        Reflect.defineMetadata(PROPERTY_METADATA_DECORATOR_KEY, metadata, classConstructor);
    };
}

export function CpClass<TEntity extends MetadataEntity>(options: ClassMetadata<TEntity>): ClassDecorator {
    return function (target: any) {
        Reflect.defineMetadata(CLASS_METADATA_DECORATOR_KEY, options, target);
    };
}

