var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { ApiHttpClientServiceBase } from '../../../api/services/api-http-client.service';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
var ChangesHttpService = /** @class */ (function (_super) {
    __extends(ChangesHttpService, _super);
    function ChangesHttpService(httpClient, apiRequestHelperService) {
        var _this = _super.call(this, httpClient, apiRequestHelperService) || this;
        _this.httpClient = httpClient;
        _this.apiRequestHelperService = apiRequestHelperService;
        return _this;
    }
    ChangesHttpService.prototype.getChanges = function (type, id, options) {
        var url = _super.prototype.generateUrl.call(this, {
            path: ['changes', type.toString(), id.toString()],
            options: options
        });
        return this.httpClient.get(url);
    };
    return ChangesHttpService;
}(ApiHttpClientServiceBase));
export { ChangesHttpService };
