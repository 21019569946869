var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BehaviorSubject } from 'rxjs';
import { ConfigurationService } from '../../../core-configuration/services/configuration.service';
import { flatMap } from 'rxjs/operators';
import { EntityMetadataService } from './entity-metadata.service';
import { lodashHelper } from '../../helpers/lodash.helper';
import { ALL_FILTER_TYPES, DESCRIBABLE_TYPE_METADATA, ALL_METADATA_TYPES, } from '../../../../models/entity/entity.type';
var LOCAL_STORAGE_PREFIX = 'cp:';
var LOCAL_STORAGE_SUFFIX = '_ListDescriptor';
var EntityListDescriptorService = /** @class */ (function () {
    function EntityListDescriptorService(configService, metadataService) {
        this.configService = configService;
        this.metadataService = metadataService;
        this.collection = {};
        this.changeSource = new BehaviorSubject(this.collection);
        this.change$ = this.changeSource.asObservable();
        this.initialized = [];
    }
    EntityListDescriptorService.prototype.initialize = function (type, options) {
        var _this = this;
        var meta = this.metadataService.get(type);
        var classMeta = meta.classMetadata;
        if (this.initialized.includes(type)) {
            this.handleOptions(options, type, meta.propertyMetadata);
            return this.change$;
        }
        var core = [];
        return this.configService.get().pipe(flatMap(function (config) {
            core = _this.generateCoreProperties(type, meta.propertyMetadata, classMeta, options, config);
            var extended = _this.generateExtendedProperties(classMeta.metadataType, config);
            var include = _this.generateIncludeEntityProperties(classMeta, config);
            var all = core.concat(extended, include);
            return _this.setStorageVisibilityAndTrigger(type, classMeta, all);
        }));
    };
    EntityListDescriptorService.prototype.getDefaultSort = function (type) {
        var defaultSortArray = this.metadataService.get(type).classMetadata
            .defaultSort;
        if (!defaultSortArray || defaultSortArray.length === 0) {
            return undefined;
        }
        return defaultSortArray
            .map(function (i) { return "" + (i.desending ? '-' : '') + i.key; })
            .join(',');
    };
    EntityListDescriptorService.prototype.setVisibility = function (entityType, items, options) {
        if (options === void 0) { options = { clearVisible: false }; }
        var meta = this.metadataService.get(entityType);
        var classMeta = meta.classMetadata;
        var list = this[entityType];
        if (options.clearVisible) {
            list.map(function (i) { return (i.visible = false); });
        }
        items.forEach(function (item) {
            var find = list.find(function (i) { return i.key === item.key; });
            if (find) {
                find.visible = item.visible;
            }
            else {
                console.log("%ckey not found in visibility list: " + item.key, 'color:red');
            }
        });
        this.saveStorageVisibility(classMeta, list);
        this.triggerEvent();
    };
    EntityListDescriptorService.prototype.getHeaderAlign = function (type) {
        switch (type) {
            case 'date':
            case 'int32':
            case 'double':
                return 'right';
            default:
                return 'left';
        }
    };
    EntityListDescriptorService.prototype.handleOptions = function (options, entityType, propertyMetadata) {
        if (options.resetDefaults) {
            var defaults = this.getCoreDefaultKeys(propertyMetadata);
            this.setVisibility(entityType, defaults.map(function (key) { return ({ key: key, visible: true }); }));
        }
    };
    EntityListDescriptorService.prototype.setStorageVisibilityAndTrigger = function (entityType, classMeta, list) {
        this.setStorageVisibility(classMeta, list);
        this[entityType] = list;
        if (!this.initialized.includes(entityType)) {
            this.initialized.push(entityType);
        }
        this.triggerEvent();
        return this.change$;
    };
    EntityListDescriptorService.prototype.triggerEvent = function () {
        var _this = this;
        ALL_METADATA_TYPES.map(function (i) {
            _this.collection[i] = _this[i];
        });
        this.changeSource.next(this.collection);
    };
    EntityListDescriptorService.prototype.getCoreDefaultKeys = function (propertyMetadata) {
        return Object.keys(propertyMetadata).filter(function (key) {
            var meta = propertyMetadata[key];
            return !meta.hideFromList && meta.defaultListVisible;
        });
    };
    EntityListDescriptorService.prototype.generateCoreProperties = function (entityType, propertyMetadata, classMeta, options, config) {
        var hasStorage = this.hasStorage(classMeta);
        var filterPropertyKeys = config.types[entityType]
            ? Object.keys(config.types[entityType])
            : [];
        var list = Object.keys(propertyMetadata).map(function (key) {
            var meta = propertyMetadata[key];
            var filterProp = filterPropertyKeys.includes(key)
                ? config.types[entityType][key]
                : undefined;
            var visible = options.resetDefaults && !!meta.defaultListVisible
                ? true
                : hasStorage || !options.resetDefaults
                    ? false
                    : !!meta.defaultListVisible;
            var item = {
                key: key,
                propertyKey: key,
                visible: visible,
                includeKey: '',
                type: 'core',
                metadata: propertyMetadata[key],
                isIncludeMany: false,
                sortable: filterProp ? filterProp.sortable : false,
            };
            return item;
        });
        return list;
    };
    EntityListDescriptorService.prototype.generateExtendedProperties = function (type, config) {
        var _this = this;
        var entityPropertyConfig = config.types[type];
        if (!entityPropertyConfig) {
            return [];
        }
        var list = Object.keys(entityPropertyConfig)
            .filter(function (key) { return key.startsWith('properties.'); })
            .map(function (key) { return entityPropertyConfig[key]; })
            .filter(function (configItem) { return !configItem.archived; })
            .map(function (configItem) {
            var isOptionType = configItem.type === 'option';
            var item = {
                key: configItem.key,
                propertyKey: configItem.key,
                visible: false,
                type: 'extended',
                isIncludeMany: false,
                includeKey: '',
                sortable: configItem.sortable,
                optionSetOptions: isOptionType
                    ? configItem.values
                    : undefined,
                optionType: configItem.optionType,
                metadata: {
                    label: configItem.name,
                    type: configItem.type,
                    hideFromList: false,
                    listHeaderAlign: _this.getHeaderAlign(configItem.type),
                },
            };
            return item;
        });
        return list;
    };
    EntityListDescriptorService.prototype.generateIncludeEntityProperties = function (classMeta, config) {
        var _this = this;
        if (!classMeta.include) {
            return [];
        }
        var list = [];
        classMeta.include.forEach(function (includeMeta) {
            var metaType = includeMeta.type;
            if (!DESCRIBABLE_TYPE_METADATA[metaType]) {
                throw new Error('Attempting to get metadata info from an object that isnt a MetadataEntity');
            }
            var meta = _this.metadataService.get(includeMeta.type);
            var includeCore = _this.generateCoreProperties(metaType, meta.propertyMetadata, classMeta, { resetDefaults: false }, config);
            var includeExtended = _this.generateExtendedProperties(meta.classMetadata.metadataType, config);
            var includeHideFromList = includeMeta.options && includeMeta.options.hideFromList;
            // this creates the entity type for filterable types
            if (ALL_FILTER_TYPES.filter(function (i) { return i !== 'entity'; }).includes(includeMeta.type)) {
                list.push(_this.createEntityListItem(includeMeta, {
                    isIncludeMany: false,
                }));
            }
            var combined = includeCore.concat(includeExtended).map(function (i) {
                var propertyKey = i.key;
                var includeKey = includeMeta.includeKey;
                return __assign({}, i, { key: metaType + "." + propertyKey, propertyKey: propertyKey, type: includeMeta.type, requiresInclude: includeMeta.options
                        ? includeMeta.options.requiresInclude
                        : undefined, includeKey: includeKey, requiresIncludeSortPrefix: includeMeta.options
                        ? includeMeta.options.requiresIncludeSortPrefix
                        : undefined, 
                    // override hideFromList of includes that are hidden
                    metadata: __assign({}, i.metadata, { hideFromList: includeHideFromList
                            ? true
                            : i.metadata.hideFromList }) });
            });
            list.push.apply(list, combined);
        });
        if (!classMeta.includeMany) {
            return list;
        }
        classMeta.includeMany.forEach(function (includeMeta) {
            var metaType = includeMeta.type;
            if (!DESCRIBABLE_TYPE_METADATA[metaType]) {
                throw new Error('Attempting to get metadata info from an object that isnt a MetadataEntity');
            }
            var meta = _this.metadataService.get(includeMeta.type);
            var includeCore = _this.generateCoreProperties(metaType, meta.propertyMetadata, classMeta, { resetDefaults: false }, config);
            var includeExtended = _this.generateExtendedProperties(meta.classMetadata.metadataType, config);
            var includeHideFromList = includeMeta.options && includeMeta.options.hideFromList;
            var combined = includeCore.concat(includeExtended).map(function (i) {
                var propertyKey = i.key;
                var includeKey = includeMeta.includeKey;
                return __assign({}, i, { key: includeKey + "." + propertyKey, propertyKey: propertyKey, type: includeMeta.type, requiresInclude: includeMeta.options
                        ? includeMeta.options.requiresInclude
                        : undefined, includeKey: includeKey, requiresIncludeSortPrefix: includeMeta.options
                        ? includeMeta.options.requiresIncludeSortPrefix
                        : undefined, isIncludeMany: true, 
                    // you can never sort on an include Many include
                    sortable: false, 
                    // override hideFromList of includes that are hidden
                    metadata: __assign({}, i.metadata, { hideFromList: includeHideFromList
                            ? true
                            : i.metadata.hideFromList }) });
            });
            // this creates the entity type
            list.push(_this.createEntityListItem(includeMeta, { isIncludeMany: true }));
            list.push.apply(list, combined);
        });
        return list;
    };
    EntityListDescriptorService.prototype.createEntityListItem = function (includeMeta, options) {
        var includeKey = includeMeta.includeKey;
        return {
            key: includeKey,
            propertyKey: '',
            type: includeMeta.type,
            includeKey: includeKey,
            requiresInclude: includeMeta.options
                ? includeMeta.options.requiresInclude
                : [],
            isIncludeMany: options.isIncludeMany,
            visible: false,
            sortable: false,
            metadata: {
                label: includeMeta.type,
                type: 'entity',
                hideFromList: includeMeta.options && includeMeta.options.hideFromList,
                listHeaderAlign: 'left',
            },
        };
    };
    EntityListDescriptorService.prototype.saveStorageVisibility = function (classMeta, list) {
        var key = this.generateKey(classMeta);
        localStorage.removeItem(key);
        // only store the items that are visible (this isn't necessary but it does reduce the size of the object)
        var toStore = list
            .filter(function (i) { return i.visible; })
            .map(function (i) { return lodashHelper.pick(i, ['key', 'visible']); });
        localStorage.setItem(key, JSON.stringify(toStore));
    };
    EntityListDescriptorService.prototype.setStorageVisibility = function (classMeta, list) {
        var visibility = this.getFromStorage(classMeta);
        visibility.map(function (item) {
            var find = list.find(function (i) { return i.key === item.key; });
            if (find) {
                find.visible = item.visible;
            }
        });
    };
    EntityListDescriptorService.prototype.hasStorage = function (classMeta) {
        var key = this.generateKey(classMeta);
        return !!localStorage.getItem(key);
    };
    EntityListDescriptorService.prototype.getFromStorage = function (classMeta) {
        var key = this.generateKey(classMeta);
        var storageString = localStorage.getItem(key);
        if (!storageString) {
            return [];
        }
        var visibility = JSON.parse(storageString);
        if (!visibility || !visibility.length) {
            return [];
        }
        return visibility;
    };
    EntityListDescriptorService.prototype.generateKey = function (classMeta) {
        return (LOCAL_STORAGE_PREFIX + classMeta.metadataType + LOCAL_STORAGE_SUFFIX);
    };
    return EntityListDescriptorService;
}());
export { EntityListDescriptorService };
