import { Injectable } from '@angular/core';
import { Block } from '../../../models/plan/block.model';
import { BlockHttpService } from './http/block.http.service';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { ResponseBlockInclude } from '../../../models/api/response.model';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { IListService, IIdListService } from '../../entity-shared/base/entity-search.base';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { PlanBlockIncludeOption } from '../../../models/shared/include.model';


@Injectable()
export class BlockService
    extends
    EntityServiceMetadataBase<Block, PlanBlockIncludeOption, ResponseBlockInclude>
    implements
    IUpdatableService<Block>, IListService<Block, PlanBlockIncludeOption, ResponseBlockInclude>,
    IIdListService  {

    protected includeOptions: PlanBlockIncludeOption[] = ['plans'];

    constructor(
        protected httpService: BlockHttpService,
        protected apiCacheService: ApiCacheService,
        protected includeMapperService: IncludeMapperService
    ) {
        super('planBlock', httpService, apiCacheService, includeMapperService);
    }

    protected onAfterCreate(entity: Block): void | RequestFilter[] {
        this.apiCacheService.clearCacheByEntityId('plans', entity.planId);
        return;
    }
    protected onAfterUpdate(entity: Block): void {
        this.apiCacheService.clearCacheByEntityId('plans', entity.planId);
        this.apiCacheService.clearCacheByType('placements');
        return;
    }
}
