import { Moment } from 'moment';
import { CpProperty, CpClass } from '../../modules/core-configuration/decorators/metadata.decorator';
import { EntityBase } from '../entity/entity.model';

export interface ILoggedType {
    added?: Moment;
    addedUserId?: number;
    lastUpdated?: Moment;
    lastUpdatedUserId?: number;
}

@CpClass({
    create: newEntityLog,
    metadataType: 'entity'
})
export class EntityLog extends EntityBase implements ILoggedType {
    @CpProperty({ type: 'string' })
    lastUpdatedName: string;

    @CpProperty({ type: 'dateTime' })
    lastUpdated?: Moment;

    @CpProperty({ type: 'int32', hideFromList: true })
    lastUpdatedUserId?: number;

    @CpProperty({ type: 'string' })
    addedName: string;

    @CpProperty({ type: 'dateTime' })
    added?: Moment;

    @CpProperty({ type: 'int32', hideFromList: true })
    addedUserId?: number;

}

export function newEntityLog() { return new EntityLog(); }
