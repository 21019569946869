import { Injectable } from '@angular/core';
import { ConfigurationService } from '../../core-configuration/services/configuration.service';
import { EntityPropertyConfig } from '../../../models/filters/filter.model';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { FilterConfigInnerService, FilterPropertyPath } from './filter-config-inner.service';
import { MetadataType } from '../../../models/entity/entity.type';

export interface FilterPath {
    key: string;
    entityIdKey?: string;
    without: boolean;
}

export interface FilterPropertyExtended extends EntityPropertyConfig {
    isEntity: boolean;
}

@Injectable()
export class FilterConfigHelperService {

    constructor(
        private configService: ConfigurationService,
        private filterConfigInnerService: FilterConfigInnerService
    ) { }

    getEntityProperties(type: MetadataType): Observable<FilterPropertyExtended[]> {

        const filterConfigObs = this.configService.getFilterProperties();

        return filterConfigObs.pipe(
            map(config => this.filterConfigInnerService.getBaseEntityProperties(config, type))
        );
    }

    getPropertyPathByPath(type: MetadataType, path: FilterPath[]): Observable<FilterPropertyPath[]> {
        const filterConfigObs = this.configService.getFilterProperties();
        return filterConfigObs.pipe(
            map(config => this.filterConfigInnerService.getPropertyPathByPath(config, type, path)!),
            filter(i => i !== undefined)
        );
    }
}
