import { EntityTypePlural, EntityType, NonEntityType, NonEntityTypePlural, MetadataTypePlural, MetadataType, ALL_METADATA_TYPE_PLURALS, DESCRIBABLE_TYPE_METADATA } from '../../../models/entity/entity.type';


// ugh. -- not so ugh anymore
export function toPlural(thisType: MetadataType | MetadataTypePlural): MetadataTypePlural {

    if (ALL_METADATA_TYPE_PLURALS.includes(thisType as MetadataTypePlural)) {
        return thisType as MetadataTypePlural;
    }

    const meta = DESCRIBABLE_TYPE_METADATA[thisType as MetadataType];
    if (meta) {
        return meta.plural;
    }

    throw new Error('type not implemented in plural function: ' + thisType);
}

export function toPluralForUrl(type: EntityTypePlural | EntityType | NonEntityType | NonEntityTypePlural): string {
    const plural = toPlural(type);

    switch (plural) {
        case 'planBlocks': return 'blocks';
        case 'studentAddresses': return 'student-addresses';
        case 'studentCourses': return 'student-courses';
    }

    return plural;
}
