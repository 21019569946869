var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var PropertiesMetadata = /** @class */ (function () {
    function PropertiesMetadata() {
    }
    return PropertiesMetadata;
}());
export { PropertiesMetadata };
var IncludeEntityMetadataOptions = /** @class */ (function () {
    function IncludeEntityMetadataOptions() {
    }
    return IncludeEntityMetadataOptions;
}());
export { IncludeEntityMetadataOptions };
var IncludeEntityMetadataCore = /** @class */ (function () {
    function IncludeEntityMetadataCore(type, includeKey, propertyMaster, propertySlave, options) {
        this.type = type;
        this.includeKey = includeKey;
        this.propertyMaster = propertyMaster;
        this.propertySlave = propertySlave;
        this.options = options;
    }
    return IncludeEntityMetadataCore;
}());
export { IncludeEntityMetadataCore };
var IncludeEntityMetadata = /** @class */ (function (_super) {
    __extends(IncludeEntityMetadata, _super);
    function IncludeEntityMetadata(type, includeKey, _propertyMaster, _propertySlave, options) {
        var _this = _super.call(this, type, includeKey, _propertyMaster, _propertySlave, options) || this;
        _this.type = type;
        _this.includeKey = includeKey;
        _this._propertyMaster = _propertyMaster;
        _this._propertySlave = _propertySlave;
        return _this;
    }
    return IncludeEntityMetadata;
}(IncludeEntityMetadataCore));
export { IncludeEntityMetadata };
var ClassMetadata = /** @class */ (function () {
    function ClassMetadata() {
    }
    return ClassMetadata;
}());
export { ClassMetadata };
var PropertyPipeMetadata = /** @class */ (function () {
    function PropertyPipeMetadata() {
    }
    return PropertyPipeMetadata;
}());
export { PropertyPipeMetadata };
var PropertyMetadata = /** @class */ (function () {
    function PropertyMetadata() {
    }
    return PropertyMetadata;
}());
export { PropertyMetadata };
