import { Injectable } from '@angular/core';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { AbsenceType } from '../../../models/absence-type/absence-type.model';
import { AbsenceTypeHttpService } from './http/absence-type.http.service';
import { ResponseNoInclude } from '../../../models/api/response.model';
import { CrudEntityServiceBase } from '../../entity-shared/base/entity-service.base';
import { NoIncludeOption } from '../../../models/shared/include.model';

@Injectable()
export class AbsenceTypeService
    extends CrudEntityServiceBase<AbsenceType, NoIncludeOption, ResponseNoInclude>
    implements IUpdatableService<AbsenceType> {

    constructor(
        protected httpService: AbsenceTypeHttpService
    ) {
        super(httpService);
    }
}
