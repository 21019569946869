import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDatepickerHelper {

    constructor() { }

    convertFromMoment(value: moment.Moment): NgbDate {
        // The ngbDatepicker using a 1-index month range, moment uses a 0-based index
        // Also important: moments .day() function returns the DayOfWeek, must use .date() to get the day of month.
        return new NgbDate(
            value.year(),
            value.month() + 1,
            value.date()
        );
    }

    convertToMoment(value: NgbDateStruct): moment.Moment {

         // The ngbDatepicker using a 1-index month range, moment uses a 0-based index
        return moment.utc({
            d: value.day,
            M: value.month - 1,
            y: value.year
        });
    }
}
