import groupBy from 'lodash/groupBy';
import intersectionBy from 'lodash/intersectionBy';
import flatten from 'lodash/flatten';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import remove from 'lodash/remove';
import intersection from 'lodash/intersection';
import sortBy from 'lodash/sortBy';

export const lodashHelper = {
    // I have no idea why typescript complains when I try to import this particular function (groupBy) in
    // two components simultaneously. But it does, and this fixes it.
    groupBy: groupBy,
    intersectionBy: intersectionBy,
    flatten: flatten,
    clone: clone,
    cloneDeep: cloneDeep,
    compact: compact,
    isEqual: isEqual,
    pick: pick,
    uniq: uniq,
    uniqBy: uniqBy,
    omit: omit,
    pickBy: pickBy,
    remove: remove,
    intersection: intersection,
    sortBy: sortBy
};
