/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../display-helper/components/title/name-title.component.ngfactory";
import * as i2 from "../../../display-helper/components/title/name-title.component";
import * as i3 from "../../../core-configuration/helpers/moment.helper";
import * as i4 from "@angular/common";
import * as i5 from "../../../display-helper/directives/loader/loader.directive";
import * as i6 from "./absence-type-entity-list-item.component";
import * as i7 from "../../../core-configuration/services/configuration.service";
var styles_AbsenceTypeEntityListItemComponent = [];
var RenderType_AbsenceTypeEntityListItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AbsenceTypeEntityListItemComponent, data: {} });
export { RenderType_AbsenceTypeEntityListItemComponent as RenderType_AbsenceTypeEntityListItemComponent };
function View_AbsenceTypeEntityListItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-name-title", [["class", "d-block"]], null, null, null, i1.View_NameTitleComponent_0, i1.RenderType_NameTitleComponent)), i0.ɵdid(1, 49152, null, 0, i2.NameTitleComponent, [i3.MomentHelper], { value: [0, "value"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AbsenceTypeEntityListItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AbsenceTypeEntityListItemComponent_2)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AbsenceTypeEntityListItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AbsenceTypeEntityListItemComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.LoaderDirective, [i0.TemplateRef, i0.ViewContainerRef, i0.ComponentFactoryResolver], { cpLoadingSmall: [0, "cpLoadingSmall"], cpLoading: [1, "cpLoading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = !_co.loaded; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AbsenceTypeEntityListItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-absence-type-entity-list-item", [], null, null, null, View_AbsenceTypeEntityListItemComponent_0, RenderType_AbsenceTypeEntityListItemComponent)), i0.ɵdid(1, 770048, null, 0, i6.AbsenceTypeEntityListItemComponent, [i7.ConfigurationService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AbsenceTypeEntityListItemComponentNgFactory = i0.ɵccf("cp-absence-type-entity-list-item", i6.AbsenceTypeEntityListItemComponent, View_AbsenceTypeEntityListItemComponent_Host_0, { dynamicValue: "dynamicValue" }, {}, []);
export { AbsenceTypeEntityListItemComponentNgFactory as AbsenceTypeEntityListItemComponentNgFactory };
