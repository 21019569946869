import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { TimelineModule } from '../timeline/timeline.module';
import { PlacementModule } from '../placement/placement.module';
import { PlanService } from './services/plan.service';
import { PlanHttpService } from './services/http/plan.http.service';
import { StudentModule } from '../student/student.module';
import { ProviderModule } from '../provider/provider.module';
import { BlockFormComponent } from './components/block/block-form.component';
import { BlockService } from './services/block.service';
import { BlockHttpService } from './services/http/block.http.service';
import { AlertModule } from '../alert/alert.module';
import { PlanResultsComponent } from './components/plan/plan-results.component';
import { BlockResultsComponent } from './components/block/block-results.component';
// import { BlockPlacementStudentListComponent } from './components/block-placement/block-placement-student-list.component';
// import { BlockPlacementPositionListComponent } from './components/block-placement/block-placement-position-list.component';
import { PositionDetailsComponent } from '../provider/components/position/position-details.component';
import { PlanDetailsComponent } from './components/plan/plan-details.component';
import { PlanFormComponent } from './components/plan/plan-form.component';
import { BlockDetailsComponent } from './components/block/block-details.component';
import { PlanDetailsMasterComponent } from './components/plan/plan-details-master.component';
import { BlockDetailsMasterComponent } from './components/block/block-details-master.component';
import { PlanTimelineResultsComponent } from './components/plan-timeline/plan-timeline-results.components';
// import { BlockPlacementStudentSummaryComponent } from './components/block-placement/block-placement-student-summary.component';


@NgModule({
    entryComponents: [
    ],
    imports: [
        SharedModule,
        TimelineModule,
        PlacementModule,
        ProviderModule,
        StudentModule,
        AlertModule
    ],
    declarations: [
        PlanDetailsMasterComponent,
        PlanResultsComponent,
        PlanDetailsComponent,
        PlanFormComponent,

        BlockResultsComponent,
        BlockFormComponent,
        BlockDetailsComponent,

        BlockDetailsMasterComponent,
        // BlockPlacementStudentListComponent,
        // BlockPlacementPositionListComponent,
        // BlockPlacementStudentSummaryComponent

        PlanTimelineResultsComponent
    ],
    providers: [

        PlanService,
        BlockService,

        PlanHttpService,
        BlockHttpService
    ],
    exports: [
        BlockDetailsMasterComponent,
        BlockFormComponent,
        BlockResultsComponent,
        PlanFormComponent,
        BlockDetailsComponent,

        // BlockPlacementStudentListComponent,
        // BlockPlacementPositionListComponent,
        // BlockPlacementStudentSummaryComponent,

        PositionDetailsComponent,

        PlanDetailsMasterComponent,
        PlanResultsComponent,
        PlanDetailsComponent,

        PlanTimelineResultsComponent
    ]
})
export class PlanModule { }
