var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SiteHttpService } from './http/site.http.service';
import { Site } from '../../../models/provider/site.model';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { ProviderBaseService } from './provider-base.service';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
var SiteService = /** @class */ (function (_super) {
    __extends(SiteService, _super);
    function SiteService(httpService, apiCacheService, includeMapperService) {
        var _this = _super.call(this, 'site', httpService, apiCacheService, includeMapperService) || this;
        _this.httpService = httpService;
        _this.apiCacheService = apiCacheService;
        _this.includeMapperService = includeMapperService;
        _this.includeOptions = ['providers', 'contacts'];
        _this.createEntity = function () { return new Site(); };
        return _this;
    }
    SiteService.prototype.onAfterCreate = function (entity) {
        this.apiCacheService.clearCacheByEntityId('providers', entity.providerId);
        return [];
    };
    SiteService.prototype.onAfterUpdate = function (entity) {
        // archivable entities need to clear master types just to be safe
        this.apiCacheService.clearCacheByType('providers');
        this.apiCacheService.clearCacheByType('sites');
        return;
    };
    return SiteService;
}(ProviderBaseService));
export { SiteService };
