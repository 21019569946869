import { Injectable } from '@angular/core';
import { Placement } from '../../../../models/placement/placement.model';
import { ResponsePlacementInclude, ResponseModelBulk } from '../../../../models/api/response.model';
import { Observable } from 'rxjs';
import { ApiHttpClientService, ICrudHttpService } from '../../../api/services/api-http-client.service';
import { HttpClient } from '@angular/common/http';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { PlacementIncludeOption } from '../../../../models/shared/include.model';

export interface IPlacementHttpService extends ICrudHttpService<Placement> {
    bulk(placements: Placement[]): Observable<ResponseModelBulk<Placement>>;
}

@Injectable()
export class PlacementHttpService extends ApiHttpClientService<Placement,  PlacementIncludeOption, ResponsePlacementInclude> implements IPlacementHttpService {

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super('placements', httpClient, apiRequestHelperService);
    }

    bulk(placements: Placement[]): Observable<ResponseModelBulk<Placement>> {
        const url = `${this.baseUrl}/placements/bulk`;

        return this.httpClient.post<ResponseModelBulk<Placement>>(url, placements);
    }
}
