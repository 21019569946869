import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { LoggingService } from '../services/logging.service';
import { ErrorHandlerService } from '../services/error-handler.service';

// 400 (Bad Request) should be handled in the context of whatever caused it
// 401 (Not Authenticated) is handled by the Authentication Interceptor
// 403 (Forbidden) should be handled in the same way as a 400, doesn't have to be authentication-related. Should never be hit from a permission point of view, because you should handle it better.
// 404 (Not Found) should be handled at a page level
const API_ERROR_IGNORED_CODES = [400, 401, 403, 404];

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {

    constructor(
        private errorService: ErrorService,
        private loggingService: LoggingService,
        private errorHandlerService: ErrorHandlerService
    ) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            // ignore the main response, we only care if it errors
        }, (err: any) => {
            const httpError = this.errorService.isHttpError(err);


            // we only care if it's an error, and not one of the ingored codes.
            // this esentially swallows all 400s and 401s, assuming they are handled independently.
            if (httpError) {
                if (!API_ERROR_IGNORED_CODES.includes(httpError.status)) {
                    const context = this.errorService.getServerContext(httpError, request);

                    this.loggingService.logError(context);
                    this.errorHandlerService.handleServerError(context);
                }

                // always throw
                // this also normalizes promises and observables... i think.
                throwError(httpError);
            }
        }));
    }
}
