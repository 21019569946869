import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class AuthenticationHttpService {

    constructor(
        private httpClient: HttpClient,
        @Inject(DOCUMENT) private document: any
    ) { }

    performRedirect(path: string): void {
        this.document.location.href = path;
    }

    logout() {
        const url = environment.apiBaseUrl + '/account/logout';

        return this.httpClient.post(url, {});
    }
}
