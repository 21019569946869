import { Injectable } from '@angular/core';
import { InternalFilter, InternalCondition } from './filter-query-helper.service';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import { FilterConditionNameValue } from '../../../models/filters/filter.model';

@Injectable()
export class FilterComparisionService {

    filterExistsInList(list: InternalFilter[], checkingFilter: InternalFilter): boolean {
        return list.some(listFilter => {

            const conditionOperatorEqual = listFilter.condition.operator === checkingFilter.condition.operator;
            const listFilterValues = this.extractComparableValues(listFilter.condition);
            const checkingFilterValues = this.extractComparableValues(checkingFilter.condition);
            const conditionValuesEqual = lodashHelper.isEqual(listFilterValues, checkingFilterValues);

            const currentPath = listFilter.path.map(c => ({
                without: c.without,
                path: c.entityIdKey || c.key
            }));
            const filterPath = checkingFilter.path.map(c => ({
                without: c.without,
                path: c.entityIdKey || c.key
            }));

            const pathEqual = lodashHelper.isEqual(currentPath, filterPath);

            // console.log('filter compare', {
            //     checkingFilter,
            //     listFilter,
            //     conditionOperatorEqual,
            //     conditionValuesEqual,
            //     pathEqual,
            //     checkingFilterValues,
            //     listFilterValues
            // });

            return conditionOperatorEqual && conditionValuesEqual && pathEqual;
        });
    }

    private extractComparableValues(condition: InternalCondition) {
        return condition.values
            .filter(v => v !== null && v !== undefined)
            .map(v => (v as FilterConditionNameValue).value || v)
            .map(v => v.toString());
    }
}
