var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BlockHttpService } from './http/block.http.service';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
var BlockService = /** @class */ (function (_super) {
    __extends(BlockService, _super);
    function BlockService(httpService, apiCacheService, includeMapperService) {
        var _this = _super.call(this, 'planBlock', httpService, apiCacheService, includeMapperService) || this;
        _this.httpService = httpService;
        _this.apiCacheService = apiCacheService;
        _this.includeMapperService = includeMapperService;
        _this.includeOptions = ['plans'];
        return _this;
    }
    BlockService.prototype.onAfterCreate = function (entity) {
        this.apiCacheService.clearCacheByEntityId('plans', entity.planId);
        return;
    };
    BlockService.prototype.onAfterUpdate = function (entity) {
        this.apiCacheService.clearCacheByEntityId('plans', entity.planId);
        this.apiCacheService.clearCacheByType('placements');
        return;
    };
    return BlockService;
}(EntityServiceMetadataBase));
export { BlockService };
