import { NgModule } from '@angular/core';
import { DateRangeBreaksDisplayDynamicComponent } from './components/date-range/date-range-breaks-display-dynamic.component';
import { CommonModule } from '@angular/common';
import { DateRangeBreaksPropertyDisplayComponent } from './components/date-range/date-range-breaks-property-display.component';
import { DateRangeBreaksDisplayComponent } from './components/date-range/date-range-breaks-display.component';
import { ExperienceModule } from '../experience/experience.module';
import { DisplayHelperModule } from '../display-helper/display-helper.module';

@NgModule({
    entryComponents: [
        DateRangeBreaksDisplayDynamicComponent
    ],
    imports: [
        CommonModule,
        DisplayHelperModule,
        ExperienceModule
    ],
    providers: [],
    declarations: [
        DateRangeBreaksPropertyDisplayComponent,
        DateRangeBreaksDisplayComponent,
        DateRangeBreaksDisplayDynamicComponent
    ],
    exports: [
        DateRangeBreaksPropertyDisplayComponent,
        DateRangeBreaksDisplayComponent,
        DateRangeBreaksDisplayDynamicComponent
    ]
})
export class DateRangeDisplayDynamicModule { }
