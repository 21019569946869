import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ProviderService } from './services/provider.service';
import { ProviderHttpService } from './services/http/provider.http.service';
import { ProviderDetailsComponent } from './components/provider/provider-details.component';
import { ProviderFormComponent } from './components/provider/provider-form.component';
import { SiteFormComponent } from './components/site/site-form.component';
import { SiteService } from './services/site.service';
import { SiteHttpService } from './services/http/site.http.service';
import { ContactFormComponent } from './components/contact/contact-form.component';
import { ContactService } from './services/contact.service';
import { ContactHttpService } from './services/http/contact.http.service';
import { PositionService } from './services/position.service';
import { PositionHttpService } from './services/http/position.http.service';
import { PositionFormComponent } from './components/position/position-form.component';
import { PositionDetailsComponent } from './components/position/position-details.component';
import { ProviderPositionListComponent } from './components/provider/provider-position-list.component';
import { PositionResultsComponent } from './components/position/position-results.component';
import { ContactResultsComponent } from './components/contact/contact-results.component';
import { SiteResultsComponent } from './components/site/site-results.component';
import { ProviderResultsComponent } from './components/provider/provider-results.component';
import { ProviderMergeComponent } from './components/provider/provider-merge.component';
import { SiteMergeComponent } from './components/site/site-merge.component';
import { SiteDetailsComponent } from './components/site/site-details.component';
import { PositionMergeComponent } from './components/position/position-merge.component';
import { ContactDetailsComponent } from './components/contact/contact-details.component';
import { ContactMergeComponent } from './components/contact/contact-merge.component';
import { ProviderDetailsMasterComponent } from './components/provider/provider-details-master.component';
import { SiteDetailsMasterComponent } from './components/site/site-details-master.component';
import { ContactDetailsMasterComponent } from './components/contact/contact-details-master.component';
import { PositionDetailsMasterComponent } from './components/position/position-details-master.component';
import { PositionAvailabilityStatusService } from './services/position-availability.service';
import { PositionAvailabilityDropdownComponent } from './components/position-availability/position-availability-dropdown.component';
import { AlertModule } from '../alert/alert.module';

@NgModule({
    imports: [
        SharedModule,
        AlertModule
    ],

    declarations: [

        ProviderDetailsMasterComponent,
        ProviderDetailsComponent,
        ProviderFormComponent,
        ProviderPositionListComponent,
        ProviderResultsComponent,
        ProviderMergeComponent,

        SiteFormComponent,
        SiteResultsComponent,
        SiteDetailsComponent,
        SiteMergeComponent,
        SiteDetailsMasterComponent,

        ContactDetailsMasterComponent,
        ContactFormComponent,
        ContactResultsComponent,
        ContactDetailsComponent,
        ContactMergeComponent,

        PositionFormComponent,
        PositionDetailsComponent,
        PositionResultsComponent,
        PositionMergeComponent,
        PositionDetailsMasterComponent,

        PositionAvailabilityDropdownComponent
    ],
    providers: [
        ProviderService,
        SiteService,
        ContactService,
        PositionService,

        ProviderHttpService,
        SiteHttpService,
        ContactHttpService,
        PositionHttpService,

        PositionAvailabilityStatusService
    ],
    entryComponents: [

    ],

    exports: [
        PositionDetailsMasterComponent,
        PositionDetailsComponent,
        PositionResultsComponent,
        PositionFormComponent,
        PositionMergeComponent,

        ProviderDetailsMasterComponent,
        ProviderDetailsComponent,
        ProviderFormComponent,
        ProviderResultsComponent,
        ProviderMergeComponent,

        SiteFormComponent,
        SiteResultsComponent,
        SiteDetailsComponent,
        SiteMergeComponent,
        SiteDetailsMasterComponent,

        ContactDetailsMasterComponent,
        ContactFormComponent,
        ContactResultsComponent,
        ContactDetailsComponent,
        ContactMergeComponent,

        // ProviderNoteFormComponent,

        ProviderPositionListComponent,

        PositionAvailabilityDropdownComponent
    ],
})
export class ProviderModule { }
