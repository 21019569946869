<div class="border d-flex minutes-summary small text-right" *ngIf="value.absent || value.attended || value.expected" [attr.title]="title">
    <div class="bg-danger-light border-danger text-danger text-truncate" [style.width.%]="absentWidth">
        <span *ngIf="value.absent" class="px-1">{{ value.absent | minutes }}</span>
    </div>
    <div class="bg-success-light boder-success text-success text-truncate" [style.width.%]="attendedWidth">
        <span *ngIf="value.attended" class="px-1">{{ value.attended | minutes }}</span>
    </div>
    <div class="bg-white text-dark text-truncate" [style.width.%]="unaccountedWidth">
        <span *ngIf="value.expected" class="px-1">{{ unaccounted | minutes }}</span>
    </div>
</div>