import { Injectable } from '@angular/core';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import { RequestListOptions } from '../../../models/api/request-list-options.model';
import { FilterValueType, DateIntervalValue } from '../../../models/filters/filter.model';
import * as moment from 'moment';
import { API_DATE_FORMAT } from '../../core-configuration/helpers/moment.helper';
import { AllIncludeOptions } from '../../../models/shared/include.model';

export interface ApiRequestHelperOptions {
    path: (string | number)[];
    options?: RequestListOptions<AllIncludeOptions>;
}

@Injectable()
export class ApiRequestHelperService {

    constructor() { }

    generateUrl(options: ApiRequestHelperOptions): string {
        if (!options.options) { options.options = {}; }

        const initialParts = [
            ...options.path.map(i => i.toString())
        ];

        const initialUrl = lodashHelper.compact(initialParts).join('/');

        let filters = '';

        if (options.options.filters && options.options.filters.length > 0) {
            filters = options.options.filters.map((i, filterIndex) => {
                return `${i.path}[${filterIndex}]=${i.operator}(${i.values.map(v => encodeURIComponent(this.filterValueMap(v))).join(',')})`;
            }).join('&');
        }

        const queryStringParts = [
            options.options.take ? `take=${options.options.take}` : false,
            options.options.skip ? `skip=${options.options.skip}` : false,
            options.options.sort ? `sort=${encodeURIComponent(options.options.sort)}` : false,
            options.options.q ? `q=${encodeURIComponent(options.options.q)}` : false,
            // filters
            filters || false,

            // include
            options.options.include && options.options.include.length > 0 ? `include=${options.options.include.join(',')}` : false
        ];

        const queryString = lodashHelper
            .compact(queryStringParts)
            .join('&');
        const hasQuery = queryString !== '';

        return `${initialUrl}${hasQuery ? '?' : ''}${queryString}`;
    }

    filterValueMap(value: FilterValueType): string {
        if (value === undefined || value === null || value === '') { return ''; }

        if (moment.isMoment(value)) { return value.format(API_DATE_FORMAT); }

        if (value.hasOwnProperty('interval')) {
            const interval = value as unknown as DateIntervalValue;
            return `${interval.number}${interval.interval}`;
        }

        return '"' + value.toString().replace('"', '""') + '"';
    }
}
