import { DisplayHelperService } from '../../services/display-helper.service';
var ContrastTextColorDirective = /** @class */ (function () {
    function ContrastTextColorDirective(service) {
        this.service = service;
    }
    Object.defineProperty(ContrastTextColorDirective.prototype, "background", {
        get: function () {
            return this._background;
        },
        set: function (value) {
            this._background = value;
            this._colorClass = this.service.caculateTextColorClass(this._background);
            this.setClass();
        },
        enumerable: true,
        configurable: true
    });
    ContrastTextColorDirective.prototype.setClass = function () {
        this.colorDark = this._colorClass === 'color-dark';
        this.colorLight = this._colorClass === 'color-light';
    };
    return ContrastTextColorDirective;
}());
export { ContrastTextColorDirective };
