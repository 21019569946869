import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangesResponse } from '../../../../models/changes/changes.model';
import { Observable } from 'rxjs';
import { RequestListOptions } from '../../../../models/api/request-list-options.model';
import { ApiHttpClientServiceBase } from '../../../api/services/api-http-client.service';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { ChangesIncludeOption } from '../../../../models/shared/include.model';
import { Entity, EntityType } from '../../../../models/entity/entity.type';

@Injectable()
export class ChangesHttpService extends ApiHttpClientServiceBase<ChangesIncludeOption> {

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super(httpClient, apiRequestHelperService);
    }

    getChanges<T extends Entity>(type: EntityType, id: number, options: RequestListOptions<ChangesIncludeOption>): Observable<ChangesResponse<T>> {
        const url = super.generateUrl({
            path: ['changes', type.toString(), id.toString()],
            options: options
        });

        return this.httpClient.get<ChangesResponse<T>>(url);
    }
}
