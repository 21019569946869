import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'cp-loader-error',
    template: `
        <cp-error [error]="error"></cp-error>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class LoaderErrorComponent {

    @Input() error: any;

    constructor() { }
}
