import { NgModule } from '@angular/core';
import { EntitySearchBarComponent } from './components/entity-search/entity-search-bar.component';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { CustomCommonModule } from '../common/common.module';
import { FilterModule } from '../filter/filter.module';
import { EntitySharedModule } from '../entity-shared/entity-shared.module';
import { SavedSearchModule } from '../saved-search/saved-search.module';

@NgModule({
    imports: [
        CustomCommonModule.forChild(),
        DisplayHelperModule,
        FilterModule,
        EntitySharedModule,
        SavedSearchModule
    ],
    providers: [
    ],
    declarations: [
        EntitySearchBarComponent
    ],
    exports: [
        EntitySearchBarComponent
    ]
})
export class EntitySearchModule { }
