import { Injectable } from '@angular/core';
import { StudentHttpService } from './http/student.http.service';
import { Observable } from 'rxjs';
import { Student, StudentResult } from '../../../models/student/student.model';
import { ResponseStudentInclude } from '../../../models/api/response.model';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { RequestPaginatedOptions } from '../../../models/api/request-list-options.model';
import { map, tap } from 'rxjs/operators';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { IListService, IIdListService } from '../../entity-shared/base/entity-search.base';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { StudentIncludeOption } from '../../../models/shared/include.model';

@Injectable()
export class StudentService
    extends
    EntityServiceMetadataBase<Student, StudentIncludeOption, ResponseStudentInclude>

    implements IUpdatableService<Student>,
    IListService<Student, StudentIncludeOption, ResponseStudentInclude>,
    IIdListService {

    protected includeOptions: StudentIncludeOption[] = ['studentAddresses', 'plans'];

    constructor(
        protected httpService: StudentHttpService,
        protected apiCacheService: ApiCacheService,
        protected includeMapperService: IncludeMapperService
    ) {
        super('student', httpService, apiCacheService, includeMapperService);
    }

    protected onAfterCreate(entity: Student): void | RequestFilter[] {
        return;
    }
    protected onAfterUpdate(entity: Student): void {
        return;
    }

    search(options: RequestPaginatedOptions): Observable<StudentResult[]> {
        return this.httpService.search(options).pipe(
            map(i => i.data)
        );
    }

    addStudentsToPlan(planId: number, studentIds: number[]) {
        return this.httpService.addToPlan(planId, studentIds).pipe(
            tap(() => {
                this.apiCacheService.clearCacheByType(this.typePlural);
                this.apiCacheService.clearCacheByType('plans');
            })
        );
    }

    removeStudentsFromPlan(planId: number, studentIds: number[]) {
        return this.httpService.removeFromPlan(planId, studentIds).pipe(
            tap(() => {
                this.apiCacheService.clearCacheByType(this.typePlural);
                this.apiCacheService.clearCacheByType('plans');
            })
        );
    }
}
