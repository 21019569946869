import { Injectable } from '@angular/core';
// import { TimelineBreak, TimelineDataItem, TimelinePlacement } from '../../../models/timeline/timeline-item.model';
// import { TimelineCoreService } from './timeline-core.service';
// import { MomentHelper } from '../../core-configuration/helpers/moment.helper';
// import { Placement } from '../../../models/placement/placement.model';
// import { DateRange } from '../../../models/shared/date-range.model';
// import { TimelineTimeBase } from '../../../models/timeline/timeline-time.model';
// import { Experience } from '../../../models/experience/experience.model';
// import { TimelinePlan } from '../../../models/timeline/timeline-plan.model';

@Injectable()
export class TimelinePlacementsService {

    // constructor(
    //     private timelineCoreService: TimelineCoreService,
    //     private momentHelper: MomentHelper
    // ) { }
    
    // createTimelinePlan(
    //     items: Placement[],
    //     experiences: Experience[],
    //     height: number,
    //     time: TimelineTimeBase
    // ):  TimelinePlan {

    //     const rows = this.timelineCoreService.calculateRows(items);
    //     const total = rows.length;

    //     const timelineItems: TimelineDataItem[] = [];

    //     rows.forEach((row, blockRowIndex) => {
    //         row.forEach(block => {

    //             // Experience - FIX
    //             const experience = experiences.find(e => block.experienceId === e.id)!;
    //             const data = this.createData(block, experience, blockRowIndex, time, height);
    //             timelineItems.push(data);
    //         });
    //     });

    //     return {
    //         rows: total,
    //         rowHeight: total * height,
    //         items: timelineItems
    //     };
    // }

    // createData<TDateRange extends DateRange>(
    //     timelineItem: Placement,
    //     timelineItemOverlaps: TDateRange[],
    //     time: TimelineTimeBase
    // ) {
    //     const startsBeforeTimelineDate = timelineItem.start.isBefore(time.start, 'days');

    //     // diffs are used to calculate the index of the week and the left position of the day (by day offset)
    //     // if the day starts before the start of the timeline (weekDayStart) we should use the weekDayStart
    //     // instead of the actual start
    //     // the difference in days and weeks should therefore be 0 (for both)

    //     const startToCalculateFrom = startsBeforeTimelineDate ? time.start : timelineItem.start;
    //     const days = timelineItem.end.diff(startToCalculateFrom, 'days');
    //     const whenActive = this.timelineCoreService.calculateWhenActive(timelineItem);

    //     const timelineBreaks: TimelineBreak[] = this.timelineCoreService.createTimelineBreaks(
    //         timelineItemOverlaps,
    //         time
    //     );

    //     const globalLeft = this.timelineCoreService.calculateGlobalLeft(timelineItem.start, time.start, time.diffDays);
    //     const globalWidth = this.timelineCoreService.calculateGlobalWidth(days, time.diffDays);

    //     const cssClasses = this.timelineCoreService.timelineCssClasses(whenActive);

    //     const data: TimelineDataItem<TimelinePlacement> = {

    //         // consider if i need to reference the entire block, maybe just the id?
    //         item: { placement: timelineItem },

    //         breaks: timelineBreaks,

    //         top: 0,

    //         height: 25,

    //         width: globalWidth,

    //         // set properties for when the block was active, ie - is it in the past, future, or is it active now?
    //         whenActive: whenActive,

    //         cssClasses: cssClasses,

    //         left: globalLeft
    //     };

    //     if (data.whenActive === 'now') {
    //         const activeDays = this.momentHelper.today().add(-1, 'days').diff(startToCalculateFrom, 'days');
    //         data.activeDaysWidth = this.timelineCoreService.calculateGlobalWidth(activeDays, time.diffDays);
    //     }

    //     return data;
    // }
}
