import { NgModule, ModuleWithProviders } from '@angular/core';
import { BreadcrumbBuilderService } from './services/breadcrumb-builder.service';
import { BreadcrumbService } from './services/breadcrumb.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ReportModule } from '../report/report.module';
import { CustomCommonModule } from '../common/common.module';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { StudentModule } from '../student/student.module';
import { PlanModule } from '../plan/plan.module';
import { ProviderModule } from '../provider/provider.module';
import { PlacementModule } from '../placement/placement.module';


@NgModule({
    imports: [
        CustomCommonModule.forChild(),
        DisplayHelperModule,

        // these are all the modules (with services that the breadcrumb use...)
        ReportModule,
        StudentModule,
        ProviderModule,
        PlanModule,
        PlacementModule
    ],
    providers: [],
    declarations: [
        BreadcrumbComponent
    ],
    exports: [
        BreadcrumbComponent
    ],
})
export class BreadcrumbModule {

    constructor() {}

    static forRoot(): ModuleWithProviders {

        return {
            ngModule: BreadcrumbModule,
            providers: [
                BreadcrumbBuilderService,
                BreadcrumbService
            ]
        };
    }

    static forChild(): ModuleWithProviders {
        return {
            ngModule: BreadcrumbModule
        };
    }
}
