/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../display-helper/pipes/translate-or.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./filter-path-display.component";
var styles_FilterPathDisplayComponent = [];
var RenderType_FilterPathDisplayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilterPathDisplayComponent, data: {} });
export { RenderType_FilterPathDisplayComponent as RenderType_FilterPathDisplayComponent };
function View_FilterPathDisplayComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "code", [["class", "pl-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpad(2, 1), i0.ɵppd(3, 2)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.property.name, _ck(_v, 2, 0, "api"))); _ck(_v, 1, 0, currVal_0); }); }
function View_FilterPathDisplayComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "code", [["class", "pl-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpad(2, 1), i0.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isIdKey(_v.parent.context.$implicit.key) ? "Specific Record" : i0.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.property.name, _ck(_v, 2, 0, "api")))); _ck(_v, 1, 0, currVal_0); }); }
function View_FilterPathDisplayComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "code", [["class", "pl-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpad(2, 1), i0.ɵppd(3, 2)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.property.name, _ck(_v, 2, 0, "api"))); _ck(_v, 1, 0, currVal_0); }); }
function View_FilterPathDisplayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], [[2, "pl-1", null]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterPathDisplayComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterPathDisplayComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterPathDisplayComponent_4)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_v.context.last; _ck(_v, 4, 0, currVal_2); var currVal_3 = (_v.context.last && !_co.isEntityType(_v.context.$implicit)); _ck(_v, 6, 0, currVal_3); var currVal_4 = (_v.context.last && _co.isEntityType(_v.context.$implicit)); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = !_v.context.first; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit.without ? "without" : "with"); _ck(_v, 2, 0, currVal_1); }); }
export function View_FilterPathDisplayComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.TranslateOrPipe, [i3.TranslateService]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterPathDisplayComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filterPropertyPath; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FilterPathDisplayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-filter-path-display", [], null, null, null, View_FilterPathDisplayComponent_0, RenderType_FilterPathDisplayComponent)), i0.ɵdid(1, 180224, null, 0, i4.FilterPathDisplayComponent, [], null, null)], null, null); }
var FilterPathDisplayComponentNgFactory = i0.ɵccf("cp-filter-path-display", i4.FilterPathDisplayComponent, View_FilterPathDisplayComponent_Host_0, { filterPropertyPath: "filterPropertyPath" }, {}, []);
export { FilterPathDisplayComponentNgFactory as FilterPathDisplayComponentNgFactory };
