import { PROPERTY_METADATA_DECORATOR_KEY, CLASS_METADATA_DECORATOR_KEY } from '../../decorators/metadata.decorator';
import { PropertiesMetadata, ClassMetadata } from '../../../../models/shared/metadata.model';
import { MetadataEntity } from '../../../../models/entity/entity.type';

export class MetadataService<T extends MetadataEntity> {

    protected _propertyMetadata: PropertiesMetadata;
    protected _classMetadata: ClassMetadata<T>;

    get propertyMetadata(): PropertiesMetadata {
        return this._propertyMetadata;
    }

    get classMetadata(): ClassMetadata<T> {
        return this._classMetadata;
    }

    constructor(
        x: new() => T
    ) {
        this._propertyMetadata = Reflect.getMetadata(PROPERTY_METADATA_DECORATOR_KEY, x);
        this._classMetadata = Reflect.getOwnMetadata(CLASS_METADATA_DECORATOR_KEY, x);

        if (!this._classMetadata) {
            throw new Error('class metadata is required for list descriptor service: ' + x.name);
        }

        let proto = Reflect.getPrototypeOf(x);
        let childProperties = Reflect.getMetadata(PROPERTY_METADATA_DECORATOR_KEY, proto);
        do {
            this._propertyMetadata = Object.assign({}, childProperties, this._propertyMetadata);

            proto = Reflect.getPrototypeOf(proto);
            childProperties = Reflect.getMetadata(PROPERTY_METADATA_DECORATOR_KEY, proto);
        } while (childProperties !== undefined);

        // console.log('metadata', x.name, this._classMetadata, this._propertyMetadata);
    }
}
