import { Injectable } from '@angular/core';
import { MetadataService } from './metadata-base.service';
import { Student } from '../../../../models/student/student.model';
import { Plan } from '../../../../models/plan/plan.model';
import { Timesheet } from '../../../../models/placement/timesheet.model';
import { Position } from '../../../../models/provider/position.model';
import { Contact } from '../../../../models/provider/contact.model';
import { Block } from '../../../../models/plan/block.model';
import { Site } from '../../../../models/provider/site.model';
import { Provider } from '../../../../models/provider/provider.model';
import { Placement } from '../../../../models/placement/placement.model';
import { StudentAddress } from '../../../../models/student/student-address.model';
import { Alert } from '../../../../models/alert/alert.model';
import { Experience } from '../../../../models/experience/experience.model';
import { AbsenceType } from '../../../../models/absence-type/absence-type.model';
import { ExtendedProperty } from '../../../../models/extended-properties/extended-property.model';
import { Mapping } from '../../../../models/placement/mapping.model';
import { SavedSearch } from '../../../../models/saved-search/saved-search.model';
import { Report } from '../../../../models/report/report.model';
import { ProviderSummary, PlacementSummary } from '../../../../models/summary/summary.model';
import { DeletedEntity } from '../../../../models/deleted-entity/deleted-entity.model';
import { EntityLog } from '../../../../models/shared/logged.model';
import { PropertyOptionSet } from '../../../../models/extended-properties/option-set.model';
import { StudentCourse } from '../../../../models/student/student-course.model';
import { Role } from '../../../../models/user/role.model';
import { Admin } from '../../../../models/admin/admin.model';
import { MetadataEntity, MetadataType } from '../../../../models/entity/entity.type';

@Injectable()
export class EntityMetadataService {

    // Core Entities
    private student             = new MetadataService<Student>(Student);
    private plan                = new MetadataService<Plan>(Plan);
    private placement           = new MetadataService<Placement>(Placement);
    private provider            = new MetadataService<Provider>(Provider);
    private position            = new MetadataService<Position>(Position);
    private contact             = new MetadataService<Contact>(Contact);
    private planBlock           = new MetadataService<Block>(Block);
    private site                = new MetadataService<Site>(Site);
    private studentAddress      = new MetadataService<StudentAddress>(StudentAddress);
    private studentCourse       = new MetadataService<StudentCourse>(StudentCourse);
    private alert               = new MetadataService<Alert>(Alert);
    private experience          = new MetadataService<Experience>(Experience);
    private absenceType         = new MetadataService<AbsenceType>(AbsenceType);
    private extendedProperty    = new MetadataService<ExtendedProperty>(ExtendedProperty);
    private mapping             = new MetadataService<Mapping>(Mapping);
    private savedSearch         = new MetadataService<SavedSearch>(SavedSearch);
    private report              = new MetadataService<Report>(Report);
    private deletedEntity       = new MetadataService<DeletedEntity>(DeletedEntity);
    private entity              = new MetadataService<EntityLog>(EntityLog);
    private propertyOptionSet   = new MetadataService<PropertyOptionSet>(PropertyOptionSet);
    private role                = new MetadataService<Role>(Role);
    private admin               = new MetadataService<Admin>(Admin);

    // Core Non Entities
    private timesheet           = new MetadataService<Timesheet>(Timesheet);

    // Summaries
    private providerSummary     = new MetadataService<ProviderSummary>(ProviderSummary);
    private placementSummary    = new MetadataService<PlacementSummary>(PlacementSummary);

    constructor() { }

    get<TEntity extends MetadataEntity>(type: MetadataType): MetadataService<TEntity> {
        if (!this[type]) { throw new Error('Metadata service not implemented for entity type: ' + type); }

        return this[type] as any as MetadataService<TEntity>;
    }

    createEntity<TEntity extends MetadataEntity>(entityType: MetadataType): TEntity {
        const meta = this.get<TEntity>(entityType);
        return meta.classMetadata.create();
    }
}
