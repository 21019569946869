import { Injectable } from '@angular/core';
import { PlacementHttpService } from './http/placement.http.service';
import { Placement, placementOverlapsPredicate } from '../../../models/placement/placement.model';
import { Observable } from 'rxjs';
import { ResponseModelBulk, ResponsePlacementInclude } from '../../../models/api/response.model';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { Plan } from '../../../models/plan/plan.model';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { Block } from '../../../models/plan/block.model';
import { Experience } from '../../../models/experience/experience.model';
import { RequestListOptions } from '../../../models/api/request-list-options.model';
import { map } from 'rxjs/operators';
import { PlacementIncludeOption } from '../../../models/shared/include.model';

export interface PlanPlacementList {
    plan?: Plan;
    placements: Placement[];
}

export interface ExperiencePlacementList {
    experience: Experience;
    placements: Placement[];
    expected: number;
    attended: number;
    absent: number;
    unaccounted: number;
}

export interface PlanExperiencePlacementList {
    plan?: Plan;
    experiencePlacements: ExperiencePlacementList[];
}

@Injectable()
export class PlacementService
    extends EntityServiceMetadataBase<Placement, PlacementIncludeOption, ResponsePlacementInclude>
    implements IUpdatableService<Placement> {

    // todo: consider, maybe move all this to list logic
    protected includeOptions: PlacementIncludeOption[] = ['experiences', 'placementOverlaps'];

    constructor(
        protected httpService: PlacementHttpService,
        protected apiCacheService: ApiCacheService,
        protected includeMapperService: IncludeMapperService
    ) {
        super('placement', httpService, apiCacheService, includeMapperService);
    }

    protected onAfterCreate(entity: Placement): void | RequestFilter[] {
        return;
    }
    protected onAfterUpdate(entity: Placement): void {
        // this is necessary because you're able to change the position Id, which then needs the entity to clear
        // this.apiCacheService.clearCacheByEntityId('placements', entity.id);
        // the above used to be true, but it aint' anymore
        // any update to any placement could effect the placement overlap of any other placement, there is no way to know...
        this.apiCacheService.clearCacheByType('placements');
        return;
    }

    placementListIncludeGetAll(
        requestOptions: RequestListOptions<PlacementIncludeOption>,
        placementOptions: { mapOverlapsWithResults: boolean } = { mapOverlapsWithResults: false }
    ): Observable<Placement[]> {
        return super.listIncludeGetAll(requestOptions).pipe(
            map(result => {

                if (placementOptions.mapOverlapsWithResults) {
                    result.forEach(placementMaster => {
                        if (!placementMaster.includeMany) {
                            placementMaster.includeMany = {};
                        }

                        // I really don't like how I've done this include system, it's so bloatful
                        // this is particularly bad tho, referencing this method directly and handling this logic here
                        placementMaster.includeMany.placementOverlaps = placementOverlapsPredicate(placementMaster, result);
                    });
                }
                return result;
            })
        );
    }

    createPlacementFromBlock(block: Block): Placement {
        // todo, this needs to be better, have to cater for required properties on placements
        // all properties for that matter
        const newPlacement = new Placement();
        newPlacement.start = block.start;
        newPlacement.end = block.end;
        newPlacement.experienceId = block.experienceId;
        newPlacement.planBlockId = block.id;
        newPlacement.minutes = block.minutes;

        return newPlacement;
    }

    bulk(placements: Placement[]): Observable<ResponseModelBulk<Placement>> {
        return this.httpService.bulk(placements);
    }

    groupByPlan(placements: Placement[]): PlanPlacementList[] {

        const noPlanPlacements = placements.filter(i => i.planBlockId === undefined);
        const planPlacements = placements.filter(i => i.planBlockId !== undefined);

        const groupedObject = lodashHelper.groupBy(planPlacements, item => (item.include!.plan! as Plan).id);

        const list: PlanPlacementList[] = Object.keys(groupedObject)
            .map(key => {
                const value = groupedObject[key];

                return {
                    plan: value[0].include!.plan! as Plan,
                    placements: value
                };
            });

        if (noPlanPlacements.length > 0) {
            list.push({
                placements: noPlanPlacements
            });
        }

        return list;
    }

    createMinutesSummaries(placements: Placement[]): ExperiencePlacementList[] {

        const flattened = lodashHelper.flatten(placements.map(p => p.include!.experience));
        const experiences = lodashHelper.uniqBy(flattened, (e: Experience) => e.id) as Experience[];
        const grouped = lodashHelper.groupBy(placements, item => (item.include!.experience as Experience).id);

        const toReturn: ExperiencePlacementList[] = Object.keys(grouped).map(key => {
            const experienceId = +key;
            const experience = experiences.find(e => e.id === experienceId)!;
            const list = grouped[key];
            const expected = list
                .map(i => i.minutes || 0)
                .reduce((a, b) => a + b, 0);

            const attended = list
                .map(i => i.include!.placementSummary!.timesheetMinutesAttended as number)
                .reduce((a, b) => a + b, 0);
            const absent = list
                .map(i => i.include!.placementSummary!.timesheetMinutesAbsent as number)
                .reduce((a, b) => a + b, 0);

            const timesheetTotal = attended + absent;
            const unaccounted = timesheetTotal > expected ? 0 : expected - timesheetTotal;

            return {
                experience: experience,
                placements: list,
                expected: expected,
                attended: attended,
                absent: absent,
                unaccounted: unaccounted
            };
        });

        return toReturn;
    }

    createMinutesSummariesByPlan(placements: Placement[]): PlanExperiencePlacementList[] {
        const groupByPlan = this.groupByPlan(placements);

        return groupByPlan.map(i => ({
            plan: i.plan,
            experiencePlacements: this.createMinutesSummaries(i.placements)
        }));
    }
}
