import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map, debounceTime } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ResponseSortService } from '../../common/services/response-sort-service';
import { ResponseSort } from '../../../models/api/response.model';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import { MetadataType } from '../../../models/entity/entity.type';

export interface SortEvent {
    prefix: string;
    pathArray: ResponseSort[];
}

export interface SortResponseEvent extends SortEvent {
    isFromHttpResponse?: boolean;
    isFromHttpType?: MetadataType;
}

@Injectable()
export class EntitySearchSortService {

    private sorts: SortResponseEvent[] = [];
    private sortSource = new BehaviorSubject<SortResponseEvent[]>(this.sorts);
    sort$ = this.sortSource.asObservable();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private responseSortService: ResponseSortService
    ) {
        this.route.queryParams.pipe(
            debounceTime(100), // testing
            map(params => {
                const newSorts = this.handleParams(params);

                if (!lodashHelper.isEqual(newSorts, this.sorts)) {
                    this.sorts = newSorts;
                    this.sortSource.next(this.sorts);
                }
            })
        ).subscribe();

        this.responseSortService.sort$.pipe(
            map(responseSort => {
                if (!responseSort) { return; }

                // this is making some assumptions.
                // basically I can't tell how many lists are active on the page that is
                // using this service, at this point. So the "prefix" is unknown.
                // therefore I just see if the search already exists in the known sorts
                // and only if it doesn't exist do I trigger an update.
                if (this.sorts.map(s => s.pathArray).some(s => responseSort.path.map(r => r.path).some(r => lodashHelper.isEqual(s, r)))) {
                    return;
                }
                this.sorts = [{
                    isFromHttpType: responseSort.type,
                    isFromHttpResponse: true,
                    pathArray: responseSort.path.map(i => ({ path: i.path, descending: i.descending})),
                    prefix: ''
                }];
                this.sortSource.next(this.sorts);
            })
        ).subscribe();
    }

    triggerSort(sort: SortEvent) {
        const key = sort.prefix ? `${sort.prefix}~sort` : 'sort';
        this.router.navigate([], {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
            queryParams: {
                [key]: sort.pathArray.length > 0 ? this.pathToString(sort.pathArray) : undefined
            }
        });
    }

    pathToString(path: ResponseSort[]): string {
        return path.map(i => `${i.descending ? '-' : ''}${i.path}`).join(',');
    }

    getDefault(type: MetadataType): ResponseSort[] | undefined {
        const responseSort = this.responseSortService.getDefault(type);
        if (!responseSort) {
            return undefined;
        }

        return responseSort.map(i => ({ path: i.path, descending: i.descending }));
    }

    setDefault(type: MetadataType, sortString: string | undefined): void {
        if (!sortString) { return; }
        const array = sortString.split(',');

        const sorts: ResponseSort[] = array.map(str => {
            const desc = str.startsWith('-');
            const sort: ResponseSort = {
                descending: desc,
                path: desc ? str.slice(1) : str
            };
            return sort;
        });

        this.responseSortService.setDefault(type, sorts);
    }

    private handleParams(params: Params): SortEvent[] {
        return Object.keys(params)
            .filter(key => key.endsWith('sort'))
            .map(key => {
                if (key === 'sort') {
                    return {
                        prefix: '',
                        pathArray: this.extractPathValues(params[key])
                    };
                }
                // has to be a prefix sort if it gets to here
                return {
                    prefix: key.slice(0, key.indexOf('~')),
                    pathArray: this.extractPathValues(params[key])
                };
        });
    }

    private extractPathValues(value: string): ResponseSort[] {
        return value.split(',').map(val => {
            const isDesc = val[0] === '-';
            return {
                descending: isDesc,
                path: isDesc ? val.slice(1) : val
            };
        });
    }
}
