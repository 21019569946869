var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { BaseSubscriber } from '../../../common/observables/base-subscriber';
import { takeUntil, map } from 'rxjs/operators';
var EMPTY_NAME = {
    id: -1,
    name: '' // don't use a name here, attended absent type should just be empty
};
var ConfigurationEntityListItemBase = /** @class */ (function (_super) {
    __extends(ConfigurationEntityListItemBase, _super);
    function ConfigurationEntityListItemBase(entityType, configService, changeDetectionRef) {
        var _this = _super.call(this) || this;
        _this.entityType = entityType;
        _this.configService = configService;
        _this.changeDetectionRef = changeDetectionRef;
        _this.dynamicValue = [];
        _this.items = [];
        _this.loaded = false;
        _this.configItems = [];
        return _this;
    }
    ConfigurationEntityListItemBase.prototype.ngOnInit = function () {
        var _this = this;
        var obs = this.getConfigObs();
        obs.pipe(map(function (all) {
            _this.configItems = all;
            _this.loaded = true;
            _this.handleItems();
        }), takeUntil(this.ngUnsubscribe)).subscribe();
    };
    ConfigurationEntityListItemBase.prototype.ngOnChanges = function (changes) {
        if (changes.item) {
            this.handleItems();
        }
    };
    ConfigurationEntityListItemBase.prototype.handleItems = function () {
        var _this = this;
        if (!this.loaded) {
            return;
        }
        if (!this.dynamicValue === undefined) {
            this.items = [];
            this.changeDetectionRef.markForCheck();
            return;
        }
        this.dynamicValue = this.dynamicValue instanceof Array ? this.dynamicValue : [this.dynamicValue];
        this.items = this.dynamicValue.map(function (i) {
            return _this.configItems.find(function (c) { return c.id === i; }) || EMPTY_NAME;
        });
        this.changeDetectionRef.markForCheck();
    };
    ConfigurationEntityListItemBase.prototype.getConfigObs = function () {
        switch (this.entityType) {
            case 'absenceType': return this.configService.getAbsentTypes();
            case 'experience': return this.configService.getExperiences();
            case 'role': return this.configService.getRoles();
            default: throw new Error('entityType not configured for dynamic list generation: ' + this.entityType);
        }
    };
    return ConfigurationEntityListItemBase;
}(BaseSubscriber));
export { ConfigurationEntityListItemBase };
