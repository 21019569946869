/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../json-to-property/json-to-property-display.component.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../json-to-property/json-to-property-display.component";
import * as i4 from "../bootstrap-alert/bootstrap-alert.component.ngfactory";
import * as i5 from "../bootstrap-alert/bootstrap-alert.component";
import * as i6 from "@angular/common";
import * as i7 from "./error.component";
var styles_ErrorComponent = [];
var RenderType_ErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
function View_ErrorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "mb-2"]], [[2, "mb-0", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "p", [["class", "mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "a", [["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showMore = !_co.showMore) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "cp-json-to-property-display", [["class", "bg-white p-2 mt-2"]], [[2, "d-block", null], [2, "collapse", null], [2, "show", null]], null, null, i1.View_JsonToPropertyDisplayComponent_0, i1.RenderType_JsonToPropertyDisplayComponent)), i0.ɵdid(6, 16384, null, 0, i2.NgbCollapse, [], { collapsed: [0, "collapsed"] }, null), i0.ɵdid(7, 49152, null, 0, i3.JsonToPropertyDisplayComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = !_co.showMore; _ck(_v, 6, 0, currVal_6); var currVal_7 = _v.context.$implicit; _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.last; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.message; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.showMore ? "Hide" : "Show more"); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.showMore; var currVal_4 = true; var currVal_5 = !i0.ɵnov(_v, 6).collapsed; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5); }); }
function View_ErrorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "cp-bootstrap-alert", [["class", "d-block my-2"], ["type", "danger"]], null, null, null, i4.View_BootstrapAlertComponent_0, i4.RenderType_BootstrapAlertComponent)), i0.ɵdid(1, 49152, null, 0, i5.BootstrapAlertComponent, [], { type: [0, "type"], small: [1, "small"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ErrorComponent_2)), i0.ɵdid(3, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "danger"; var currVal_1 = _co.small; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.array; _ck(_v, 3, 0, currVal_2); }, null); }
function View_ErrorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "cp-bootstrap-alert", [["class", "d-block my-2"], ["type", "danger"]], null, null, null, i4.View_BootstrapAlertComponent_0, i4.RenderType_BootstrapAlertComponent)), i0.ɵdid(1, 49152, null, 0, i5.BootstrapAlertComponent, [], { type: [0, "type"], small: [1, "small"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "p", [["class", "mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["An Unexpected Error Occurred"])), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "cp-json-to-property-display", [], null, null, null, i1.View_JsonToPropertyDisplayComponent_0, i1.RenderType_JsonToPropertyDisplayComponent)), i0.ɵdid(5, 49152, null, 0, i3.JsonToPropertyDisplayComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "danger"; var currVal_1 = _co.small; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.error; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ErrorComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_3)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.array.length > 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showRaw; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i0.ɵdid(1, 573440, null, 0, i7.ErrorComponent, [], null, null)], null, null); }
var ErrorComponentNgFactory = i0.ɵccf("cp-error", i7.ErrorComponent, View_ErrorComponent_Host_0, { error: "error", small: "small" }, {}, []);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
