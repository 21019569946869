/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../display-helper/components/date-range/date-range-display.component.ngfactory";
import * as i2 from "../../../display-helper/components/date-range/date-range-display.component";
import * as i3 from "../../../core-configuration/helpers/moment.helper";
import * as i4 from "../../../experience/components/experience-property/experience-property-display-list.component.ngfactory";
import * as i5 from "../../../experience/components/experience-property/experience-property-display-list.component";
import * as i6 from "../../../core-configuration/services/configuration.service";
import * as i7 from "@angular/common";
import * as i8 from "./date-range-breaks-display.component";
var styles_DateRangeBreaksDisplayComponent = [];
var RenderType_DateRangeBreaksDisplayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DateRangeBreaksDisplayComponent, data: {} });
export { RenderType_DateRangeBreaksDisplayComponent as RenderType_DateRangeBreaksDisplayComponent };
function View_DateRangeBreaksDisplayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "cp-date-range-display", [["class", "small"], ["property", ""]], null, null, null, i1.View_DateRangeDisplayComponent_0, i1.RenderType_DateRangeDisplayComponent)), i0.ɵdid(2, 638976, null, 0, i2.DateRangeDisplayComponent, [i3.MomentHelper], { start: [0, "start"], end: [1, "end"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "cp-experience-property-display-list", [], null, null, null, i4.View_ExperiencePropertyDisplayListComponent_0, i4.RenderType_ExperiencePropertyDisplayListComponent)), i0.ɵdid(4, 245760, null, 0, i5.ExperiencePropertyDisplayListComponent, [i6.ConfigurationService, i0.ChangeDetectorRef], { experienceIds: [0, "experienceIds"], cssClass: [1, "cssClass"] }, null), i0.ɵpad(5, 1)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.start; var currVal_1 = _v.context.$implicit.end; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, _v.context.$implicit.experienceId); var currVal_3 = "ml-2"; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
export function View_DateRangeBreaksDisplayComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-date-range-display", [], null, null, null, i1.View_DateRangeDisplayComponent_0, i1.RenderType_DateRangeDisplayComponent)), i0.ɵdid(1, 638976, null, 0, i2.DateRangeDisplayComponent, [i3.MomentHelper], { start: [0, "start"], end: [1, "end"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DateRangeBreaksDisplayComponent_1)), i0.ɵdid(3, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.start; var currVal_1 = _co.end; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.ranges; _ck(_v, 3, 0, currVal_2); }, null); }
export function View_DateRangeBreaksDisplayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-date-range-breaks-display", [], null, null, null, View_DateRangeBreaksDisplayComponent_0, RenderType_DateRangeBreaksDisplayComponent)), i0.ɵdid(1, 573440, null, 0, i8.DateRangeBreaksDisplayComponent, [i3.MomentHelper], null, null)], null, null); }
var DateRangeBreaksDisplayComponentNgFactory = i0.ɵccf("cp-date-range-breaks-display", i8.DateRangeBreaksDisplayComponent, View_DateRangeBreaksDisplayComponent_Host_0, { start: "start", end: "end", overlaps: "overlaps", removeMarginBottom: "removeMarginBottom" }, {}, []);
export { DateRangeBreaksDisplayComponentNgFactory as DateRangeBreaksDisplayComponentNgFactory };
