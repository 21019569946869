import { EntityBase } from '../entity/entity.model';
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { ArchiveDisplayDynamicComponent } from '../../modules/display-helper/components/archive/archive-display-dynamic.component';
import { ExtendedPropertyType } from '../shared/types.model';

export interface OptionSetOption {
    id: number;
    name: string;
    archived: boolean;
}

@CpClass({
    create: newPropertyOptionSet,
    metadataType: 'propertyOptionSet'
})
export class PropertyOptionSet extends EntityBase {
    @CpProperty({ type: 'string' })
    name: string;

    @CpProperty({ type: 'boolean', dynamicComponent: ArchiveDisplayDynamicComponent })
    archived: boolean;

    @CpProperty({ type: 'string', showInDetails: true })
    type: ExtendedPropertyType;

    @CpProperty({ type: 'dynamic' })
    options: OptionSetOption[];
}

export function newPropertyOptionSet() { return new PropertyOptionSet(); }
