/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../display-helper/directives/contrast-text-color/contrast-text-color.directive";
import * as i2 from "../../../display-helper/services/display-helper.service";
import * as i3 from "@angular/common";
import * as i4 from "../../../display-helper/directives/loader/loader.directive";
import * as i5 from "./experience-entity-list-item.component";
import * as i6 from "../../../core-configuration/services/configuration.service";
var styles_ExperienceEntityListItemComponent = [];
var RenderType_ExperienceEntityListItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ExperienceEntityListItemComponent, data: {} });
export { RenderType_ExperienceEntityListItemComponent as RenderType_ExperienceEntityListItemComponent };
function View_ExperienceEntityListItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], [[2, "badge", null], [4, "background-color", null], [2, "color-light", null], [2, "color-dark", null]], null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i1.ContrastTextColorDirective, [i2.DisplayHelperService], { background: [0, "background"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_4 = _v.context.$implicit["color"]; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit["color"]; var currVal_1 = _v.context.$implicit["color"]; var currVal_2 = i0.ɵnov(_v, 2).colorLight; var currVal_3 = i0.ɵnov(_v, 2).colorDark; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_5); }); }
function View_ExperienceEntityListItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExperienceEntityListItemComponent_2)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ExperienceEntityListItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExperienceEntityListItemComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.LoaderDirective, [i0.TemplateRef, i0.ViewContainerRef, i0.ComponentFactoryResolver], { cpLoadingSmall: [0, "cpLoadingSmall"], cpLoading: [1, "cpLoading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = !_co.loaded; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ExperienceEntityListItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-experience-entity-list-item", [], null, null, null, View_ExperienceEntityListItemComponent_0, RenderType_ExperienceEntityListItemComponent)), i0.ɵdid(1, 770048, null, 0, i5.ExperienceEntityListItemComponent, [i6.ConfigurationService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExperienceEntityListItemComponentNgFactory = i0.ɵccf("cp-experience-entity-list-item", i5.ExperienceEntityListItemComponent, View_ExperienceEntityListItemComponent_Host_0, { dynamicValue: "dynamicValue" }, {}, []);
export { ExperienceEntityListItemComponentNgFactory as ExperienceEntityListItemComponentNgFactory };
