import { Component, Input } from '@angular/core';
import { IEntityListItemDynamicComponent } from '../../../entity-shared/components/entity-list/entity-list-item-dynamic.component';
import { RoleClaim } from '../../../../models/user/role.model';

@Component({
    selector: 'cp-claim-display-dynamic',
    template: `<cp-claim-display [claims]="dynamicValue"></cp-claim-display>`
})

export class ClaimDisplayDynamicComponent implements IEntityListItemDynamicComponent {
    @Input() dynamicValue: RoleClaim[];
}
