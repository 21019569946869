import { NgModule } from '@angular/core';
import { InputComponent } from './components/input/input.component';
import { DatepickerComponent, DatepickerDirective } from './components/datepicker/datepicker.component';
import { SelectComponent } from './components/select/select.component';
import { InputValidationComponent } from './components/input-validation/input-validation.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { SelectMultipleComponent } from './components/select-multiple/select-multiple.component';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import { TypeaheadSearchComponent } from './components/typeahead/typeahead-search.component';
import { InputMultipleComponent } from './components/input-multiple/input-multiple.component';
import { IntervalComponent } from './components/interval/interval.component';
import { SelectOptionComponent } from './components/select-option/select-option.component';
import { SelectOptionMultipleComponent } from './components/select-option/select-option-mutliple.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { DateRangeMultipleComponent } from './components/date-range/date-range-multiple.component';
import { ExtendedPropertiesFormComponent } from './components/extended-properties/extended-properties.component';
import { FormElementDefaultValidationDirective, InputMultipleChildDirective } from './directives/form-element.default-validation.directive';
import { FormElementFocusOnCreationDirective } from './directives/form-element.focus-on-creation.directive';
import { FormMarkAsTouchedDirective } from './directives/form.mark-as-touched.directive';
import { ArrayValidatorDirective } from './directives/array-validator.directive';
import { FormButtonsComponent } from './components/form/form-buttons.component';
import { NgbDatepickerHelper } from './components/datepicker/datepicker.helper';
import { NgbDateParserFormatter, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentLocaleParserFormatter } from './components/datepicker/moment-locale.ngb-date-parser-formatter';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { MomentModule } from 'angular2-moment';
import { CoreConfigurationModule } from '../core-configuration/core-configuration.module';
import { DateRangeValidatorDirective } from './directives/date-range-validator.directive';
import { DatepickerMultipleComponent } from './components/datepicker/datepicker-multiple.component';
import { DatepickerMultiplePreselectedComponent } from './components/datepicker/datepicker-multiple-preselected.component';
import { DatepickerMultipleTimesheetsComponent } from './components/datepicker/datepicker-multiple-timesheets.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { FormContainerComponent } from './components/form/form-container.component';
import { DatepickerRangeComponent } from './components/datepicker/datepicker-range.component';
import { DatepickerRangeMultipleComponent } from './components/datepicker/datepicker-range-multiple.component';
import { DatepickerRangeTimesheetsComponent } from './components/datepicker/datepicker-range-timesheets.component';
import { IntervalStringComponent } from './components/interval/interval-string.component';
import { TextareaMultipleComponent } from './components/textarea/textarea-multiple.component';
import { TypeaheadCoreComponent } from './components/typeahead/typeahead-core.component';
import { DatepickerFormService } from './services/datepicker-form.service';
import { DatePickerRangeStringComponent } from './components/datepicker/datepicker-range-string.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CoreConfigurationModule,

        DisplayHelperModule,

        NgbModule,
        NgbDatepickerModule,
        ColorPickerModule,

        ClickOutsideModule,

        MomentModule
    ],

    providers: [
        DatepickerFormService,
        NgbDatepickerHelper,
        { provide: NgbDateParserFormatter, useClass: MomentLocaleParserFormatter }
    ],

    declarations: [
        FormContainerComponent,

        InputComponent,
        DatepickerComponent,
        DatepickerRangeComponent,
        DatePickerRangeStringComponent,
        DatepickerRangeMultipleComponent,
        DatepickerRangeTimesheetsComponent,
        SelectComponent,
        InputValidationComponent,
        TextareaComponent,
        TextareaMultipleComponent,
        ToggleComponent,
        SelectMultipleComponent,
        TypeaheadComponent,
        TypeaheadSearchComponent,
        TypeaheadCoreComponent,

        InputMultipleComponent,
        IntervalComponent,
        IntervalStringComponent,
        SelectOptionComponent,
        SelectOptionMultipleComponent,
        DateRangeComponent,
        DateRangeMultipleComponent,
        DatepickerMultipleComponent,
        DatepickerMultiplePreselectedComponent,
        DatepickerMultipleTimesheetsComponent,
        ColorPickerComponent,

        ExtendedPropertiesFormComponent,

        FormElementDefaultValidationDirective,
        FormElementFocusOnCreationDirective,
        FormMarkAsTouchedDirective,
        ArrayValidatorDirective,
        DateRangeValidatorDirective,
        InputMultipleChildDirective,
        DatepickerDirective,

        FormButtonsComponent,
    ],

    exports: [
        // Module
        FormsModule,
        MomentModule,
        NgbModule,

        // components
        FormContainerComponent,

        InputComponent,
        DatepickerComponent,
        DatepickerRangeComponent,
        DatePickerRangeStringComponent,
        DatepickerRangeMultipleComponent,
        DatepickerRangeTimesheetsComponent,
        SelectComponent,
        InputValidationComponent,
        TextareaComponent,
        TextareaMultipleComponent,
        ToggleComponent,
        SelectMultipleComponent,
        TypeaheadComponent,
        TypeaheadSearchComponent,
        TypeaheadCoreComponent,
        InputMultipleComponent,
        IntervalComponent,
        SelectOptionComponent,
        SelectOptionMultipleComponent,
        DateRangeComponent,
        DateRangeMultipleComponent,
        DatepickerMultipleComponent,
        DatepickerMultiplePreselectedComponent,
        DatepickerMultipleTimesheetsComponent,
        ColorPickerComponent,

        ExtendedPropertiesFormComponent,

        FormButtonsComponent,

        // directives
        FormElementDefaultValidationDirective,
        FormElementFocusOnCreationDirective,
        FormMarkAsTouchedDirective,
        ArrayValidatorDirective,
        DateRangeValidatorDirective,
        InputMultipleChildDirective,
        DatepickerDirective
    ],
})
export class CustomFormsModule { }
