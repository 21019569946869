import { Observable ,  Subject ,  throwError , of } from 'rxjs';
import { Injectable } from '@angular/core';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { SimpleEntity } from '../../../models/shared/simple-entity.model';
import { ResponseModelSingle, AllResponseIncludes, ResponseModelList, ResponseQuery } from '../../../models/api/response.model';
import { RequestListOptions } from '../../../models/api/request-list-options.model';
import { catchError, tap, map, finalize } from 'rxjs/operators';
import { FilterValueType } from '../../../models/filters/filter.model';
import { RequestIdEntity } from '../../../models/api/request.model';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import { ApiRequestHelperService } from './api-request-helper.service';
import { AllIncludeOptions } from '../../../models/shared/include.model';
import { MetadataTypePlural } from '../../../models/entity/entity.type';

export const CACHE_DEFAULT_MAX_AGE = 300000; // 300 000 = 5 mins

interface ICacheContent {
    expiry: number;
    value: any;
}


class RequestCacheContent<K extends MetadataTypePlural> {
    type: K;
    ids: number[];
    filters: RequestFilter[];
    include: Map<K, number[]>;
    nonEntityInclude: Map<K, any[]>;
    skip?: number;
    take?: number;
    query: ResponseQuery;
    total: number;
}

class IdCacheKey<K extends MetadataTypePlural> {
    id: number;
    type: K;
}

class RequestCacheKey<K extends MetadataTypePlural> {
    type: K;
    options: RequestListOptions<AllIncludeOptions>;
    extra?: string;
}

class SingleCacheKey<K extends MetadataTypePlural> extends IdCacheKey<K> {
    include: AllIncludeOptions[];
}

/**
 * This is a modified class of the service explained here:
 * https://hackernoon.com/angular-simple-in-memory-cache-service-on-the-ui-with-rxjs-77f167387e39
 *
 * Updated to work with our project, and our API, specifically filters
 */
@Injectable()
export class ApiCacheService {

    constructor(
        private apiRequestHelperService: ApiRequestHelperService
    ) {

    }

    // private cache: Map<string, ICacheContent> = new Map<string, ICacheContent>();
    // private inFlightObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();

    readonly DEFAULT_MAX_AGE: number = CACHE_DEFAULT_MAX_AGE; // 300 000 = 5 mins

    private cacheById: Map<string, ICacheContent> = new Map<string, ICacheContent>();
    private cacheByRequest: Map<string, RequestCacheContent<MetadataTypePlural>> = new Map<string, RequestCacheContent<MetadataTypePlural>>();

    private inFlightObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();

    /**
     * Gets the value from cache if the key is provided.
     * If no value exists in cache, then check if the same call exists
     * in flight, if so return the subject. If not create a new
     * Subject inFlightObservable and return the source observable.
     */
    getByRequest<T extends RequestIdEntity, K extends MetadataTypePlural, Y extends AllResponseIncludes>(key: RequestCacheKey<K>, fallback: Observable<ResponseModelList<T, Y>>): Observable<ResponseModelList<T, Y>> | Subject<ResponseModelList<T, Y>> {

        const cacheKey: string = this.getCacheKeyForRequest(key);

        if (this.hasValidCachedValueByFilter(key)) {
            console.log(`%cGetting from cache ${cacheKey}`, 'color: green');

            const filterResult = this.cacheByRequest.get(cacheKey)!;

            // this gets the actual items from the id cache system
            const items: T[] = filterResult.ids.map(id => this.cacheById.get(this.getCacheKeyForId({ type: key.type, id: id}))!.value);

            // this gets the includes by id from the id cache system
            const include: any = {};

            filterResult.include.forEach((ids: number[], type: MetadataTypePlural) => {
                include[type] = ids.map(id => this.cacheById.get(this.getCacheKeyForId({ id: id, type: type }))!.value);
            });

            filterResult.nonEntityInclude.forEach((nonEntityItems, type) => {
                include[type] = nonEntityItems;
            });

            // wraps it up
            const cachedResponse: ResponseModelList<T, Y> = {
                data: items,
                include: include,
                query: filterResult.query,
                total: filterResult.total
            };

            // sends it out
            return of(cachedResponse);
        }

        return this.handleInflightProcess(cacheKey, fallback, (value) => {
            this.setByRequest(key, value);
        });
    }

    // this is actually just a helper method. The reality is that a "get with include" call is exactly the same as a
    // filtered call where "id is equal to"... the id. So this just maps to this filter and stores it like so
    // makes you kinda wonder why i even have single get calls with includes in the API...
    getSingleByRequest<T extends RequestIdEntity, K extends MetadataTypePlural, Y extends AllResponseIncludes>(key: SingleCacheKey<K>, fallback: Observable<ResponseModelSingle<T, Y>>): Observable<ResponseModelSingle<T, Y>> | Subject<ResponseModelSingle<T, Y>> {

        const filter: RequestFilter = {
            type: key.type as MetadataTypePlural,
            path: 'id',
            operator: 'eq',
            values: [key.id]
        };

        const requestKey: RequestCacheKey<K> = {
            options: {
                filters: [filter],
                include: key.include as AllIncludeOptions[]
            },
            type: key.type
        };

        const observable = this.getByRequest(requestKey, fallback.pipe(map(i => {
            if (!i.data) {
                throw new Error('no data in response');
            }

            return {
                data: [i.data],
                include: i.include,
                // this is never used in a single call so it's okay to "Fake" in here
                query: (undefined as any),
                total: 1
            } as ResponseModelList<T, Y>;
        })));

        return observable.pipe(
            map(i => {
                // yikes
                if (i && i.data && i.data.length > 0) {
                    return {
                        data: i.data[0],
                        include: i.include
                    };
                }

                throw throwError(i);
            })
        );
    }

    private handleInflightProcess<T>(cacheKey: string, fallback: Observable<T>, onFallbackComplete: (result: T) => void) {

        if (this.inFlightObservables.has(cacheKey)) {

            const subject = this.inFlightObservables.get(cacheKey)!;
            console.log('expected inflight response for cacheKey', cacheKey);

            return subject;

        } else if (fallback && fallback instanceof Observable) {
            const subject = new Subject();
            this.inFlightObservables.set(cacheKey, subject);

            console.log(`%cCalling api for ${cacheKey}`, 'color: purple', fallback);

            const fallbackHandle: Observable<T> = fallback.pipe(
                tap(innerResult => onFallbackComplete(innerResult)),
                catchError(error => {
                    console.log('error in caching stream', error);
                    // throw error for all inflights
                    this.handleInFlightOnError(cacheKey, error);
                    return throwError(error); // Observable.of(i);
                }),
                finalize(() => {
                    // console.log('on complete was called, usually occurs when a http request is cancelled');
                    this.handleInFlightOnCancel(cacheKey, fallbackHandle);
                })
            );

            return fallbackHandle;

        } else {
            return throwError('Requested key is not available in Cache');
        }
    }

    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     */
    setByRequest<T extends RequestIdEntity, K extends MetadataTypePlural, Y extends AllResponseIncludes>(key: RequestCacheKey<K>, value: ResponseModelList<T, Y>): void {

        const cacheKey: string = this.getCacheKeyForRequest(key);
        const ids: number[] = [];
        const items: { key: IdCacheKey<K>, item: T }[] = [];

        value.data.forEach(i => {
            const idCacheKey: IdCacheKey<K> = {
                id: i.id,
                type: key.type
            };

            ids.push(i.id);
            items.push({ key: idCacheKey, item: i });
        });

        const include = new Map<K, number[]>();
        const nonEntityInclude = new Map<K, any[]>();
        Object.keys(value.include || {}).forEach((i: K) => {
            const type = i;

            const includeItems: any[] = value.include[type.toString()];
            const includeIds: number[] = [];

            let hasId = false;

            includeItems.forEach(item => {
                if (item.id) {
                    hasId = true;
                }

                if (hasId) {
                    const includeIdCacheKey: IdCacheKey<K> = {
                        id: item.id,
                        type: type
                    };

                    includeIds.push(item.id);

                    items.push({
                        key: includeIdCacheKey,
                        item: item
                    });
                }
            });

            if (hasId) {
                include.set(type, includeIds);
            } else {
                nonEntityInclude.set(type, includeItems);
            }
        });

        const cacheItem: RequestCacheContent<K> = {
            type: key.type,
            ids: ids,
            filters: key.options.filters || [],
            include: include,
            nonEntityInclude: nonEntityInclude,
            query: value.query,
            total: value.total,
            skip: key.options.skip,
            take: key.options.take
        };

        this.cacheByRequest.set(cacheKey, cacheItem);

        // set the expiry to exactly the same time.
        const maxAge = Date.now() + this.DEFAULT_MAX_AGE;
        items.forEach(i => {
            const itemCacheKey = this.getCacheKeyForId(i.key);
            this.cacheById.set(itemCacheKey, { value: i.item, expiry: maxAge });
        });

        this.notifyInFlightObservers(cacheKey, value);
    }

    /**
     *
     * @param itemType a representation of the created entity
     * @param item the item that was created
     * @param masterFilter the filter that represents the request that would need to be updated with the new entity
     */
    addCreatedEntity<T extends RequestIdEntity, K extends MetadataTypePlural>(type: K, item: T, masterFilters: RequestFilter[]) {
        /** OK, this is getting pretty complicated.
         * let's take an example of a "site" being added to the system.
         * The sites "master entity" is a provider.
         *
         * There are four things that happen in this method, four things that
         * handle all the cases for a new object with as little impact to the cache
         * as I can possibly think to do.
         *
         * The goal is to ensure that no matter what request is made with any filter
         * the "state" of the cache is correct.
         *
         * Here are the four actions:
         * 1. For every cached request, if the "include" DOES NOT have this entity type, ignore it, it has no relevance
         * 2. Any filter that DOES have this entity type as an include and is MORE COMPLICATED than the simpliest of EQUALITY filters
         *    needs to be removed. We do this by comparing the filter against the masterFilters sent in. If it doesn't match, we delete
         *    the cached request.
         * 3. The EQAULITY filters passed in as the "masterFilters" parameter is updated to include the new entity type
         * 4. The entity is saved in cache by id
         */
        const itemType: IdCacheKey<K> = { id: item.id, type: type };
        this.cacheByRequest.forEach((cacheContent: RequestCacheContent<MetadataTypePlural>, cacheKey: string) => {

            // check if the cache item is relevant to this entity type
            if (cacheContent.include && cacheContent.include.has(itemType.type)) {

                const isEqualityFilter = cacheContent.filters.every(i => i.operator === 'eq');
                const hasOnlyOneFilter = cacheContent.filters.length === 1;

                // if it's not the simpliest of eq filters, remove the cache entry
                if (!isEqualityFilter || !hasOnlyOneFilter) {
                    console.log(`%cAn item was created, and cache has been removed (because it was too complex to update): ${cacheKey}`, 'color:red');
                    this.cacheByRequest.delete(cacheKey);
                } else {

                    const cachedEqFilter = cacheContent.filters[0];
                    const isRepresented = masterFilters.find(i => lodashHelper.isEqual(i, cachedEqFilter));

                    if (!isRepresented) {
                        // OK, so this doesn't have to do a delete here, it would all still work
                        // SO LONG as no developer "missed" a masterFilter when creating a new entity.
                        // I think it's safer to assume someone will mess up later, and therefore this should remain
                        // with a forced clearance...
                        console.log(`%cAn item was created, and cache has been removed: ${cacheKey}`, 'color:red');
                        this.cacheByRequest.delete(cacheKey);
                    } else {
                        console.log(`%cAn item was created, and cache has been updated: ${cacheKey}`, 'color:green');
                        const cachedItem = cacheContent.include.get(itemType.type)!;
                        cachedItem.push(itemType.id);
                    }
                }
            }
        });

        const itemCacheKey = this.getCacheKeyForId(itemType);
        this.cacheById.set(itemCacheKey, { value: item, expiry: Date.now() + this.DEFAULT_MAX_AGE });
    }

    clearCacheByType(type: MetadataTypePlural) {

        this.cacheByRequest.forEach((cacheContent: RequestCacheContent<MetadataTypePlural>, cacheKey: string) => {

            // check for this specific entity type
            if (cacheContent.type === type) {
                console.log(`%cCache was cleared by type: ${cacheKey}`, 'color:red');
                this.cacheByRequest.delete(cacheKey);
            }

            // check if the cache item is relevant to this entity type
            if (cacheContent.include && cacheContent.include.has(type)) {
                console.log(`%cCache was cleared by type: ${cacheKey}`, 'color:red');
                this.cacheByRequest.delete(cacheKey);
            }
        });
    }

    clearCacheByEntityId<K extends MetadataTypePlural>(type: K, id: number) {
        const key: IdCacheKey<K> = { type: type, id: id };

        this.remove(key);
    }

    updateEntity<T extends SimpleEntity>(item: T, type: MetadataTypePlural) {
        const cacheKey = this.getCacheKeyForId({ id: item.id, type: type });
        this.cacheById.set(cacheKey, { value: item, expiry: Date.now() + this.DEFAULT_MAX_AGE });
    }

    /**
     * Publishes the value to all observers of the given
     * in progress observables if observers exist.
     */
    private notifyInFlightObservers<T, Y extends AllResponseIncludes>(cacheKey: string, value: ResponseModelList<T, Y> | null): void {
        if (!this.inFlightObservables.has(cacheKey)) { return; }

        const inFlight = this.inFlightObservables.get(cacheKey)!;

        const observersCount = inFlight.observers.length;

        if (observersCount) {
            console.log(`%cNotifying ${observersCount} flight subscribers for ${cacheKey}`, 'color: blue');
            if (value !== null) {
                inFlight.next(value);
            }
        }
        inFlight.complete();
        this.inFlightObservables.delete(cacheKey);
    }

    private handleInFlightOnError(key: string, error: any) {
        if (!this.inFlightObservables.has(key)) { return; }

        const inFlight = this.inFlightObservables.get(key)!;
        inFlight.error(error);

        this.inFlightObservables.delete(key);
    }

    private handleInFlightOnCancel<T>(key: string, fallback: Observable<T>) {
        if (!this.inFlightObservables.has(key)) { return; }

        // this works... but i'm not sure if it's the best way
        fallback.toPromise();
    }

    /**
     * Checks if the key exists and   has not expired.
     */
    private hasValidCachedValueById<K extends MetadataTypePlural>(key: IdCacheKey<K>): boolean {
        const cacheKey: string = this.getCacheKeyForId(key);

        if (this.cacheById.has(cacheKey)) {
            if (this.cacheById.get(cacheKey)!.expiry < Date.now()) {
                this.cacheById.delete(cacheKey);
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    private remove<K extends MetadataTypePlural>(key: IdCacheKey<K>) {
        const cacheKey: string = this.getCacheKeyForId(key);

        if (this.cacheById.has(cacheKey)) {
            this.cacheById.delete(cacheKey);
        }
    }

    private hasValidCachedValueByFilter<K extends MetadataTypePlural>(key: RequestCacheKey<K>): boolean {


        const cacheKey = this.getCacheKeyForRequest(key);
        const hasFilterCache = this.cacheByRequest.has(cacheKey);
        if (!hasFilterCache) { return false; }


        const filterResult = this.cacheByRequest.get(cacheKey)!;

        const idsExist: boolean = filterResult.ids.every(id => this.hasValidCachedValueById({ type: key.type, id: id }));
        if (!idsExist) {
            console.log(`%cDeleting from cache, does not have core entity type ${cacheKey}`, 'color:red');
            this.cacheByRequest.delete(cacheKey);
            return false;
        }

        // The "Map" generic type implements some sort of iterator pattern,
        // doesn't seem to work with this version of typescript tho.. or seomthing. I'm not really sure
        // anyway this is pretty messy but at least it works.
        const hasIncludes: boolean[] = [];
        filterResult.include.forEach((value, includeType) => {
            const includeIdExists = value.every(id => this.hasValidCachedValueById({ id: id, type: includeType }));
            hasIncludes.push(includeIdExists);
        });

        if (!hasIncludes.every(i => i)) {
            console.log(`%cDeleting from cache, does not have includes ${cacheKey}`, 'color:red');
            this.cacheByRequest.delete(cacheKey);
            return false;
        }

        return true;
    }

    private getCacheKeyForId<K extends MetadataTypePlural>(key: IdCacheKey<K>): string {
        return `${key.type}:${key.id}`;
    }

    private getCacheKeyForRequest<K extends MetadataTypePlural>(key: RequestCacheKey<K>): string {
        const filterStrings: string[] = key.options.filters
            ? key.options.filters.map(i => `${i.type}:where:${i.path.toString()}:${i.operator}:[${i.values.map(f => this.filterValueMap(f)).join(',')}]`)
            : [];

        const includeString: string = key.options.include
            ? key.options.include.join(',')
            : '';

        const filters = filterStrings.length > 0 ? `:with-filters:[${filterStrings.join('&')}]` : '';
        const includes = includeString ? `:with-include:[${includeString}]` : '';
        const skip = key.options.skip ? `:skip=${key.options.skip}` : '';
        const take = key.options.take ? `:take=${key.options.take}` : '';
        const q = key.options.q ? `:q=${key.options.q}` : '';
        const sort = key.options.sort ? `:sort=${key.options.sort}` : '';
        const extra = key.extra ? `:extra:[${key.extra}]` : '';

        return `${key.type}${filters}${includes}${skip}${take}${q}${sort}${extra}`;
    }

    private filterValueMap(value: FilterValueType): string {
        return this.apiRequestHelperService.filterValueMap(value);
    }
}
