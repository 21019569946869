import { CpClass } from '../../modules/core-configuration/decorators/metadata.decorator';
import { ResponseFilter, ResponseError } from '../api/response.model';
import { EntityBase } from '../entity/entity.model';
import { AlertSeverity } from './alert.type';

@CpClass({
    create: newAlert,
    metadataType: 'alert'
})
export class Alert extends EntityBase {
    severity: AlertSeverity;
    name: string;
    archived: boolean;
    filter: ResponseFilter;
    errors?: ResponseError[];
}


export function newAlert() { return new Alert(); }

export class AlertResult {
    alert: Alert;
    ids: number[];
}
