import { Observable, BehaviorSubject } from 'rxjs';
import { ConfigurationService } from '../../../core-configuration/services/configuration.service';
import { flatMap } from 'rxjs/operators';
import {
    EntityPropertyConfig,
    EntityPropertyConfigKeyMap,
} from '../../../../models/filters/filter.model';
import { EntityMetadataService } from './entity-metadata.service';
import { Injectable } from '@angular/core';
import {
    PropertyMetadata,
    PropertiesMetadata,
    ClassMetadata,
    IncludeEntityMetadataCore,
    TextAlign,
} from '../../../../models/shared/metadata.model';
import { Configuration } from '../../../../models/shared/configuration.model';
import {
    DisplayPropertyType,
    ExtendedPropertyType,
} from '../../../../models/shared/types.model';
import { lodashHelper } from '../../helpers/lodash.helper';
import { OptionSetOption } from '../../../../models/extended-properties/option-set.model';
import { AllIncludeOptions } from '../../../../models/shared/include.model';
import {
    EntityType,
    MetadataType,
    MetadataIncludeKeys,
    MetadataEntity,
    ALL_FILTER_TYPES,
    DESCRIBABLE_TYPE_METADATA,
    ALL_METADATA_TYPES,
} from '../../../../models/entity/entity.type';

const LOCAL_STORAGE_PREFIX = 'cp:';
const LOCAL_STORAGE_SUFFIX = '_ListDescriptor';

export type ListVisibilityPropertyType = 'core' | 'extended' | MetadataType;

export interface ListVisibilityKey {
    key: string;
    visible: boolean;
}

export interface ListVisibilityMetadata extends ListVisibilityKey {
    type: ListVisibilityPropertyType;
    includeKey: MetadataIncludeKeys | '';
    propertyKey: string;
    requiresIncludeSortPrefix?: string;
    requiresInclude?: AllIncludeOptions[];
    isIncludeMany: boolean;
    metadata: PropertyMetadata;
    sortable: boolean;
    optionSetOptions?: OptionSetOption[];
    optionType?: ExtendedPropertyType;
}

export interface ListMetadataCollection {
    [key: string]: ListVisibilityMetadata[];
}

export interface ListDescriptorOptions {
    resetDefaults: boolean;
}

@Injectable()
export class EntityListDescriptorService {
    private collection: ListMetadataCollection = {};
    private changeSource = new BehaviorSubject<ListMetadataCollection>(
        this.collection
    );
    change$ = this.changeSource.asObservable();

    private initialized: MetadataType[] = [];

    constructor(
        protected configService: ConfigurationService,
        private metadataService: EntityMetadataService
    ) {}

    initialize(
        type: MetadataType,
        options: ListDescriptorOptions
    ): Observable<ListMetadataCollection> {
        const meta = this.metadataService.get(type);
        const classMeta = meta.classMetadata;

        if (this.initialized.includes(type)) {
            this.handleOptions(options, type, meta.propertyMetadata);
            return this.change$;
        }

        let core: ListVisibilityMetadata[] = [];

        return this.configService.get().pipe(
            flatMap(config => {
                core = this.generateCoreProperties(
                    type,
                    meta.propertyMetadata,
                    classMeta,
                    options,
                    config
                );
                const extended = this.generateExtendedProperties(
                    classMeta.metadataType,
                    config
                );
                const include = this.generateIncludeEntityProperties(
                    classMeta,
                    config
                );
                const all = [...core, ...extended, ...include];

                return this.setStorageVisibilityAndTrigger(
                    type,
                    classMeta,
                    all
                );
            })
        );
    }

    getDefaultSort(type: MetadataType): string | undefined {
        const defaultSortArray = this.metadataService.get(type).classMetadata
            .defaultSort;

        if (!defaultSortArray || defaultSortArray.length === 0) {
            return undefined;
        }
        return defaultSortArray
            .map(i => `${i.desending ? '-' : ''}${i.key}`)
            .join(',');
    }

    setVisibility(
        entityType: MetadataType,
        items: ListVisibilityKey[],
        options: { clearVisible: boolean } = { clearVisible: false }
    ) {
        const meta = this.metadataService.get(entityType);
        const classMeta = meta.classMetadata;
        const list = this[entityType];

        if (options.clearVisible) {
            (list as ListVisibilityMetadata[]).map(i => (i.visible = false));
        }

        items.forEach(item => {
            const find = list.find(
                (i: ListVisibilityKey) => i.key === item.key
            );

            if (find) {
                find.visible = item.visible;
            } else {
                console.log(
                    `%ckey not found in visibility list: ${item.key}`,
                    'color:red'
                );
            }
        });

        this.saveStorageVisibility(classMeta, list);
        this.triggerEvent();
    }

    getHeaderAlign(type: DisplayPropertyType): TextAlign {
        switch (type) {
            case 'date':
            case 'int32':
            case 'double':
                return 'right';
            default:
                return 'left';
        }
    }

    private handleOptions(
        options: ListDescriptorOptions,
        entityType: MetadataType,
        propertyMetadata: PropertiesMetadata
    ) {
        if (options.resetDefaults) {
            const defaults = this.getCoreDefaultKeys(propertyMetadata);
            this.setVisibility(
                entityType,
                defaults.map(key => ({ key: key, visible: true }))
            );
        }
    }

    private setStorageVisibilityAndTrigger(
        entityType: MetadataType,
        classMeta: ClassMetadata<MetadataEntity>,
        list: ListVisibilityMetadata[]
    ) {
        this.setStorageVisibility(classMeta, list);

        this[entityType] = list;

        if (!this.initialized.includes(entityType)) {
            this.initialized.push(entityType);
        }

        this.triggerEvent();
        return this.change$;
    }

    private triggerEvent() {
        ALL_METADATA_TYPES.map(i => {
            this.collection[i] = this[i];
        });
        this.changeSource.next(this.collection);
    }

    private getCoreDefaultKeys(propertyMetadata: PropertiesMetadata): string[] {
        return Object.keys(propertyMetadata).filter(key => {
            const meta = propertyMetadata[key];
            return !meta.hideFromList && meta.defaultListVisible;
        });
    }

    private generateCoreProperties(
        entityType: MetadataType,
        propertyMetadata: PropertiesMetadata,
        classMeta: ClassMetadata<MetadataEntity>,
        options: ListDescriptorOptions,
        config: Configuration
    ): ListVisibilityMetadata[] {
        const hasStorage = this.hasStorage(classMeta);

        const filterPropertyKeys = config.types[entityType]
            ? Object.keys(config.types[entityType])
            : [];

        const list: ListVisibilityMetadata[] = Object.keys(
            propertyMetadata
        ).map(key => {
            const meta = propertyMetadata[key];
            const filterProp = filterPropertyKeys.includes(key)
                ? (config.types[entityType][key] as EntityPropertyConfig)
                : undefined;

            const visible =
                options.resetDefaults && !!meta.defaultListVisible
                    ? true
                    : hasStorage || !options.resetDefaults
                    ? false
                    : !!meta.defaultListVisible;

            const item: ListVisibilityMetadata = {
                key: key,
                propertyKey: key,
                visible: visible,
                includeKey: '',
                type: 'core',
                metadata: propertyMetadata[key],
                isIncludeMany: false,
                sortable: filterProp ? filterProp.sortable : false,
            };

            return item;
        });

        return list;
    }

    private generateExtendedProperties(
        type: MetadataType,
        config: Configuration
    ): ListVisibilityMetadata[] {
        const entityPropertyConfig = config.types[
            type
        ] as EntityPropertyConfigKeyMap;
        if (!entityPropertyConfig) {
            return [];
        }

        const list: ListVisibilityMetadata[] = Object.keys(entityPropertyConfig)
            .filter(key => key.startsWith('properties.'))
            .map(key => entityPropertyConfig[key])
            .filter(configItem => !configItem.archived)
            .map(configItem => {
                const isOptionType = configItem.type === 'option';
                const item: ListVisibilityMetadata = {
                    key: configItem.key,
                    propertyKey: configItem.key,
                    visible: false,
                    type: 'extended',
                    isIncludeMany: false,
                    includeKey: '',
                    sortable: configItem.sortable,
                    optionSetOptions: isOptionType
                        ? (configItem.values as OptionSetOption[])
                        : undefined,
                    optionType: configItem.optionType,
                    metadata: {
                        label: configItem.name,
                        type: configItem.type as DisplayPropertyType,
                        hideFromList: false,
                        listHeaderAlign: this.getHeaderAlign(
                            configItem.type as DisplayPropertyType
                        ),
                    },
                };

                return item;
            });

        return list;
    }

    private generateIncludeEntityProperties(
        classMeta: ClassMetadata<MetadataEntity>,
        config: Configuration
    ): ListVisibilityMetadata[] {
        if (!classMeta.include) {
            return [];
        }

        const list: ListVisibilityMetadata[] = [];

        classMeta.include.forEach(includeMeta => {
            const metaType = includeMeta.type as MetadataType;
            if (!DESCRIBABLE_TYPE_METADATA[metaType]) {
                throw new Error(
                    'Attempting to get metadata info from an object that isnt a MetadataEntity'
                );
            }
            const meta = this.metadataService.get(includeMeta.type);
            const includeCore = this.generateCoreProperties(
                metaType,
                meta.propertyMetadata,
                classMeta,
                { resetDefaults: false },
                config
            );
            const includeExtended = this.generateExtendedProperties(
                meta.classMetadata.metadataType,
                config
            );

            const includeHideFromList =
                includeMeta.options && includeMeta.options.hideFromList;

            // this creates the entity type for filterable types
            if (
                ALL_FILTER_TYPES.filter(i => i !== 'entity').includes(
                    includeMeta.type as EntityType
                )
            ) {
                list.push(
                    this.createEntityListItem(includeMeta, {
                        isIncludeMany: false,
                    })
                );
            }

            const combined = [...includeCore, ...includeExtended].map(i => {
                const propertyKey = i.key;
                const includeKey = includeMeta.includeKey;

                return {
                    ...i,
                    key: `${metaType}.${propertyKey}`,
                    propertyKey: propertyKey,
                    type: includeMeta.type,
                    requiresInclude: includeMeta.options
                        ? includeMeta.options.requiresInclude
                        : undefined,
                    includeKey: includeKey,
                    requiresIncludeSortPrefix: includeMeta.options
                        ? includeMeta.options.requiresIncludeSortPrefix
                        : undefined,
                    // override hideFromList of includes that are hidden
                    metadata: {
                        ...i.metadata,
                        hideFromList: includeHideFromList
                            ? true
                            : i.metadata.hideFromList,
                    },
                };
            });

            list.push(...combined);
        });

        if (!classMeta.includeMany) {
            return list;
        }

        classMeta.includeMany.forEach(includeMeta => {
            const metaType = includeMeta.type as MetadataType;
            if (!DESCRIBABLE_TYPE_METADATA[metaType]) {
                throw new Error(
                    'Attempting to get metadata info from an object that isnt a MetadataEntity'
                );
            }

            const meta = this.metadataService.get(includeMeta.type);
            const includeCore = this.generateCoreProperties(
                metaType,
                meta.propertyMetadata,
                classMeta,
                { resetDefaults: false },
                config
            );
            const includeExtended = this.generateExtendedProperties(
                meta.classMetadata.metadataType,
                config
            );

            const includeHideFromList =
                includeMeta.options && includeMeta.options.hideFromList;

            const combined = [...includeCore, ...includeExtended].map(i => {
                const propertyKey = i.key;
                const includeKey = includeMeta.includeKey;

                return {
                    ...i,
                    key: `${includeKey}.${propertyKey}`,
                    propertyKey: propertyKey,
                    type: includeMeta.type,
                    requiresInclude: includeMeta.options
                        ? includeMeta.options.requiresInclude
                        : undefined,
                    includeKey: includeKey,
                    requiresIncludeSortPrefix: includeMeta.options
                        ? includeMeta.options.requiresIncludeSortPrefix
                        : undefined,
                    isIncludeMany: true,
                    // you can never sort on an include Many include
                    sortable: false,
                    // override hideFromList of includes that are hidden
                    metadata: {
                        ...i.metadata,
                        hideFromList: includeHideFromList
                            ? true
                            : i.metadata.hideFromList,
                    },
                };
            });

            // this creates the entity type
            list.push(
                this.createEntityListItem(includeMeta, { isIncludeMany: true })
            );

            list.push(...combined);
        });

        return list;
    }

    private createEntityListItem(
        includeMeta: IncludeEntityMetadataCore<MetadataEntity, MetadataEntity>,
        options: { isIncludeMany: boolean }
    ) {
        const includeKey = includeMeta.includeKey;

        return {
            key: includeKey,
            propertyKey: '',
            type: includeMeta.type,
            includeKey: includeKey,
            requiresInclude: includeMeta.options
                ? includeMeta.options.requiresInclude
                : [],
            isIncludeMany: options.isIncludeMany,
            visible: false,
            sortable: false,
            metadata: {
                label: includeMeta.type,
                type: 'entity' as DisplayPropertyType,
                hideFromList:
                    includeMeta.options && includeMeta.options.hideFromList,
                listHeaderAlign: 'left' as TextAlign,
            },
        };
    }

    private saveStorageVisibility(
        classMeta: ClassMetadata<MetadataEntity>,
        list: ListVisibilityKey[]
    ) {
        const key = this.generateKey(classMeta);

        localStorage.removeItem(key);

        // only store the items that are visible (this isn't necessary but it does reduce the size of the object)
        const toStore = list
            .filter(i => i.visible)
            .map(i => lodashHelper.pick(i, ['key', 'visible']));

        localStorage.setItem(key, JSON.stringify(toStore));
    }

    private setStorageVisibility(
        classMeta: ClassMetadata<MetadataEntity>,
        list: ListVisibilityMetadata[]
    ) {
        const visibility = this.getFromStorage(classMeta);

        visibility.map(item => {
            const find = list.find(i => i.key === item.key);
            if (find) {
                find.visible = item.visible;
            }
        });
    }

    private hasStorage(classMeta: ClassMetadata<MetadataEntity>): boolean {
        const key = this.generateKey(classMeta);

        return !!localStorage.getItem(key);
    }

    private getFromStorage(
        classMeta: ClassMetadata<MetadataEntity>
    ): ListVisibilityKey[] {
        const key = this.generateKey(classMeta);

        const storageString = localStorage.getItem(key);
        if (!storageString) {
            return [];
        }

        const visibility = JSON.parse(storageString) as ListVisibilityKey[];

        if (!visibility || !visibility.length) {
            return [];
        }

        return visibility;
    }

    private generateKey(classMeta: ClassMetadata<MetadataEntity>): string {
        return (
            LOCAL_STORAGE_PREFIX + classMeta.metadataType + LOCAL_STORAGE_SUFFIX
        );
    }
}
