/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "angular2-moment/date-format.pipe";
import * as i6 from "../icon/icon.component.ngfactory";
import * as i7 from "../icon/icon.component";
import * as i8 from "./date-range-display.component";
import * as i9 from "../../../core-configuration/helpers/moment.helper";
var styles_DateRangeDisplayComponent = [];
var RenderType_DateRangeDisplayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DateRangeDisplayComponent, data: {} });
export { RenderType_DateRangeDisplayComponent as RenderType_DateRangeDisplayComponent };
function View_DateRangeDisplayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "span", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "fa-icon", [["class", "mx-2 ng-fa-icon"], ["icon", "long-arrow-alt-right"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i0.ɵdid(5, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵppd(8, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textClass; _ck(_v, 1, 0, currVal_0); var currVal_2 = "long-arrow-alt-right"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵnov(_v, 5).renderedIconHTML; _ck(_v, 4, 0, currVal_1); var currVal_3 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent, 0), _co.end, _co.dateFormat)); _ck(_v, 7, 0, currVal_3); }); }
export function View_DateRangeDisplayComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.DateFormatPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 2, "cp-icon", [], [[1, "title", 0]], null, null, i6.View_IconComponent_0, i6.RenderType_IconComponent)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(3, 49152, null, 0, i7.IconComponent, [], { icon: [0, "icon"], hideIconIf: [1, "hideIconIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 3, "span", [], null, null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵppd(7, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DateRangeDisplayComponent_1)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.activeClass; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.icon; var currVal_3 = _co.hideIcon; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.textClass; _ck(_v, 5, 0, currVal_4); var currVal_6 = ((_co.end && !_co.hideEnd) && !_co.endIsSame); _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconTitle; _ck(_v, 1, 0, currVal_0); var currVal_5 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v, 0), _co.start, _co.dateFormat)); _ck(_v, 6, 0, currVal_5); }); }
export function View_DateRangeDisplayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-date-range-display", [], null, null, null, View_DateRangeDisplayComponent_0, RenderType_DateRangeDisplayComponent)), i0.ɵdid(1, 638976, null, 0, i8.DateRangeDisplayComponent, [i9.MomentHelper], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DateRangeDisplayComponentNgFactory = i0.ɵccf("cp-date-range-display", i8.DateRangeDisplayComponent, View_DateRangeDisplayComponent_Host_0, { start: "start", end: "end", hideEnd: "hideEnd", hideIcon: "hideIcon", disableTextClasses: "disableTextClasses" }, {}, []);
export { DateRangeDisplayComponentNgFactory as DateRangeDisplayComponentNgFactory };
