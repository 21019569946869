import { ExtendedPropertyType } from '../shared/types.model';
import { Moment } from 'moment';
import { CpClass } from '../../modules/core-configuration/decorators/metadata.decorator';
import { EntityBase } from '../entity/entity.model';
import { PropertyOptionSet, OptionSetOption } from './option-set.model';

export type ExtendedPropertyAllowedBaseType =
    | string
    | number
    | boolean
    | Moment
    | OptionSetOption;

@CpClass({
    create: newExtendedProperty,
    metadataType: 'extendedProperty',
})
export class ExtendedProperty extends EntityBase {
    key: string;
    name: string;
    description?: string;
    type: ExtendedPropertyType;
    array: boolean;
    archived: boolean;
    provisionable: boolean;
    optionSetId?: number;

    required = false;
    min?: number;
    max?: number;
    pattern?: string;
    patternDescription?: string;
    placeholder?: string;

    userTypes: { [key: string]: 'read' | 'write' };

    // only returned in a get, not used in forms
    optionSet?: PropertyOptionSet;
}

export class ExtendedProperties {
    [key: string]:
        | ExtendedPropertyAllowedBaseType
        | Array<ExtendedPropertyAllowedBaseType>;
}

export function newExtendedProperty() {
    return new ExtendedProperty();
}
