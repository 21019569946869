var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { environment } from '../../../../environments/environment';
var ApiHttpClientServiceBase = /** @class */ (function () {
    function ApiHttpClientServiceBase(httpClient, apiRequestHelperService) {
        this.httpClient = httpClient;
        this.apiRequestHelperService = apiRequestHelperService;
        this.baseUrl = environment.apiBaseUrl + '/' + environment.apiVersion;
        this.baseEntityPath = [];
    }
    ApiHttpClientServiceBase.prototype.generateUrl = function (generateOptions) {
        if (!generateOptions.options) {
            generateOptions.options = {};
        }
        if (!generateOptions.path) {
            generateOptions.path = [];
        }
        var pathToSend = [
            this.baseUrl
        ].concat((generateOptions.overrideBasePath ? [] : this.baseEntityPath), generateOptions.path.map(function (i) { return i.toString(); }));
        return this.apiRequestHelperService.generateUrl({ path: pathToSend, options: generateOptions.options });
    };
    return ApiHttpClientServiceBase;
}());
export { ApiHttpClientServiceBase };
var ApiGetHttpClientService = /** @class */ (function (_super) {
    __extends(ApiGetHttpClientService, _super);
    function ApiGetHttpClientService(typePlural, httpClient, apiRequestHelperService) {
        var _this = _super.call(this, httpClient, apiRequestHelperService) || this;
        _this.typePlural = typePlural;
        _this.httpClient = httpClient;
        _this.apiRequestHelperService = apiRequestHelperService;
        // this overrides the base path
        _this.baseEntityPath = [_this.typePlural];
        return _this;
    }
    ApiGetHttpClientService.prototype.get = function (path, include) {
        var url = this.generateUrl({ path: path, options: { include: include } });
        return this.httpClient.get(url);
    };
    ApiGetHttpClientService.prototype.list = function (options, routeParamSuffix) {
        var url = this.generateUrl({ options: options, path: routeParamSuffix });
        return this.httpClient.get(url);
    };
    return ApiGetHttpClientService;
}(ApiHttpClientServiceBase));
export { ApiGetHttpClientService };
var ApiHttpClientService = /** @class */ (function (_super) {
    __extends(ApiHttpClientService, _super);
    function ApiHttpClientService(typePlural, httpClient, apiRequestHelperService) {
        var _this = _super.call(this, typePlural, httpClient, apiRequestHelperService) || this;
        _this.typePlural = typePlural;
        _this.httpClient = httpClient;
        _this.apiRequestHelperService = apiRequestHelperService;
        return _this;
    }
    ApiHttpClientService.prototype.create = function (item, routeParamSuffix) {
        var url = this.generateUrl({ path: routeParamSuffix });
        return this.httpClient.post(url, item);
    };
    ApiHttpClientService.prototype.update = function (item, routeParamSuffix) {
        if (!item.id) {
            throw new Error('id must exist in update');
        }
        var url = this.generateUrl({ path: [item.id].concat((routeParamSuffix || [])) });
        return this.httpClient.put(url, item);
    };
    ApiHttpClientService.prototype.delete = function (path) {
        var url = this.generateUrl({ path: path });
        return this.httpClient.delete(url);
    };
    ApiHttpClientService.prototype.ids = function (options, routeParamSuffix) {
        var url = this.generateUrl({ options: options, path: (routeParamSuffix || []).concat(['ids']) });
        return this.httpClient.get(url);
    };
    return ApiHttpClientService;
}(ApiGetHttpClientService));
export { ApiHttpClientService };
var MergeApiHttpClientService = /** @class */ (function (_super) {
    __extends(MergeApiHttpClientService, _super);
    function MergeApiHttpClientService(typePlural, httpClient, apiRequestHelperService) {
        var _this = _super.call(this, typePlural, httpClient, apiRequestHelperService) || this;
        _this.typePlural = typePlural;
        _this.httpClient = httpClient;
        _this.apiRequestHelperService = apiRequestHelperService;
        return _this;
    }
    MergeApiHttpClientService.prototype.dryMerge = function (deleteId, targetId) {
        var url = this.generateMergeUrl(deleteId, targetId);
        return this.httpClient.get(url);
    };
    MergeApiHttpClientService.prototype.merge = function (deleteId, targetId) {
        var url = this.generateMergeUrl(deleteId, targetId);
        return this.httpClient.post(url, {});
    };
    MergeApiHttpClientService.prototype.generateMergeUrl = function (deleteId, targetId) {
        return this.generateUrl({ path: [targetId, 'merge', deleteId] });
    };
    return MergeApiHttpClientService;
}(ApiHttpClientService));
export { MergeApiHttpClientService };
