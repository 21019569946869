import { Injectable } from '@angular/core';
import { MomentHelper } from '../../core-configuration/helpers/moment.helper';
import { TimelineTime } from '../../../models/timeline/timeline-time.model';
import { TimelinePlan } from '../../../models/timeline/timeline-plan.model';
import { TimelineDataItem, TimelineBreak, TimelineIdItem } from '../../../models/timeline/timeline-item.model';
import { TimelineCoreService } from './timeline-core.service';
import { Experience } from '../../../models/experience/experience.model';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';

export const MULTIPLE_PLAN_BLOCK_HEIGHT = 18;

export class TimelineUpdate {
    timeData: TimelineTime;
}

@Injectable()
export class TimelinePlansService {

    constructor(
        private momentHelper: MomentHelper,
        private timelineCoreService: TimelineCoreService
    ) {

    }

    createTimelinePlan(
        items: TimelineIdItem[],
        possibleOverlaps: TimelineIdItem[],
        experiences: Experience[],
        height: number,
        time: TimelineTime
    ): TimelinePlan {

        const uniqPossibleOverlaps = lodashHelper.uniqBy(possibleOverlaps, i => i.id);

        const rows = this.timelineCoreService.calculateRows(items);
        const total = rows.length;

        const timelineItems: TimelineDataItem[] = [];

        rows.forEach((row, rowIndex) => {
            row.forEach(item => {

                // Experience - FIX
                const experience = experiences.find(e => item.experienceId === e.id)!;
                const data = this.createData(item, uniqPossibleOverlaps, experience, rowIndex, time, height);
                timelineItems.push(data);
            });
        });

        return {
            rows: total,
            rowHeight: total * height,
            items: timelineItems
        };
    }

    private createData(
        item: TimelineIdItem,
        possibleOverlaps: TimelineIdItem[],
        experience: Experience,
        rowIndex: number,
        time: TimelineTime,
        height: number
    ) {
        // need to figure out how to show this
        // probably just a styling trick?
        const startsBeforeTimelineDate = item.start.isBefore(time.start);

        // diffs are used to calculate the index of the week and the left position of the day (by day offset)
        // if the day starts before the start of the timeline (weekDayStart) we should use the weekDayStart
        // instead of the actual start
        // the difference in days and weeks should therefore be 0 (for both)
        const position = this.timelineCoreService.calculateWeekAndDay(item.start, time.start);
        const weekIndex = startsBeforeTimelineDate ? 0 : position.weekIndex;

        const startToCalculateFrom = weekIndex === 0 ? time.start : item.start;
        const days = item.end.diff(startToCalculateFrom, 'days');
        const whenActive = this.timelineCoreService.calculateWhenActive(item);

        const top = (rowIndex * height);

        const cssClasses = this.timelineCoreService.timelineCssClasses(whenActive);

        const globalLeft = this.timelineCoreService.calculateGlobalLeft(item.start, time.start, time.diffDays);
        const globalWidth = this.timelineCoreService.calculateGlobalWidth(days, time.diffDays);

        const breaks: TimelineBreak[] = this.timelineCoreService.createTimelineBreaks(
            item,
            possibleOverlaps,
            time,
            height,
            rowIndex
        );

        const data: TimelineDataItem = {

            // consider if i need to reference the entire block, maybe just the id?
            item: {
                entity: item,
                experience: experience
            },

            breaks: breaks,

            top: top,

            height: height,

            width: globalWidth,

            // set properties for when the block was active, ie - is it in the past, future, or is it active now?
            whenActive: whenActive,

            cssClasses: cssClasses,

            left: globalLeft
        };

        if (data.whenActive === 'now') {
            const activeDays = this.momentHelper.today().add(-1, 'days').diff(startToCalculateFrom, 'days');
            data.activeDaysWidth = this.timelineCoreService.calculateGlobalWidth(activeDays, time.diffDays);
        }

        return data;
    }
}
