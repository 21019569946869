import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/*  This class just makes subscribing and unsubscribing less of a hassle.
    Extend this class and
    Just use the takeUntil(this.ngUnsubscribe) on all subscriptions, and
    this will ensure that it gets unsubscribe on a angular component destroy.
    */
export class BaseSubscriber implements OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor() { }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.unsubscribe();
    }
}
