var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { ResponseFilter } from '../../../../models/api/response.model';
import { CpRequiredOnInit } from '../../../core-configuration/decorators/required-on-init.decorator';
import { ResponseFilterService } from '../../services/response-filter.service';
import { ConfigurationService } from '../../../core-configuration/services/configuration.service';
import { map, takeUntil } from 'rxjs/operators';
import { BaseSubscriber } from '../../../common/observables/base-subscriber';
import { FilterConfigInnerService } from '../../services/filter-config-inner.service';
var FilterDisplayComponent = /** @class */ (function (_super) {
    __extends(FilterDisplayComponent, _super);
    function FilterDisplayComponent(configService, filterConfigInnerService, responseFilterHelper, changeDetectorRef) {
        var _this = _super.call(this) || this;
        _this.configService = configService;
        _this.filterConfigInnerService = filterConfigInnerService;
        _this.responseFilterHelper = responseFilterHelper;
        _this.changeDetectorRef = changeDetectorRef;
        _this.internalFilters = [];
        _this.loaded = false;
        return _this;
    }
    FilterDisplayComponent.prototype.getConditionProperty = function (internalFilter) {
        return internalFilter.entityIdProperty || internalFilter.property;
    };
    FilterDisplayComponent.prototype.getPropertyPath = function (internalFilter) {
        return this.filterConfigInnerService.getPropertyPathByPath(this.config, this.filter.type, internalFilter.path) || [];
    };
    FilterDisplayComponent.prototype.ngOnInit = function () {
        var _this = this;
        // need to make this more dynamic eventually
        // changes to the filter will do nothing to override this guard
        if (this.filter.conditions.length === 0) {
            this.loaded = true;
            return;
        }
        this.configService.getFilterProperties().pipe(map(function (configResult) {
            _this.config = configResult;
            _this.internalFilters = _this.responseFilterHelper.responseFilterToInternalFilter(_this.config, _this.filter);
            _this.loaded = true;
            _this.changeDetectorRef.markForCheck();
        }), takeUntil(this.ngUnsubscribe)).subscribe();
    };
    __decorate([
        CpRequiredOnInit(),
        __metadata("design:type", ResponseFilter)
    ], FilterDisplayComponent.prototype, "filter", void 0);
    return FilterDisplayComponent;
}(BaseSubscriber));
export { FilterDisplayComponent };
