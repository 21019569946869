import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseModel, AllResponseIncludes, ResponseModelSingle, ResponseModelList, ResponseModelIdList, ResponseModelMerge } from '../../../models/api/response.model';
import { RequestListOptions, RequestSearchOptions } from '../../../models/api/request-list-options.model';
import { RequestEntity, RequestIdEntity } from '../../../models/api/request.model';
import { ApiRequestHelperService } from './api-request-helper.service';
import { AllIncludeOptions, NoIncludeOption } from '../../../models/shared/include.model';
import { Entity, EntityTypePlural, MetadataTypePlural } from '../../../models/entity/entity.type';

export interface IMergeHttpService<TEntity> {
    dryMerge(id: number, mergeId: number): Observable<ResponseModelMerge<TEntity>>;
    merge(id: number, mergeId: number): Observable<ResponseModelMerge<TEntity>>;
}

export interface IGetHttpService<
    TEntity extends RequestEntity,
    TInlcudeOptions extends AllIncludeOptions,
    TResponseIncludes extends AllResponseIncludes
> {
    get(path: (number | string)[], include: TInlcudeOptions[]): Observable<ResponseModelSingle<TEntity, TResponseIncludes>>;
    list(options: RequestListOptions<TInlcudeOptions>, routeParamSuffix?: string[]): Observable<ResponseModelList<TEntity, TResponseIncludes>>;
}

export interface ICrudHttpService<
    TEntity extends RequestEntity
> {
    create(entity: TEntity): Observable<ResponseModel<TEntity>>;
    update(entity: Partial<TEntity>): Observable<ResponseModel<TEntity>>;
    delete(path: (number | string)[]): Observable<Object>;
}

export abstract class ApiHttpClientServiceBase<TIncludeOptions extends AllIncludeOptions = NoIncludeOption> {
    protected baseUrl = environment.apiBaseUrl + '/' + environment.apiVersion;
    protected baseEntityPath: any[] = [];

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
    }

    protected generateUrl(generateOptions: { path?: (string | number)[], overrideBasePath?: boolean, options?: RequestListOptions<TIncludeOptions> }): string {
        if (!generateOptions.options) { generateOptions.options = {}; }
        if (!generateOptions.path) { generateOptions.path = []; }

        const pathToSend = [
            this.baseUrl,
            ...(generateOptions.overrideBasePath ? [] : this.baseEntityPath),
            ...generateOptions.path.map(i => i.toString())
        ];

        return this.apiRequestHelperService.generateUrl({ path: pathToSend, options: generateOptions.options });
    }
}

export abstract class ApiGetHttpClientService<
    TEntity extends RequestEntity,
    TIncludeOptions extends AllIncludeOptions,
    TResponseIncludes extends AllResponseIncludes>
    extends ApiHttpClientServiceBase<TIncludeOptions>
    implements IGetHttpService<TEntity, TIncludeOptions, TResponseIncludes
> {

    // this overrides the base path
    protected baseEntityPath: any[] = [this.typePlural];

    constructor(
        protected typePlural: MetadataTypePlural,
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super(httpClient, apiRequestHelperService);
    }

    get(path: (number | string)[], include: TIncludeOptions[]): Observable<ResponseModelSingle<TEntity, TResponseIncludes>> {
        const url = this.generateUrl({ path: path, options: { include: include } });
        return this.httpClient.get<ResponseModelSingle<TEntity, TResponseIncludes>>(url);
    }

    list(options: RequestListOptions<TIncludeOptions>, routeParamSuffix?: string[]): Observable<ResponseModelList<TEntity, TResponseIncludes>> {
        const url = this.generateUrl({ options: options, path: routeParamSuffix });

        return this.httpClient.get<ResponseModelList<TEntity, TResponseIncludes>>(url);
    }
}

export abstract class ApiHttpClientService<
    TEntity extends RequestIdEntity,
    TInlcudeOptions extends AllIncludeOptions,
    TResponseIncludes extends AllResponseIncludes>
    extends ApiGetHttpClientService<TEntity, TInlcudeOptions, TResponseIncludes>
    implements ICrudHttpService<TEntity> {

    constructor(
        protected typePlural: MetadataTypePlural,
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super(typePlural, httpClient, apiRequestHelperService);
    }

    create(item: TEntity, routeParamSuffix?: string[]): Observable<ResponseModel<TEntity>> {
        const url = this.generateUrl({ path: routeParamSuffix });

        return this.httpClient.post<ResponseModel<TEntity>>(url, item);
    }

    update(item: Partial<TEntity>, routeParamSuffix?: string[]): Observable<ResponseModel<TEntity>> {
        if (!item.id) { throw new Error('id must exist in update'); }

        const url = this.generateUrl({ path: [item.id, ...(routeParamSuffix || [])] });

        return this.httpClient.put<ResponseModel<TEntity>>(url, item);
    }

    delete(path: (number | string)[]): Observable<Object> {
        const url = this.generateUrl({ path: path });

        return this.httpClient.delete<Object>(url);
    }

    ids(options: RequestSearchOptions, routeParamSuffix?: string[]): Observable<ResponseModelIdList> {
        const url = this.generateUrl({ options: options, path: [...(routeParamSuffix || []), 'ids'] });

        return this.httpClient.get<ResponseModelIdList>(url);
    }
}

export abstract class MergeApiHttpClientService<
    TEntity extends Entity,
    TInlcudeOptions extends AllIncludeOptions,
    TResponseIncludes extends AllResponseIncludes>
    extends ApiHttpClientService<TEntity, TInlcudeOptions, TResponseIncludes>
    implements IMergeHttpService<TEntity> {

    constructor(
        protected typePlural: EntityTypePlural,
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super(typePlural, httpClient, apiRequestHelperService);
    }

    dryMerge(deleteId: number, targetId: number): Observable<ResponseModelMerge<TEntity>>  {
        const url = this.generateMergeUrl(deleteId, targetId);

        return this.httpClient.get<ResponseModelMerge<TEntity>>(url);
    }

    merge(deleteId: number, targetId: number): Observable<ResponseModelMerge<TEntity>> {
        const url = this.generateMergeUrl(deleteId, targetId);

        return this.httpClient.post<ResponseModelMerge<TEntity>>(url, {});
    }

    private generateMergeUrl(deleteId: number, targetId: number): string {
        return this.generateUrl({ path: [targetId, 'merge', deleteId] });
    }
}
