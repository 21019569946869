var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ContactHttpService } from './http/contact.http.service';
import { Contact } from '../../../models/provider/contact.model';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { ProviderBaseService } from './provider-base.service';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
var ContactService = /** @class */ (function (_super) {
    __extends(ContactService, _super);
    function ContactService(httpService, apiCacheService, includeMapperService) {
        var _this = _super.call(this, 'contact', httpService, apiCacheService, includeMapperService) || this;
        _this.httpService = httpService;
        _this.apiCacheService = apiCacheService;
        _this.includeMapperService = includeMapperService;
        _this.includeOptions = ['providers', 'sites'];
        _this.createEntity = function () { return new Contact(); };
        return _this;
    }
    ContactService.prototype.onAfterCreate = function (entity) {
        this.apiCacheService.clearCacheByEntityId('providers', entity.providerId);
        return [];
    };
    ContactService.prototype.onAfterUpdate = function (entity) {
        // have to just clear provider cache here because of the
        // 'joining table' means that both site and contact needs to be updated.
        // the above now happens in the base class :/
        // archivable entities need to clear master types just to be safe
        this.apiCacheService.clearCacheByType('providers');
        // needs to happen because of site.contactId array cache
        this.apiCacheService.clearCacheByType(this.typePlural);
        // contacts update can change results for a site call, so sites have to be cleared too
        this.apiCacheService.clearCacheByType('sites');
    };
    return ContactService;
}(ProviderBaseService));
export { ContactService };
