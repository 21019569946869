var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CpClass, CpProperty, } from '../../modules/core-configuration/decorators/metadata.decorator';
import { ResponseFilter } from '../api/response.model';
import { ArchiveDisplayDynamicComponent } from '../../modules/display-helper/components/archive/archive-display-dynamic.component';
import { EntityBase } from '../entity/entity.model';
import { FilterDisplayDynamic } from '../../modules/filter/components/filter-display/filter-display-dynamic.component';
export var ALL_MAPPING_PRIORITIES = [
    'requiredConstraint',
    'optionalConstraint',
    'preference1',
    'preference2',
    'preference3',
];
export var ALL_MAPPING_PROPERTY_TYPES = [
    'leftAll',
    'leftNone',
    'leftAnyOrLeftEmpty',
    'filters',
];
export var All_MAPPING_ENTITY_TYPES = [
    'position',
    'student',
    'planBlock',
];
var Mapping = /** @class */ (function (_super) {
    __extends(Mapping, _super);
    function Mapping() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        CpProperty({ type: 'string' }),
        __metadata("design:type", String)
    ], Mapping.prototype, "name", void 0);
    __decorate([
        CpProperty({ type: 'multilineText', showInDetails: true }),
        __metadata("design:type", String)
    ], Mapping.prototype, "description", void 0);
    __decorate([
        CpProperty({
            type: 'boolean',
            dynamicComponent: ArchiveDisplayDynamicComponent,
        }),
        __metadata("design:type", Boolean)
    ], Mapping.prototype, "archived", void 0);
    __decorate([
        CpProperty({ type: 'string' }),
        __metadata("design:type", String)
    ], Mapping.prototype, "priority", void 0);
    __decorate([
        CpProperty({ type: 'dynamic', dynamicComponent: FilterDisplayDynamic }),
        __metadata("design:type", ResponseFilter)
    ], Mapping.prototype, "leftFilter", void 0);
    __decorate([
        CpProperty({ type: 'dynamic', dynamicComponent: FilterDisplayDynamic }),
        __metadata("design:type", ResponseFilter)
    ], Mapping.prototype, "rightFilter", void 0);
    __decorate([
        CpProperty({ type: 'string' }),
        __metadata("design:type", String)
    ], Mapping.prototype, "rightPath", void 0);
    __decorate([
        CpProperty({ type: 'string' }),
        __metadata("design:type", String)
    ], Mapping.prototype, "leftPath", void 0);
    __decorate([
        CpProperty({ type: 'string' }),
        __metadata("design:type", String)
    ], Mapping.prototype, "type", void 0);
    Mapping = __decorate([
        CpClass({
            create: createMapping,
            metadataType: 'mapping',
        })
    ], Mapping);
    return Mapping;
}(EntityBase));
export { Mapping };
export function createMapping() {
    return new Mapping();
}
