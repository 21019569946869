/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../experience-badge/experience-badge.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "../experience-badge/experience-badge.component";
import * as i4 from "../../../display-helper/directives/loader/loader.directive";
import * as i5 from "./experience-property-display-list.component";
import * as i6 from "../../../core-configuration/services/configuration.service";
var styles_ExperiencePropertyDisplayListComponent = [];
var RenderType_ExperiencePropertyDisplayListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ExperiencePropertyDisplayListComponent, data: {} });
export { RenderType_ExperiencePropertyDisplayListComponent as RenderType_ExperiencePropertyDisplayListComponent };
function View_ExperiencePropertyDisplayListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "cp-experience-badge", [], null, null, null, i1.View_ExperienceBadgeComponent_0, i1.RenderType_ExperienceBadgeComponent)), i0.ɵdid(1, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(2, 49152, null, 0, i3.ExperienceBadgeComponent, [], { experience: [0, "experience"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cssClass; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, null); }
function View_ExperiencePropertyDisplayListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExperiencePropertyDisplayListComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.experiences; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ExperiencePropertyDisplayListComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExperiencePropertyDisplayListComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.LoaderDirective, [i0.TemplateRef, i0.ViewContainerRef, i0.ComponentFactoryResolver], { cpLoadingSmall: [0, "cpLoadingSmall"], cpLoading: [1, "cpLoading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = !_co.loaded; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ExperiencePropertyDisplayListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-experience-property-display-list", [], null, null, null, View_ExperiencePropertyDisplayListComponent_0, RenderType_ExperiencePropertyDisplayListComponent)), i0.ɵdid(1, 245760, null, 0, i5.ExperiencePropertyDisplayListComponent, [i6.ConfigurationService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExperiencePropertyDisplayListComponentNgFactory = i0.ɵccf("cp-experience-property-display-list", i5.ExperiencePropertyDisplayListComponent, View_ExperiencePropertyDisplayListComponent_Host_0, { experienceIds: "experienceIds", cssClass: "cssClass" }, {}, []);
export { ExperiencePropertyDisplayListComponentNgFactory as ExperiencePropertyDisplayListComponentNgFactory };
