var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiCacheService } from '../../api/services/api-cache.service';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { StudentCourseHttpService } from './http/student-course.http.service';
var StudentCourseService = /** @class */ (function (_super) {
    __extends(StudentCourseService, _super);
    function StudentCourseService(httpService, apiCacheService, includeMapperService) {
        var _this = _super.call(this, 'studentCourse', httpService, apiCacheService, includeMapperService) || this;
        _this.httpService = httpService;
        _this.apiCacheService = apiCacheService;
        _this.includeMapperService = includeMapperService;
        _this.includeOptions = [];
        return _this;
    }
    StudentCourseService.prototype.onAfterCreate = function (entity) {
        return [new RequestFilter('students', 'id', 'eq', [entity.studentId])];
    };
    StudentCourseService.prototype.onAfterUpdate = function (entity) {
        this.apiCacheService.clearCacheByEntityId('students', entity.studentId);
    };
    return StudentCourseService;
}(EntityServiceMetadataBase));
export { StudentCourseService };
