import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DateRange } from '../../../models/shared/date-range.model';
import { NGB_DATE_FORMAT } from '../../form/components/datepicker/moment-locale.ngb-date-parser-formatter';
import { environment } from '../../../../environments/environment';

export const API_DATE_FORMAT = 'YYYY-MM-DD';

@Injectable()
export class MomentHelper {

    // eeeh, this needs to be considered very carefully
    private _today =  moment.utc().startOf('day');

    private dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/;

    private utcDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;

    private apiDateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;

    // not sure about this, uses set formats, but in non-strict mode... dates are wierd
    private textParseDateFormats = [NGB_DATE_FORMAT, environment.momentDateFormat, API_DATE_FORMAT];

    constructor() { }

    today() {
        return this._today.clone();
    }

    dateSort(dateRangeA: DateRange, dateRangeB: DateRange): number {
        const dayDiff = dateRangeA.start.diff(dateRangeB.start, 'days');

        if (dayDiff !== 0) { return dayDiff; }
        return dateRangeB.end.diff(dateRangeA.end, 'days');
    }

    // "day() method in moment returns the day of the WEEK (as an integer)"
    dateToStartOfWeek(date: moment.Moment): moment.Moment {

        const startDay = date.weekday();
        if (startDay === 0) {
            return date.clone().startOf('day');
        }

        return date.clone().startOf('week'); // .add(-startDay, 'days');
    }

    // erroneous wording, returns the "Start of the week if the date is not exactly on day "0"
    dateToStartOfNextWeek(date: moment.Moment): moment.Moment {
        const startDay = date.weekday();
        if (startDay === 0) {
            return date.clone().startOf('day').add(7, 'days');
        }

        return date.clone().startOf('week').add(7, 'days');

        // const endDay = date.day();
        // return endDay === 0 ? date.clone() : date.clone().add(7 - endDay, 'days');
    }

    stringToMoment(value: string): moment.Moment | undefined {
        // regex checks
        if (this.apiDateRegex.test(value)) {
            return moment.utc(value, API_DATE_FORMAT, true);
        }

        if (this.dateRegex.test(value)) {
            return moment(value);
        }

        if (this.utcDateRegex.test(value)) {
            return moment.utc(value);
        }

        return undefined;
    }

    stringToMomentNonStrict(value: string): moment.Moment | undefined {
        const momentValue = moment.utc(value, this.textParseDateFormats);
        return momentValue.isValid() ? momentValue : undefined;
    }

    // sets the end to the start of the day, but end of the week.
    generateStartEnd(start: string, end: string, defaultAmount: moment.DurationInputArg1, duration: moment.DurationInputArg2): DateRange {

        let startMoment: moment.Moment;
        let endMoment: moment.Moment;

        // if invalid, reset to defaults
        if (!start || !end) {
            startMoment = this.today().add(-2, 'weeks');
            endMoment = startMoment.clone().add(defaultAmount, duration);
        } else {
            startMoment = moment.utc(start);
            endMoment = moment.utc(end);
        }

        startMoment = this.dateToStartOfWeek(startMoment);
        endMoment = endMoment.endOf('week').startOf('day');

        return {
            start: startMoment,
            end: endMoment
        };
    }

    isFuture(date: moment.Moment): boolean {
        return date.isAfter(this._today, 'days');
    }

    isActive(start: moment.Moment, end: moment.Moment): boolean {
        return start.isBefore(this._today, 'days') && end.isSameOrAfter(this._today, 'days');
    }

    isPast(date: moment.Moment) {
        return date.isBefore(this._today, 'days');
    }

    getDaysBetweenDates(range: DateRange, options: { includeEnd: boolean, breaks: DateRange[] } = { includeEnd: false, breaks: [] }): moment.Moment[] {
        const dates = [];

        const currDate = moment.utc(range.start).clone().startOf('day');
        const lastDate = moment.utc(range.end).clone().startOf('day');

        if (options.includeEnd) {
            lastDate.add(1, 'days');
        }

        dates.push(currDate.clone());

        while (currDate.add(1, 'days').diff(lastDate) < 0) {

            const inBreak = options.breaks.some(brk => {
                return currDate.isBetween(brk.start, brk.end, 'days', '[]');
            });

            if (!inBreak) {
                dates.push(currDate.clone());
            }
        }

        return dates;
    }
}
