var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ElementRef, AfterViewInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { CpRequiredOnInit } from '../../../core-configuration/decorators/required-on-init.decorator';
export var MINI_PROFILER_USER_TYPES = ['support'];
var MiniProfilerComponent = /** @class */ (function () {
    function MiniProfilerComponent() {
    }
    MiniProfilerComponent.prototype.ngAfterViewInit = function () {
        this.appendScript();
    };
    MiniProfilerComponent.prototype.appendScript = function () {
        var _this = this;
        var script = document.getElementById('mini-profiler');
        if (!script) { //  && this.show(user)
            var path = environment.apiBaseUrl + '/mini-profiler-resources/';
            script = document.createElement('script');
            script.id = 'mini-profiler';
            script.type = 'text/javascript';
            script.src = path + 'includes.min.js?v=4.0.0.0';
            script.onload = function () { return _this.onScriptLoad(); };
            script.async = true;
            var dataset_1 = {
                'current-id': '',
                'ids': '',
                'version': '4.0.133',
                'path': path,
                'position': 'BottomRight',
                'authorized': 'true',
                'max-traces': '15',
                'toggle-shortcut': 'Alt+P',
                'trivial-milliseconds': '2.0',
                'ignored-duplicate-execute-types': 'Open,OpenAsync,Close,CloseAsync'
            };
            Object.keys(dataset_1).forEach(function (key) {
                script.setAttribute('data-' + key, dataset_1[key]);
            });
            var elm = this.elementRef.nativeElement.parentElement;
            elm.parentElement.replaceChild(script, elm);
        }
    };
    MiniProfilerComponent.prototype.onScriptLoad = function () {
        var _this = this;
        if (MiniProfiler && MiniProfiler.jq && MiniProfiler.jq.ajaxSetup) {
            MiniProfiler.jq.ajaxSetup({
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', "Bearer " + _this.token);
                }
            });
        }
    };
    __decorate([
        CpRequiredOnInit(),
        __metadata("design:type", String)
    ], MiniProfilerComponent.prototype, "token", void 0);
    return MiniProfilerComponent;
}());
export { MiniProfilerComponent };
