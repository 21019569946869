import { Directive, Input, HostBinding } from '@angular/core';

export class Highlights {
    [key: string]: string[];
}

@Directive({ selector: '[hl]' })
export class HighlightsDirective {

    private classes: string[] = [];

    @Input()
    hlOn: string;

    @Input('hl')
    highlights: Highlights;

    @Input('class')
    @HostBinding('class')
    get elementClass(): string {
        const highlights = this.hasHighlight() ? this.highlights[this.hlOn] : [];
        return [...highlights, ...this.classes].join(' ');
    }
    set elementClass(val: string) {
        this.classes = val.split(' ');
    }

    constructor() { }

    private hasHighlight(): boolean {
        return this.highlights && !!this.hlOn && this.highlights[this.hlOn] !== undefined;
    }
}

// hl="{ name: 'text-success' }" hlOn="name"
