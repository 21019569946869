import { Input, Component } from '@angular/core';
import { IEntityListItemDynamicComponent } from '../../../entity-shared/components/entity-list/entity-list-item-dynamic.component';
import { ResponseFilter } from '../../../../models/api/response.model';

@Component({
    selector: 'cp-filter-display-dynamic',
    template: `
        <cp-filter-display
            *ngIf="dynamicValue"
            [filter]="dynamicValue">
        </cp-filter-display>
    `
})
export class FilterDisplayDynamic implements IEntityListItemDynamicComponent {
    @Input() dynamicValue: ResponseFilter;
}
