import { ActivatedRoute, Router } from '@angular/router';
import { map, debounceTime } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ResponseSortService } from '../../common/services/response-sort-service';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
var EntitySearchSortService = /** @class */ (function () {
    function EntitySearchSortService(route, router, responseSortService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.responseSortService = responseSortService;
        this.sorts = [];
        this.sortSource = new BehaviorSubject(this.sorts);
        this.sort$ = this.sortSource.asObservable();
        this.route.queryParams.pipe(debounceTime(100), // testing
        map(function (params) {
            var newSorts = _this.handleParams(params);
            if (!lodashHelper.isEqual(newSorts, _this.sorts)) {
                _this.sorts = newSorts;
                _this.sortSource.next(_this.sorts);
            }
        })).subscribe();
        this.responseSortService.sort$.pipe(map(function (responseSort) {
            if (!responseSort) {
                return;
            }
            // this is making some assumptions.
            // basically I can't tell how many lists are active on the page that is
            // using this service, at this point. So the "prefix" is unknown.
            // therefore I just see if the search already exists in the known sorts
            // and only if it doesn't exist do I trigger an update.
            if (_this.sorts.map(function (s) { return s.pathArray; }).some(function (s) { return responseSort.path.map(function (r) { return r.path; }).some(function (r) { return lodashHelper.isEqual(s, r); }); })) {
                return;
            }
            _this.sorts = [{
                    isFromHttpType: responseSort.type,
                    isFromHttpResponse: true,
                    pathArray: responseSort.path.map(function (i) { return ({ path: i.path, descending: i.descending }); }),
                    prefix: ''
                }];
            _this.sortSource.next(_this.sorts);
        })).subscribe();
    }
    EntitySearchSortService.prototype.triggerSort = function (sort) {
        var _a;
        var key = sort.prefix ? sort.prefix + "~sort" : 'sort';
        this.router.navigate([], {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
            queryParams: (_a = {},
                _a[key] = sort.pathArray.length > 0 ? this.pathToString(sort.pathArray) : undefined,
                _a)
        });
    };
    EntitySearchSortService.prototype.pathToString = function (path) {
        return path.map(function (i) { return "" + (i.descending ? '-' : '') + i.path; }).join(',');
    };
    EntitySearchSortService.prototype.getDefault = function (type) {
        var responseSort = this.responseSortService.getDefault(type);
        if (!responseSort) {
            return undefined;
        }
        return responseSort.map(function (i) { return ({ path: i.path, descending: i.descending }); });
    };
    EntitySearchSortService.prototype.setDefault = function (type, sortString) {
        if (!sortString) {
            return;
        }
        var array = sortString.split(',');
        var sorts = array.map(function (str) {
            var desc = str.startsWith('-');
            var sort = {
                descending: desc,
                path: desc ? str.slice(1) : str
            };
            return sort;
        });
        this.responseSortService.setDefault(type, sorts);
    };
    EntitySearchSortService.prototype.handleParams = function (params) {
        var _this = this;
        return Object.keys(params)
            .filter(function (key) { return key.endsWith('sort'); })
            .map(function (key) {
            if (key === 'sort') {
                return {
                    prefix: '',
                    pathArray: _this.extractPathValues(params[key])
                };
            }
            // has to be a prefix sort if it gets to here
            return {
                prefix: key.slice(0, key.indexOf('~')),
                pathArray: _this.extractPathValues(params[key])
            };
        });
    };
    EntitySearchSortService.prototype.extractPathValues = function (value) {
        return value.split(',').map(function (val) {
            var isDesc = val[0] === '-';
            return {
                descending: isDesc,
                path: isDesc ? val.slice(1) : val
            };
        });
    };
    return EntitySearchSortService;
}());
export { EntitySearchSortService };
