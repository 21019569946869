import { Moment } from 'moment';
import { TimelineWeek } from './timeline-week.model';

export interface TimelinePeriod {
    date: Moment;
    width: number;
    left: number;
}

export class TimelineTime {
    start: Moment;
    end: Moment;
    diffDays: number;

    monthsVisible: number;

    diffWeeks: number;
    diffMonths: number;
    diffYears: number;

    weeksArray: TimelineWeek[] = [];
    weekWidth: number;

    months: TimelinePeriod[];
    years: TimelinePeriod[];
}
