var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as moment from 'moment';
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { ExperienceEntityListItemComponent } from '../../modules/configuration-entity-shared/components/experience-entity-list-item/experience-entity-list-item.component';
import { EntityBase } from '../entity/entity.model';
var providerInclude = new IncludeEntityMetadata('provider', 'providers', 'providerId', 'id');
var siteInclude = new IncludeEntityMetadata('site', 'sites', 'siteId', 'id');
var logInclude = new IncludeEntityMetadata('entity', 'entities', 'id', 'id');
var contactsInclude = new IncludeEntityMetadata('contact', 'contacts', 'contactIds', 'id');
var placementsInclude = new IncludeEntityMetadata('placement', 'placements', 'id', 'positionId', { hideFromList: true });
var Position = /** @class */ (function (_super) {
    __extends(Position, _super);
    function Position() {
        var _this = _super.call(this) || this;
        _this.contactIds = [];
        _this.experienceIds = [];
        return _this;
    }
    __decorate([
        CpProperty({ type: 'int32' }),
        __metadata("design:type", Number)
    ], Position.prototype, "providerId", void 0);
    __decorate([
        CpProperty({ type: 'int32' }),
        __metadata("design:type", Number)
    ], Position.prototype, "siteId", void 0);
    __decorate([
        CpProperty({ type: 'int32' }),
        __metadata("design:type", Array)
    ], Position.prototype, "contactIds", void 0);
    __decorate([
        CpProperty({ type: 'string' }),
        __metadata("design:type", String)
    ], Position.prototype, "name", void 0);
    __decorate([
        CpProperty({ type: 'multilineText' }),
        __metadata("design:type", String)
    ], Position.prototype, "description", void 0);
    __decorate([
        CpProperty({ label: 'Experiences', listHeaderAlign: 'left', type: 'int32', dynamicComponent: ExperienceEntityListItemComponent }),
        __metadata("design:type", Array)
    ], Position.prototype, "experienceIds", void 0);
    __decorate([
        CpProperty({ type: 'int32', showInDetails: true }),
        __metadata("design:type", Number)
    ], Position.prototype, "capacity", void 0);
    __decorate([
        CpProperty({ type: 'date' }),
        __metadata("design:type", Object)
    ], Position.prototype, "availableFrom", void 0);
    __decorate([
        CpProperty({ type: 'date' }),
        __metadata("design:type", Object)
    ], Position.prototype, "availableTo", void 0);
    Position = __decorate([
        CpClass({
            include: [providerInclude, siteInclude, logInclude],
            includeMany: [contactsInclude, placementsInclude],
            metadataType: 'position',
            create: newPosition
        }),
        __metadata("design:paramtypes", [])
    ], Position);
    return Position;
}(EntityBase));
export { Position };
export function newPosition() { return new Position(); }
