var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { ClaimDisplayDynamicComponent } from '../../modules/role/components/claim/claim-display-dynamic.component';
var Role = /** @class */ (function () {
    function Role() {
    }
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], Role.prototype, "id", void 0);
    __decorate([
        CpProperty({ type: 'string' }),
        __metadata("design:type", String)
    ], Role.prototype, "name", void 0);
    __decorate([
        CpProperty({ type: 'multilineText' }),
        __metadata("design:type", String)
    ], Role.prototype, "description", void 0);
    __decorate([
        CpProperty({ type: 'string' }),
        __metadata("design:type", String)
    ], Role.prototype, "userType", void 0);
    __decorate([
        CpProperty({ type: 'dynamic', dynamicComponent: ClaimDisplayDynamicComponent }),
        __metadata("design:type", Array)
    ], Role.prototype, "claims", void 0);
    Role = __decorate([
        CpClass({
            create: newRole,
            metadataType: 'role',
            defaultSort: [{ key: 'name' }]
        })
    ], Role);
    return Role;
}());
export { Role };
export function newRole() { return new Role(); }
