import { Address } from '../shared/address.model';
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IIncludeEntity, ProviderSubEntityIncludeType } from '../shared/include.model';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { Provider } from './provider.model';
import { ArchiveDisplayDynamicComponent } from '../../modules/display-helper/components/archive/archive-display-dynamic.component';
import { Position } from './position.model';
import { Contact } from './contact.model';
import { EntityLog } from '../shared/logged.model';

// one to one
const providerInclude = new IncludeEntityMetadata<Site, Provider>('provider', 'providers', 'providerId', 'id');
const logInclude = new IncludeEntityMetadata<Site, EntityLog>('entity', 'entities', 'id', 'id');

// one to many
const positionsInclude = new IncludeEntityMetadata<Site, Position>('position', 'positions', 'id', 'siteId');
const contactsInclude = new IncludeEntityMetadata<Site, Contact>('contact', 'contacts', 'id', 'siteIds');

@CpClass({
    include: [providerInclude, logInclude],
    includeMany: [positionsInclude, contactsInclude],
    metadataType: 'site',
    create: newSite
})
export class Site extends Address implements IIncludeEntity<ProviderSubEntityIncludeType> {
    @CpProperty({ type: 'string' })
    name: string;

    @CpProperty({ type: 'multilineText' })
    description: string;

    @CpProperty({ type: 'int32' })
    providerId: number;

    @CpProperty({ type: 'int32', dynamicComponent: ArchiveDisplayDynamicComponent })
    archived: boolean;
}

export function newSite() { return new Site(); }
