import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { MomentHelper } from '../../../core-configuration/helpers/moment.helper';
import { environment } from '../../../../../environments/environment';
var DateRangeDisplayComponent = /** @class */ (function () {
    function DateRangeDisplayComponent(momentHelper) {
        this.momentHelper = momentHelper;
        this.hideEnd = false;
        this.hideIcon = false;
        this.disableTextClasses = false;
        this.initialized = false;
    }
    Object.defineProperty(DateRangeDisplayComponent.prototype, "isActive", {
        get: function () { return this.activeWhen === 'now'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeDisplayComponent.prototype, "isPast", {
        get: function () { return this.activeWhen === 'past'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeDisplayComponent.prototype, "isFuture", {
        get: function () { return this.activeWhen === 'future'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeDisplayComponent.prototype, "dateFormat", {
        get: function () { return environment.momentDateFormat; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeDisplayComponent.prototype, "icon", {
        get: function () {
            if (this.isActive) {
                return 'circle';
            }
            if (this.isPast) {
                return 'caret-down';
            }
            if (this.isFuture) {
                return 'caret-up';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeDisplayComponent.prototype, "iconTitle", {
        get: function () {
            if (this.isActive) {
                return 'Currently Active';
            }
            if (this.isPast) {
                return 'Past';
            }
            if (this.isFuture) {
                return 'Upcoming';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeDisplayComponent.prototype, "endIsSame", {
        get: function () {
            if (!this.end) {
                return false;
            }
            return this.end.isSame(this.start, 'days');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeDisplayComponent.prototype, "activeClass", {
        get: function () {
            return {
                'text-success': this.isActive,
                'text-muted': !this.isActive
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeDisplayComponent.prototype, "textClass", {
        get: function () {
            if (this.disableTextClasses) {
                return;
            }
            return this.activeClass;
        },
        enumerable: true,
        configurable: true
    });
    DateRangeDisplayComponent.prototype.ngOnInit = function () {
        if (!this.start) {
            throw new Error('start is required');
        }
        this.initialized = true;
        this.calculateLabels();
    };
    DateRangeDisplayComponent.prototype.ngOnChanges = function (changes) {
        if (changes.start || changes.end) {
            this.calculateLabels();
        }
    };
    DateRangeDisplayComponent.prototype.calculateLabels = function () {
        if (!this.initialized) {
            return;
        }
        var today = this.momentHelper.today();
        if (this.start.isAfter(today, 'days')) {
            this.activeWhen = 'future';
            return;
        }
        if (this.start.isBefore(today, 'days')) {
            if (!this.end || this.end.isSameOrAfter(today, 'days')) {
                this.activeWhen = 'now';
                return;
            }
        }
        this.activeWhen = 'past';
    };
    return DateRangeDisplayComponent;
}());
export { DateRangeDisplayComponent };
