import { Injectable } from '@angular/core';
import { PositionHttpService } from './http/position.http.service';
import { Position, CapacityResult } from '../../../models/provider/position.model';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { ResponsePositionInclude, ResponseModelListQuery } from '../../../models/api/response.model';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { IListService, IIdListService } from '../../entity-shared/base/entity-search.base';
import { IMergeService } from '../../entity-shared/components/entity-merge/entity-merge.base.component';
import { ProviderBaseService } from './provider-base.service';
import { RequestListOptions } from '../../../models/api/request-list-options.model';
import { Observable } from 'rxjs';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { Moment } from 'moment';
import { PositionIncludeOption } from '../../../models/shared/include.model';


@Injectable()
export class PositionService
    extends
    ProviderBaseService<Position, PositionIncludeOption, ResponsePositionInclude>
    implements
    IListService<Position, PositionIncludeOption, ResponsePositionInclude>,
    IUpdatableService<Position>,
    IIdListService,
    IMergeService<Position> {

    protected includeOptions: PositionIncludeOption[] = ['sites', 'contacts'];

    constructor(
        protected httpService: PositionHttpService,
        protected apiCacheService: ApiCacheService,
        protected includeMapperService: IncludeMapperService
    ) {
        super('position', httpService, apiCacheService, includeMapperService);
    }

    capacity(start: Moment, end: Moment, options: RequestListOptions<PositionIncludeOption>): Observable<ResponseModelListQuery<CapacityResult>> {
        return this.httpService.capacity(start, end, options);
    }

    protected createEntity = () => new Position();

    protected onAfterCreate(entity: Position): RequestFilter[] | void {
        this.apiCacheService.clearCacheByEntityId('providers', entity.providerId);
        return [];
    }

    protected onAfterUpdate(entity: Position): void {
        // archivable entities need to clear master types just to be safe
        this.apiCacheService.clearCacheByType('providers');
        this.apiCacheService.clearCacheByType('positions');
        // this.apiCacheService.clearCacheByEntityId('positions', entity.id);
        return;
    }
}
