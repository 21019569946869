import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../../api/services/api-http-client.service';
import { ResponseNoInclude } from '../../../../models/api/response.model';

import { HttpClient } from '@angular/common/http';
import { AbsenceType } from '../../../../models/absence-type/absence-type.model';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { NoIncludeOption } from '../../../../models/shared/include.model';

@Injectable()
export class AbsenceTypeHttpService
    extends ApiHttpClientService<AbsenceType, NoIncludeOption, ResponseNoInclude> {

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super('absenceTypes', httpClient, apiRequestHelperService);
    }
}
