import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { TimelineTime } from '../../../models/timeline/timeline-time.model';
import { MomentHelper } from '../../core-configuration/helpers/moment.helper';
import { TimelineWeek } from '../../../models/timeline/timeline-week.model';
import { TimelineWeekDayDiff } from '../../../models/timeline/timeline-shared.model';
import { TimelineBreak, TimelineIdItem } from '../../../models/timeline/timeline-item.model';
import { DateRange } from '../../../models/shared/date-range.model';

@Injectable()
export class TimelineCoreService {

    constructor(
        private momentHelper: MomentHelper
    ) { }


    createTimeData(startOfWeek: moment.Moment, startOfEndWeek: moment.Moment): TimelineTime {

        // start week, start day - based on the start of the week/ end of the week
        const weekStart = startOfWeek;
        const weekEnd = startOfEndWeek;

        if (weekStart.isSameOrAfter(weekEnd)) {
            throw new Error('start week cannot be after the end week');
        }

        const data: TimelineTime = new TimelineTime();

        // check logic here, how do we calculate today?
        const nowUtc = this.momentHelper.today();

        data.start = weekStart.clone();
        data.end = weekEnd.clone();

        // todo look into the "difference" logic for moment, cause for months and years, it's quite tricky
        // we shouldnt' have to worry about weeks, as we force the start-week and end-week logic.
        // however for months, we need to be careful.
        // By default, moment#diff will truncate the result to zero decimal places, returning an integer
        // passing "true" into the third parameter gets the floating point value, and we should use this for months.
        data.diffDays = weekEnd.diff(weekStart, 'days');
        data.diffWeeks = weekEnd.diff(weekStart, 'weeks');
        data.diffMonths = weekEnd.diff(weekStart, 'months', true);

        const weeksArray: TimelineWeek[] = [];
        const nowUtcMinusAWeek: moment.Moment = nowUtc.clone().add('-1', 'weeks');
        let left = 0;
        const leftAdd = (100 / data.diffWeeks);

        for (let i = 0; i < data.diffWeeks; i++) {
            const date = weekStart.clone().add(i, 'weeks');
            weeksArray.push({
                date: date,
                isPast: date.isSameOrBefore(nowUtcMinusAWeek),
                left: left
            });

            left += leftAdd;
        }

        data.months = [];

        // months
        for (let i = 0; i < data.diffMonths; i++) {
            // at the moment, this ignores the "first" month
            const month = weekStart.clone().add(i + 1, 'months').startOf('month');
            const monthEnd = month.clone().endOf('month');
            const weekDiff = month.diff(weekStart, 'weeks');

            // cut the tail weeks off from the month, we don't need to show them
            if (weekDiff > weeksArray.length - 1) { break; }

            const daysInMonth = month.daysInMonth();
            let daysInMonthShown;

            // to calculate the width
            // if the month is included entirely in the span of time, use the days in month to calulate the width
            // if the month is NOT (ie, it ends after the last specified date) - take a diff, and width it
            const monthEndDiff = monthEnd.diff(weekEnd, 'days');
            if (monthEndDiff < 0) {
                daysInMonthShown = daysInMonth;
            } else {
                daysInMonthShown = daysInMonth - monthEndDiff - 1;
            }

            data.months.push({
                date: month,
                left: this.calculateGlobalLeft(month, data.start, data.diffDays),
                width:  (daysInMonthShown / data.diffDays) * 100
            });
        }

        data.weeksArray = weeksArray;
        data.weekWidth = (100 / (data.diffWeeks));

        return data;
    }

    calculateWeekAndDay(date: moment.Moment, startDate: moment.Moment): TimelineWeekDayDiff {
        const dayDiff = date.diff(startDate, 'days') % 7;
        const weekDiff = date.diff(startDate, 'weeks');

        return {
            weekIndex: weekDiff,
            dayDiff
        };
    }

    calculateWhenActive(timelineItem: DateRange): 'past' | 'future' | 'now' {
        const today = this.momentHelper.today();

        if (timelineItem.end.isSameOrBefore(today, 'days')) {
            return 'past';
        }
        if (timelineItem.start.isSameOrAfter(today, 'days')) {
            return 'future';
        }
        return 'now';
    }

    calculateGlobalLeft(start: moment.Moment, timelineStart: moment.Moment, totalTimelineDays: number) {
        const startsBeforeTimelineDate = start.isBefore(timelineStart, 'days');
        return startsBeforeTimelineDate ? 0 : start.diff(timelineStart, 'days') / totalTimelineDays * 100;
    }

    calculateGlobalWidth(days: number, totalTimelineDays: number) {
        return (days / totalTimelineDays) * 100
             + (1 / totalTimelineDays * 100); // +1 day
    }

    timelineCssClasses(whenActive: string) {
        const cssClasses = {};

        cssClasses['tl-active-' + whenActive] = true;

        return cssClasses;
    }

    createTimelineBreaks(item: TimelineIdItem, possibleOverlaps: TimelineIdItem[], time: TimelineTime, height: number, rowIndex: number): TimelineBreak[] {

        const timelineBreaks: TimelineBreak[] = possibleOverlaps
            // overlap is not itself
            .filter(overlap => overlap.id !== item.id)
            // overlap exists in between timeline dates
            .filter(overlap => overlap.start.isBefore(time.end, 'days') && overlap.end.isSameOrAfter(time.start, 'days'))
            // overlap exists for current placement
            .filter(overlap =>
                overlap.start.isBefore(item.end, 'days') && overlap.end.isSameOrAfter(item.start, 'days')
                && (overlap.start.isAfter(item.start, 'days') || (overlap.start.isSame(item.start, 'days') && overlap.end.isBefore(item.end, 'days')))
            )
            .map(overlap => {
                // start date
                const startToCalcFrom = overlap.start.isBefore(time.start, 'days') ? time.start : overlap.start;

                // end date
                const breakEndBeforeItem = overlap.end.isBefore(item.end, 'days');
                const breakEndToCalcFrom = breakEndBeforeItem ? overlap.end : item.end;
                const breakDays = breakEndToCalcFrom.diff(startToCalcFrom, 'days');

                // console.log('break', item, overlap, breakDays, rowIndex);

                return {
                    width: this.calculateGlobalWidth(breakDays, time.diffDays),
                    left: this.calculateGlobalLeft(startToCalcFrom, time.start, time.diffDays),
                    height: height,
                    top: rowIndex * height
                };
            });

        return timelineBreaks;
    }

    calculateRows<TDateRange extends DateRange>(dateRanges: TDateRange[]): TDateRange[][] {
        const rows: TDateRange[][] = [];

        dateRanges
            .sort(this.momentHelper.dateSort)
            .forEach(block => this.rowPush(block, rows, 0));

        return rows;
    }

    private rowPush(dateRange: DateRange, rows: DateRange[][], rowIndex: number) {
        // debugger;
        const row = rows[rowIndex];

        if (row === undefined) {
            rows.push([dateRange]);
            return;
        }

        if (row.some(existing => existing.start.isSameOrBefore(dateRange.end) && existing.end.isAfter(dateRange.start))) {
            this.rowPush(dateRange, rows, rowIndex + 1);
            return;
        }

        row.push(dateRange);
    }
}
