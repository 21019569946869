import { Injectable } from '@angular/core';
import { FilterOperator, FilterValueType, FilterConditionNameValue, ALL_FILTER_OPERATORS, FilterPropertyType, EntityPropertiesConfig } from '../../../models/filters/filter.model';
import { FilterPath, FilterPropertyExtended } from './filter-config-helper.service';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from '../../core-configuration/services/configuration.service';
import { FilterConfigInnerService } from './filter-config-inner.service';
import { MomentHelper, API_DATE_FORMAT } from '../../core-configuration/helpers/moment.helper';
import * as moment from 'moment';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { toPlural } from '../../entity-shared/helper/entity-shared.helper';
import { FilterPathHelperService } from './filter-path-helper.service';
import { MetadataType } from '../../../models/entity/entity.type';

export const PREFIX_SEPERATOR = '~';

export type QueryFilterPrefixType = '' | 'student' | 'position';

export interface QueryFilterKey {
    prefix: string;
    type: MetadataType;
}

export interface InternalCondition {
    operator: FilterOperator;
    values: (FilterConditionNameValue | FilterValueType)[];
}

export interface InternalFilterBase {
    path: FilterPath[];
    condition: InternalCondition;
}

export interface InternalFilter extends InternalFilterBase {
    property: FilterPropertyExtended;
    entityIdProperty?: FilterPropertyExtended;
}

export interface InternalPathStringCondition {
    path: FilterPath[];
    condition: string;
}

@Injectable()
export class FilterQueryHelperService {

    // "option" is qustionable as depending on where the filter comes from, it could be an object
    private filterTypeNumbers: FilterPropertyType[] = ['int16', 'int32', 'double', 'option'];
    private filterTypeDates: FilterPropertyType[] = ['dateTime', 'date'];

    constructor(
        private filterConfigInnerService: FilterConfigInnerService,
        private filterPathHelper: FilterPathHelperService,
        private configService: ConfigurationService,
        private momentHelper: MomentHelper
    ) {

    }

    filters(key: QueryFilterKey, queryParams: Params): Observable<InternalFilter[]> {
        const possiblePathConditions = this.extractPathConditions(key, queryParams);
        return this.configService.getFilterProperties().pipe(
            map(config => this.handle(config, possiblePathConditions, key.type))
        );
    }

    createQueryParams(queryKey: QueryFilterKey, internalFilters: InternalFilterBase[]): Object | undefined {
        const obj = {};

        internalFilters.map(f => {
            const key = this.createQueryKey(queryKey, f.path);
            const value = this.createQueryValueStringFromCondition(f.condition);
            this.appendToQueryObject(obj, key, value);
        });

        return obj;
    }

    extractNonFilterParams(queryKey: QueryFilterKey, queryParams: Params): Observable<Object> {

        const baseEntityFilterParametersObs = this.configService.getFilterProperties().pipe(
            map(config => {
                const baseProperties = this.filterConfigInnerService.getBaseEntityProperties(config, queryKey.type);
                return baseProperties.map(i => i.key);
            })
        );

        return baseEntityFilterParametersObs.pipe(
            map(propertyKeys => {
                const negativePropertyKeys = [...propertyKeys.map(i => '!' + i)];
                const allPropertyKeys = [...propertyKeys, ...negativePropertyKeys]
                    .map(key => queryKey.prefix ? queryKey.prefix + PREFIX_SEPERATOR + key : key);

                const obj = {};
                // uses the base config dictionary to detemine if the parameter is a filter parameter
                //
                Object.keys(queryParams)
                    .filter(paramKey =>
                        !allPropertyKeys.includes(paramKey) &&
                        !allPropertyKeys.some(propertyKey => paramKey.startsWith(propertyKey + '.')))
                    .map(key => this.appendToQueryObject(obj, key, queryParams[key]));

                return obj;
            }));
    }

    // possibly temporary
    createRequestFilters(type: MetadataType, filters: InternalFilter[], options: { pathPrefix: string } = { pathPrefix: '' }): RequestFilter[] {
        if (filters.length === 0) { return []; }

        return filters.map(filter => {
            const lastPathIndex = filter.path.length - 1;

            let path = filter.path.map((p, index) => {

                if (index === lastPathIndex) {
                    return `${p.without ? '!' : ''}${p.entityIdKey || p.key}`;
                }

                return `${p.without ? '!' : ''}${p.key}`;
            }).join('.');

            if (options.pathPrefix) {
                path = options.pathPrefix + '.' + path;
            }

            return new RequestFilter(
                toPlural(type),
                path,
                filter.condition.operator,
                filter.condition.values as any
            );
        });
    }

    createQueryValueStringFromCondition(condition: InternalCondition): string {
        return `${condition.operator}(${condition.values.map(value => this.createQueryValueString(condition.operator, value)).join(',')})`;
    }

    appendToQueryObject(obj: Object, key: string, value: string) {
        const existingValue = obj[key];
        if (!existingValue) {
            obj[key] = value;

        } else if (existingValue instanceof Array) {
            obj[key] = [...existingValue, value];
        } else {
            obj[key] = [existingValue, value];
        }
    }

    private createQueryKey(queryKey: QueryFilterKey, path: FilterPath[]): string {
        const queryPrefix = queryKey.prefix === '' ? '' : queryKey.prefix + PREFIX_SEPERATOR;
        const finalIndex = path.length - 1;
        const queryPath = path.map((item, index) => {
            const key = index === finalIndex && item.entityIdKey ? item.entityIdKey : item.key;
            return `${item.without ? '!' : ''}${key}`;
        }).join('.');

        return `${queryPrefix}${queryPath}`;
    }

    private createQueryValueString(operator: FilterOperator, conditionValue: FilterValueType | FilterConditionNameValue): string {

        // handle dates/ moments
        const isMoment = moment.isMoment(conditionValue);
        if (isMoment) {
            return '"' + (conditionValue as moment.Moment).format(API_DATE_FORMAT) + '"';
        }

        if (!isMoment && typeof(conditionValue) === 'object') {
            // this could throw a null reference error on value, but it really should always be there...
            return this.cleanString((conditionValue as FilterConditionNameValue).value.toString());
        }

        return this.cleanString(conditionValue as any);
    }

    private cleanString(str: string | number | boolean) {
        str = '' + str; // make everything a string
        str = str.replace(/"/g, '""');
        return '"' + str + '"';
    }

    private handle(config: EntityPropertiesConfig, possiblePathConditions: InternalPathStringCondition[], type: MetadataType): InternalFilter[] {
        const filters: InternalFilter[] = [];
        possiblePathConditions.map(internal => {
            const propertyPathResult = this.filterConfigInnerService.getPropertyPathByPath(config, type, internal.path);

            if (!propertyPathResult) {
                return;
            }

            const final = propertyPathResult[propertyPathResult.length - 1];

            // cater for conditions that are incorrect
            const condition = this.extractCondition(internal.condition, final.entityIdProperty ? final.entityIdProperty.type : final.property.type);

            if (!condition) {
                return;
            }


            const filter: InternalFilter = {
                path: this.filterConfigInnerService.propertyPathToFilterPath(propertyPathResult),
                condition: condition,
                property: final.property,
                entityIdProperty: final.entityIdProperty,
            };
            filters.push(filter);
        });

        return filters.filter(i => i !== undefined) as InternalFilter[];
    }

    private extractPathConditions(key: QueryFilterKey, queryParams: Params): InternalPathStringCondition[] {
        const filteredQueryParams = this.filterToRelevantParameters(key, queryParams);

        const possiblyDoubleArray = Object.keys(filteredQueryParams)
            .map(queryKey => {
                const possiblePath = this.filterPathHelper.stringToFilterPath(queryKey);
                let conditionString = filteredQueryParams[queryKey] as string | string[];
                conditionString = Array.isArray(conditionString) ? conditionString : [conditionString];

                return conditionString.map(condition => ({
                    path: possiblePath,
                    condition: condition
                }));
        });

        return lodashHelper.flatten(possiblyDoubleArray);
    }

    private filterToRelevantParameters(key: QueryFilterKey, queryParams: Params): Params {
        const obj = {};
        Object.keys(queryParams)
            .filter(queryKey => key.prefix === '' || queryKey.startsWith(key.prefix + PREFIX_SEPERATOR))
            .map(queryKey => {
                if (key.prefix === '') {
                    this.appendToQueryObject(obj, queryKey, queryParams[queryKey]);
                    return;
                }

                const slice = queryKey.slice(queryKey.indexOf(PREFIX_SEPERATOR) + 1);
                this.appendToQueryObject(obj, slice, queryParams[queryKey]);
            });

        return obj;
    }

    private extractCondition(stringValue: string, propertyType: FilterPropertyType): InternalCondition | undefined {
        const openBracket = stringValue.indexOf('(');
        const closeBracket = stringValue.lastIndexOf(')');

        if (openBracket === -1 || closeBracket === -1) {
            return undefined;
        }

        const operator = stringValue.slice(0, openBracket) as FilterOperator;
        if (!ALL_FILTER_OPERATORS.includes(operator)) {
            return undefined;
        }

        if (operator === 'any') {
            return {
                operator: operator,
                values: []
            };
        }

        // asummes that all values are wrapped in double quotes
        const innerValueArray = stringValue.slice(openBracket + 2, closeBracket - 1).split('","');
        const conditionValues = innerValueArray.map(str => {
            if (this.filterTypeDates.includes(propertyType)) {
                // handle moments
                const validMoment = this.momentHelper.stringToMoment(str);
                if (validMoment) {
                    return validMoment;
                }

                // intervals and ranges are just strings
                return str;
            }

            if (propertyType === 'boolean') {
                if (str.toLowerCase() === 'true') {
                    return true;
                } else if (str.toLowerCase() === 'false') {
                    return false;
                }
            }

            if (this.filterTypeNumbers.includes(propertyType)) {
                return +str;
            }

            return str;
        });

        return {
            operator: operator,
            values: conditionValues
        };
    }
}
