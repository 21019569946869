/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./badge.component";
import * as i3 from "../../services/display-helper.service";
var styles_BadgeComponent = [];
var RenderType_BadgeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BadgeComponent, data: {} });
export { RenderType_BadgeComponent as RenderType_BadgeComponent };
export function View_BadgeComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "badge"]], [[4, "background-color", null]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "badge"; var currVal_2 = _co.textClass(_co.color); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.label; _ck(_v, 2, 0, currVal_3); }); }
export function View_BadgeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-badge", [], null, null, null, View_BadgeComponent_0, RenderType_BadgeComponent)), i0.ɵdid(1, 49152, null, 0, i2.BadgeComponent, [i3.DisplayHelperService], null, null)], null, null); }
var BadgeComponentNgFactory = i0.ɵccf("cp-badge", i2.BadgeComponent, View_BadgeComponent_Host_0, { color: "color", label: "label" }, {}, []);
export { BadgeComponentNgFactory as BadgeComponentNgFactory };
