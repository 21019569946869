import { NgModule, ModuleWithProviders } from '@angular/core';
import { ApiCacheService } from './services/api-cache.service';
import { ApiCacheSimpleService } from './services/api-cache-simple.service';
import { ApiRequestHelperService } from './services/api-request-helper.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: []
})
export class ApiModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [
                ApiCacheService,
                ApiCacheSimpleService,
                ApiRequestHelperService
            ]
        };
    }
}
