var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgbDatepickerHelper } from './datepicker.helper';
export var NGB_DATE_FORMAT = 'L';
var MomentLocaleParserFormatter = /** @class */ (function (_super) {
    __extends(MomentLocaleParserFormatter, _super);
    function MomentLocaleParserFormatter(helper) {
        var _this = _super.call(this) || this;
        _this.helper = helper;
        return _this;
    }
    MomentLocaleParserFormatter.prototype.parse = function (value) {
        var parsed = moment.utc(value, NGB_DATE_FORMAT);
        // note to lint punkasses: i had to do it.
        if (!parsed.isValid()) {
            return null;
        }
        return this.helper.convertFromMoment(parsed);
    };
    MomentLocaleParserFormatter.prototype.format = function (date) {
        if (!date) {
            return '';
        }
        return this.helper.convertToMoment(date).format(NGB_DATE_FORMAT);
    };
    return MomentLocaleParserFormatter;
}(NgbDateParserFormatter));
export { MomentLocaleParserFormatter };
