import { EntityBase } from '../entity/entity.model';
import { CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { ArchiveDisplayDynamicComponent } from '../../modules/display-helper/components/archive/archive-display-dynamic.component';
import { RoleEntityListItemComponent } from '../../modules/configuration-entity-shared/components/role-entity-list-item/role-entity-list-item.component';

export class UserEntity extends EntityBase {
    @CpProperty({ type: 'string' })
    firstName: string;

    @CpProperty({ type: 'string' })
    lastName: string;

    @CpProperty({ type: 'phoneNumber', showInDetails: true })
    phoneNumber: string;

    @CpProperty({ type: 'emailAddress', showInDetails: true })
    email: string;

    @CpProperty({ type: 'int32', label: 'Roles', listHeaderAlign: 'left', dynamicComponent: RoleEntityListItemComponent })
    roleIds: number[];

    @CpProperty({ type: 'boolean', dynamicComponent: ArchiveDisplayDynamicComponent })
    archived: boolean;

    constructor() {
        super();

        this.roleIds = [];
    }
}
