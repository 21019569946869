import { Directive, Input, ComponentRef, ComponentFactory, ComponentFactoryResolver, ViewContainerRef, TemplateRef } from '@angular/core';
import { LoaderComponent } from '../../../display-helper/components/loader/loader.component';
import { LoaderErrorComponent } from '../../components/loader/loader-error.component';

@Directive({ selector: '[cpLoading]' })
export class LoaderDirective {
    loaderFactory: ComponentFactory<LoaderComponent>;
    loaderComponent: ComponentRef<LoaderComponent>;

    loaderErrorFactory: ComponentFactory<LoaderErrorComponent>;
    loaderErrorComponent: ComponentRef<LoaderErrorComponent>;

    @Input() cpLoadingSmall = false;
    @Input() cpLoadingDisableFadeIn = false;

    @Input()
    set cpLoading(loading: boolean) {
        this.vcRef.clear();
        this._loading = loading;

        this.handleTemplate(loading);
    }
    get cpLoading(): boolean { return this._loading; }

    @Input()
    set cpLoadingError(error: any) {
        this.vcRef.clear();
        this._error = error;

        this.handleTemplate(this.cpLoading);
    }
    get cpLoadingError(): any { return this._error; }

    private _loading: boolean;
    private _error: any;

    constructor(
        private templateRef: TemplateRef<any>,
        private vcRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
        // Create resolver for loading component
        this.loaderFactory = this.componentFactoryResolver.resolveComponentFactory(LoaderComponent);
        this.loaderErrorFactory = this.componentFactoryResolver.resolveComponentFactory(LoaderErrorComponent);
    }

    private handleTemplate(loading: boolean) {

        if (this.cpLoadingError) {
            this.loaderErrorComponent = this.vcRef.createComponent(this.loaderErrorFactory);
            this.loaderErrorComponent.instance.error = this.cpLoadingError;
        } else if (loading) {
            // create and embed an instance of the loading component
            this.loaderComponent = this.vcRef.createComponent(this.loaderFactory);
            this.loaderComponent.instance.small = this.cpLoadingSmall;
            this.loaderComponent.instance.disableFadeIn = this.cpLoadingDisableFadeIn;
        } else {
            // embed the contents of the host template
            this.vcRef.createEmbeddedView(this.templateRef);
        }
    }
}
