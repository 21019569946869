import { Component, ElementRef, ViewChild, AfterViewInit, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AuthUserType } from '../../../../models/authentication/authentication.model';
import { CpRequiredOnInit } from '../../../core-configuration/decorators/required-on-init.decorator';

export const MINI_PROFILER_USER_TYPES: AuthUserType[] = ['support'];
declare var MiniProfiler: any;

@Component({
    selector: 'cp-mini-profiler',
    template: `<div #elementRef style.display="none"></div>`
})
export class MiniProfilerComponent implements AfterViewInit {

    @ViewChild('elementRef') elementRef: ElementRef;

    @CpRequiredOnInit() @Input() token: string;

    ngAfterViewInit(): void {
        this.appendScript();
    }

    appendScript(): void {
        let script = document.getElementById('mini-profiler') as HTMLScriptElement;

        if (!script) { //  && this.show(user)
            const path = environment.apiBaseUrl + '/mini-profiler-resources/';

            script = document.createElement('script');

            script.id = 'mini-profiler';
            script.type = 'text/javascript';
            script.src = path + 'includes.min.js?v=4.0.0.0';
            script.onload = () => this.onScriptLoad();
            script.async = true;

            const dataset = {
                'current-id': '',
                'ids': '',
                'version': '4.0.133',
                'path': path,
                'position': 'BottomRight',
                'authorized': 'true',
                'max-traces': '15',
                'toggle-shortcut': 'Alt+P',
                'trivial-milliseconds': '2.0',
                'ignored-duplicate-execute-types': 'Open,OpenAsync,Close,CloseAsync'
            };

            Object.keys(dataset).forEach(key => {
                script.setAttribute('data-' + key, dataset[key]);
            });

            const elm = this.elementRef.nativeElement.parentElement;
            elm.parentElement.replaceChild(script, elm);
        }
    }

    private onScriptLoad() {
        if (MiniProfiler && MiniProfiler.jq && MiniProfiler.jq.ajaxSetup) {
            MiniProfiler.jq.ajaxSetup({
                beforeSend: (xhr: XMLHttpRequest) => {
                    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
                }
            });
        }
    }
}
