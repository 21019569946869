import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DateRange } from '../../../models/shared/date-range.model';

@Injectable()
export class DatepickerFormService {

    getMinDateWithinRangeArray(index: number, minDate: moment.Moment, maxDate: moment.Moment | undefined, range: DateRange[]): moment.Moment | undefined {
        // now do all the mutli-control logic
        if (index === 0) { return minDate; }

        const previousDateRangeArray = range
            .filter((val, itemIndex) => val && val.end && itemIndex < index);

        if (previousDateRangeArray.length === 0) { return minDate; }

        const previousMaxValue = previousDateRangeArray[previousDateRangeArray.length - 1].end;

        if (maxDate && previousMaxValue.isSame(maxDate, 'days')) { return maxDate; }

        return previousDateRangeArray[previousDateRangeArray.length - 1].end.clone().add(1, 'days');
    }

    getMaxDateWithinRangeArray(index: number, maxDate: moment.Moment, range: DateRange[]): moment.Moment | undefined {

        if (index === range.length - 1) { return maxDate; }

        const nextDateRangeArray = range
            .filter((val, itemIndex) => val && val.start && itemIndex > index);

        if (nextDateRangeArray.length === 0) { return maxDate; }

        return nextDateRangeArray[0].start.clone();
    }
}
