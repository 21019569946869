var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as moment from 'moment';
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { MinutesPipe } from '../../modules/display-helper/pipes/minutes.pipe';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { ExperienceEntityListItemComponent } from '../../modules/configuration-entity-shared/components/experience-entity-list-item/experience-entity-list-item.component';
import { EntityBase } from '../entity/entity.model';
import { DateRangeBreaksDisplayDynamicComponent } from '../../modules/date-range-display-dynamic/components/date-range/date-range-breaks-display-dynamic.component';
var planInclude = new IncludeEntityMetadata('plan', 'plans', 'planId', 'id');
var logInclude = new IncludeEntityMetadata('entity', 'entities', 'id', 'id');
var Block = /** @class */ (function (_super) {
    __extends(Block, _super);
    function Block() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Block.prototype, "dates", {
        get: function () {
            return {
                start: this.start,
                end: this.end,
                overlaps: []
            };
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        CpProperty({ label: 'Plan Id', type: 'int32' }),
        __metadata("design:type", Number)
    ], Block.prototype, "planId", void 0);
    __decorate([
        CpProperty({ label: 'Experience', type: 'int32', listHeaderAlign: 'left', defaultListVisible: true, dynamicComponent: ExperienceEntityListItemComponent }),
        __metadata("design:type", Number)
    ], Block.prototype, "experienceId", void 0);
    __decorate([
        CpProperty({ label: 'Expected Hours', type: 'int32', defaultListVisible: true, showInDetails: true, pipe: { pipe: new MinutesPipe() } }),
        __metadata("design:type", Number)
    ], Block.prototype, "minutes", void 0);
    __decorate([
        CpProperty({ label: 'Start', type: 'date' }),
        __metadata("design:type", Object)
    ], Block.prototype, "start", void 0);
    __decorate([
        CpProperty({ label: 'End', type: 'date' }),
        __metadata("design:type", Object)
    ], Block.prototype, "end", void 0);
    __decorate([
        CpProperty({ type: 'dynamic', defaultListVisible: true, dynamicComponent: DateRangeBreaksDisplayDynamicComponent }),
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], Block.prototype, "dates", null);
    Block = __decorate([
        CpClass({
            include: [planInclude, logInclude],
            metadataType: 'planBlock',
            defaultSort: [{ key: 'start' }],
            create: newBlock
        })
    ], Block);
    return Block;
}(EntityBase));
export { Block };
export function newBlock() { return new Block(); }
