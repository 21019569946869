import { Injectable } from '@angular/core';
import { DateRange } from '../../../models/shared/date-range.model';
import { API_DATE_FORMAT, MomentHelper } from './moment.helper';
import { DateRangeResult } from '../../../models/shared/search.model';
import * as moment from 'moment';

const RANGE_SEPERATOR = '|';

@Injectable()
export class DateRangeHelper {

    constructor(
        private momentHelper: MomentHelper
    ) {

    }

    rangeToString(value: DateRange | undefined | null): string {
        if (!value) { return ''; }

        return `${value.start.format(API_DATE_FORMAT)}${RANGE_SEPERATOR}${value.end.format(API_DATE_FORMAT)}`;
    }

    convertToRange(value: string | DateRangeResult | moment.Moment): DateRange | undefined {

        if (typeof value === 'object') {
            if (moment.isMoment(value)) {
                return undefined;
            }

            if (value.from && value.to) {
                return {
                    start: value.from.clone(),
                    end: value.to.clone()
                };
            }

            if (!value.value || typeof(value.value) !== 'string') {
                return undefined;
            }

            value = value.value as string;
        }

        if (typeof value !== 'string') {
            return undefined;
        }

        const split = value.split(RANGE_SEPERATOR);
        if (split.length !== 2) {
            return undefined;
        }

        const start = this.momentHelper.stringToMoment(split[0]);
        const end = this.momentHelper.stringToMoment(split[1]);

        if (
            !start ||
            !start.isValid() ||
            !end ||
            !end.isValid()
        ) {
            return undefined;
        }

        return {
            start: start,
            end: end
        };
    }
}
