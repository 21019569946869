import { Contact } from '../provider/contact.model';
import { Position } from '../provider/position.model';
import { Site } from '../provider/site.model';
import { Provider } from '../provider/provider.model';
import { Block } from '../plan/block.model';
import { Plan } from '../plan/plan.model';
import { Student } from '../student/student.model';
import { StudentAddress } from '../student/student-address.model';
import { FilterConditionBase, FilterConditionNameValue, FilterValueType } from '../filters/filter.model';
import { Placement } from '../placement/placement.model';
import { Experience } from '../experience/experience.model';
import { UserSummary } from '../shared/user.model';
import { PlacementSummary, ProviderSummary } from '../summary/summary.model';
import { PlacementMatch, MappingResult, Mapping } from '../placement/mapping.model';
import { AllIncludeOptions } from '../shared/include.model';
import { Role } from '../user/role.model';
import { Entity, MetadataType } from '../entity/entity.type';

export type ResponseErrorCodes =
    'invalidJson' |
    'forbidden' |
    'unauthorizedFilter' |
    'notFound' |
    'propertyInvalid' |
    'invalidModelState' |
    'propertyNotFound' |
    'propertyNotSupported' |
    'propertyNotUnique' |
    'propertyExtendedError' |
    'propertyExtendedArrayItemError' |
    'propertyInvalidFilterPath' |
    'propertyInvalidFilterValue' |
    'identityError' |
    'vaidationFilterFail' |
    'unauthorizedPermission' |
    'provisionedDeleteForbidden' |
    'provisionedUpdateForbidden' |
    'propertyProvisionedUpdateForbidden';

// basic implementation, known types have known responses
// will exapand later
export interface ResponseError {
    code: ResponseErrorCodes;
    message: string;
    property?: string;
}

export interface ResponseModelError {
    errors?: ResponseError[];
}

export interface ResponseProviderInclude {
    contacts: Contact[];
    sites: Site[];
    positions: Position[];
    providerSummaries: ProviderSummary[];
}

export interface ResponseProviderSubEntityInclude {
    contacts: Contact[];
    sites: Site[];
    providers: Provider[];
}

export interface ResponsePositionInclude {
    contacts: Contact[];
    sites: Site[];
    providers: Provider[];
    experiences: Experience[];
    placements: Placement[];
}

export interface ResponsePlanInclude {
    planBlocks: Block[];
    experiences: Experience[];
}

export interface ResponseBlockInclude {
    plans: Plan[];
    experiences: Experience[];
}

export interface ResponseStudentInclude {
    students: Student[];
    studentAddresses: StudentAddress[];
    plans: Plan[];
}

export interface ResponseStudentSubEntityInclude {
    students: Student[];
}

//  Block | PlanTerm | Plan | Student | Position | Provider | Site | Contact
export interface ResponsePlacementInclude {
    students: Student[];
    positions: Position[];
    // timesheets: Timesheet[];
    planBlocks: Block[];
    plans: Plan[];
    providers: Provider[];
    sites: Site[];
    contacts: Contact[];
    placementSummaries: PlacementSummary[];
    experiences: Experience[];
}

export interface ResponseTimesheetInclude {
    placements: Placement[];
}

export interface ResponseNoteInclude {
    users: UserSummary[];
}

export interface ResponseChangesInclude {
    users: UserSummary[];
}

export interface ResponseAdminInclude {
    roles: Role[];
}

// tslint:disable-next-line: no-empty-interface
export interface ResponseNoInclude { }

export type AllResponseIncludes
    = ResponseProviderInclude
    | ResponseProviderSubEntityInclude
    | ResponsePositionInclude
    | ResponsePlanInclude
    | ResponseBlockInclude
    | ResponseStudentInclude
    | ResponsePlacementInclude
    | ResponseTimesheetInclude
    | ResponseNoteInclude
    | ResponseChangesInclude
    | ResponseAdminInclude
    | ResponseNoInclude;

export interface ResponseSort {
    path: string;
    descending: boolean;
}

export class ResponseFilterCondition extends FilterConditionBase {
    values: (FilterConditionNameValue | FilterValueType)[]; // FilterValueBaseType
}

export class ResponseFilter {
    type: MetadataType;
    conditions: ResponseFilterCondition[];

    constructor() {
        this.conditions = [];
    }
}

export interface ResponseQueryPagination {
    take: number;
    skip: number;
}

export interface ResponseQuery extends ResponseQueryPagination {
    filter: ResponseFilter;
    sort: ResponseSort[];
    include: AllIncludeOptions[];
    q?: string;
}

export interface ResponseModel<TEntity> extends ResponseModelError {
    data: TEntity;
}

export interface ResponseModelIdList {
    data: number[];
    query: ResponseQuery;
}

export interface ResponseModelListQuery<TEntity> extends ResponseModel<TEntity[]> {
    query: ResponseQuery;
    total: number;
}

export interface ResponseModelList<TEntity, TResponseIncludes extends AllResponseIncludes> extends ResponseModelListQuery<TEntity> {
    include: TResponseIncludes;
}

export interface ResponseModelSingle<TEntity, TResponseIncludes extends AllResponseIncludes> extends ResponseModel<TEntity> {
    include: TResponseIncludes;
}

export interface ResponseModelSingleNull extends ResponseModelSingle<any, any> {}

export interface ResponseModelMerge<TEntity> extends ResponseModel<TEntity> {
    deleted: TEntity;
    target: TEntity;
    dry: boolean;
}

export interface ResponseBulkItem<TEntity> {
    model: TEntity;
    errors?: ResponseModelError[];
}

export interface ResponseModelBulk<TEntity> extends ResponseModelError {
    data: ResponseBulkItem<TEntity>[];
}

export interface ResponseMappingMatches<TDataEntity, TResponseIncludes extends AllResponseIncludes>
    extends ResponseModelList<TDataEntity, TResponseIncludes> {

    data: TDataEntity[];
    entities: Entity[];
    mappingResults: MappingResult[];
}

export interface ResponsePlanBlockPlacements extends ResponseModelList<Placement, ResponsePlacementInclude> {
    planBlock: Block;
}

export interface ResponsePlanBlockMatchesQuery {
    planBlockId: number;
    disableMappingPropertyIds: number[];
    disableMappingFilterIds: number[];

    studentFilter: ResponseFilter;
    positionFilter: ResponseFilter;
}

export interface ResponsePlanBlockMatches extends ResponseModel<PlacementMatch[]> {
    planBlock: Block;
    mappings: Mapping[];
    query: ResponsePlanBlockMatchesQuery;
}

export interface ResponseStudentPlanUpdate extends ResponseModel<{
    plan: Plan;
    added: number;
    removed: number;
}> {}
