import { Component, Input, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { CpRequiredOnInit } from '../../../core-configuration/decorators/required-on-init.decorator';
import { Overlap } from '../../../../models/shared/break.model';
import { MomentHelper } from '../../../core-configuration/helpers/moment.helper';

export interface DateRangeOverlaps {
    start: moment.Moment;
    end: moment.Moment;
    overlaps: Overlap[];
}

@Component({
    selector: 'cp-date-range-breaks-display',
    templateUrl: 'date-range-breaks-display.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DateRangeBreaksDisplayComponent implements OnChanges {

    @CpRequiredOnInit() @Input() start: moment.Moment;
    @CpRequiredOnInit() @Input() end: moment.Moment;
    @Input() overlaps: Overlap[] = [];

    @Input() removeMarginBottom = false;

    ranges: Overlap[];

    constructor(
        private momentHelper: MomentHelper
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.overlaps) {
            this.ranges = [];
        } else {
            this.ranges = this.overlaps.sort(this.momentHelper.dateSort);
        }
    }
}
