import { CpClass } from '../../modules/core-configuration/decorators/metadata.decorator';
import { EntityBase } from '../entity/entity.model';

@CpClass({
    create: newAbsenceType,
    metadataType: 'absenceType'
})
export class AbsenceType extends EntityBase {
    name: string;
    description: string;
    archived: boolean;
}

export function newAbsenceType() { return new AbsenceType(); }
