import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { ResponseModelError, ResponseError } from '../../../../models/api/response.model';

@Component({
    selector: 'cp-error',
    templateUrl: 'error.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ErrorComponent implements OnChanges {

    @Input() error: ResponseModelError | ResponseError[] | any;
    @Input() small = false;

    array: { message: string }[] = [];
    showRaw = false;
    showMore = false;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.error) {
            this.handleChange();
        }
    }

    private handleChange() {
        this.showRaw = false;
        let arrayValue: any[] | undefined;

        if (!this.error) {
            this.array = [];
            return;
        }

        arrayValue = this.checkErrorArray(this.error);
        if (arrayValue) {
            this.array = arrayValue;
            return;
        }

        arrayValue = this.error.error && this.checkErrorArray(this.error.error);
        if (arrayValue) {
            this.array = arrayValue;
            return;
        }

        this.showRaw = true;
    }

    private checkErrorArray(value: any): any[] | undefined {
        if (!value) {
            return undefined;
        }

        if (Array.isArray(value) && value.every(e => !!e.message)) {
            return value;
        }

        if (value.errors && Array.isArray(value.errors) && value.errors.every((e: any) => !!e.message)) {
            return value.errors;
        }

        return undefined;
    }
}
