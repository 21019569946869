<ng-container *ngIf="filter.conditions.length > 0">
    <ng-container *cpLoading="!loaded; small: true">
        <span class="pr-1">All <code>{{ filter.type + '.plural' | translate }}</code></span>
        <span *ngFor="let internalFilter of internalFilters" [class.d-block]="internalFilters.length > 1">
            <cp-filter-path-display [filterPropertyPath]="getPropertyPath(internalFilter)"></cp-filter-path-display>
            <!-- remember -->
            <cp-filter-condition-display 
                *ngIf="internalFilter.condition && getConditionProperty(internalFilter); let conditionProperty" 
                [condition]="internalFilter.condition" 
                [property]="conditionProperty">
            </cp-filter-condition-display>
        </span>
    </ng-container>
</ng-container>