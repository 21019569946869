import { Injectable } from '@angular/core';
import { RBG_RGBA_EXTRACT_PATTERN_LITERAL } from '../../common/constants/pattern.constants';

@Injectable()
export class DisplayHelperService {

    constructor() { }

    caculateTextColorClass(rgba: string): 'color-light' | 'color-dark' | '' {

        if (!rgba) { return ''; }

        const match = RBG_RGBA_EXTRACT_PATTERN_LITERAL.exec(rgba);

        // but if it does, just default to dark
        if (!match) { return 'color-dark'; }

        const r = +match[1];
        const g = +match[2];
        const b = +match[3];

        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? 'color-dark'
            : 'color-light';
    }
}
