import { CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { EntityBase } from '../entity/entity.model';

export class Address extends EntityBase {

    @CpProperty({ type: 'multilineText', showInDetails: true })
    address: string;

    @CpProperty({ type: 'string', showInDetails: true })
    postcode: string;

    @CpProperty({ type: 'string', showInDetails: true })
    city: string;

    @CpProperty({ type: 'string', showInDetails: true })
    region: string;

    @CpProperty({ type: 'string', showInDetails: true })
    countryCode: string;

    @CpProperty({ label: 'latitude', type: 'int32', hideFromList: true })
    lat?: number;

    @CpProperty({ label: 'longitude', type: 'int32', hideFromList: true })
    lng?: number;
}
