import { Pipe, PipeTransform } from '@angular/core';

export function dotPropertyTransform(value: any, dotProperty: string): any {
    if (!value || !dotProperty) { return value; }

    const path = dotProperty.split('.');
    if (!path) { return value; }

    while (path.length > 0) {

        value = value[path.shift()!];

        if (value === undefined) { break; }
     }
     return value;
}

@Pipe({
    name: 'dotProperty'
})
export class DotPropertyPipe implements PipeTransform {

    transform(value: any, dotProperty: string): any {
        return dotPropertyTransform(value, dotProperty);
    }
}
