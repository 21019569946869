import { Directive, Input, HostBinding } from '@angular/core';
import { DisplayHelperService } from '../../services/display-helper.service';

@Directive({ selector: '[textContrast]' })
export class ContrastTextColorDirective {

    private _background: string;
    private _colorClass: string;

    @Input('textContrast')
    set background(value: string) {
        this._background = value;
        this._colorClass = this.service.caculateTextColorClass(this._background);
        this.setClass();
    }

    get background(): string {
        return this._background;
    }

    @HostBinding('class.color-light')
    colorLight: boolean;

    @HostBinding('class.color-dark')
    colorDark: boolean;

    constructor(
        private service: DisplayHelperService
    ) { }

    private setClass() {
        this.colorDark = this._colorClass === 'color-dark';
        this.colorLight = this._colorClass === 'color-light';
    }
}
