import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { AuthenticationHttpService } from './http/authentication.http.service';
import { ConfirmModalComponent } from '../../common/modals/confirm/confirm.modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UuidHelper } from '../../core-configuration/helpers/uuid.helper';
import { TokenUser, AuthUserType, LOCALSTORAGE_JWT_TOKEN, LOCALSTORAGE_ID_TOKEN, LOCALSTORAGE_NONCE, AccessToken, AUTH_USER_TYPE_LITERAL } from '../../../models/authentication/authentication.model';

const AUTH_CONSTANTS = {
    clientId: 'admin',
    scope: ['openid', 'profile', '*.*'],
    responseTypes: ['id_token', 'token']
};

@Injectable()
export class AuthenticationService {

    private user?: TokenUser;
    private userSource: BehaviorSubject<TokenUser | undefined> = new BehaviorSubject(this.user);
    private expiry?: moment.Moment;
    private userType?: AuthUserType;

    private modalTitle: string;
    private modalMessage: string;

    user$ = this.userSource.asObservable();

    constructor(
        private translateService: TranslateService,
        private modalService: NgbModal,
        private httpService: AuthenticationHttpService,
        private uuidHelper: UuidHelper
    ) {
        this.getFromStorage();
    }

    isAuthenticated() {
        if (!this.user || !this.expiry || moment().isAfter(this.expiry)) {
            // very important that the "check" (this call) doesn't trigger an update that
            // might cause components to "check" again.
            this.clearUser(false);
            return false;
        }

        return true;
    }

    getUserType() {
        return this.userType;
    }

    performInitialRedirect(returnPath?: string) {
        const externalRedirectUrl = environment.baseUrl + '/login';

        const state = `?returnUrl=${returnPath}`;

        const scope = AUTH_CONSTANTS.scope;
        const clientId = AUTH_CONSTANTS.clientId;
        const responseType = AUTH_CONSTANTS.responseTypes;
        const nonce = this.getNonceOrRecreate();

        const base = `${environment.apiBaseUrl}/connect/authorize`;
        const parts = {
            client_id: clientId,
            scope: scope.join(' '),
            redirect_uri: externalRedirectUrl,
            response_type: responseType.join(' '),
            state: state,
            nonce: nonce
        };

        const uri = Object.keys(parts).map(key => `${key}=${encodeURIComponent(parts[key])}`).join('&');
        const path = `${base}?${uri}`;

        // are you suuuuure?
        this.openModal();
        this.httpService.performRedirect(path);
    }

    save(accessToken: string, idToken: string) {
        // saveToken
        localStorage.setItem(LOCALSTORAGE_JWT_TOKEN, accessToken);
        localStorage.setItem(LOCALSTORAGE_ID_TOKEN, idToken);

        // update user
        this.getFromStorage();
    }

    logout(options: { triggerUpdate: boolean }) {
        return this.httpService.logout().toPromise().then(
            () => this.clearUser(options.triggerUpdate),
            () => this.clearUser(options.triggerUpdate)
        );
    }

    getToken() {
        return localStorage.getItem(LOCALSTORAGE_JWT_TOKEN);
    }

    private getNonceOrRecreate() {
        // debugger;
        let nonce = localStorage.getItem(LOCALSTORAGE_NONCE);
        if (!nonce) {
            nonce = this.uuidHelper.generate();
            localStorage.setItem(LOCALSTORAGE_NONCE, nonce);
        }

        return nonce;
    }

    private normalizeUser(token: string) {
        const user = jwt_decode(token) as TokenUser;
        this.user = user;
    }

    private clearUser(triggerUpdate: boolean = true) {

        localStorage.removeItem(LOCALSTORAGE_JWT_TOKEN);
        localStorage.removeItem(LOCALSTORAGE_ID_TOKEN);

        this.user = undefined;
        this.expiry = undefined;
        this.userType = undefined;

        if (triggerUpdate) {
            this.triggerUpdate();
        }
    }

    private getFromStorage(triggerUpdate: boolean = true) {
        // handle the nonce first
        const nonce = this.getNonceOrRecreate();
        const accessToken = localStorage.getItem(LOCALSTORAGE_JWT_TOKEN);
        const idToken = localStorage.getItem(LOCALSTORAGE_ID_TOKEN);

        if (accessToken && idToken) {
            this.normalizeUser(idToken);

            const access = jwt_decode(accessToken) as AccessToken;
            this.expiry = moment.unix(access.exp);
            this.userType = access[AUTH_USER_TYPE_LITERAL];

            // check the nonce value
            if (this.user && this.user.nonce !== nonce) {
                console.log(`%cAUTHENTICATION NONCE INVALID`, 'color:red', this.user.nonce, nonce);
                this.clearUser(false);

                // i think it's okay to error here, cause this should never ever be hit (unless there is malicious intent)
                throw new Error('Suspicious Authentication Activity');
            }
            // console.log(`%cAUTHENTICATION VALID`, 'color:green', this.user, nonce, access);

            if (triggerUpdate) {
                this.triggerUpdate();
            }
        }
    }

    private triggerUpdate() {
        this.userSource.next(this.user);
    }

    private openModal() {
        const authStrings = this.translateService.instant('authentication.modals.redirect');
        // console.log('auth string', authStrings, this.translateService.instant('api.Position'));
        this.modalTitle = authStrings.title;
        this.modalMessage = authStrings.message;

        const modalRef = this.modalService.open(ConfirmModalComponent, { backdrop: 'static' });
        modalRef.componentInstance.title = this.modalTitle;
        modalRef.componentInstance.message = this.modalMessage;
        modalRef.componentInstance.hideConfirm = true;
        modalRef.componentInstance.hideDismiss = true;
    }
}
