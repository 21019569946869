import { CpProperty, CpClass } from '../../modules/core-configuration/decorators/metadata.decorator';
import { Moment } from 'moment';
import { MinutesPipe } from '../../modules/display-helper/pipes/minutes.pipe';
import { AbsenceSummaryListComponent } from '../../modules/absence-type/components/absence-summary/absence-summary-list.component';
import { MinutesSummaryDynamic, MinutesSummaryDynamicComponent } from '../../modules/display-helper/components/minutes/minutes-summary-dynamic.component';

export type Summary = PlacementSummary | ProviderSummary;
export type SummaryType = 'placementSummary' | 'providerSummary';
export type SummaryTypePlural = 'placementSummaries' | 'providerSummaries';
export const ALL_SUMMARY_TYPES: SummaryType[] = ['placementSummary', 'providerSummary'];
export const ALL_SUMMARY_TYPES_PLURAL: SummaryTypePlural[] = ['placementSummaries', 'providerSummaries'];

export abstract class SummaryBase {
    @CpProperty({ label: 'History Count', type: 'int32', hideFromList: true })
    changeCount: number;

    @CpProperty({ type: 'int32', hideFromList: true })
    noteCount: number;
}

@CpClass({
    create: newProviderSummary,
    metadataType: 'providerSummary'
})
export class ProviderSummary extends SummaryBase {

    @CpProperty({ type: 'int32', hideFromList: true })
    providerId: number;

    @CpProperty({ type: 'int32' })
    contactCount: number;

    @CpProperty({ type: 'int32' })
    positionCount: number;

    @CpProperty({ type: 'int32' })
    siteCount: number;
}
// todo: change this when fixed in back end
export class AbsenceSummary {
    absenceTypeId: number;
    minutes: number;
}

@CpClass({
    create: newPlacementSummary,
    metadataType: 'placementSummary'
})
export class PlacementSummary extends SummaryBase {

    @CpProperty({ type: 'dynamic', dynamicComponent: MinutesSummaryDynamicComponent })
    get hoursSummary(): MinutesSummaryDynamic {
        return {
            attended: this.timesheetMinutesAttended,
            absent: this.timesheetMinutesAbsent
        };
    }

    @CpProperty({ type: 'int32', hideFromList: true })
    placementId: number;

    @CpProperty({ label: 'Attended Hours', type: 'int32', pipe: { pipe: new MinutesPipe() }})
    timesheetMinutesAttended: number;

    @CpProperty({ label: 'Absent Hours', type: 'dynamic', dynamicComponent: AbsenceSummaryListComponent })
    timesheetAbsences: AbsenceSummary;

    @CpProperty({ label: 'Absent Hours Total', type: 'int32', pipe: { pipe: new MinutesPipe() }})
    timesheetMinutesAbsent: number;

    @CpProperty({ type: 'int32', hideFromList: true })
    timesheetCount: number;

    @CpProperty({ label: 'Timesheet Max Date', type: 'date' })
    timesheetDateMin?: Moment;

    @CpProperty({ label: 'Timesheet Min Date', type: 'date' })
    timesheetDateMax?: Moment;
}

export function newProviderSummary() { return new ProviderSummary(); }
export function newPlacementSummary() { return new PlacementSummary(); }
