var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ApiGetHttpClientService } from '../../../api/services/api-http-client.service';
import { API_DATE_FORMAT } from '../../../core-configuration/helpers/moment.helper';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
var ReportHttpService = /** @class */ (function (_super) {
    __extends(ReportHttpService, _super);
    function ReportHttpService(httpClient, apiRequestHelperService) {
        var _this = _super.call(this, 'reports', httpClient, apiRequestHelperService) || this;
        _this.httpClient = httpClient;
        _this.apiRequestHelperService = apiRequestHelperService;
        return _this;
    }
    ReportHttpService.prototype.run = function (request) {
        var httpParams = this.createBaseHttpParams(request);
        httpParams = this.addRunParams(request, httpParams);
        var url = this.generateUrl({ path: ['reportActions', 'run', request.key], overrideBasePath: true, options: {
                filters: request.filters,
            } });
        return this.httpClient.get(url, {
            params: httpParams
        });
    };
    ReportHttpService.prototype.download = function (request) {
        var httpParams = this.createBaseHttpParams(request);
        httpParams = this.addDownloadParams(request, httpParams);
        var url = this.generateUrl({ path: ['reportActions', 'download', request.key], overrideBasePath: true, options: {
                filters: request.filters
            } });
        var headers = new HttpHeaders({
            'Accept': 'application/zip, text/csv'
        });
        return this.httpClient.get(url, {
            headers: headers,
            params: httpParams,
            // unbelivable angular, this is a hack 'arraybuffer' as 'json'
            responseType: 'arraybuffer'
        });
    };
    ReportHttpService.prototype.createBaseHttpParams = function (request) {
        var httpParams = new HttpParams();
        Object.keys(request.parameters)
            .map(function (key) { return ({ key: key, parameter: request.parameters[key] }); })
            .filter(function (item) {
            // filters are handled separately
            return item.parameter.type !== 'filter' &&
                // userIds are always pulled from the authentication token
                item.parameter.type !== 'userId' &&
                // only add parameters that have a value
                item.parameter.value;
        })
            .forEach(function (item) {
            if (item.parameter.type === 'date') {
                httpParams = httpParams.set(item.key, item.parameter.value.format(API_DATE_FORMAT));
            }
            else {
                httpParams = httpParams.set(item.key, item.parameter.value.toString());
            }
        });
        // you always have to include all tables (unless it's the first run call that is)
        if (request.tables && request.tables.length > 0) {
            request.tables.forEach(function (t) {
                var key = "tables[" + t.index + "].exclude";
                httpParams = httpParams.set(key, t.visible ? 'false' : 'true');
            });
        }
        return httpParams;
    };
    ReportHttpService.prototype.addRunParams = function (request, httpParams) {
        request.pagination.forEach(function (item) {
            var prefix = "tables[" + item.index + "].";
            ['skip', 'take'].forEach(function (key) {
                var value = item[key];
                if (value) {
                    httpParams = httpParams.set(prefix + key, value.toString());
                }
            });
            if (item.columns) {
                httpParams = httpParams.set(prefix + 'columns', item.columns.join(','));
            }
            if (item.sort) {
                var sortString = item.sort
                    .map(function (i) { return i.descending ? '-' + i.name : i.name; })
                    .join(',');
                httpParams = httpParams.set(prefix + 'sort', sortString);
            }
        });
        return httpParams;
    };
    ReportHttpService.prototype.addDownloadParams = function (request, httpParams) {
        if (request.tableColumns) {
            request.tableColumns.forEach(function (item) {
                var key = "tables[" + item.index + "].columns";
                httpParams = httpParams.set(key, item.columns.join(','));
            });
        }
        return httpParams;
    };
    return ReportHttpService;
}(ApiGetHttpClientService));
export { ReportHttpService };
