import { NgModule } from '@angular/core';
import { LoaderComponent } from './components/loader/loader.component';
import { RowComponent } from './components/row/row.component';
import { ArrayTableComponent } from './components/array-to-table/array-to-table.component';
import { LinkComponent } from './components/link/link.component';
import { PropertyDisplayComponent } from './components/property-display/property-display.component';
import { BootstrapAlertComponent } from './components/bootstrap-alert/bootstrap-alert.component';
import { CommonModule } from '@angular/common';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DropdownLinkComponent } from './components/dropdown/dropdown-link.component';
import { DropdownLinkCogComponent } from './components/dropdown/dropdown-link-cog.component';
import { DropdownHeaderComponent } from './components/dropdown/dropdown-header.component';
import { ClickOutsideModule } from 'ng-click-outside';
import {
    faExclamationTriangle,
    faInfoCircle,
    faBell,
    faLongArrowAltUp,
    faLongArrowAltDown,
    faThumbtack,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faLongArrowAltRight,
    faCog,
    faCheck,
    faCheckDouble,
    faCaretRight,
    faPlus,
    faTimes,
    faPlusCircle,
    faInfo,
    faEnvelope,
    faMapMarkerAlt,
    faSearch,
    faUser,
    faThumbsUp,
    faHandPointRight,
    faArchive,
    faArrowAltCircleUp,
    faArrowAltCircleDown,
    faColumns,
    faBars,
    faThList,
    faPen,
    faMinus,
    faClock,
    faDotCircle,
    faCheckCircle,
    faExternalLinkAlt,
    faPhone,
    faCaretDown,
    faCaretUp,
    faCircle,
    faRobot,
    faAmbulance,
    faClipboard,
    faCaretLeft,
    faGraduationCap,
    faDownload,
    faPrint,
    faCalendarAlt,
    faStream,
    faCalendarMinus,
    faChartLine,
    faUserGraduate,
    faHospital,
    faFilter,
    faSave,
    faUsers,
    faUserLock,
    faTasks
} from '@fortawesome/free-solid-svg-icons';
import { LoaderDirective } from './directives/loader/loader.directive';
import { DotPropertyPipe } from './pipes/dot-property.pipe';
import { BooleanPipe } from './pipes/boolean.pipe';
import { ContrastTextColorDirective } from './directives/contrast-text-color/contrast-text-color.directive';
import { DisplayHelperService } from './services/display-helper.service';
import { MinutesPipe } from './pipes/minutes.pipe';
import { MomentModule } from 'angular2-moment';
import { DomChangeDirective } from './directives/dom-change/dom-change.directive';
import { ElementChangeDirective } from './directives/element-change/element-change.directive';
import { RepeatPipe } from './pipes/repeat.pipe';
import { PropertyDisplayItemComponent } from './components/property-display/property-display-item.component';
import { HighlightsDirective } from './directives/highlights/highlights.directive';
import { ActionHeaderComponent } from './components/action-header/action-header.component';
import { MasterPageComponent } from './components/master-page/master-page.component';
import { ScrollIntoViewDirective } from './directives/scroll-into-view/scroll-into-view.directive';
import { SubNavigationComponent } from './components/navigation/sub-navigation.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderErrorComponent } from './components/loader/loader-error.component';
import { MinutesSummaryComponent } from './components/minutes/minutes-summary.component';
import { MinutesSummaryDynamicComponent } from './components/minutes/minutes-summary-dynamic.component';
import { TranslateOrPipe } from './pipes/translate-or.pipe';
import { EntityTitleLinkComponent } from './components/title/entity-title-link.component';
import { BlockTitleComponent } from './components/title/block-title.component';
import { NameTitleComponent } from './components/title/name-title.component';
import { PersonTitleComponent } from './components/title/person-title.component';
import { DisplayToggleComponent } from './components/display-toggle/display-toggle.component';
import { ElementLeftFromWindowDirective } from './directives/element-position/element-position.directive';
import { ElementWidthDirective } from './directives/element-width/element-width.directive';
import { DropdownButtonComponent } from './components/dropdown/dropdown-button.component';
import { TextLongComponent } from './components/text-long/text-long.component';
import { ArchiveDisplayDynamicComponent } from './components/archive/archive-display-dynamic.component';
import { DateRangeDisplayComponent } from './components/date-range/date-range-display.component';
import { AddressTitleComponent } from './components/title/address-title.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { PropertyDisplayValuePipe } from './pipes/property-display-value.pipe';
import { PersonDisplayComponent } from './components/person-display/person-display.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ColorDisplayDynamicComponent } from './components/color-display-dynamic/color-display-dynamic.component';
import { LinkActionComponent } from './components/link/link-action.component';
import { PrintActionComponent } from './components/actions/print-action.component';
import { IconComponent } from './components/icon/icon.component';
import { ErrorComponent } from './components/error/error.component';
import { JsonToPropertyDisplayComponent } from './components/json-to-property/json-to-property-display.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { KeyValueGroupedDisplayComponent } from './components/key-value-grouped-display/key-value-grouped-display.component';

[
    faExclamationTriangle,
    faInfoCircle,
    faBell,
    faLongArrowAltUp,
    faLongArrowAltDown,
    faArrowAltCircleUp,
    faArrowAltCircleDown,
    faThumbtack,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faLongArrowAltRight,
    faCog,
    faCheck,
    faCheckDouble,
    faCaretRight,
    faCaretLeft,
    faCaretDown,
    faCaretUp,
    faPlus,
    faTimes,
    faPlusCircle,
    faInfo,
    faEnvelope,
    faExternalLinkAlt,
    faPhone,
    faMapMarkerAlt,
    faSearch,
    faUser,
    faThumbsUp,
    faHandPointRight,
    faArchive,
    faColumns,
    faBars,
    faThList,
    faPen,
    faMinus,
    faClock,
    faDotCircle,
    faCheckCircle,
    faCircle,
    faRobot,
    faAmbulance,
    faClipboard,
    faGraduationCap,
    faDownload,
    faPrint,
    faCalendarAlt,
    faStream,
    faCalendarMinus,
    faChartLine,
    faUserGraduate,
    faHospital,
    faFilter,
    faSave,
    faUsers,
    faUserLock,
    faTasks
].forEach(icon => library.add(icon));

export function getLocalStorage() {
    return (typeof window !== 'undefined') ? window.localStorage : undefined;
}

export function getWindow() {
    return (typeof window !== 'undefined') ? window : undefined;
}

@NgModule({
    entryComponents: [
        LoaderComponent,
        LoaderErrorComponent,
        ArchiveDisplayDynamicComponent,
        MinutesSummaryDynamicComponent,
        ColorDisplayDynamicComponent
    ],
    imports: [
        NgbCollapseModule,
        CommonModule,
        RouterModule,

        FontAwesomeModule,

        TranslateModule,

        ClickOutsideModule,

        MomentModule
    ],
    providers: [
        DisplayHelperService,
        { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
        { provide: 'WINDOW', useFactory: getWindow }
    ],
    declarations: [
        RowComponent,
        LoaderComponent,
        LoaderErrorComponent,
        ArrayTableComponent,
        IconComponent,
        LinkComponent,
        LinkActionComponent,
        PropertyDisplayComponent,
        PropertyDisplayItemComponent,
        BootstrapAlertComponent,
        TextLongComponent,
        MinutesSummaryComponent,
        DateRangeDisplayComponent,
        TabsComponent,
        PersonDisplayComponent,
        BadgeComponent,
        PrintActionComponent,
        JsonToPropertyDisplayComponent,
        KeyValueGroupedDisplayComponent,

        ArchiveDisplayDynamicComponent,
        MinutesSummaryDynamicComponent,
        ColorDisplayDynamicComponent,

        DisplayToggleComponent,
        ErrorComponent,

        DropdownLinkComponent,
        DropdownLinkCogComponent,
        DropdownHeaderComponent,
        DropdownButtonComponent,

        MasterPageComponent,
        ActionHeaderComponent,
        SubNavigationComponent,

        EntityTitleLinkComponent,
        BlockTitleComponent,
        NameTitleComponent,
        PersonTitleComponent,
        AddressTitleComponent,

        LoaderDirective,
        ContrastTextColorDirective,
        DomChangeDirective,
        ElementChangeDirective,
        HighlightsDirective,
        ScrollIntoViewDirective,
        ElementLeftFromWindowDirective,
        ElementWidthDirective,

        DotPropertyPipe,
        BooleanPipe,
        MinutesPipe,
        RepeatPipe,
        TranslateOrPipe,
        PropertyDisplayValuePipe
    ],
    exports: [
        // modules
        FontAwesomeModule,
        TranslateModule,

        // components
        RowComponent,
        LoaderComponent,
        LoaderErrorComponent,
        ArrayTableComponent,
        IconComponent,
        LinkComponent,
        LinkActionComponent,
        PropertyDisplayComponent,
        PropertyDisplayItemComponent,
        BootstrapAlertComponent,
        TextLongComponent,
        MinutesSummaryComponent,
        DateRangeDisplayComponent,
        TabsComponent,
        PersonDisplayComponent,
        BadgeComponent,
        PrintActionComponent,
        JsonToPropertyDisplayComponent,
        KeyValueGroupedDisplayComponent,

        ArchiveDisplayDynamicComponent,
        MinutesSummaryDynamicComponent,
        ColorDisplayDynamicComponent,

        DisplayToggleComponent,
        ErrorComponent,

        DropdownLinkComponent,
        DropdownLinkCogComponent,
        DropdownHeaderComponent,
        DropdownButtonComponent,

        MasterPageComponent,
        ActionHeaderComponent,
        SubNavigationComponent,

        EntityTitleLinkComponent,
        BlockTitleComponent,
        NameTitleComponent,
        PersonTitleComponent,
        AddressTitleComponent,

        // directives
        LoaderDirective,
        ContrastTextColorDirective,
        DomChangeDirective,
        ElementChangeDirective,
        HighlightsDirective,
        ScrollIntoViewDirective,
        ElementLeftFromWindowDirective,
        ElementWidthDirective,

        // pipes
        DotPropertyPipe,
        BooleanPipe,
        MinutesPipe,
        RepeatPipe,
        TranslateOrPipe,
        PropertyDisplayValuePipe
    ],
})
export class DisplayHelperModule { }
