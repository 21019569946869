import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { ClaimDisplayDynamicComponent } from '../../modules/role/components/claim/claim-display-dynamic.component';

export type RoleUserType = 'student' | 'contact' | 'admin';

export interface RoleClaim {
    type: string;
    value: string;
}

@CpClass({
    create: newRole,
    metadataType: 'role',
    defaultSort: [{ key: 'name' }]
})
export class Role {

    @CpProperty({ type: 'int32', hideFromList: true })
    id: number;

    @CpProperty({ type: 'string' })
    name: string;

    @CpProperty({ type: 'multilineText' })
    description: string;

    @CpProperty({ type: 'string' })
    userType: RoleUserType;

    @CpProperty({ type: 'dynamic', dynamicComponent: ClaimDisplayDynamicComponent })
    claims: RoleClaim[];

}

export function newRole() { return new Role(); }
