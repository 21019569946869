import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CpRequiredOnInit } from '../../../core-configuration/decorators/required-on-init.decorator';
import { Highlights } from '../../directives/highlights/highlights.directive';
import * as moment from 'moment';
import { MomentHelper } from '../../../core-configuration/helpers/moment.helper';

export interface NameLike {
    archived?: boolean;
    availableTo?: moment.Moment; // this is probably over extending this component
    name: string;
}

@Component({
    selector: 'cp-name-title',
    templateUrl: 'name-title.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class NameTitleComponent {

    today: moment.Moment;

    // NOTE: it should never be a string unless the API is returning something incorrectly or something is passed in that is just wrong.
    @CpRequiredOnInit() @Input() value: NameLike | string;
    @Input() highlights: Highlights;

    get isString(): boolean {
        return typeof(this.value) === 'string';
    }

    get isNameLike(): NameLike | undefined {
        return this.isString ? undefined : this.value as NameLike;
    }

    get isUnavailable(): boolean {
        const nameLike = this.isNameLike;
        if (!nameLike) { return false; }
        if (!nameLike.availableTo) { return false; }

        return this.momentHelper.isPast(nameLike.availableTo);
    }

    constructor(
        private momentHelper: MomentHelper
    ) {
        this.today = this.momentHelper.today();
    }
}
