import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { environment } from '../../../../environments/environment';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';

export const COURSE_STATUS_KEY = 'status';
export type StudentCourseStatus = 'current' | 'noncurrent' | 'all';
export const ALL_COURSE_STATUSES: StudentCourseStatus[] = ['current', 'noncurrent', 'all'];

@Injectable()
export class StudentCourseStatusService {

    defaultStatus: StudentCourseStatus = 'current';

    private currentFilter: RequestFilter;
    private nonCurrentFilter: RequestFilter;

    constructor() {
        this.currentFilter =  lodashHelper.clone(environment.currentStudentFilter);

        const nonCurrent = lodashHelper.clone(environment.currentStudentFilter);
        nonCurrent.path = nonCurrent.path.startsWith('!') ? nonCurrent.path.substring(1) : '!' + nonCurrent.path;
        this.nonCurrentFilter = nonCurrent;
    }

    hasNewStatus(params: Params, currentStatus: StudentCourseStatus): StudentCourseStatus | undefined {
        const queryStatus = params[COURSE_STATUS_KEY];

        if (queryStatus && ALL_COURSE_STATUSES.includes(queryStatus) && queryStatus !== currentStatus) {
            return queryStatus;
        }

        if (!queryStatus && currentStatus !== 'current') {
            return 'current';
        }

        return;
    }

    createRequestFilter(status: StudentCourseStatus): RequestFilter | undefined {

        if (status === 'current') {
            return this.currentFilter;
        }

        if (status === 'noncurrent') {
            return this.nonCurrentFilter;
        }

        return;
    }
}
