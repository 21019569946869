import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { UserEntity } from '../user/user-entity.model';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { Role } from '../user/role.model';
import { AdminUserType } from './admin.type';

const rolesInclude = new IncludeEntityMetadata<Admin, Role>('role', 'roles', 'roleIds', 'id');

@CpClass({
    includeMany: [rolesInclude],
    create: newAdmin,
    metadataType: 'admin',
    defaultSort: [{ key: 'lastName' }, { key: 'firstName' }]
})
export class Admin extends UserEntity {

    @CpProperty({ type: 'string'})
    userType: AdminUserType;
}

export function newAdmin() { return new Admin(); }
