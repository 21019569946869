import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'cp-property-display',
    templateUrl: 'property-display.component.html',
    host: { class: 'd-block property-display' }
})

export class PropertyDisplayComponent implements OnInit {

    @Input() removeMargin = false;
    @Input() removeBorder = false;

    @HostBinding('class.property-display-margin') get margin(): boolean {
        return !this.removeMargin;
    }

    @HostBinding('class.border-light') get borderColor(): boolean {
        return !this.removeBorder;
    }
    @HostBinding('class.border-bottom') get borderBottom(): boolean {
        return !this.removeBorder;
    }

    constructor() { }

    ngOnInit() { }
}
