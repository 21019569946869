/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./icon.component";
var styles_IconComponent = [];
var RenderType_IconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconComponent, data: {} });
export { RenderType_IconComponent as RenderType_IconComponent };
function View_IconComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "hidden", 0], [1, "title", 0], [8, "innerHTML", 1]], null, null, i1.View_FaIconComponent_0, i1.RenderType_FaIconComponent)), i0.ɵdid(1, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(2, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"], size: [1, "size"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.iconClass; _ck(_v, 1, 0, currVal_3); var currVal_4 = _co.icon; var currVal_5 = _co.iconSize; _ck(_v, 2, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hideIconIf; var currVal_1 = _co.iconTitle; var currVal_2 = i0.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_IconComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-icon", [], null, null, null, View_IconComponent_0, RenderType_IconComponent)), i0.ɵdid(1, 49152, null, 0, i5.IconComponent, [], null, null)], null, null); }
var IconComponentNgFactory = i0.ɵccf("cp-icon", i5.IconComponent, View_IconComponent_Host_0, { icon: "icon", hideIconIf: "hideIconIf", iconTitle: "iconTitle", iconSize: "iconSize", iconClass: "iconClass" }, {}, ["*"]);
export { IconComponentNgFactory as IconComponentNgFactory };
