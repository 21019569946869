import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { Moment } from 'moment';
import { EntityBase } from '../entity/entity.model';
import { EntityType } from '../entity/entity.type';

@CpClass<DeletedEntity>({
    defaultSort: [{ key: 'deleted', desending: true }],
    create: newDeletedEntity,
    metadataType: 'deletedEntity'
})
export class DeletedEntity extends EntityBase {

    @CpProperty({ type: 'string', defaultListVisible: true })
    type: EntityType;

    @CpProperty({ type: 'date' })
    deleted: Moment;

    @CpProperty({ type: 'int32', hideFromList: true })
    deletedUserId: number;

    @CpProperty({ type: 'dynamic' })
    value: any; // not sure about any here... This is a RESULT (should always have a name value, but will have more based on the type...)
}

export function newDeletedEntity() { return new DeletedEntity(); }
