import { SearchResult } from '../shared/search.model';
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IIncludeEntity, StudentIncludeType } from '../shared/include.model';
import { IncludeEntityMetadata, IncludeEntityMetadataCore } from '../shared/metadata.model';
import { Plan } from '../plan/plan.model';
import { EntityBase, IProvisionable } from '../entity/entity.model';
import { EntityLog } from '../shared/logged.model';
import { StudentAddress } from './student-address.model';
import { StudentCourse } from './student-course.model';


const logInclude = new IncludeEntityMetadata<Student, EntityLog>('entity', 'entities', 'id', 'id');

const planInclude = new IncludeEntityMetadata<Student, Plan>('plan', 'plans', 'planIds', 'id');
const addressInclude = new IncludeEntityMetadata<Student, StudentAddress>('studentAddress', 'studentAddresses', 'id', 'studentId');
const courseInclude = new IncludeEntityMetadataCore<Student, StudentCourse>('studentCourse', 'studentCourses', 'id', 'studentId');
const currentCourseInclude = new IncludeEntityMetadataCore<Student, StudentCourse>('studentCourse', 'studentCurrentCourses', 'id', 'studentId');

@CpClass<Student>({
    include: [logInclude],
    includeMany: [planInclude, addressInclude, courseInclude, currentCourseInclude],
    metadataType: 'student',
    defaultSort: [{ key: 'lastName' }, { key: 'firstName' }],
    create: newStudent
})
export class Student extends EntityBase implements IIncludeEntity<StudentIncludeType>, IProvisionable {

    @CpProperty({ type: 'string', defaultListVisible: true, showInDetails: true })
    externalId: string;

    @CpProperty({ type: 'string' })
    firstName: string;

    @CpProperty({ type: 'string' })
    lastName: string;

    @CpProperty({ type: 'emailAddress', showInDetails: true })
    email: string;

    @CpProperty({ type: 'phoneNumber', showInDetails: true })
    phoneNumber: string;

    @CpProperty({ type: 'int32' })
    planIds: number[];

    provisioningKey?: string;
    provisioned?: boolean;
    provisionedClientId?: string;
}

export interface StudentResult extends SearchResult {
    externalId: string;
    firstName: string;
    lastName: string;
    archived: boolean;
}

export function newStudent() { return new Student(); }
