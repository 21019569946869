import { OnInit } from '@angular/core';
var MinutesSummaryDynamicComponent = /** @class */ (function () {
    function MinutesSummaryDynamicComponent() {
    }
    Object.defineProperty(MinutesSummaryDynamicComponent.prototype, "summary", {
        get: function () {
            if (!this.dynamicValue) {
                return undefined;
            }
            return {
                attended: this.dynamicValue.attended,
                absent: this.dynamicValue.absent,
                expected: this.baseEntity.minutes
            };
        },
        enumerable: true,
        configurable: true
    });
    MinutesSummaryDynamicComponent.prototype.ngOnInit = function () {
        if (!this.baseEntity) {
            throw new Error('baseEntity is required for this dynamic component');
        }
    };
    return MinutesSummaryDynamicComponent;
}());
export { MinutesSummaryDynamicComponent };
