var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CpRequiredOnInit } from '../../../core-configuration/decorators/required-on-init.decorator';
import { Highlights } from '../../directives/highlights/highlights.directive';
import { MomentHelper } from '../../../core-configuration/helpers/moment.helper';
var NameTitleComponent = /** @class */ (function () {
    function NameTitleComponent(momentHelper) {
        this.momentHelper = momentHelper;
        this.today = this.momentHelper.today();
    }
    Object.defineProperty(NameTitleComponent.prototype, "isString", {
        get: function () {
            return typeof (this.value) === 'string';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NameTitleComponent.prototype, "isNameLike", {
        get: function () {
            return this.isString ? undefined : this.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NameTitleComponent.prototype, "isUnavailable", {
        get: function () {
            var nameLike = this.isNameLike;
            if (!nameLike) {
                return false;
            }
            if (!nameLike.availableTo) {
                return false;
            }
            return this.momentHelper.isPast(nameLike.availableTo);
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        CpRequiredOnInit(),
        __metadata("design:type", Object)
    ], NameTitleComponent.prototype, "value", void 0);
    return NameTitleComponent;
}());
export { NameTitleComponent };
