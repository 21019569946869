import { ApiCacheService } from '../../api/services/api-cache.service';
import { AllResponseIncludes, ResponseModelSingle, ResponseModelList, ResponseModelIdList } from '../../../models/api/response.model';
import { RequestIdEntity } from '../../../models/api/request.model';
import { ApiHttpClientService } from '../../api/services/api-http-client.service';
import { Observable } from 'rxjs';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { map, tap } from 'rxjs/operators';
import { RequestListOptions, RequestSearchOptions } from '../../../models/api/request-list-options.model';
import { toPlural } from '../helper/entity-shared.helper';
import { CrudEntityServiceBase } from './entity-service.base';
import { IncludeMapperService } from '../services/include-mapper.service';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import { AllIncludeOptions } from '../../../models/shared/include.model';
import { EntityTypePlural, MetadataType } from '../../../models/entity/entity.type';

export abstract class EntityServiceMetadataBase<
    TEntity extends RequestIdEntity,
    TIncludeOptions extends AllIncludeOptions,
    TResponseIncludes extends AllResponseIncludes>
    extends CrudEntityServiceBase<TEntity, TIncludeOptions, TResponseIncludes> {

    // protected meta: MetadataService<TEntity>;
    protected typePlural: EntityTypePlural;

    constructor(
        protected type: MetadataType,
        protected httpService: ApiHttpClientService<TEntity, TIncludeOptions, TResponseIncludes>,
        protected apiCacheService: ApiCacheService,
        protected includeMapperService: IncludeMapperService
    ) {
        super(httpService);

        this.typePlural = toPlural(this.type) as EntityTypePlural;
    }

    protected abstract includeOptions: TIncludeOptions[];
    protected abstract onAfterCreate(entity: TEntity): RequestFilter[] | void;
    protected abstract onAfterUpdate(entity: TEntity): void;

    create(entity: TEntity): Observable<TEntity> {
        return super.create(entity).pipe(
            tap(i => {
                const filter1 = new RequestFilter(this.typePlural, 'id', 'eq', [i.id]);
                // need to look into this, seems pointless to do the second part
                // if we just clear the entity entity...
                this.apiCacheService.clearCacheByType(this.typePlural);

                const addCreatedEntityExtraFilters = this.onAfterCreate(i) || [];

                this.apiCacheService.addCreatedEntity(this.typePlural, i, [filter1, ...addCreatedEntityExtraFilters]);
            })
        );
    }

    update(entity: TEntity): Observable<TEntity> {
        // todo, this is a hack - include and includeMany should probably not go on the base object at all
        // I should use some type of entity store (though that's kinda waht the cache engine was meant to be...)
        const clone = lodashHelper.omit(entity, ['include', 'includeMany']) as TEntity;
        return super.update(clone).pipe(
            tap(i => {
                this.apiCacheService.updateEntity(i, this.typePlural);
                this.onAfterUpdate(i);
            })
        );
    }

    updatePartial(entity: Partial<TEntity>): Observable<TEntity> {
        // see comment above
        const clone = lodashHelper.omit(entity, ['include', 'includeMany']) as TEntity;
        return super.updatePartial(clone).pipe(
            tap(i => {
                this.apiCacheService.updateEntity(i, this.typePlural);
                this.onAfterUpdate(i);
            })
        );
    }

    get(id: number): Observable<TEntity> {
        return this.getInclude(id).pipe(
            map(i => i.data)
        );
    }

    getInclude(id: number, include?: TIncludeOptions[]): Observable<ResponseModelSingle<TEntity, TResponseIncludes>> {
        const includeCombined = lodashHelper.uniq([...(this.includeOptions || []), ...(include || [])]);

        // this.apiCacheService.getSingleByRequest({ id: id, include: includeCombined, type: this.typePlural }, this.httpService.get([id], includeCombined))
        return this.httpService.get([id], includeCombined).pipe(
            tap(i => this.includeMapperService.handleSingle(this.type, i))
        );
    }

    // no longer uses cache...
    listInclude(options: RequestListOptions<TIncludeOptions>): Observable<ResponseModelList<TEntity, TResponseIncludes>> {
        return super.listInclude(options).pipe(
            tap(i => this.includeMapperService.handleList(this.type, i))
        );
    }

    delete(entity: TEntity): Observable<Object> {
        return super.delete(entity).pipe(
            tap(() => this.apiCacheService.clearCacheByEntityId(this.typePlural, entity.id))
        );
    }

    ids(options: RequestSearchOptions): Observable<ResponseModelIdList> {
        return this.httpService.ids(options);
    }

    protected getWithNext(options: RequestListOptions<TIncludeOptions>) {
        // note: this uses the super method (without cache)
        return super.getWithNext(options).pipe(
            tap(({ response }) => this.includeMapperService.handleList(this.type, response))
        );
    }
}
