import { Component, Input } from '@angular/core';

@Component({
    selector: 'cp-loader',
    template: `
        <span [class.fade-in]="!disableFadeIn">
            <span class="spinner-border text-primary"
                [class.spinner-border-sm]="small"
                [class.m-2]="!small">
            </span>
        </span>
    `
})

export class LoaderComponent {
    @Input() small = false;
    @Input() disableFadeIn = false;
}
