var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiHttpClientService } from '../../../api/services/api-http-client.service';
import { HttpClient } from '@angular/common/http';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
var PlacementHttpService = /** @class */ (function (_super) {
    __extends(PlacementHttpService, _super);
    function PlacementHttpService(httpClient, apiRequestHelperService) {
        var _this = _super.call(this, 'placements', httpClient, apiRequestHelperService) || this;
        _this.httpClient = httpClient;
        _this.apiRequestHelperService = apiRequestHelperService;
        return _this;
    }
    PlacementHttpService.prototype.bulk = function (placements) {
        var url = this.baseUrl + "/placements/bulk";
        return this.httpClient.post(url, placements);
    };
    return PlacementHttpService;
}(ApiHttpClientService));
export { PlacementHttpService };
