import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AbsenceSummary } from '../../../../models/summary/summary.model';
import { AbsenceType } from '../../../../models/absence-type/absence-type.model';
import { ConfigurationService } from '../../../core-configuration/services/configuration.service';
import { BaseSubscriber } from '../../../common/observables/base-subscriber';
import { takeUntil, map } from 'rxjs/operators';
import { IEntityListItemDynamicComponent } from '../../../entity-shared/components/entity-list/entity-list-item-dynamic.component';

export interface AbsenceSummaryViewModel {
    name: string;
    minutes: number;
}

@Component({
    selector: 'cp-absence-summary-list',
    templateUrl: 'absence-summary-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AbsenceSummaryListComponent extends BaseSubscriber implements OnInit, OnChanges, IEntityListItemDynamicComponent {

    @Input() dynamicValue: AbsenceSummary[];

    absenceTypes: AbsenceType[];

    viewModel: AbsenceSummaryViewModel[];
    loaded = false;

    constructor(
        private configService: ConfigurationService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.configService.getAbsentTypes().pipe(
            map(i => {
                this.absenceTypes = i;
                this.generateViewModel();
                this.loaded = true;
                this.changeDetectorRef.markForCheck();
            }),
            takeUntil(this.ngUnsubscribe)
        ).subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dynamicValue) {
            this.generateViewModel();
        }
    }

    private generateViewModel() {
        if (!this.dynamicValue || !this.absenceTypes) {
            this.viewModel = [];
            return;
        }

        this.viewModel = this.dynamicValue
            .filter(i => this.absenceTypes.map(a => a.id).includes(i.absenceTypeId))
            .map(i => ({
                name: this.absenceTypes.find(a => a.id === i.absenceTypeId)!.name,
                minutes: i.minutes
            }));
    }
}
