import { Injectable } from '@angular/core';
import { ResponseQueryService } from '../../common/services/response-query.service';
import { tap } from 'rxjs/operators';
import { FilterConditionNameValue, FilterValueType } from '../../../models/filters/filter.model';
import { BehaviorSubject } from 'rxjs';
import { ResponseFilterCondition, ResponseFilter } from '../../../models/api/response.model';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import * as moment from 'moment';

const MAX_IN_MEMORY = 50;

@Injectable()
export class ResponseFilterCacheService {

    private filterConditions: ResponseFilterCondition[] = [];
    private addSource = new BehaviorSubject<void>(undefined);
    change$ = this.addSource.asObservable();

    get list(): ResponseFilterCondition[] {
        return [...this.filterConditions];
    }

    constructor(
        private queryService: ResponseQueryService
    ) {
        this.queryService.response$.pipe(
            tap(response => {
                if (!response) { return; }
                if (!response.responseQuery.filter) { return; }
                if (!response.responseQuery.filter.conditions) { return; }

                this.handleConditions(response.responseQuery.filter.conditions);
            })
        ).subscribe();

        this.queryService.reportResponse$.pipe(
            tap(response => {
                if (!response) { return; }
                if (!response.responseFiltersQuery || !response.responseFiltersQuery.parameters) { return; }

                const params = response.responseFiltersQuery.parameters;
                Object.keys(params)
                    .filter(key => params[key].type === 'filter')
                    .forEach(key => {
                        const reportFilter = params[key].value as ResponseFilter;
                        this.handleConditions(reportFilter.conditions);
                    });
            })
        ).subscribe();
    }

    // OK, this is making some pretty broad assumptions. Esentially it ignores the "path" (and operator),
    // and assumes that the value being checked is either a number or a string...
    findConditionName(value: number | string): FilterConditionNameValue | undefined {

        const filteredValues = this.list.reverse()
            .map(i => this.filterValueArray(i.values));

        const match = lodashHelper.flatten(filteredValues)
            .find(conditionNameValue => conditionNameValue.value === value);

        // console.log('filtered values for', value, 'is', filteredValues, match, this.list);

        return match;
    }

    private handleConditions(responseFilterConditions: ResponseFilterCondition[]) {
        const toPush = responseFilterConditions
            .filter(r => (r.values as any[]).some((v: any) => v.name !== undefined))
            .filter(r => this.filterConditions.every((fc: any) => !lodashHelper.isEqual(fc, r)));

        this.filterConditions.push(...toPush);

        if (this.filterConditions.length > MAX_IN_MEMORY) {
            this.filterConditions = this.filterConditions.slice(this.filterConditions.length - MAX_IN_MEMORY, MAX_IN_MEMORY);
        }

        this.addSource.next(undefined);
    }

    private filterValueArray(values: (FilterConditionNameValue | FilterValueType)[]): FilterConditionNameValue[] {
        return values.filter(i => typeof(i) === 'object' && !moment.isMoment(i) && i.name) as FilterConditionNameValue[];
    }
}
