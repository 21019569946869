import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiHttpClientService } from '../../../api/services/api-http-client.service';
import { AllResponseIncludes, ResponseMappingMatches, ResponseModel, ResponseNoInclude } from '../../../../models/api/response.model';
import { MatchToRequest, Mapping } from '../../../../models/placement/mapping.model';
import { RequestListOptions, RequestPaginatedOptions } from '../../../../models/api/request-list-options.model';
import { NameValueResult } from '../../../../models/shared/search.model';
import { Observable } from 'rxjs';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { AllIncludeOptions, NoIncludeOption } from '../../../../models/shared/include.model';
import { Entity, MetadataType } from '../../../../models/entity/entity.type';

@Injectable()
export class MappingHttpService extends ApiHttpClientService<Mapping, NoIncludeOption, ResponseNoInclude> {

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super('mappings', httpClient, apiRequestHelperService);
    }

    mappingMatch<TDataEntity extends Entity, TIncludeOptions extends AllIncludeOptions, TResponseIncludes extends AllResponseIncludes>(
        dataType: MetadataType,
        matchTo: MatchToRequest,
        options: RequestListOptions<TIncludeOptions>
    ) {

        const url = this.generateMappingMatchUrl(
            ['mappings', 'matches'],
            dataType,
            matchTo,
            options
        );

        const newParmas = this.generateMappingMatchParams(matchTo);

        return this.httpClient.get<ResponseMappingMatches<TDataEntity, TResponseIncludes>>(url, {
            params: newParmas
        });
    }

    mappingMatchSearch<TResult extends NameValueResult>(
        dataType: MetadataType,
        matchTo: MatchToRequest,
        options: RequestPaginatedOptions
    ): Observable<ResponseModel<TResult[]>> {
        const url = this.generateMappingMatchUrl(
            ['mappings', 'matches', 'search'],
            dataType,
            matchTo,
            options
        );

        const newParmas = this.generateMappingMatchParams(matchTo);

        return this.httpClient.get<ResponseModel<TResult[]>>(url, {
            params: newParmas
        });
    }

    private generateMappingMatchUrl<TIncludeOptions extends AllIncludeOptions>(
        initialPath: string[],
        dataType: MetadataType,
        matchTo: MatchToRequest,
        options: RequestListOptions<TIncludeOptions>
    ): string {
        const matchToPath = matchTo.entities.map(i => [i.entityType as string, i.id]);
        const path: (string | number)[] = [...initialPath, dataType];
        matchToPath.forEach(m => path.push(m[0], m[1]));

        const url = this.generateUrl({
            path: path,
            overrideBasePath: true,
            // this has a type restriction because this service generally doesn't use options from another entity
            // but this matching call is an exception.
            options: options as any
        });

        return url;
    }

    private generateMappingMatchParams(matchTo: MatchToRequest) {
        let newParmas = new HttpParams();
        if (matchTo.ignoreIds.length > 0) {
            newParmas = newParmas.set('disableMappingIds', matchTo.ignoreIds.join(','));
        }

        return newParmas;
    }
}
