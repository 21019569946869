import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PlacementService } from './services/placement.service';
import { PlacementHttpService } from './services/http/placement.http.service';
import { ProviderModule } from '../provider/provider.module';
import { PlacementListComponent } from './components/placement/placement-list.component';
import { TimesheetModule } from '../timesheet/timesheet.module';
import { PlacementPlanListComponent } from './components/placement/placement-plan-list.component';
import { PlacementResultsComponent } from './components/placement/placement-results.component';
import { TimelineModule } from '../timeline/timeline.module';
import { StudentModule } from '../student/student.module';
import { PlacementTimelineResultsComponent } from './components/placement-timeline/placement-timeline-results.component';
import { PlacementDetailsComponent } from './components/placement/placement-details.component';
import { PlacementDetailsMasterComponent } from './components/placement/placement-details-master.component';
import { PlacementFormBaseComponent } from './components/placement/placement-form-base.component';
import { PlacementFormComponent } from './components/placement/placement-form.component';
import { PlacementDetailsBaseComponent } from './components/placement/placement-details-base.component';
import { StudentPlacementFormPositionSelectComponent } from './components/student-placement/student-placement-form-position-select.component';
import { PlacementPlanListDisplayComponent } from './components/placement/placement-plan-list-display.component';
import { MappingModule } from '../mapping/mapping.module';
import { CapacityCellComponent } from './components/capacity/capacity-cell.component';
import { StudentBlockPlacementFormComponent } from './components/student-block-placement/student-block-placement-form.component';
import { PlacementAllocationResultsComponent } from './components/placement/placement-allocation-results.component';
import { AlertModule } from '../alert/alert.module';

@NgModule({
    imports: [
        SharedModule,
        ProviderModule,
        TimesheetModule,
        TimelineModule,
        StudentModule,
        MappingModule,
        AlertModule
    ],
    providers: [
        PlacementService,
        PlacementHttpService
    ],
    declarations: [
        PlacementResultsComponent,
        PlacementListComponent,
        PlacementPlanListComponent,
        PlacementPlanListDisplayComponent,

        PlacementTimelineResultsComponent,

        StudentPlacementFormPositionSelectComponent,

        PlacementDetailsBaseComponent,
        PlacementDetailsComponent,
        PlacementDetailsMasterComponent,

        PlacementFormBaseComponent,
        PlacementFormComponent,

        StudentBlockPlacementFormComponent,

        PlacementAllocationResultsComponent,

        CapacityCellComponent
    ],
    exports: [
        PlacementResultsComponent,
        PlacementListComponent,
        PlacementPlanListComponent,
        PlacementPlanListDisplayComponent,

        PlacementTimelineResultsComponent,

        StudentPlacementFormPositionSelectComponent,

        PlacementDetailsBaseComponent,
        PlacementDetailsComponent,
        PlacementDetailsMasterComponent,

        PlacementFormBaseComponent,
        PlacementFormComponent,

        StudentBlockPlacementFormComponent,

        PlacementAllocationResultsComponent,

        CapacityCellComponent
    ]
})
export class PlacementModule {}
