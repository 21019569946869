import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';

// super basic

@Injectable()
export class RoutingHistoryService {

    private history: string[] = [];

    constructor(
        private router: Router
    ) {
        this.router.events.pipe(
            filter(i => i instanceof NavigationEnd),
            map((event: NavigationEnd) => this.history = [event.urlAfterRedirects, ...this.history.slice(0, 19)])
        ).subscribe();
    }

    getHistory(): string[] {
        return this.history;
    }
}
