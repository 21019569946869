import { Injectable } from '@angular/core';

// see:  https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript

// for the lazy: main points:
// - Not completely random
// - efficient
// - most inefficent part is forcing the uuid standard (which we dont' really need to be honest)

@Injectable()
export class UuidHelper {
    private static staticHeper = new UuidHelper();

    private lut: string[] = [];

    static generate() {
        return UuidHelper.staticHeper.generate();
    }


    constructor() {
        for (let i = 0; i < 256; i++) { this.lut[i] = (i < 16 ? '0' : '') + (i).toString(16); }
    }

    generate() {
        // tslint:disable:no-bitwise
        const d0 = Math.random() * 0xffffffff | 0;
        const d1 = Math.random() * 0xffffffff | 0;
        const d2 = Math.random() * 0xffffffff | 0;
        const d3 = Math.random() * 0xffffffff | 0;
        return this.lut[d0 & 0xff] + this.lut[d0 >> 8 & 0xff] + this.lut[d0 >> 16 & 0xff] + this.lut[d0 >> 24 & 0xff] + '-' +
            this.lut[d1 & 0xff] + this.lut[d1 >> 8 & 0xff] + '-' + this.lut[d1 >> 16 & 0x0f | 0x40] + this.lut[d1 >> 24 & 0xff] + '-' +
            this.lut[d2 & 0x3f | 0x80] + this.lut[d2 >> 8 & 0xff] + '-' + this.lut[d2 >> 16 & 0xff] + this.lut[d2 >> 24 & 0xff] +
            this.lut[d3 & 0xff] + this.lut[d3 >> 8 & 0xff] + this.lut[d3 >> 16 & 0xff] + this.lut[d3 >> 24 & 0xff];
    }
}
