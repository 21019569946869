import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';

export interface Breadcrumb {
    route: any[];
    text: string;
    combinePreviousRoute?: boolean;
}

@Injectable()
export class BreadcrumbService {

    private breadcrumbsSource = new BehaviorSubject<Breadcrumb[]>([]);
    breadcrumbs$ = this.breadcrumbsSource.asObservable();

    constructor(
        private titleService: Title
    ) { }

    setCrumbs(crumbs: Breadcrumb[]) {
        this.breadcrumbsSource.next(crumbs);

        const title = [environment.titlePrefix, ...crumbs.map(i => i.text)].join(' - ');
        this.titleService.setTitle(title);
    }
}
