import { EntityPropertyConfig } from '../../../../models/filters/filter.model';
import { FilterConditionHelperService } from '../../services/filter-condition-helper.service';
var FilterConditionDisplayComponent = /** @class */ (function () {
    function FilterConditionDisplayComponent(filterConditionHelper) {
        this.filterConditionHelper = filterConditionHelper;
    }
    Object.defineProperty(FilterConditionDisplayComponent.prototype, "displayOptions", {
        // this is a bit of a hack - todo, clean up
        get: function () {
            return {
                type: this.property.type,
                arrayOptions: this.property.values
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterConditionDisplayComponent.prototype, "operatorName", {
        get: function () {
            return this.filterConditionHelper.getOperatorName(this.condition.operator, this.property.type);
        },
        enumerable: true,
        configurable: true
    });
    return FilterConditionDisplayComponent;
}());
export { FilterConditionDisplayComponent };
