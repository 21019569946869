import { Injectable } from '@angular/core';
import { Position, CapacityResult } from '../../../../models/provider/position.model';
import { MergeApiHttpClientService } from '../../../api/services/api-http-client.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseModelList, ResponsePositionInclude, ResponseModelListQuery } from '../../../../models/api/response.model';
import { RequestListOptions } from '../../../../models/api/request-list-options.model';
import { Observable } from 'rxjs';
import { Moment } from 'moment';
import { API_DATE_FORMAT } from '../../../core-configuration/helpers/moment.helper';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { PositionIncludeOption } from '../../../../models/shared/include.model';

@Injectable()
export class PositionHttpService
    extends MergeApiHttpClientService<Position, PositionIncludeOption, ResponsePositionInclude> {

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super('positions', httpClient, apiRequestHelperService);
    }

    capacity(start: Moment, end: Moment, options: RequestListOptions<PositionIncludeOption>): Observable<ResponseModelListQuery<CapacityResult>> {
        const url = this.generateUrl({
            path: ['capacity'],
            options: options
        });

        const extraParams = new HttpParams().set(
            'start', start.format(API_DATE_FORMAT)
        ).set(
            'end', end.format(API_DATE_FORMAT)
        );

        return this.httpClient.get<ResponseModelListQuery<CapacityResult>>(url, { params: extraParams });
    }

    // Note: see student API... which is a copy-pasta of this ^.^
    blockMatches(blockId: number, options: RequestListOptions<PositionIncludeOption>): Observable<ResponseModelList<Position, ResponsePositionInclude>> {
        const url = this.generateUrl({
            path: ['planBlocks', blockId, 'positions'],
            overrideBasePath: true,
            options: options
        });

        return this.httpClient.get<ResponseModelList<Position, ResponsePositionInclude>>(url);
    }
}
