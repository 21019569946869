import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'cp-json-to-property-display',
    templateUrl: 'json-to-property-display.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class JsonToPropertyDisplayComponent {

    @Input() item: any;

    isObject(value: any): boolean {
        return !this.isArray(value) && typeof value === 'object';
    }

    isArray(value: any): boolean {
        return Array.isArray(value);
    }
}
