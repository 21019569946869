import { Injectable } from '@angular/core';
import { AlertHttpService } from './http/alert.http.service';
import { AlertResult, Alert } from '../../../models/alert/alert.model';
import { Observable, of } from 'rxjs';
import { map, tap, flatMap } from 'rxjs/operators';
import { ApiCacheSimpleService } from '../../api/services/api-cache-simple.service';
import { RequestSortPaginatedOptions } from '../../../models/api/request-list-options.model';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { ResponseNoInclude, ResponseModelList } from '../../../models/api/response.model';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { CACHE_DEFAULT_MAX_AGE } from '../../api/services/api-cache.service';
import { MetadataType, ALL_FILTER_TYPES } from '../../../models/entity/entity.type';

const ALERT_RESULT_CACHE = 1000; // one second
const ALERT_TYPE_CACHE = CACHE_DEFAULT_MAX_AGE;

@Injectable()
export class AlertService implements IUpdatableService<Alert> {

    constructor(
        private httpService: AlertHttpService,
        private simpleCacheService: ApiCacheSimpleService
    ) { }

    get(id: number): Observable<Alert> {
        return this.httpService.get([id], []).pipe(
            map(i => i.data)
        );
    }

    list(options: RequestSortPaginatedOptions): Observable<ResponseModelList<Alert, ResponseNoInclude>> {
        return this.httpService.list(options);
    }

    // this may be temporary... I haven't quite figured out how I'm going to do filter-filter requests (GET alert?filter.type = 'blah')
    listByType(type: MetadataType): Observable<Alert[]> {
        const typeFilter = this.createTypeFilter(type);
        const cacheTypeKey = this.createTypeCacheKey(type);

        const obs = this.httpService.list({ filters: [typeFilter] });

        return this.simpleCacheService.get(cacheTypeKey, obs, ALERT_TYPE_CACHE).pipe(
            map(i => i.data)
        );
    }

    results(type: MetadataType): Observable<AlertResult[]> {
        const typeFilter = this.createTypeFilter(type);
        const cacheKey = this.createTypeResultsCacheKey(type);
        const resultsObs = this.simpleCacheService.get(cacheKey, this.httpService.results({ filters: [typeFilter] }), ALERT_RESULT_CACHE);

        return this.listByType(type).pipe(
            flatMap(getResult => getResult.length > 0 ? resultsObs : of(null)),
            map(resultsResult => resultsResult ? resultsResult.data : [])
        );
    }

    create(entity: Alert): Observable<Alert> {
        return this.httpService.create(entity).pipe(
            map(i => i.data),
            tap(i => this.clearCache())
        );
    }

    update(entity: Alert): Observable<Alert> {
        return this.httpService.update(entity).pipe(
            map(i => i.data),
            tap(i => this.clearCache())
        );
    }

    updatePartial(entity: Partial<Alert>): Observable<Alert> {
        return this.httpService.update(entity).pipe(
            map(i => i.data),
            tap(i => this.clearCache())
        );
    }

    delete(entity: Alert): Observable<Object> {
        return this.httpService.delete([entity.id]).pipe(
            tap(i => this.clearCache())
        );
    }

    extractErrorConditions(alert: Alert) {
        let errorIndexes: number[] =  [];
        if (alert.errors) {
            errorIndexes = Object.keys(alert.errors)
                .filter(k => k.startsWith('filter.conditions'))
                .map(key => {
                    const startIndex = key.indexOf('[') + 1;
                    const endIndex = key.indexOf(']');
                    return +key.slice(startIndex, endIndex);
                });
        }

        const conditions = alert.filter.conditions
            .filter((value, index) => !errorIndexes.includes(index));

        const errorConditions = alert.filter.conditions
            .filter((value, index) => errorIndexes.includes(index));

        return {
            conditions: conditions,
            errorConditions: errorConditions
        };
    }

    private clearCache() {
        ALL_FILTER_TYPES.forEach(type => {
            const typeKey = this.createTypeCacheKey(type);
            if (this.simpleCacheService.has(typeKey)) {
                this.simpleCacheService.remove(typeKey);
            }

            const resultsKey = this.createTypeResultsCacheKey(type);
            if (this.simpleCacheService.has(resultsKey)) {
                this.simpleCacheService.remove(resultsKey);
            }
        });
    }

    private createTypeFilter(type: MetadataType) {
        return new RequestFilter('alerts', 'filter.type', 'eq', [type]);
    }

    private createTypeCacheKey(type: MetadataType) {
        return `alerts-${type}`;
    }

    private createTypeResultsCacheKey(type: MetadataType) {
        return `alert-results-${type}`;
    }
}
