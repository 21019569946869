import { Injectable } from '@angular/core';
import { SiteHttpService } from './http/site.http.service';
import { Site } from '../../../models/provider/site.model';
import { ApiCacheService } from '../../api/services/api-cache.service';
import {  ResponseProviderSubEntityInclude } from '../../../models/api/response.model';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { IListService, IIdListService } from '../../entity-shared/base/entity-search.base';
import { IMergeService } from '../../entity-shared/components/entity-merge/entity-merge.base.component';
import { ProviderBaseService } from './provider-base.service';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { ProviderSubEntityIncludeOption } from '../../../models/shared/include.model';

@Injectable()
export class SiteService extends
    ProviderBaseService<Site, ProviderSubEntityIncludeOption, ResponseProviderSubEntityInclude> implements
    IUpdatableService<Site>,
    IListService<Site, ProviderSubEntityIncludeOption, ResponseProviderSubEntityInclude>,
    IIdListService,
    IMergeService<Site> {

    protected includeOptions: ProviderSubEntityIncludeOption[] = ['providers', 'contacts'];

    constructor(
        protected httpService: SiteHttpService,
        protected apiCacheService: ApiCacheService,
        protected includeMapperService: IncludeMapperService
    ) {
        super('site', httpService, apiCacheService, includeMapperService);
    }

    protected createEntity = () => new Site();


    protected onAfterCreate(entity: Site): RequestFilter[] | undefined {
        this.apiCacheService.clearCacheByEntityId('providers', entity.providerId);
        return [];
    }

    protected onAfterUpdate(entity: Site): void {
        // archivable entities need to clear master types just to be safe
        this.apiCacheService.clearCacheByType('providers');
        this.apiCacheService.clearCacheByType('sites');
        return;
    }
}
