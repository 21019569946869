var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { ArchiveDisplayDynamicComponent } from '../../modules/display-helper/components/archive/archive-display-dynamic.component';
import { EntityBase } from '../entity/entity.model';
// one to one
var providerSummaryInclude = new IncludeEntityMetadata('providerSummary', 'providerSummaries', 'id', 'providerId');
var logInclude = new IncludeEntityMetadata('entity', 'entities', 'id', 'id');
// one to many
var sitesInclude = new IncludeEntityMetadata('site', 'sites', 'id', 'providerId');
var contactsInclude = new IncludeEntityMetadata('contact', 'contacts', 'id', 'providerId');
var positionsInclude = new IncludeEntityMetadata('position', 'positions', 'id', 'providerId');
var Provider = /** @class */ (function (_super) {
    __extends(Provider, _super);
    function Provider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        CpProperty({ type: 'boolean', dynamicComponent: ArchiveDisplayDynamicComponent }),
        __metadata("design:type", Boolean)
    ], Provider.prototype, "archived", void 0);
    __decorate([
        CpProperty({ type: 'string', showInDetails: true }),
        __metadata("design:type", String)
    ], Provider.prototype, "name", void 0);
    __decorate([
        CpProperty({ type: 'multilineText', showInDetails: true }),
        __metadata("design:type", String)
    ], Provider.prototype, "description", void 0);
    Provider = __decorate([
        CpClass({
            include: [providerSummaryInclude, logInclude],
            includeMany: [sitesInclude, contactsInclude, positionsInclude],
            defaultSort: [{ key: 'name' }],
            metadataType: 'provider',
            create: newProvider
        })
    ], Provider);
    return Provider;
}(EntityBase));
export { Provider };
export function newProvider() { return new Provider; }
