import { FilterValueType, FilterOperator, SearchFilterCondition } from '../filters/filter.model';
import { EntityTypePlural, NonEntityTypePlural } from '../entity/entity.type';

export class RequestFilter extends SearchFilterCondition {

    constructor(
        public type: EntityTypePlural | NonEntityTypePlural,
        path: string,
        operator: FilterOperator,
        values: FilterValueType[]
    ) {
        super();
        this.type = type;
        this.path = path;
        this.operator = operator;
        this.values = values;
    }
}
