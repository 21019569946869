import { NgModule } from '@angular/core';
import { ChangesListComponent } from './components/changes/changes-list.component';
import { CustomCommonModule } from '../common/common.module';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { ChangesService } from './services/changes.service';
import { ChangesHttpService } from './services/http/changes.http.service';

@NgModule({
    imports: [
        CustomCommonModule.forChild(),
        DisplayHelperModule
    ],
    providers: [
        ChangesService,
        ChangesHttpService
    ],
    declarations: [
        ChangesListComponent
    ],
    exports: [
        ChangesListComponent
    ],
})
export class ChangesModule { }
