var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NgZone } from '@angular/core';
import { BrowserClient, Hub, Severity } from '@sentry/browser';
import { environment } from '../../../../environments/environment';
import { RoutingHistoryService } from './routing-history.service';
// useful docs: https://docs.sentry.io/enriching-error-data/context/?platform=javascript
var SentryLoggingService = /** @class */ (function () {
    function SentryLoggingService(zone, routerHistoryService) {
        this.zone = zone;
        this.routerHistoryService = routerHistoryService;
        this.client = new BrowserClient({
            dsn: environment.sentryDsn,
            release: environment.commitRef
        });
    }
    SentryLoggingService.prototype.logError = function (error) {
        this.handleZones(error);
        // if (error.clientError) {
        //     console.error(error.clientError, error);
        // } else {
        //     console.error(error);
        // }
    };
    SentryLoggingService.prototype.handleZones = function (error) {
        var _this = this;
        if (NgZone.isInAngularZone()) {
            this.notifySentry(error);
        }
        else {
            this.zone.run(function () {
                _this.notifySentry(error);
            });
        }
    };
    SentryLoggingService.prototype.notifySentry = function (error) {
        var _this = this;
        var hub = new Hub(this.client);
        hub.configureScope(function (scope) {
            if (error.user) {
                var sentryUser = {
                    username: error.user.given_name + " " + error.user.family_name
                };
                // this requires either email, id, or username
                scope.setUser(__assign({}, sentryUser, error.user));
            }
            // Tags for major error data groups
            scope.setTag('status', error.status);
            scope.setTag('route', error.route);
            scope.setTag('environment', error.environment);
            scope.setTag('errorOrigin', error.clientError ? 'client' : 'server');
            // extra info for anything extra that could be useful
            scope.setExtra('fullUrl', error.fullUrl);
            scope.setExtra('routeParameters', error.routeParameters);
            scope.setExtra('routeQueryParameters', error.routeQueryParameters);
            scope.setExtra('message', error.message);
            scope.setExtra('routeHistory', _this.routerHistoryService.getHistory());
            if (!error.clientError) {
                scope.setExtra('requestUrl', error.requestUrl || 'none');
                scope.setExtra('requestBody', error.requestBody || 'none');
                scope.setExtra('requestMethod', error.requestMethod || 'none');
            }
            if (error.clientError) {
                hub.captureException(error.clientError);
            }
            else {
                var message = "Http Error Response " + error.status + " - " + error.requestUrl;
                hub.captureMessage(message, Severity.Error);
            }
        });
    };
    return SentryLoggingService;
}());
export { SentryLoggingService };
