import { Injectable } from '@angular/core';
import { Contact } from '../../../../models/provider/contact.model';
import { ResponseProviderSubEntityInclude } from '../../../../models/api/response.model';
import { HttpClient } from '@angular/common/http';
import { MergeApiHttpClientService } from '../../../api/services/api-http-client.service';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { ProviderSubEntityIncludeOption } from '../../../../models/shared/include.model';

@Injectable()
export class ContactHttpService
    extends MergeApiHttpClientService<Contact, ProviderSubEntityIncludeOption, ResponseProviderSubEntityInclude> {

    constructor(
            protected httpClient: HttpClient,
            protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super('contacts', httpClient, apiRequestHelperService);
    }
}
