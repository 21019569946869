import { DisplayPropertyType } from './types.model';
import { PipeTransform, Type } from '@angular/core';
import { IEntityListItemDynamicComponent } from '../../modules/entity-shared/components/entity-list/entity-list-item-dynamic.component';
import { AllIncludeOptions } from './include.model';
import { MetadataType, MetadataIncludeKeys, MetadataEntity } from '../entity/entity.type';

export class PropertiesMetadata {
    [key: string]: PropertyMetadata;
}

export class IncludeEntityMetadataOptions {
    // this shouldn't be here (sortPrefix) - the entity sort logic should avoid
    // this metadata class, and just use the config... (I think it's possible)
    requiresIncludeSortPrefix?: string;
    requiresIncludeIsNullable?: boolean;
    requiresInclude?: AllIncludeOptions[];
    hideFromList?: boolean;
    predicateManyOverride?: (master: any, slaves: any[]) => any[];
}

export class IncludeEntityMetadataCore<TMaster extends MetadataEntity, TSlave extends MetadataEntity> {
    constructor(
        public type: MetadataType,
        public includeKey: MetadataIncludeKeys,
        public propertyMaster: string,
        public propertySlave: string,
        public options?: IncludeEntityMetadataOptions) {
    }
}

export class IncludeEntityMetadata<TMaster extends MetadataEntity, TSlave extends MetadataEntity> extends IncludeEntityMetadataCore<TMaster, TSlave> {
    constructor(
        public type: MetadataType,
        public includeKey: MetadataIncludeKeys,
        protected _propertyMaster: keyof TMaster,
        protected _propertySlave: keyof TSlave,
        options?: IncludeEntityMetadataOptions) {

        super(type, includeKey, _propertyMaster as string, _propertySlave as string, options);
    }
}

export class ClassMetadata<TEntity extends MetadataEntity> {
    include?: IncludeEntityMetadataCore<TEntity, MetadataEntity>[];
    includeMany?: IncludeEntityMetadataCore<TEntity, MetadataEntity>[];
    defaultSort?: { key: keyof TEntity, desending?: boolean }[];
    metadataType: MetadataType;
    create: () => TEntity;
}

export class PropertyPipeMetadata {
    pipe: PipeTransform;
    args?: any[];
}

// lots of extended property cross over
export type TextAlign = 'left' | 'center' | 'right';
export class PropertyMetadata {
    type: DisplayPropertyType;
    label?: string;

    showInDetails?: boolean;
    hideFromList?: boolean;
    defaultListVisible?: boolean;
    listHeaderAlign?: TextAlign;

    pipe?: PropertyPipeMetadata;
    dynamicComponent?: Type<IEntityListItemDynamicComponent>;
}

