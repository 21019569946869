import { IEnvironmentConfig } from './environment.model';

export const environment: IEnvironmentConfig = {
    production: true,
    envType: 'dmu-master',
    useSentry: true,
    apiBaseUrl: 'https://lm3ojs37x9.execute-api.eu-west-2.amazonaws.com/dmu-master/api',
    apiVersion: 'v1',
    baseUrl: 'https://pas.dmu.ac.uk',
    defaultCountryCode: 'GB',
    momentLocale: 'en-gb',
    momentDateFormat: 'D-MMM-YYYY',
    momentDateTimeFormat: 'lll',
    titlePrefix: 'PAS',

    // dmu specific current filter
    currentStudentFilter: {
        type: 'students',
        path: 'currentCourses.properties.code',
        operator: 'eq',
        values: ['B70052', 'B70053', 'B70054', 'B70056', 'B72048', 'B72051', 'B70092'],
    },

    // sentry
    sentryDsn: 'https://e4dec47a1b424a1e8d97959f7343b821@errors.careerhub.support/6',
    commitRef: '92366534a1b1b2fa5a20faced8aedda567eb8dd6',

    defaultRegionList: [
        'Derbyshire',
        'Leicestershire',
        'Lincolnshire',
        'Northamptonshire',
        'Nottinghamshire',
        'Other',
    ],
};
