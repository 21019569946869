import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ConfigurationEntityListItemBase } from '../entity-list-item/configuration-entity-list-item.base';
import { ConfigurationService } from '../../../core-configuration/services/configuration.service';

@Component({
    selector: 'cp-role-entity-list-item',
    templateUrl: 'role-entity-list-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RoleEntityListItemComponent extends ConfigurationEntityListItemBase implements OnInit {
    constructor(
        protected configService: ConfigurationService,
        protected changeDetectionRef: ChangeDetectorRef
    ) {
        super('role', configService, changeDetectionRef);
    }
}
