import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { TimelinePlansService } from './services/timeline-plans.service';
import { ViewModeControlComponent } from './components/view-mode-control/view-mode-control.component';
import { TimelineWeeksContainerComponent } from './components/timeline/timeline-weeks-container.component';
import { TimelinePlacementsComponent } from './components/timeline-master/timeline-placements.component';
import { TimelinePlacementsService } from './services/timeline-placements.service';
import { TimelineCoreService } from './services/timeline-core.service';
import { TimelinePlacementDataItemComponent } from './components/timeline/timeline-placements-data-item.component';
import { TimelinePlanDataItemComponent } from './components/timeline/timeline-plans-data-item.component';
import { TimelineSinglePlanComponent } from './components/timeline-master/timeline-single-plan.component';
import { TimelineColumnHeaderComponent } from './components/timeline-column/timeline-column-header.component';
import { TimelineMasterComponent } from './components/timeline-master/timeline-master.component';
import { TimelineShadowComponent } from './components/timeline/timeline-shadow.component';

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        TimelinePlansService,
        TimelinePlacementsService,
        TimelineCoreService
    ],
    declarations: [
        TimelineShadowComponent,
        TimelineSinglePlanComponent,
        ViewModeControlComponent,
        TimelineWeeksContainerComponent,
        TimelinePlacementsComponent,
        TimelinePlacementDataItemComponent,
        TimelinePlanDataItemComponent,
        TimelineColumnHeaderComponent,
        TimelineMasterComponent
    ],
    exports: [
        TimelineShadowComponent,
        TimelineSinglePlanComponent,
        ViewModeControlComponent,
        TimelinePlacementsComponent,
        TimelinePlacementDataItemComponent,
        TimelinePlanDataItemComponent,
        TimelineColumnHeaderComponent
    ]
})
export class TimelineModule { }
