import { Injectable } from '@angular/core';
import { Subject, forkJoin, of } from 'rxjs';
import { FilterPath, FilterConfigHelperService } from './filter-config-helper.service';
import { InternalFilter, QueryFilterKey } from './filter-query-helper.service';
import { flatMap, map } from 'rxjs/operators';
import { FilterConditionHelperService } from './filter-condition-helper.service';
import { FilterPropertyPath } from './filter-config-inner.service';

export interface QueryFilterPathEvent {
    key: QueryFilterKey;
    path: FilterPath[];
}

export interface QueryFilterAddEvent {
    key: QueryFilterKey;
    filter: InternalFilter;
}

@Injectable()
export class EntityFilterListService {

    private pathAddSource = new Subject<QueryFilterPathEvent>();
    pathAdd$ = this.pathAddSource.asObservable();

    private filterAddSource = new Subject<QueryFilterAddEvent>();
    filterAdd$ = this.filterAddSource.asObservable();

    constructor(
        private configHelper: FilterConfigHelperService,
        private conditionHelper: FilterConditionHelperService
    ) {
        this.pathAdd$.pipe(
            flatMap(event => forkJoin(
                this.configHelper.getPropertyPathByPath(event.key.type, event.path),
                of(event.key)
            )),
            map(result => {
                const key = result[1];
                const emptyFilter = this.createEmptyFilter(result[0]);
                this.filterAddSource.next({
                    key: key,
                    filter: emptyFilter
                });
            })
        ).subscribe();
    }

    addPath(key: QueryFilterKey, filterPath: FilterPath[]) {
        this.pathAddSource.next({
            key: key,
            path: filterPath
        });
    }

    addFilter(key: QueryFilterKey, filter: InternalFilter) {
        this.filterAddSource.next({
            key: key,
            filter: filter
        });
    }

    private createEmptyFilter(entityFilterPath: FilterPropertyPath[]): InternalFilter {
        const final = entityFilterPath[entityFilterPath.length - 1];
        const condition = this.conditionHelper.createEmptyCondition(final.entityIdProperty || final.property);

        const filter: InternalFilter = {
            path: entityFilterPath,
            property: final.property,
            entityIdProperty: final.entityIdProperty,
            condition: condition
        };

        return filter;
    }
}
