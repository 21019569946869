import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../services/error.service';
import { LoggingService } from '../services/logging.service';
import { ErrorHandlerService } from '../services/error-handler.service';
import { EnvType } from '../../../../environments/environment.model';
import { TokenUser } from '../../../models/authentication/authentication.model';

export interface IErrorContext {
    route: string;
    fullUrl: string;
    routeParameters: Object;
    routeQueryParameters: Object;
    status: string;
    message: string;

    environment: EnvType;

    clientError?: Error;
    requestUrl?: string;
    requestBody?: string;
    requestMethod?: string;
    user?: TokenUser;
}

@Injectable()
export class ErrorHandlerInterceptor implements ErrorHandler {

    // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
    constructor(private injector: Injector) { }

    handleError(error: Error | HttpErrorResponse): void {

        const errorService = this.injector.get(ErrorService);
        const loggingService = this.injector.get(LoggingService);
        const errorHandlerService = this.injector.get(ErrorHandlerService);

        // server side error are handled at a higher level
        // in the api-error-interceptor
        if (!errorService.isHttpError(error)) {
            // client side errors
            const context = errorService.getClientContext(error);
            loggingService.logError(context);
            errorHandlerService.handleClientError(context);
        }
    }
}
