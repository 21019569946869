import { Injectable, Type } from '@angular/core';
import { EntityListDescriptorService, ListMetadataCollection } from '../../core-configuration/services/metadata/entity-list-descriptor.service';
import { map, concatAll, toArray, takeUntil, filter, catchError } from 'rxjs/operators';
import { PropertyPipeMetadata } from '../../../models/shared/metadata.model';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { DisplayPropertyType, ExtendedPropertyType } from '../../../models/shared/types.model';
import { OptionSetOption } from '../../../models/extended-properties/option-set.model';
import { AllResponseIncludes } from '../../../models/api/response.model';
import { IListService } from '../base/entity-search.base';
import { RequestListOptions } from '../../../models/api/request-list-options.model';
import { dotPropertyTransform } from '../../display-helper/pipes/dot-property.pipe';
import { ExportToCsv, Options as CsvOptions } from 'export-to-csv';
import { TranslateService } from '@ngx-translate/core';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import { entityToString } from '../../../models/entity/entity.model';
import { IEntityListItemDynamicComponent } from '../components/entity-list/entity-list-item-dynamic.component';
import { AllIncludeOptions } from '../../../models/shared/include.model';
import { MetadataIncludeKeys, MetadataType } from '../../../models/entity/entity.type';

export interface CsvDownloadProcess {
    count: number;
    total: number;
    page: number;
    totalPages: number;
    error?: any;
}

export interface CsvVisibleMeta {
    key: string;
    propertyKey: string;
    type: DisplayPropertyType;
    label: string;
    isIncludeMany: boolean;
    includeKey?: MetadataIncludeKeys;

    transformToEntityPath: string;

    optionsType?: ExtendedPropertyType;
    optionSetOptions?: OptionSetOption[];
    dynamicComponent?: Type<IEntityListItemDynamicComponent>;
    pipe?: PropertyPipeMetadata;
}

@Injectable()
export class EntityListCsvService {

    private downloadSource = new Subject<boolean>();
    download$ = this.downloadSource.asObservable();

    private progressSource = new BehaviorSubject<CsvDownloadProcess | undefined>(undefined);
    progress$ = this.progressSource.asObservable();

    private allListMeta: ListMetadataCollection;

    private active = false;

    constructor(
        private entityListDescriptor: EntityListDescriptorService,
        private translateService: TranslateService
    ) {
        this.entityListDescriptor.change$.pipe(
            map(all => this.allListMeta = all)
        ).subscribe();
    }

    toggleDownload(active: boolean) {
        this.active = active;
        this.downloadSource.next(this.active);
    }

    startDownload<TEntity, TIncludeOption extends AllIncludeOptions, TResponseIncludes extends AllResponseIncludes>(
        type: MetadataType,
        service: IListService<TEntity, AllIncludeOptions, TResponseIncludes>,
        options: RequestListOptions<TIncludeOption>
    ) {
        const newOptions = lodashHelper.cloneDeep(options);
        newOptions.skip = 0;
        newOptions.take = 200;

        const takeUntilObs = this.download$.pipe(
            filter(i => i === false)
        );

        return service.listIncludeGetAllStream(newOptions).pipe(
            map(response => {
                const totalPages = response.total ? Math.ceil(response.total / response.query.take) : 0;
                const page = Math.ceil((response.query.skip + response.query.take) / response.query.take);

                this.progressSource.next({ page: page, totalPages: totalPages, count: response.query.skip + response.query.take, total: response.total });

                return response.data;
            }),
            concatAll(),
            toArray(),
            map(array => {
                const meta = this.getVisible(type);
                this.extractData(type, array, meta);
                this.toggleDownload(false);
            }),
            catchError(err => {
                this.progressSource.next({ page: 0, totalPages: 0, count: 0, total: 0, error: err });
                this.toggleDownload(false);
                return of(null);
            }),
            takeUntil(takeUntilObs),
        );
    }

    private extractData<TEntity>(type: MetadataType, array: TEntity[], meta: CsvVisibleMeta[]) {

        const visKeys = meta.map(i => i.label);
        const typeLabel = this.translateService.instant(`${type}.entityName`);

        const data = array
            .map(item => {
                const obj = {};
                obj[typeLabel] = entityToString(item);

                meta.map(m => {
                    obj[m.label] = this.extractValues(item, m);
                });

                return obj;
            });


        if (data.length === 0) {
            return;
        }

        console.log('generating csv', data, meta);

        const csvOptions: CsvOptions = {
            fieldSeparator: ',',
            showLabels: true,
            filename: type.toString(),
            headers: [typeLabel, ...visKeys]
        };

        const csvExporter = new ExportToCsv(csvOptions);

        csvExporter.generateCsv(data);
    }

    private extractValues(item: any, meta: CsvVisibleMeta): string {
        const entityOrArray = dotPropertyTransform(item, meta.transformToEntityPath) || [];
        const asArray = Array.isArray(entityOrArray) ? entityOrArray : [entityOrArray];
        const valueArray = lodashHelper.flatten(asArray.map((i: any) => this.extractSingleValue(i, meta)));

        return valueArray.map(v => v.toString()).join('\r\n');
    }

    // The strange ones are
    // person, option, entity, dynamic
    // person - display as string
    // entity - display using toString
    // option - get option "name"
    // dynamic - ??
    private extractSingleValue(value: any, meta: CsvVisibleMeta) {
        // sometimes the "type" is still an actual valid type (like experienceId is still an int)
        // so you need to check if the component exists
        if (meta.dynamicComponent) {
            return 'Unable to render dynamic column';
        }

        if (meta.type === 'entity') {
            return value;
        }

        const transformedValue = dotPropertyTransform(value, meta.propertyKey) || '';
        if (meta.type === 'option') {
            const tValueArray = Array.isArray(transformedValue) ? transformedValue : [transformedValue];

            return tValueArray.map(tv => {
                if ((tv as OptionSetOption).name) {
                    return tv.name;
                }

                if (!meta.optionSetOptions) {
                    return '';
                }

                const optionSetOption = meta.optionSetOptions.find(o => (tv as OptionSetOption).id === o.id);
                if (!optionSetOption) {
                    return '';
                }

                return optionSetOption.name;
            });
        }

        if (meta.pipe) {
            return meta.pipe.pipe.transform(transformedValue, meta.pipe.args || []);
        }

        return transformedValue;
    }

    private getVisible(type: MetadataType): CsvVisibleMeta[] {

        const list = this.allListMeta[type];
        if (!list) {
            throw new Error('metadata not initilized for this entity type' + type);
        }

        return list
            .filter(item => item.visible)
            .map(item => {
                const hasIncludeKey = item.includeKey !== '';
                let label = '';
                if (hasIncludeKey) {
                    // be careful of the two "types" being used here
                    label = this.translateService.instant(item.type + '.entityName') || '';
                    if (item.metadata.type !== 'entity') {
                        label += ' > ' + item.metadata.label || item.propertyKey;
                    }
                } else {
                    label = item.metadata.label || item.propertyKey;
                }

                let transformToEntityPath = '';
                if (hasIncludeKey) {
                    if (item.isIncludeMany) {
                        transformToEntityPath = `includeMany.${item.includeKey}`;
                    } else {
                        // uses a different type to the type returned in the csvVis object
                        transformToEntityPath = `include.${item.type}`;
                    }
                }

                const csvItem: CsvVisibleMeta = {
                    label: label,
                    isIncludeMany: item.isIncludeMany,
                    includeKey: item.includeKey !== '' ? item.includeKey : undefined,
                    key: item.key,
                    propertyKey: item.propertyKey,
                    type: item.metadata.type,
                    optionsType: item.optionType,
                    optionSetOptions: item.optionSetOptions,
                    dynamicComponent: item.metadata.dynamicComponent,
                    pipe: item.metadata.pipe,

                    transformToEntityPath: transformToEntityPath
                };

                return csvItem;
            });
    }
}
