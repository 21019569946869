import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { StudentService } from './services/student.service';
import { StudentHttpService } from './services/http/student.http.service';
import { StudentFormComponent } from './components/student/student-form.component';
import { StudentAddressFormComponent } from './components/student-address/student-address-form.component';
import { StudentAddressService } from './services/student-address.service';
import { StudentAddressHttpService } from './services/http/student-address.http.service';
import { StudentDetailsComponent } from './components/student/student-details.component';
import { StudentResultsComponent } from './components/student/student-results.component';
import { StudentDetailsMasterComponent } from './components/student/student-details-master.component';
import { StudentAddressResultsComponent } from './components/student-address/student-address-results.component';
import { StudentCourseService } from './services/student-course.service';
import { StudentCourseHttpService } from './services/http/student-course.http.service';
import { StudentCourseResultsComponent } from './components/student-course/student-course-results.component';
import { StudentCourseFormComponent } from './components/student-course/student-course-form.component';
import { StudentSummaryComponent } from './components/student-summary/student-summary.component';
import { StudentCourseDropdownComponent } from './components/student-course/student-course-dropdown.component';
import { StudentCourseStatusService } from './services/student-course-status.service';
import { AlertModule } from '../alert/alert.module';

@NgModule({
    imports: [
        SharedModule,
        AlertModule
    ],
    providers: [
        StudentService,
        StudentAddressService,

        StudentHttpService,
        StudentAddressHttpService,

        StudentCourseService,
        StudentCourseHttpService,

        StudentCourseStatusService
    ],

    declarations: [
        StudentFormComponent,
        StudentDetailsMasterComponent,
        StudentDetailsComponent,
        StudentResultsComponent,

        StudentAddressFormComponent,
        StudentAddressResultsComponent,

        StudentCourseFormComponent,
        StudentCourseResultsComponent,
        StudentCourseDropdownComponent,

        StudentSummaryComponent
    ],

    exports: [
        StudentResultsComponent,
        StudentDetailsMasterComponent,
        StudentDetailsComponent,
        StudentAddressFormComponent,
        StudentFormComponent,
        StudentAddressResultsComponent,

        StudentCourseFormComponent,
        StudentCourseResultsComponent,
        StudentCourseDropdownComponent,

        StudentSummaryComponent
    ]
})
export class StudentModule { }
