import { SystemType } from '../shared/types.model';
import { CpClass } from '../../modules/core-configuration/decorators/metadata.decorator';
import { EntityBase } from '../entity/entity.model';
import { ResponseQueryPagination, ResponseFilter, ResponseError } from '../api/response.model';
import { Moment } from 'moment';
import { RequestFilter } from '../api/request-filter.model';

export type ReportTableColumnType = SystemType;
export type ReportParameterType = 'dateRange' | 'date' | 'int32' | 'filter' | 'string' | 'boolean' | 'userId' | 'clientUri';

export interface ReportParameter {
    required: boolean;
    type: ReportParameterType;
    name: string;
    description: string;
    value?: number | Moment | ResponseFilter;
}

export interface ReportParameterKeyMap {
    [key: string]: ReportParameter;
}

@CpClass({
    create: newReport,
    metadataType: 'report',
    defaultSort: [{ key: 'name' }]
})
export class Report extends EntityBase {
    name: string;
    key: string;
    description: string;
    archived: boolean;
    sql: string;
    parameters: ReportParameterKeyMap;
    errors?: ResponseError[];
}

export interface ReportTableColumn {
    name: string;
    type: ReportTableColumnType;
    sum?: number;
}

export interface ReportTable {
    columns: ReportTableColumn[];
    rows: any[][];
    total: number;
    name: string;
}

export interface ReportResponse {
    report: Report;
    data: ReportTable[];
    query: ResponseFiltersQuery;
    messages: string[];
    statistics: { [key: string]: any };
}

export interface ReportSort {
    name: string;
    descending: boolean;
}

export interface ReportTableResponse extends ResponseQueryPagination {
    exclude: boolean;
    columns: string[];
    sort: ReportSort[];
}

export interface ResponseFiltersQuery {
    id: number;
    parameters: ReportParameterKeyMap;
    tables: ReportTableResponse[];
}

export interface ReportPaginationQuery {
    index: number;
    skip?: number;
    take?: number;
    sort?: ReportSort[];
    columns?: string[];
}

export interface ReportRequestQuery {
    key: string;
    parameters: ReportParameterKeyMap;
    filters: RequestFilter[];
    tables?: ReportTableVisibility[];
}

export interface ReportRequestRunQuery extends ReportRequestQuery {
    pagination: ReportPaginationQuery[];
}

export interface ReportTableVisibility {
    name: string;
    visible: boolean;
    index: number;
}

export interface ReportRequestDownloadQuery extends ReportRequestQuery {
    tableColumns?: { index: number, columns: string[]}[];
}

export function newReport() { return new Report(); }
