import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class RouteHelper {

    constructor() { }
    // THIS DOESNT WORK, DO NOT USE
    // combines all route params as well as query params
    combineAllRouteParams(route: ActivatedRoute): Observable<Params> {
        const routeParamsChildObs = this.recursiveParamsChild(route);
        const routeParamsParentObs = this.recursiveParamsParent(route);

        return combineLatest(routeParamsChildObs, routeParamsParentObs, route.queryParams).pipe(
            tap(i => console.log('is this messing it up?')),
            map(results => ({ ...results[0], ...results[1], ...results[2] }))
        );
    }

    private recursiveParamsParent(route: ActivatedRoute, params?: Observable<Params>): Observable<Params> {
        const combined = combineLatest(route.params, params || of({})).pipe(
            map(results => ({ ...results[0], ...results[1] }))
        );

        if (route.parent === null) {
            return combined;
        }

        return this.recursiveParamsParent(route.parent, combined);
    }

    private recursiveParamsChild(route: ActivatedRoute, params?: Observable<Params>): Observable<Params> {
        if (!route.firstChild) { return params || of({}); }

        const combined = combineLatest(route.firstChild.params, params || of({})).pipe(
            map(results => ({ ...results[0], ...results[1] }))
        );

        return this.recursiveParamsChild(route.firstChild, combined);
    }
}
