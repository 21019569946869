import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { ArchiveDisplayDynamicComponent } from '../../modules/display-helper/components/archive/archive-display-dynamic.component';
import { EntityBase } from '../entity/entity.model';
import { ColorDisplayDynamicComponent } from '../../modules/display-helper/components/color-display-dynamic/color-display-dynamic.component';
import { MinutesPipe } from '../../modules/display-helper/pipes/minutes.pipe';

@CpClass({
    metadataType: 'experience',
    create: createExperience
})
export class Experience extends EntityBase {
    @CpProperty({ type: 'string' })
    name: string;

    @CpProperty({ type: 'boolean', dynamicComponent: ArchiveDisplayDynamicComponent })
    archived: boolean;

    @CpProperty({ type: 'string', defaultListVisible: true, dynamicComponent: ColorDisplayDynamicComponent })
    color: string;

    @CpProperty({ label: 'Disallow Position', showInDetails: true, type: 'boolean' })
    excludePositions: boolean;

    @CpProperty({ label: 'Disallow Plan Block', showInDetails: true, type: 'boolean' })
    excludePlanBlocks: boolean;

    @CpProperty({ label: 'Hours', type: 'int32', showInDetails: true, pipe: { pipe: new MinutesPipe() } })
    minutes?: number;
}

export function createExperience() { return new Experience; }
