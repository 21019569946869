import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'cp-icon',
    template: `
        <fa-icon
            *ngIf="icon"
            [icon]="icon"
            [size]="iconSize"
            [hidden]="hideIconIf"
            [attr.title]="iconTitle"
            [ngClass]="iconClass">
        </fa-icon><ng-content></ng-content>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class IconComponent {
    @Input() icon: string;
    @Input() hideIconIf = false;
    @Input() iconTitle = '';
    @Input() iconSize = 'md';
    @Input() iconClass: Object | string = 'mr-1';
}
