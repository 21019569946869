import { NgModule } from '@angular/core';
import { AbsenceTypeService } from './services/absence-type.service';
import { AbsenceTypeHttpService } from './services/http/absence-type.http.service';
import { CustomCommonModule } from '../common/common.module';
import { CustomFormsModule } from '../form/form.module';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { AbsenceTypeFormComponent } from './components/absence-type/absence-type-form.component';
import { AbsenceTypeListComponent } from './components/absence-type/absence-type-list.component';
import { AbsenceTypeSelectComponent } from './components/absence-type-select/absence-type-select.component';
import { AbsenceSummaryListComponent } from './components/absence-summary/absence-summary-list.component';

@NgModule({
    entryComponents: [
        AbsenceSummaryListComponent
    ],
    imports: [
        CustomCommonModule.forChild(),
        CustomFormsModule,
        DisplayHelperModule
    ],
    providers: [
        AbsenceTypeService,
        AbsenceTypeHttpService
    ],
    declarations: [
        AbsenceTypeFormComponent,
        AbsenceTypeListComponent,
        AbsenceTypeSelectComponent,
        AbsenceSummaryListComponent
    ],
    exports: [
        AbsenceTypeFormComponent,
        AbsenceTypeListComponent,
        AbsenceTypeSelectComponent,
        AbsenceSummaryListComponent
    ]
})
export class AbsenceTypeModule { }
