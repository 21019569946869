import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { minutesToHoursDisplay } from '../../pipes/minutes.pipe';

export interface MinutesSummary {
    absent: number;
    attended: number;
    expected: number;
}

@Component({
    selector: 'cp-minutes-summary',
    templateUrl: 'minutes-summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MinutesSummaryComponent {

    @Input() value: MinutesSummary;

    get total(): number {
        const timesheetTotal = this.value.attended + this.value.absent;

        if (timesheetTotal > this.value.expected) {
            return timesheetTotal;
        }

        return this.value.expected || 0;
    }

    get unaccounted(): number {
        if (this.total > this.value.expected) { return 0; }

        return this.value.expected - this.value.attended - this.value.absent;
    }

    get absentWidth(): number {
        if (this.total === 0) { return 0; }
        return this.value.absent / this.total * 100;
    }

    get attendedWidth(): number {
        if (this.total === 0) { return 0; }

        const max = this.value.expected && this.value.attended > this.value.expected ?
            this.value.expected : this.value.attended;

        return max / this.total * 100;
    }

    get unaccountedWidth(): number {
        if (this.unaccounted === 0) { return 0; }

        return this.unaccounted / this.total * 100;
    }

    get title(): string {
        const array: string[] = [
            `Expected hours: ${minutesToHoursDisplay(this.value.expected) || 0}`,
            `Attended hours: ${minutesToHoursDisplay(this.value.attended) || 0}`,
            `Absent hours: ${minutesToHoursDisplay(this.value.absent) || 0}`,
            `Unaccounted hours: ${minutesToHoursDisplay(this.unaccounted) || 0}`
        ];
        return array.join('\r\n');
    }
}
