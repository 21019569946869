import { Moment } from 'moment';
import {
    CpClass,
    CpProperty,
} from '../../modules/core-configuration/decorators/metadata.decorator';
import { ResponseModelError, ResponseFilter } from '../api/response.model';
import { ArchiveDisplayDynamicComponent } from '../../modules/display-helper/components/archive/archive-display-dynamic.component';
import { DateRange } from '../shared/date-range.model';
import { EntityBase } from '../entity/entity.model';
import { StudentResult } from '../student/student.model';
import { PositionMatchResult } from '../provider/position.model';
import { FilterDisplayDynamic } from '../../modules/filter/components/filter-display/filter-display-dynamic.component';
import { EntityType } from '../entity/entity.type';

export type MappingPriority =
    | 'requiredConstraint'
    | 'optionalConstraint'
    | 'preference1'
    | 'preference2'
    | 'preference3';
export const ALL_MAPPING_PRIORITIES: MappingPriority[] = [
    'requiredConstraint',
    'optionalConstraint',
    'preference1',
    'preference2',
    'preference3',
];

export type MappingPropertyType =
    | 'leftAll'
    | 'leftNone'
    | 'leftAnyOrLeftEmpty'
    | 'filters';
export const ALL_MAPPING_PROPERTY_TYPES: MappingPropertyType[] = [
    'leftAll',
    'leftNone',
    'leftAnyOrLeftEmpty',
    'filters',
];

export type MappingEntityType = 'position' | 'student' | 'planBlock';
export const All_MAPPING_ENTITY_TYPES: MappingEntityType[] = [
    'position',
    'student',
    'planBlock',
];

export interface PropertyMatch {
    mappingId: number;
    value: any;
    name?: string;
}

export interface MatchBase {
    id: number;
    matches: MappingMatches;
}

export interface MappingMatches {
    filters: number[];
    properties: PropertyMatch[];
}

export interface StudentMatch extends MatchBase {
    firstName: string;
    lastName: string;
    externalId: string;
}

export interface PositionMatch extends MatchBase {
    name: string;
    providerId: number;
    providerName: string;
    capacity: number;
    capacityUsed: number;
    availableFrom: Moment;
    availableTo?: Moment;
}

@CpClass({
    create: createMapping,
    metadataType: 'mapping',
})
export class Mapping extends EntityBase {
    @CpProperty({ type: 'string' })
    name: string;

    @CpProperty({ type: 'multilineText', showInDetails: true })
    description: string;

    @CpProperty({
        type: 'boolean',
        dynamicComponent: ArchiveDisplayDynamicComponent,
    })
    archived: boolean;

    @CpProperty({ type: 'string' })
    priority: MappingPriority;

    @CpProperty({ type: 'dynamic', dynamicComponent: FilterDisplayDynamic })
    leftFilter: ResponseFilter;

    @CpProperty({ type: 'dynamic', dynamicComponent: FilterDisplayDynamic })
    rightFilter: ResponseFilter;

    @CpProperty({ type: 'string' })
    rightPath: string;

    @CpProperty({ type: 'string' })
    leftPath: string;

    @CpProperty({ type: 'string' })
    type: MappingPropertyType;

    // inner error object. API will return the entity if it exists, even if there
    // is an array on the inner mapping filter or property.
    errors?: ResponseModelError;
}

export function createMapping() {
    return new Mapping();
}

export interface MatchToEntity {
    id: number;
    entityType: EntityType;
}

export interface MatchToRequest {
    entities: MatchToEntity[];
    ignoreIds: number[];
}

export interface MatchToDaterangeRequest extends MatchToRequest {
    dateRange: DateRange;
}

export interface MappingMatchValue {
    id: number;
    filter: boolean;
    values?: any[];
}

export interface MappingResult {
    disabled: boolean;
    leftValues: MappingMatchValue[];
    rightValues: MappingMatchValue[];
    mapping: Mapping;
}

// auto matching
export interface MappingPropertyMatch {
    id: number;
    left: any[];
    right: any[];
}

export interface MappingFilterMatch {
    id: number;
    left: boolean;
    right: boolean;
}

export interface PlacementMatch {
    student: StudentResult;
    position: PositionMatchResult;
    properties: MappingPropertyMatch[];
    filters: MappingFilterMatch[];
}
