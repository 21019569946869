
export type AuthUserType = 'support' | 'admin' | 'contact' | 'student' | 'superAdmin';

export const LOCALSTORAGE_JWT_TOKEN = 'cp:auth:jwt';
export const LOCALSTORAGE_ID_TOKEN = 'cp:auth:id';
export const LOCALSTORAGE_NONCE = 'cp:auth:nonce';

export const AUTH_USER_TYPE_LITERAL = 'x_type';

export class TokenUser {
    given_name?: string;
    family_name?: string;
    email?: string;
    phone_number?: string;
    nonce: string;
}

export class AccessToken {
    exp: number;
    [AUTH_USER_TYPE_LITERAL]: AuthUserType;
}

export class LocalStorageTokens {
    idToken: TokenUser;
    accessToken: AccessToken;
    nonce: string;
}
