import { Component, Input } from '@angular/core';
import { IEntityListItemDynamicComponent } from '../../../entity-shared/components/entity-list/entity-list-item-dynamic.component';
import { DateRangeOverlaps } from './date-range-breaks-display.component';

@Component({
    selector: 'cp-date-range-breaks-display-dynamic',
    template: `
        <cp-date-range-breaks-display
            *ngIf="dynamicValue"
            [start]="dynamicValue.start"
            [end]="dynamicValue.end"
            [overlaps]="dynamicValue.overlaps"
            [removeMarginBottom]="true">
        </cp-date-range-breaks-display>
    `
})

export class DateRangeBreaksDisplayDynamicComponent implements IEntityListItemDynamicComponent {
    @Input() dynamicValue: DateRangeOverlaps;
}
