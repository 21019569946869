import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MomentHelper } from '../../core-configuration/helpers/moment.helper';
import { tap } from 'rxjs/operators';

@Injectable()
export class MomentInterceptor implements HttpInterceptor {

    constructor(
        private momentHelper: MomentHelper
    ) { }

    // I don't know if this is efficient... but it works, might have issues if we ever expect a string instead of a date? idk
    // the anser is no, it's not efficient.
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

       // return next.handle(request);

        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.convertDates(event.body);
                }
        }));
    }

    private convertDates(object: Object) {
        if (!object || !(object instanceof Object)) {
            return;
        }

        if (object instanceof Array) {
            for (const item of object) {
                this.convertDates(item);
            }
        }

        for (const key of Object.keys(object)) {
            const value = object[key];

            if (value instanceof Array) {
                for (const item of value) {
                    this.convertDates(item);
                }
            }

            if (value instanceof Object) {
                this.convertDates(value);
            }

            if (typeof value !== 'string') {
                continue;
            }

            const validMoment = this.momentHelper.stringToMoment(value);
            if (validMoment) {
                object[key] = validMoment;
                continue;
            }
        }
    }
}
