/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../badge/badge.component.ngfactory";
import * as i2 from "../badge/badge.component";
import * as i3 from "../../services/display-helper.service";
import * as i4 from "./color-display-dynamic.component";
var styles_ColorDisplayDynamicComponent = [];
var RenderType_ColorDisplayDynamicComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ColorDisplayDynamicComponent, data: {} });
export { RenderType_ColorDisplayDynamicComponent as RenderType_ColorDisplayDynamicComponent };
export function View_ColorDisplayDynamicComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-badge", [], null, null, null, i1.View_BadgeComponent_0, i1.RenderType_BadgeComponent)), i0.ɵdid(1, 49152, null, 0, i2.BadgeComponent, [i3.DisplayHelperService], { color: [0, "color"], label: [1, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dynamicValue; var currVal_1 = _co.dynamicValue; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ColorDisplayDynamicComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-color-display-dynamic", [], null, null, null, View_ColorDisplayDynamicComponent_0, RenderType_ColorDisplayDynamicComponent)), i0.ɵdid(1, 49152, null, 0, i4.ColorDisplayDynamicComponent, [], null, null)], null, null); }
var ColorDisplayDynamicComponentNgFactory = i0.ɵccf("cp-color-display-dynamic", i4.ColorDisplayDynamicComponent, View_ColorDisplayDynamicComponent_Host_0, { dynamicValue: "dynamicValue" }, {}, []);
export { ColorDisplayDynamicComponentNgFactory as ColorDisplayDynamicComponentNgFactory };
