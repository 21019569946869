import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
var BulkSummaryModalComponent = /** @class */ (function () {
    function BulkSummaryModalComponent(activeModal) {
        this.activeModal = activeModal;
        this.title = 'Saving...';
        this.completed = false;
        this.objectKeys = Object.keys;
        this.showFailedStyles = false;
        this.errorItems = [];
        // ???? I want to keep this generic, but this is pretty poor
        this.errorItemHeader = function (_) { return ''; };
    }
    Object.defineProperty(BulkSummaryModalComponent.prototype, "response", {
        get: function () {
            return this._response;
        },
        set: function (value) {
            if (!value) {
                return;
            }
            this._response = value;
            this.handleResponse();
            this.completed = true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulkSummaryModalComponent.prototype, "error", {
        get: function () {
            return this._error;
        },
        set: function (value) {
            if (value) {
                this._error = value;
                this.showFailedStyles = true;
                this.completed = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    BulkSummaryModalComponent.prototype.close = function (reason) {
        this.activeModal.close(reason);
    };
    BulkSummaryModalComponent.prototype.dismiss = function (reason) {
        this.activeModal.dismiss(reason);
    };
    BulkSummaryModalComponent.prototype.handleResponse = function () {
        this.total = this.response.data.length;
        // this.totalCreated = this.response.data.filter(d => d.status === 'created').length;
        // this.totalUpdated = this.response.data.filter(d => d.status === 'ok').length;
        // this.totalFailed = this.response.data.filter(d => d.status !== 'created' && d.status !== 'ok').length;
        var success = this.response.data.every(function (i) { return !i.errors; });
        this.errorItems = this.response.data.filter(function (d) { return d.errors; })
            .map(function (item) { return ({ model: item.model, errors: item.errors }); });
        if (success) {
            this.title = 'Success';
        }
        else {
            this.title = 'Failed';
            this.showFailedStyles = true;
        }
    };
    return BulkSummaryModalComponent;
}());
export { BulkSummaryModalComponent };
