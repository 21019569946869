import { Injectable } from '@angular/core';
import { MetadataType } from '../../../../models/entity/entity.type';

@Injectable()
export class EntityListSizeService {

    store: {
        [keyof: string]: boolean
    } = {};

    constructor() { }

    get(type: MetadataType): boolean {
        return !!this.store[type];
    }

    setSize(type: MetadataType, options: { small: boolean }) {
        this.store[type] = options.small;
    }
}
