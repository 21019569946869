import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IIncludeEntity, ProviderIncludeType } from '../shared/include.model';
import { ProviderSummary } from '../summary/summary.model';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { ArchiveDisplayDynamicComponent } from '../../modules/display-helper/components/archive/archive-display-dynamic.component';
import { Site } from './site.model';
import { Contact } from './contact.model';
import { Position } from './position.model';
import { EntityBase } from '../entity/entity.model';
import { EntityLog } from '../shared/logged.model';

// one to one
const providerSummaryInclude = new IncludeEntityMetadata<Provider, ProviderSummary>('providerSummary', 'providerSummaries', 'id', 'providerId');
const logInclude = new IncludeEntityMetadata<Provider, EntityLog>('entity', 'entities', 'id', 'id');

// one to many
const sitesInclude = new IncludeEntityMetadata<Provider, Site>('site', 'sites', 'id', 'providerId');
const contactsInclude = new IncludeEntityMetadata<Provider, Contact>('contact', 'contacts', 'id', 'providerId');
const positionsInclude = new IncludeEntityMetadata<Provider, Position>('position', 'positions', 'id', 'providerId');

@CpClass({
    include: [providerSummaryInclude, logInclude],
    includeMany: [sitesInclude, contactsInclude, positionsInclude],
    defaultSort: [{ key: 'name' }],
    metadataType: 'provider',
    create: newProvider
})
export class Provider extends EntityBase implements IIncludeEntity<ProviderIncludeType> {
    @CpProperty({ type: 'boolean', dynamicComponent: ArchiveDisplayDynamicComponent })
    archived: boolean;
    @CpProperty({ type: 'string', showInDetails: true })
    name: string;
    @CpProperty({ type: 'multilineText', showInDetails: true })
    description: string;
}

export function newProvider() { return new Provider; }
