import { NgModule } from '@angular/core';
import { ExperienceSelectMultipleComponent } from './components/experience-select/experience-select-multiple.component';
import { CustomCommonModule } from '../common/common.module';
import { CustomFormsModule } from '../form/form.module';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { ExperiencePropertyDisplayListComponent } from './components/experience-property/experience-property-display-list.component';
import { ExperiencePropertyDisplayComponent } from './components/experience-property/experience-property-display.component';
import { ExperienceHttpService } from './services/http/experience.http.service';
import { ExperienceService } from './services/experience.service';
import { ExperienceResultsComponent } from './components/experience/experience-results.component';
import { ExperienceFormComponent } from './components/experience/experience-form.component';
import { ExperienceSelectComponent } from './components/experience-select/experience-select.component';
import { EntitySharedModule } from '../entity-shared/entity-shared.module';
import { ExperienceBadgeComponent } from './components/experience-badge/experience-badge.component';
import { ExperienceDetailsMasterComponent } from './components/experience/experience-details-master.component';
import { ExperienceDetailsComponent } from './components/experience/experience-details.component';
import { AlertModule } from '../alert/alert.module';

@NgModule({
    imports: [
        CustomCommonModule.forChild(),
        CustomFormsModule,
        DisplayHelperModule,
        EntitySharedModule,
        AlertModule
    ],
    providers: [
        ExperienceService,
        ExperienceHttpService
    ],
    declarations: [
        ExperienceDetailsMasterComponent,
        ExperienceDetailsComponent,

        ExperienceSelectMultipleComponent,
        ExperienceSelectComponent,

        ExperiencePropertyDisplayListComponent,
        ExperiencePropertyDisplayComponent,
        ExperienceResultsComponent,
        ExperienceFormComponent,
        ExperienceBadgeComponent
    ],
    exports: [
        ExperienceDetailsMasterComponent,
        ExperienceDetailsComponent,

        ExperienceSelectMultipleComponent,
        ExperienceSelectComponent,

        ExperiencePropertyDisplayListComponent,
        ExperiencePropertyDisplayComponent,
        ExperienceResultsComponent,
        ExperienceFormComponent,
        ExperienceBadgeComponent
    ],
})
export class ExperienceModule { }
