import { Injectable } from '@angular/core';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { Moment } from 'moment';

@Injectable()
export class FilterBuilderHelper {

    positionDateFilters(start: Moment, end: Moment, experienceId: number): RequestFilter[] {
        return [
            new RequestFilter('positions', 'availableFrom', 'lte', [start]),
            new RequestFilter('positions', '!availableTo', 'lte', [end]),
            new RequestFilter('positions', 'experienceIds', 'eq', [experienceId])
        ];
    }
}
