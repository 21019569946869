var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RequestFilter } from '../../../models/api/request-filter.model';
import { map, tap } from 'rxjs/operators';
import { toPlural } from '../helper/entity-shared.helper';
import { CrudEntityServiceBase } from './entity-service.base';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
var EntityServiceMetadataBase = /** @class */ (function (_super) {
    __extends(EntityServiceMetadataBase, _super);
    function EntityServiceMetadataBase(type, httpService, apiCacheService, includeMapperService) {
        var _this = _super.call(this, httpService) || this;
        _this.type = type;
        _this.httpService = httpService;
        _this.apiCacheService = apiCacheService;
        _this.includeMapperService = includeMapperService;
        _this.typePlural = toPlural(_this.type);
        return _this;
    }
    EntityServiceMetadataBase.prototype.create = function (entity) {
        var _this = this;
        return _super.prototype.create.call(this, entity).pipe(tap(function (i) {
            var filter1 = new RequestFilter(_this.typePlural, 'id', 'eq', [i.id]);
            // need to look into this, seems pointless to do the second part
            // if we just clear the entity entity...
            _this.apiCacheService.clearCacheByType(_this.typePlural);
            var addCreatedEntityExtraFilters = _this.onAfterCreate(i) || [];
            _this.apiCacheService.addCreatedEntity(_this.typePlural, i, [filter1].concat(addCreatedEntityExtraFilters));
        }));
    };
    EntityServiceMetadataBase.prototype.update = function (entity) {
        var _this = this;
        // todo, this is a hack - include and includeMany should probably not go on the base object at all
        // I should use some type of entity store (though that's kinda waht the cache engine was meant to be...)
        var clone = lodashHelper.omit(entity, ['include', 'includeMany']);
        return _super.prototype.update.call(this, clone).pipe(tap(function (i) {
            _this.apiCacheService.updateEntity(i, _this.typePlural);
            _this.onAfterUpdate(i);
        }));
    };
    EntityServiceMetadataBase.prototype.updatePartial = function (entity) {
        var _this = this;
        // see comment above
        var clone = lodashHelper.omit(entity, ['include', 'includeMany']);
        return _super.prototype.updatePartial.call(this, clone).pipe(tap(function (i) {
            _this.apiCacheService.updateEntity(i, _this.typePlural);
            _this.onAfterUpdate(i);
        }));
    };
    EntityServiceMetadataBase.prototype.get = function (id) {
        return this.getInclude(id).pipe(map(function (i) { return i.data; }));
    };
    EntityServiceMetadataBase.prototype.getInclude = function (id, include) {
        var _this = this;
        var includeCombined = lodashHelper.uniq((this.includeOptions || []).concat((include || [])));
        // this.apiCacheService.getSingleByRequest({ id: id, include: includeCombined, type: this.typePlural }, this.httpService.get([id], includeCombined))
        return this.httpService.get([id], includeCombined).pipe(tap(function (i) { return _this.includeMapperService.handleSingle(_this.type, i); }));
    };
    // no longer uses cache...
    EntityServiceMetadataBase.prototype.listInclude = function (options) {
        var _this = this;
        return _super.prototype.listInclude.call(this, options).pipe(tap(function (i) { return _this.includeMapperService.handleList(_this.type, i); }));
    };
    EntityServiceMetadataBase.prototype.delete = function (entity) {
        var _this = this;
        return _super.prototype.delete.call(this, entity).pipe(tap(function () { return _this.apiCacheService.clearCacheByEntityId(_this.typePlural, entity.id); }));
    };
    EntityServiceMetadataBase.prototype.ids = function (options) {
        return this.httpService.ids(options);
    };
    EntityServiceMetadataBase.prototype.getWithNext = function (options) {
        var _this = this;
        // note: this uses the super method (without cache)
        return _super.prototype.getWithNext.call(this, options).pipe(tap(function (_a) {
            var response = _a.response;
            return _this.includeMapperService.handleList(_this.type, response);
        }));
    };
    return EntityServiceMetadataBase;
}(CrudEntityServiceBase));
export { EntityServiceMetadataBase };
