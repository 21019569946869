var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ProviderHttpService } from './http/provider.http.service';
import { Provider } from '../../../models/provider/provider.model';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { ProviderBaseService } from './provider-base.service';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
var ProviderService = /** @class */ (function (_super) {
    __extends(ProviderService, _super);
    function ProviderService(httpService, apiCacheService, includeMapperService) {
        var _this = _super.call(this, 'provider', httpService, apiCacheService, includeMapperService) || this;
        _this.httpService = httpService;
        _this.apiCacheService = apiCacheService;
        _this.includeMapperService = includeMapperService;
        _this.includeOptions = ['contacts', 'positions', 'sites', 'providerSummaries'];
        _this.createEntity = function () { return new Provider(); };
        return _this;
    }
    ProviderService.prototype.onAfterCreate = function () {
        return;
    };
    ProviderService.prototype.onAfterUpdate = function (entity) {
        this.apiCacheService.clearCacheByType('providers');
        return;
    };
    return ProviderService;
}(ProviderBaseService));
export { ProviderService };
