var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/locale/en-gb';
import { environment } from '../environments/environment';
import { routeConfig } from './app.routes';
import { Router } from '@angular/router';
import { MINI_PROFILER_USER_TYPES } from './modules/mini-profiler/components/mini-profiler/mini-profiler.component';
import { AuthenticationService } from './modules/authentication/services/authentication.service';
import { map, takeUntil } from 'rxjs/operators';
import { BaseSubscriber } from './modules/common/observables/base-subscriber';
import { BreadcrumbBuilderService } from './modules/breadcrumb/services/breadcrumb-builder.service';
var MOMENT_CUSTOM_LOCALE = 'en-awesome';
var BootstrapPage = /** @class */ (function (_super) {
    __extends(BootstrapPage, _super);
    function BootstrapPage(authService, translate, router, breadcrumbBuilderService) {
        var _this = _super.call(this) || this;
        _this.authService = authService;
        _this.translate = translate;
        _this.router = router;
        _this.breadcrumbBuilderService = breadcrumbBuilderService;
        _this.supportUserTypes = MINI_PROFILER_USER_TYPES;
        _this.isDevelopment = false;
        _this.router.resetConfig(routeConfig);
        // this language will be used as a fallback when a translation isn't found in the current language
        _this.translate.setDefaultLang('default');
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        _this.translate.use('default');
        // moment locale customisation
        moment.defineLocale(MOMENT_CUSTOM_LOCALE, { parentLocale: environment.momentLocale });
        // set moment locale
        moment.locale(MOMENT_CUSTOM_LOCALE);
        // initialize breadcrumb
        _this.breadcrumbBuilderService.initialize();
        _this.isDevelopment = environment.envType === 'local';
        return _this;
    }
    BootstrapPage.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.user$.pipe(map(function () { return _this.token = _this.authService.getToken(); }), takeUntil(this.ngUnsubscribe)).subscribe();
    };
    return BootstrapPage;
}(BaseSubscriber));
export { BootstrapPage };
