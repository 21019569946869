import { Component, Input } from '@angular/core';
import { PropertyDisplayItemOptions } from '../../../display-helper/components/property-display/property-display-item.component';
import { DisplayPropertyType } from '../../../../models/shared/types.model';
import { InternalCondition } from '../../services/filter-query-helper.service';
import { EntityPropertyConfig } from '../../../../models/filters/filter.model';
import { FilterConditionHelperService } from '../../services/filter-condition-helper.service';

@Component({
    selector: 'cp-filter-condition-display',
    templateUrl: 'filter-condition-display.component.html'
})

export class FilterConditionDisplayComponent {

    @Input() property: EntityPropertyConfig;
    @Input() condition: InternalCondition;

    // this is a bit of a hack - todo, clean up
    get displayOptions(): PropertyDisplayItemOptions {
        return {
            type: this.property.type as DisplayPropertyType,
            arrayOptions: this.property.values as any
        };
    }

    get operatorName(): string {
        return this.filterConditionHelper.getOperatorName(this.condition.operator, this.property.type);
    }

    constructor(
        private filterConditionHelper: FilterConditionHelperService
    ) {
    }
}
