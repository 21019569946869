import { FilterPropertyBasicType, ExtendedPropertyType } from '../shared/types.model';
import { Plan } from '../plan/plan.model';
import { Student } from '../student/student.model';
import { Position } from '../provider/position.model';
import { Placement } from '../placement/placement.model';
import { Provider } from '../provider/provider.model';
import { Contact } from '../provider/contact.model';
import { Block } from '../plan/block.model';
import { Site } from '../provider/site.model';
import { Timesheet } from '../placement/timesheet.model';
import { Moment } from 'moment';
import { StudentAddress } from '../student/student-address.model';
import { OptionSetOption } from '../extended-properties/option-set.model';
import { Role } from '../user/role.model';
import { StudentCourse } from '../student/student-course.model';
import { MetadataType } from '../entity/entity.type';

export type FilterOperator = 'eq' | 'gt' | 'gte' | 'lt' | 'lte' | 'contains' | 'startsWith' | 'endsWith' | 'range' | 'any';
export type FilterEntity =
    Plan |
    Student |
    Placement |
    Position |
    Provider |
    Contact |
    Block |
    Site |
    Timesheet |
    StudentAddress |
    StudentCourse |
    Role;

export type FilterPropertyType = FilterPropertyBasicType | FilterPropertyValueType;
export type FilterPropertyValueType = keyof EntityPropertiesConfig;
export type FilterValueType = string | number | boolean | Moment; // FilterValueBasicType
// export type FilterValueType = FilterValueBasicType | DateIntervalValue;
export type DateIntervalValueType = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';
export const ALL_DATE_INTERVAL_VALUES: DateIntervalValueType[] = [
    'second',
    'minute',
    'hour',
    'day',
    'week',
    'month',
    'year'
];
export const ALL_FILTER_OPERATORS: FilterOperator[] = [
    'eq',
    'gt',
    'gte',
    'lt',
    'lte',
    'contains',
    'startsWith',
    'endsWith',
    'range',
    'any'
];


export class DateIntervalValue {
    number: number;
    interval: DateIntervalValueType;
}

export class FilterConditionNameValue {
    value: string | number;
    name?: string;
}

export abstract class FilterConditionBase {
    operator: FilterOperator;
    path: string;
}

export class SearchFilterCondition extends FilterConditionBase {
    values: FilterValueType[];
}

// name could change (GenericFilter? just filter?)
export class SearchFilter {
    conditions: SearchFilterCondition[];
    type: MetadataType;
}

export class EntityPropertyConfig {
    key: string;
    propertyKey: string;
    type: FilterPropertyType; // or ExtendedPropertyType
    description?: string;
    name: string;
    searchUrl?: string;

    values?: (FilterConditionNameValue | FilterValueType | OptionSetOption)[]; // FilterValueBasicType

    array: boolean;
    archived: boolean;
    nullable: boolean;
    sortable: boolean;
    provisionable: boolean;

    required = false;
    min?: number;
    max?: number;
    pattern?: string;
    patternDescription?: string;
    placeholder?: string;
    optionType?: ExtendedPropertyType;
}

export class EntityPropertyConfigKeyMap {
    [key: string]: EntityPropertyConfig;
}

export type EntityPropertiesConfig = {
    [k in MetadataType]: EntityPropertyConfigKeyMap;
};
