import { ResponseQueryService } from '../../common/services/response-query.service';
import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import * as moment from 'moment';
var MAX_IN_MEMORY = 50;
var ResponseFilterCacheService = /** @class */ (function () {
    function ResponseFilterCacheService(queryService) {
        var _this = this;
        this.queryService = queryService;
        this.filterConditions = [];
        this.addSource = new BehaviorSubject(undefined);
        this.change$ = this.addSource.asObservable();
        this.queryService.response$.pipe(tap(function (response) {
            if (!response) {
                return;
            }
            if (!response.responseQuery.filter) {
                return;
            }
            if (!response.responseQuery.filter.conditions) {
                return;
            }
            _this.handleConditions(response.responseQuery.filter.conditions);
        })).subscribe();
        this.queryService.reportResponse$.pipe(tap(function (response) {
            if (!response) {
                return;
            }
            if (!response.responseFiltersQuery || !response.responseFiltersQuery.parameters) {
                return;
            }
            var params = response.responseFiltersQuery.parameters;
            Object.keys(params)
                .filter(function (key) { return params[key].type === 'filter'; })
                .forEach(function (key) {
                var reportFilter = params[key].value;
                _this.handleConditions(reportFilter.conditions);
            });
        })).subscribe();
    }
    Object.defineProperty(ResponseFilterCacheService.prototype, "list", {
        get: function () {
            return this.filterConditions.slice();
        },
        enumerable: true,
        configurable: true
    });
    // OK, this is making some pretty broad assumptions. Esentially it ignores the "path" (and operator),
    // and assumes that the value being checked is either a number or a string...
    ResponseFilterCacheService.prototype.findConditionName = function (value) {
        var _this = this;
        var filteredValues = this.list.reverse()
            .map(function (i) { return _this.filterValueArray(i.values); });
        var match = lodashHelper.flatten(filteredValues)
            .find(function (conditionNameValue) { return conditionNameValue.value === value; });
        // console.log('filtered values for', value, 'is', filteredValues, match, this.list);
        return match;
    };
    ResponseFilterCacheService.prototype.handleConditions = function (responseFilterConditions) {
        var _this = this;
        var _a;
        var toPush = responseFilterConditions
            .filter(function (r) { return r.values.some(function (v) { return v.name !== undefined; }); })
            .filter(function (r) { return _this.filterConditions.every(function (fc) { return !lodashHelper.isEqual(fc, r); }); });
        (_a = this.filterConditions).push.apply(_a, toPush);
        if (this.filterConditions.length > MAX_IN_MEMORY) {
            this.filterConditions = this.filterConditions.slice(this.filterConditions.length - MAX_IN_MEMORY, MAX_IN_MEMORY);
        }
        this.addSource.next(undefined);
    };
    ResponseFilterCacheService.prototype.filterValueArray = function (values) {
        return values.filter(function (i) { return typeof (i) === 'object' && !moment.isMoment(i) && i.name; });
    };
    return ResponseFilterCacheService;
}());
export { ResponseFilterCacheService };
