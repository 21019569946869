import { Injectable } from '@angular/core';
import { Block } from '../../../../models/plan/block.model';
import { ResponseBlockInclude } from '../../../../models/api/response.model';
import { ApiHttpClientService } from '../../../api/services/api-http-client.service';
import { HttpClient } from '@angular/common/http';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { PlanBlockIncludeOption } from '../../../../models/shared/include.model';

@Injectable()
export class BlockHttpService
    extends ApiHttpClientService<Block, PlanBlockIncludeOption, ResponseBlockInclude> {

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super('planBlocks', httpClient, apiRequestHelperService);
    }
}
