var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var ALL_DATE_INTERVAL_VALUES = [
    'second',
    'minute',
    'hour',
    'day',
    'week',
    'month',
    'year'
];
export var ALL_FILTER_OPERATORS = [
    'eq',
    'gt',
    'gte',
    'lt',
    'lte',
    'contains',
    'startsWith',
    'endsWith',
    'range',
    'any'
];
var DateIntervalValue = /** @class */ (function () {
    function DateIntervalValue() {
    }
    return DateIntervalValue;
}());
export { DateIntervalValue };
var FilterConditionNameValue = /** @class */ (function () {
    function FilterConditionNameValue() {
    }
    return FilterConditionNameValue;
}());
export { FilterConditionNameValue };
var FilterConditionBase = /** @class */ (function () {
    function FilterConditionBase() {
    }
    return FilterConditionBase;
}());
export { FilterConditionBase };
var SearchFilterCondition = /** @class */ (function (_super) {
    __extends(SearchFilterCondition, _super);
    function SearchFilterCondition() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SearchFilterCondition;
}(FilterConditionBase));
export { SearchFilterCondition };
// name could change (GenericFilter? just filter?)
var SearchFilter = /** @class */ (function () {
    function SearchFilter() {
    }
    return SearchFilter;
}());
export { SearchFilter };
var EntityPropertyConfig = /** @class */ (function () {
    function EntityPropertyConfig() {
        this.required = false;
    }
    return EntityPropertyConfig;
}());
export { EntityPropertyConfig };
var EntityPropertyConfigKeyMap = /** @class */ (function () {
    function EntityPropertyConfigKeyMap() {
    }
    return EntityPropertyConfigKeyMap;
}());
export { EntityPropertyConfigKeyMap };
