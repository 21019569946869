var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { tap } from 'rxjs/operators';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
var ProviderBaseService = /** @class */ (function (_super) {
    __extends(ProviderBaseService, _super);
    function ProviderBaseService(type, httpService, apiCacheService, includeMapperService) {
        var _this = _super.call(this, type, httpService, apiCacheService, includeMapperService) || this;
        _this.type = type;
        _this.httpService = httpService;
        _this.apiCacheService = apiCacheService;
        _this.includeMapperService = includeMapperService;
        return _this;
    }
    ProviderBaseService.prototype.merge = function (deleteId, targetId, options) {
        var _this = this;
        return options.dry ?
            this.httpService.dryMerge(deleteId, targetId) :
            this.httpService.merge(deleteId, targetId).pipe(tap(function () {
                _this.apiCacheService.clearCacheByType('providers');
                _this.apiCacheService.clearCacheByType('positions');
                _this.apiCacheService.clearCacheByType('contacts');
                _this.apiCacheService.clearCacheByType('sites');
            }));
    };
    return ProviderBaseService;
}(EntityServiceMetadataBase));
export { ProviderBaseService };
