/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modules/mini-profiler/components/mini-profiler/mini-profiler.component.ngfactory";
import * as i2 from "./modules/mini-profiler/components/mini-profiler/mini-profiler.component";
import * as i3 from "./modules/authentication/directives/if-authed/if-authed.directive";
import * as i4 from "./modules/authentication/services/authentication.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./bootstrap.page";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./modules/breadcrumb/services/breadcrumb-builder.service";
var styles_BootstrapPage = [];
var RenderType_BootstrapPage = i0.ɵcrt({ encapsulation: 2, styles: styles_BootstrapPage, data: {} });
export { RenderType_BootstrapPage as RenderType_BootstrapPage };
function View_BootstrapPage_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-mini-profiler", [], null, null, null, i1.View_MiniProfilerComponent_0, i1.RenderType_MiniProfilerComponent)), i0.ɵdid(1, 4243456, null, 0, i2.MiniProfilerComponent, [], { token: [0, "token"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.token; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BootstrapPage_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BootstrapPage_2)), i0.ɵdid(2, 212992, null, 0, i3.IfAuthedDirective, [i0.TemplateRef, i4.AuthenticationService, i0.ViewContainerRef], { ifAuthed: [0, "ifAuthed"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = true; _ck(_v, 2, 0, currVal_0); }, null); }
function View_BootstrapPage_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-mini-profiler", [], null, null, null, i1.View_MiniProfilerComponent_0, i1.RenderType_MiniProfilerComponent)), i0.ɵdid(1, 4243456, null, 0, i2.MiniProfilerComponent, [], { token: [0, "token"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.token; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BootstrapPage_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BootstrapPage_4)), i0.ɵdid(2, 212992, null, 0, i3.IfAuthedDirective, [i0.TemplateRef, i4.AuthenticationService, i0.ViewContainerRef], { ifAuthed: [0, "ifAuthed"], forUserType: [1, "forUserType"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.supportUserTypes; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_BootstrapPage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BootstrapPage_1)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BootstrapPage_3)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.isDevelopment; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.isDevelopment; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_BootstrapPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-bootstrap", [], null, null, null, View_BootstrapPage_0, RenderType_BootstrapPage)), i0.ɵdid(1, 245760, null, 0, i7.BootstrapPage, [i4.AuthenticationService, i8.TranslateService, i5.Router, i9.BreadcrumbBuilderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BootstrapPageNgFactory = i0.ɵccf("cp-bootstrap", i7.BootstrapPage, View_BootstrapPage_Host_0, {}, {}, []);
export { BootstrapPageNgFactory as BootstrapPageNgFactory };
