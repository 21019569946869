import { Injectable } from '@angular/core';
import { ReportResponse } from '../../../models/report/report.model';
import { BehaviorSubject } from 'rxjs';
import { arrayHelper } from '../../core-configuration/helpers/array.helper';

export interface ReportColumnStore {
    name: string;
    visible: boolean;
}

export interface ReportTableStore {
    name: string;
    // sort?: ReportSort[];
    columns: ReportColumnStore[];
    visible: boolean;
}

export interface ReportStore {
    tables: ReportTableStore[];
}

export interface ReportStoreContainer {
    [id: number]: ReportStore;
}

@Injectable()
export class ReportSessionService {

    private reportStoreContainer: ReportStoreContainer = {};
    private reportStoreContainerSource = new BehaviorSubject<ReportStoreContainer>(this.reportStoreContainer);
    change$ = this.reportStoreContainerSource.asObservable();

    constructor() { }

    isInitialized(reportKey: string): boolean {
        return !!this.reportStoreContainer[reportKey];
    }

    initialize(reportResponse: ReportResponse) {
        let store = this.reportStoreContainer[reportResponse.report.key];

        this.reportStoreContainer[reportResponse.report.key] = { tables: [] };
        store = this.reportStoreContainer[reportResponse.report.key];

        store.tables = reportResponse.data.map(item => ({
            name: item.name,
            visible: true,
            columns: item.columns.map(i => ({
                name: i.name,
                visible: true
            })
        )}));

        this.triggerChange();
    }

    clearStore() {
        this.reportStoreContainer = {};
    }

    setTableVisibility(reportKey: string, tableIndex: number, visible: boolean) {
        const store = this.getReportStore(reportKey);
        store.tables[tableIndex].visible = visible;
        this.triggerChange();
    }

    setColumnVisibility(reportKey: string, tableIndex: number, columns: ReportColumnStore[]) {
        const store = this.getReportStore(reportKey);
        const columnStore = store.tables[tableIndex].columns;

        columns.map(
            column => arrayHelper.addOrReplace(columnStore, column, i => i.name === column.name)
        );
        this.triggerChange();
    }

    getVisibleColumns(reportKey: string, tableIndex: number): ReportColumnStore[] {
        const store = this.getReportStore(reportKey);
        return store.tables[tableIndex].columns;
    }

    getReportStore(reportKey: string, options: { allowUndefined: boolean } = { allowUndefined: false }): ReportStore {
        const store = this.reportStoreContainer[reportKey];
        if (!store && !options.allowUndefined) {
            throw new Error(`report with key: ${reportKey} has not been initialized`);
        }

        return store;
    }

    private triggerChange() {
        this.reportStoreContainerSource.next(this.reportStoreContainer);
    }
}
