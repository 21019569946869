var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PlacementHttpService } from './http/placement.http.service';
import { Placement, placementOverlapsPredicate } from '../../../models/placement/placement.model';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { map } from 'rxjs/operators';
var PlacementService = /** @class */ (function (_super) {
    __extends(PlacementService, _super);
    function PlacementService(httpService, apiCacheService, includeMapperService) {
        var _this = _super.call(this, 'placement', httpService, apiCacheService, includeMapperService) || this;
        _this.httpService = httpService;
        _this.apiCacheService = apiCacheService;
        _this.includeMapperService = includeMapperService;
        // todo: consider, maybe move all this to list logic
        _this.includeOptions = ['experiences', 'placementOverlaps'];
        return _this;
    }
    PlacementService.prototype.onAfterCreate = function (entity) {
        return;
    };
    PlacementService.prototype.onAfterUpdate = function (entity) {
        // this is necessary because you're able to change the position Id, which then needs the entity to clear
        // this.apiCacheService.clearCacheByEntityId('placements', entity.id);
        // the above used to be true, but it aint' anymore
        // any update to any placement could effect the placement overlap of any other placement, there is no way to know...
        this.apiCacheService.clearCacheByType('placements');
        return;
    };
    PlacementService.prototype.placementListIncludeGetAll = function (requestOptions, placementOptions) {
        if (placementOptions === void 0) { placementOptions = { mapOverlapsWithResults: false }; }
        return _super.prototype.listIncludeGetAll.call(this, requestOptions).pipe(map(function (result) {
            if (placementOptions.mapOverlapsWithResults) {
                result.forEach(function (placementMaster) {
                    if (!placementMaster.includeMany) {
                        placementMaster.includeMany = {};
                    }
                    // I really don't like how I've done this include system, it's so bloatful
                    // this is particularly bad tho, referencing this method directly and handling this logic here
                    placementMaster.includeMany.placementOverlaps = placementOverlapsPredicate(placementMaster, result);
                });
            }
            return result;
        }));
    };
    PlacementService.prototype.createPlacementFromBlock = function (block) {
        // todo, this needs to be better, have to cater for required properties on placements
        // all properties for that matter
        var newPlacement = new Placement();
        newPlacement.start = block.start;
        newPlacement.end = block.end;
        newPlacement.experienceId = block.experienceId;
        newPlacement.planBlockId = block.id;
        newPlacement.minutes = block.minutes;
        return newPlacement;
    };
    PlacementService.prototype.bulk = function (placements) {
        return this.httpService.bulk(placements);
    };
    PlacementService.prototype.groupByPlan = function (placements) {
        var noPlanPlacements = placements.filter(function (i) { return i.planBlockId === undefined; });
        var planPlacements = placements.filter(function (i) { return i.planBlockId !== undefined; });
        var groupedObject = lodashHelper.groupBy(planPlacements, function (item) { return item.include.plan.id; });
        var list = Object.keys(groupedObject)
            .map(function (key) {
            var value = groupedObject[key];
            return {
                plan: value[0].include.plan,
                placements: value
            };
        });
        if (noPlanPlacements.length > 0) {
            list.push({
                placements: noPlanPlacements
            });
        }
        return list;
    };
    PlacementService.prototype.createMinutesSummaries = function (placements) {
        var flattened = lodashHelper.flatten(placements.map(function (p) { return p.include.experience; }));
        var experiences = lodashHelper.uniqBy(flattened, function (e) { return e.id; });
        var grouped = lodashHelper.groupBy(placements, function (item) { return item.include.experience.id; });
        var toReturn = Object.keys(grouped).map(function (key) {
            var experienceId = +key;
            var experience = experiences.find(function (e) { return e.id === experienceId; });
            var list = grouped[key];
            var expected = list
                .map(function (i) { return i.minutes || 0; })
                .reduce(function (a, b) { return a + b; }, 0);
            var attended = list
                .map(function (i) { return i.include.placementSummary.timesheetMinutesAttended; })
                .reduce(function (a, b) { return a + b; }, 0);
            var absent = list
                .map(function (i) { return i.include.placementSummary.timesheetMinutesAbsent; })
                .reduce(function (a, b) { return a + b; }, 0);
            var timesheetTotal = attended + absent;
            var unaccounted = timesheetTotal > expected ? 0 : expected - timesheetTotal;
            return {
                experience: experience,
                placements: list,
                expected: expected,
                attended: attended,
                absent: absent,
                unaccounted: unaccounted
            };
        });
        return toReturn;
    };
    PlacementService.prototype.createMinutesSummariesByPlan = function (placements) {
        var _this = this;
        var groupByPlan = this.groupByPlan(placements);
        return groupByPlan.map(function (i) { return ({
            plan: i.plan,
            experiencePlacements: _this.createMinutesSummaries(i.placements)
        }); });
    };
    return PlacementService;
}(EntityServiceMetadataBase));
export { PlacementService };
