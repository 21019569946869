import { Injectable } from '@angular/core';
import { MappingHttpService } from './http/mapping.http.service';
import { Observable } from 'rxjs';
import { ResponseMappingMatches, AllResponseIncludes, ResponseModel, ResponseNoInclude } from '../../../models/api/response.model';
import { MatchToRequest, Mapping } from '../../../models/placement/mapping.model';
import { RequestListOptions, RequestPaginatedOptions } from '../../../models/api/request-list-options.model';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { tap, map } from 'rxjs/operators';
import { NameValueResult } from '../../../models/shared/search.model';
import { CrudEntityServiceBase } from '../../entity-shared/base/entity-service.base';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { PositionResult } from '../../../models/provider/position.model';
import { AllIncludeOptions, NoIncludeOption } from '../../../models/shared/include.model';
import { Entity, MetadataType } from '../../../models/entity/entity.type';

@Injectable()
export class MappingService extends CrudEntityServiceBase<Mapping, NoIncludeOption, ResponseNoInclude>
    implements IUpdatableService<Mapping> {

    constructor(
        protected httpService: MappingHttpService,
        private includeMapperService: IncludeMapperService
    ) {
        super(httpService);
    }

    getMatches<
        TDataEntity extends Entity,
        TIncludeOptions extends AllIncludeOptions,
        TResponseIncludes extends AllResponseIncludes
    >(
        dataType: MetadataType,
        matchto: MatchToRequest,
        options: RequestListOptions<TIncludeOptions>
    ): Observable<ResponseMappingMatches<TDataEntity, TResponseIncludes>> {

        return this.httpService.mappingMatch<TDataEntity, TIncludeOptions, TResponseIncludes>(dataType, matchto, options).pipe(
            tap(response => this.includeMapperService.handleList(dataType, response))
        );
    }

    getMatchesSearch<
        TResult extends NameValueResult
    >(
        dataType: MetadataType,
        matchto: MatchToRequest,
        options: RequestPaginatedOptions
    ): Observable<ResponseModel<TResult[]>> {

        return this.httpService.mappingMatchSearch(dataType, matchto, options);
    }

    getPositionsForStudentMatchesSearch(
        query: string,
        studentId: number,
        ignoreIds: number[],
        additionalFilters: RequestFilter[] = [],
        take = 10
    ): Observable<PositionResult[]> {

        const matchTo = this.createMatchTo(studentId, ignoreIds);

        return this.getMatchesSearch<PositionResult>(
            'position',
            matchTo,
            { filters: additionalFilters, take: take, q: query }
        ).pipe(
            map(i => i.data)
        );
    }

    private createMatchTo(studentId: number, ignoreIds: number[]): MatchToRequest {
        const matchTo: MatchToRequest = {
            entities: [{
                entityType: 'student',
                id: studentId
            }],
            ignoreIds: ignoreIds
        };

        return matchTo;
    }
}
