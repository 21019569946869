import { Injectable } from '@angular/core';
import { PlanHttpService } from './http/plan.http.service';
import { Plan } from '../../../models/plan/plan.model';
import { ResponsePlanInclude } from '../../../models/api/response.model';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { IListService, IIdListService } from '../../entity-shared/base/entity-search.base';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { PlanIncludeOption } from '../../../models/shared/include.model';


@Injectable()
export class PlanService
    extends
    EntityServiceMetadataBase<Plan, PlanIncludeOption, ResponsePlanInclude>
    implements
    IListService<Plan, PlanIncludeOption, ResponsePlanInclude>,
    IIdListService,
    IUpdatableService<Plan> {

    protected includeOptions: PlanIncludeOption[] = ['planBlocks', 'experiences'];

    constructor(
        protected httpService: PlanHttpService,
        protected apiCacheService: ApiCacheService,
        protected includeMapperService: IncludeMapperService
    ) {
        super('plan', httpService, apiCacheService, includeMapperService);
    }

    protected onAfterCreate(entity: Plan): void | RequestFilter[] {
        return;
    }
    protected onAfterUpdate(entity: Plan): void {
        return;
    }
}
