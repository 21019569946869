import { Component, Input } from '@angular/core';
import { FilterPropertyPath } from '../../services/filter-config-inner.service';
import { FilterPathBase } from './filter-path.base';

@Component({
    selector: 'cp-filter-path-display',
    templateUrl: 'filter-path-display.component.html'
})

export class FilterPathDisplayComponent extends FilterPathBase {
    @Input() filterPropertyPath: FilterPropertyPath[] = [];
}
