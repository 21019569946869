import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app.routes';
// import { LayoutModule } from './modules/layout/layout.module';
import { AuthenticationInterceptor } from './modules/authentication/interceptors/authentication.interceptor';
import { ApiErrorInterceptor } from './modules/error-logging/interceptors/api-error.interceptor';
import { BootstrapPage } from './bootstrap.page';

import { MiniProfilerInterceptor } from './modules/common/interceptors/mini-profiler.interceptor';
import { AuthorizationInterceptor } from './modules/authentication/interceptors/authorization.interceptor';
import { MomentInterceptor } from './modules/common/interceptors/moment.interceptor';
// import { CustomMissingTranslationHandler } from './modules/common/translate/custom-missing-translation.handler';
import { ResponseQueryInterceptor } from './modules/common/interceptors/response-query.interceptor';
import { ErrorHandlerInterceptor } from './modules/error-logging/interceptors/error-handler.interceptor';
import { ErrorLoggingModule } from './modules/error-logging/error-logging.module';
import { ResponseQueryService } from './modules/common/services/response-query.service';
import { AuthenticationService } from './modules/authentication/services/authentication.service';
import { CustomCommonModule } from './modules/common/common.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { MiniProfilerModule } from './modules/mini-profiler/mini-profiler.module';
import { BreadcrumbModule } from './modules/breadcrumb/breadcrumb.module';
import { ApiModule } from './modules/api/api.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/strings/', '.strings.json');
}

@NgModule({

  declarations: [
    BootstrapPage
  ],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    //   missingTranslationHandler: {
    //     provide: MissingTranslationHandler,
    //     useClass: CustomMissingTranslationHandler,
    //   }
    }),
    BrowserModule,
    AppRoutingModule,

    ErrorLoggingModule,
    MiniProfilerModule,

    CustomCommonModule.forRoot(),
    BreadcrumbModule.forRoot(),
    ApiModule.forRoot(),
    AuthenticationModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MiniProfilerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MomentInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseQueryInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerInterceptor },

    AuthenticationService,
    ResponseQueryService
  ],
  bootstrap: [BootstrapPage]
})
export class AppModule {}
