import { Injectable } from '@angular/core';
import { DateIntervalValue, ALL_DATE_INTERVAL_VALUES, DateIntervalValueType } from '../../../models/filters/filter.model';
import { INTERVAL_PATTERN_LITERAL } from '../../common/constants/pattern.constants';
import { NameValueResult } from '../../../models/shared/search.model';

@Injectable()
export class IntervalHelper {

    get today(): string {
        return '0day';
    }

    stringOrResultToInterval(str: string | NameValueResult): DateIntervalValue | undefined {
        if (!str) {
            return undefined;
        }

        if (typeof(str) === 'object') {
            if (!str.value) {
                return undefined;
            }

            str = str.value as string;
        }
        // handle intervals
        const intervalRegex = str.match(INTERVAL_PATTERN_LITERAL);
        if (intervalRegex !== null && ALL_DATE_INTERVAL_VALUES.includes(intervalRegex[2] as DateIntervalValueType)) {
            return {
                number: +intervalRegex[1],
                interval: intervalRegex[2] as DateIntervalValueType
            };
        }

        return undefined;
    }

    intervalToString(interval: DateIntervalValue): string {
        return `${interval.number}${interval.interval}`;
    }

    updateStringIntervalValue(value: string, interval: DateIntervalValueType): string | undefined {
        const intervalObj = this.stringOrResultToInterval(value);
        if (!intervalObj) { return; }

        intervalObj.interval = interval;

        return this.intervalToString(intervalObj);
    }
}
