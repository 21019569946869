import { Address } from '../shared/address.model';
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IProvisionable } from '../entity/entity.model';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { EntityLog } from '../shared/logged.model';
import { Student } from './student.model';

const logInclude = new IncludeEntityMetadata<StudentAddress, EntityLog>('entity', 'entities', 'id', 'id');
const studentInclude = new IncludeEntityMetadata<StudentAddress, Student>('student', 'students', 'studentId', 'id');

@CpClass({
    include: [studentInclude, logInclude],
    metadataType: 'studentAddress',
    defaultSort: [{ key: 'studentId' }],
    create: newStudentAddress
})
export class StudentAddress extends Address implements IProvisionable {
    @CpProperty({ type: 'int32', hideFromList: true })
    studentId: number;

    provisioningKey?: string;
    provisioned?: boolean;
    provisionedClientId?: string;

    toString(): string {
        return `${this.address}${this.postcode ? ', ' + this.postcode : ''}`;
    }
}

export function newStudentAddress() { return new StudentAddress(); }
