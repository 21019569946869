import { NgModule } from '@angular/core';
import { SavedSearchService } from './services/saved-search.service';
import { SavedSearchHttpService } from './services/http/saved-search.http.service';
import { CustomCommonModule } from '../common/common.module';
import { CustomFormsModule } from '../form/form.module';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { EntitySharedModule } from '../entity-shared/entity-shared.module';
import { SavedSearchFormComponent } from './components/saved-search/saved-search-form.component';
import { SavedSearchResultsComponent } from './components/saved-search/saved-search-results.component';
import { FilterModule } from '../filter/filter.module';
import { SavedSearchDropdownButtonComponent } from './components/saved-search-dropdown/saved-search-dropdown-button.component';
import { SavedSearchInputButtonsComponent } from './components/saved-search-input-buttons/saved-search-input-buttons.component';
import { SavedSearchDropdownActiveButtonComponent } from './components/saved-search-dropdown/saved-search-dropdown-active-button.component';
import { SavedSearchDropdownFormComponent } from './components/saved-search-dropdown/saved-search-dropdown-form.component';

@NgModule({
    imports: [
        CustomCommonModule.forChild(),
        CustomFormsModule,
        DisplayHelperModule,
        EntitySharedModule,
        FilterModule
    ],
    providers: [
        SavedSearchService,
        SavedSearchHttpService
    ],
    declarations: [
        SavedSearchFormComponent,
        SavedSearchResultsComponent,

        SavedSearchDropdownButtonComponent,
        SavedSearchDropdownActiveButtonComponent,
        SavedSearchDropdownFormComponent,
        SavedSearchInputButtonsComponent
    ],
    exports: [
        SavedSearchFormComponent,
        SavedSearchResultsComponent,

        SavedSearchInputButtonsComponent
    ]
})
export class SavedSearchModule { }
