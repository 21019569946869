var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as moment from 'moment';
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IncludeEntityMetadata, IncludeEntityMetadataCore } from '../shared/metadata.model';
import { MinutesPipe } from '../../modules/display-helper/pipes/minutes.pipe';
import { ExperienceEntityListItemComponent } from '../../modules/configuration-entity-shared/components/experience-entity-list-item/experience-entity-list-item.component';
import { EntityBase } from '../entity/entity.model';
import { DateRangeBreaksDisplayDynamicComponent } from '../../modules/date-range-display-dynamic/components/date-range/date-range-breaks-display-dynamic.component';
// these are the simple ones
var placementSummaryInclude = new IncludeEntityMetadata('placementSummary', 'placementSummaries', 'id', 'placementId');
var blockInclude = new IncludeEntityMetadata('planBlock', 'planBlocks', 'planBlockId', 'id');
var studentInclude = new IncludeEntityMetadata('student', 'students', 'studentId', 'id');
var positionInclude = new IncludeEntityMetadata('position', 'positions', 'positionId', 'id');
var logInclude = new IncludeEntityMetadata('entity', 'entities', 'id', 'id');
// singles that aren't shown
var experienceInclude = new IncludeEntityMetadata('experience', 'experiences', 'experienceId', 'id', { hideFromList: true });
// these require a bit more logic
var planInclude = new IncludeEntityMetadataCore('plan', 'plans', 'planBlock.planId', 'id', {
    requiresInclude: ['planBlocks'],
    requiresIncludeSortPrefix: 'planBlock'
});
var providerInclude = new IncludeEntityMetadataCore('provider', 'providers', 'position.providerId', 'id', {
    requiresInclude: ['positions'],
    requiresIncludeSortPrefix: 'position'
});
var siteInclude = new IncludeEntityMetadataCore('site', 'sites', 'position.siteId', 'id', {
    requiresInclude: ['positions'],
    requiresIncludeSortPrefix: 'position',
    requiresIncludeIsNullable: true
});
// require even more thought.
var contactsInclude = new IncludeEntityMetadataCore('contact', 'contacts', 'position.contactIds', 'id', {
    requiresInclude: ['positions'],
    requiresIncludeSortPrefix: 'position',
    requiresIncludeIsNullable: true
});
var placementOverlapsInclude = new IncludeEntityMetadata('placement', 'placementOverlaps', 'studentId', 'studentId', {
    predicateManyOverride: placementOverlapsPredicate,
    hideFromList: true
});
// Note: can't use lambda functions in functions used in Metadata objects
export function placementOverlapsPredicate(master, slaves) {
    var filteredArray = [];
    for (var i = 0; i < slaves.length; i++) {
        var slave = slaves[i];
        if (slave.studentId !== master.studentId) {
            continue;
        }
        if (slave.id === master.id) {
            continue;
        }
        if (slave.start.isBefore(master.end, 'days')
            && slave.end.isSameOrAfter(master.start, 'days')
            && (slave.start.isAfter(master.start, 'days') || (slave.start.isSame(master.start, 'days') && slave.end.isBefore(master.end, 'days')))) {
            filteredArray.push(slave);
        }
    }
    return filteredArray;
}
var Placement = /** @class */ (function (_super) {
    __extends(Placement, _super);
    function Placement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Placement.prototype, "dates", {
        get: function () {
            return {
                start: this.start,
                end: this.end,
                overlaps: this.overlaps
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Placement.prototype, "experienceIds", {
        get: function () {
            return [this.experienceId].concat(this.overlaps.map(function (o) { return o.experienceId; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Placement.prototype, "overlaps", {
        get: function () {
            if (!this.includeMany || !this.includeMany.placementOverlaps) {
                return [];
            }
            return this.includeMany.placementOverlaps
                .sort(function (a, b) {
                if (a.start.isSame(b.start, 'days')) {
                    return 0;
                }
                if (a.start.isAfter(b.start, 'days')) {
                    return 1;
                }
                return -1;
            })
                .map(function (i) { return ({
                experienceId: i.experienceId,
                placementId: i.id,
                start: i.start,
                end: i.end,
                minutes: i.minutes
            }); });
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        CpProperty({ type: 'dynamic', dynamicComponent: DateRangeBreaksDisplayDynamicComponent }),
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], Placement.prototype, "dates", null);
    __decorate([
        CpProperty({ label: 'Experience', type: 'int32', listHeaderAlign: 'left', dynamicComponent: ExperienceEntityListItemComponent }),
        __metadata("design:type", Number)
    ], Placement.prototype, "experienceId", void 0);
    __decorate([
        CpProperty({ label: 'Experiences', type: 'int32', listHeaderAlign: 'left', defaultListVisible: true, dynamicComponent: ExperienceEntityListItemComponent }),
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], Placement.prototype, "experienceIds", null);
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], Placement.prototype, "studentId", void 0);
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], Placement.prototype, "positionId", void 0);
    __decorate([
        CpProperty({ label: 'Block Id', type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], Placement.prototype, "planBlockId", void 0);
    __decorate([
        CpProperty({ type: 'date' }),
        __metadata("design:type", Object)
    ], Placement.prototype, "start", void 0);
    __decorate([
        CpProperty({ type: 'date' }),
        __metadata("design:type", Object)
    ], Placement.prototype, "end", void 0);
    __decorate([
        CpProperty({ label: 'Expected Hours', type: 'int32', pipe: { pipe: new MinutesPipe() } }),
        __metadata("design:type", Number)
    ], Placement.prototype, "minutes", void 0);
    Placement = __decorate([
        CpClass({
            include: [
                placementSummaryInclude,
                blockInclude,
                studentInclude,
                positionInclude,
                planInclude,
                providerInclude,
                siteInclude,
                experienceInclude,
                logInclude
            ],
            includeMany: [
                contactsInclude,
                placementOverlapsInclude
            ],
            metadataType: 'placement',
            defaultSort: [{ key: 'start', desending: true }, { key: 'experienceId' }],
            create: newPlacement
        })
    ], Placement);
    return Placement;
}(EntityBase));
export { Placement };
export function newPlacement() { return new Placement(); }
