import * as moment from 'moment';
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { MinutesPipe } from '../../modules/display-helper/pipes/minutes.pipe';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { Plan } from './plan.model';
import { IIncludeEntity, PlanBlockIncludeType } from '../shared/include.model';
import { ExperienceEntityListItemComponent } from '../../modules/configuration-entity-shared/components/experience-entity-list-item/experience-entity-list-item.component';
import { EntityBase } from '../entity/entity.model';
import { EntityLog } from '../shared/logged.model';
import { DateRangeBreaksDisplayDynamicComponent } from '../../modules/date-range-display-dynamic/components/date-range/date-range-breaks-display-dynamic.component';
import { DateRangeOverlaps } from '../../modules/date-range-display-dynamic/components/date-range/date-range-breaks-display.component';

const planInclude = new IncludeEntityMetadata<Block, Plan>('plan', 'plans', 'planId', 'id');
const logInclude = new IncludeEntityMetadata<Block, EntityLog>('entity', 'entities', 'id', 'id');

@CpClass<Block>({
    include: [planInclude, logInclude],
    metadataType: 'planBlock',
    defaultSort: [{ key: 'start' }],
    create: newBlock
})
export class Block extends EntityBase implements IIncludeEntity<PlanBlockIncludeType> {

    @CpProperty({ label: 'Plan Id', type: 'int32' })
    planId: number;

    @CpProperty({ label: 'Experience', type: 'int32', listHeaderAlign: 'left', defaultListVisible: true, dynamicComponent: ExperienceEntityListItemComponent })
    experienceId: number;

    @CpProperty({ label: 'Expected Hours', type: 'int32', defaultListVisible: true, showInDetails: true, pipe: { pipe: new MinutesPipe() } })
    minutes: number;

    @CpProperty({ label: 'Start', type: 'date' })
    start: moment.Moment;

    @CpProperty({ label: 'End', type: 'date' })
    end: moment.Moment;

    @CpProperty({ type: 'dynamic', defaultListVisible: true, dynamicComponent: DateRangeBreaksDisplayDynamicComponent })
    get dates(): DateRangeOverlaps {
        return {
            start: this.start,
            end: this.end,
            overlaps: []
        };
    }
}

export function newBlock() { return new Block(); }
