var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterPathBase } from './filter-path.base';
var FilterPathDisplayComponent = /** @class */ (function (_super) {
    __extends(FilterPathDisplayComponent, _super);
    function FilterPathDisplayComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.filterPropertyPath = [];
        return _this;
    }
    return FilterPathDisplayComponent;
}(FilterPathBase));
export { FilterPathDisplayComponent };
