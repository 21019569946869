var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MergeApiHttpClientService } from '../../../api/services/api-http-client.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_DATE_FORMAT } from '../../../core-configuration/helpers/moment.helper';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
var PositionHttpService = /** @class */ (function (_super) {
    __extends(PositionHttpService, _super);
    function PositionHttpService(httpClient, apiRequestHelperService) {
        var _this = _super.call(this, 'positions', httpClient, apiRequestHelperService) || this;
        _this.httpClient = httpClient;
        _this.apiRequestHelperService = apiRequestHelperService;
        return _this;
    }
    PositionHttpService.prototype.capacity = function (start, end, options) {
        var url = this.generateUrl({
            path: ['capacity'],
            options: options
        });
        var extraParams = new HttpParams().set('start', start.format(API_DATE_FORMAT)).set('end', end.format(API_DATE_FORMAT));
        return this.httpClient.get(url, { params: extraParams });
    };
    // Note: see student API... which is a copy-pasta of this ^.^
    PositionHttpService.prototype.blockMatches = function (blockId, options) {
        var url = this.generateUrl({
            path: ['planBlocks', blockId, 'positions'],
            overrideBasePath: true,
            options: options
        });
        return this.httpClient.get(url);
    };
    return PositionHttpService;
}(MergeApiHttpClientService));
export { PositionHttpService };
