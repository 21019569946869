import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseModelBulk, ResponseModelError } from '../../../../models/api/response.model';

@Component({
    selector: 'cp-bulk-summary-modal',
    templateUrl: 'bulk-summary.modal.html'
})

export class BulkSummaryModalComponent<T> {

    private _response: ResponseModelBulk<T>;
    private _error: any; // need to look into what this returns. todo

    @Input()
    set response(value: ResponseModelBulk<T>) {
        if (!value) { return; }

        this._response = value;
        this.handleResponse();
        this.completed = true;
    }

    get response(): ResponseModelBulk<T> {
        return this._response;
    }

    @Input()
    set error(value: any) {
        if (value) {
            this._error = value;
            this.showFailedStyles = true;
            this.completed = true;
        }
    }

    get error(): any {
        return this._error;
    }

    title = 'Saving...';
    completed = false;
    total: number;
    objectKeys = Object.keys;
    showFailedStyles = false;

    errorItems: { model: T, errors: ResponseModelError[] }[] = [];

    // ???? I want to keep this generic, but this is pretty poor
    @Input() errorItemHeader: (item: T) => string = (_) => '';

    constructor(private activeModal: NgbActiveModal) {

    }

    close(reason: string) {
        this.activeModal.close(reason);
    }

    dismiss(reason: string) {
        this.activeModal.dismiss(reason);
    }

    handleResponse() {
        this.total = this.response.data.length;
        // this.totalCreated = this.response.data.filter(d => d.status === 'created').length;
        // this.totalUpdated = this.response.data.filter(d => d.status === 'ok').length;
        // this.totalFailed = this.response.data.filter(d => d.status !== 'created' && d.status !== 'ok').length;
        const success = this.response.data.every(i => !i.errors);

        this.errorItems = this.response.data.filter(d => d.errors)
            .map(item => ({ model: item.model, errors: item.errors!}));

        if (success) {
            this.title = 'Success';
        } else {
            this.title = 'Failed';
            this.showFailedStyles = true;
        }
    }
}
