import { NgModule } from '@angular/core';
import { EntityListComponent } from './components/entity-list/entity-list.component';
import { EntityListContainerComponent } from './components/entity-list/entity-list-container.component';
import { EntityListColumnSelectorComponent } from './components/entity-list/entity-list-column-selector.component';
import { CustomCommonModule } from '../common/common.module';
import { FilterModule } from '../filter/filter.module';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { CustomFormsModule } from '../form/form.module';
import { ConfigurationEntitySharedModule } from '../configuration-entity-shared/configuration-entity-shared.module';
import { EntityMergeContainerComponent } from './components/entity-merge/entity-merge-continer.component';
import { EntityListArchiveToggleComponent } from './components/entity-list/entity-list-archive-toggle.component';
import { EntityDetailsContainerComponent } from './components/entity-details/entity-details-container.component';
import { EntityDetailsPropertyDisplayComponent } from './components/entity-details/entity-details-property-display.component';
import { EntityListDisplayToggleComponent } from './components/entity-list/entity-list-display-toggle.component';
import { EntityListItemComponent } from './components/entity-list/entity-list-item.component';
import { AbsenceTypeModule } from '../absence-type/absence-type.module';
import { EntityListItemDynamicComponent } from './components/entity-list/entity-list-item-dynamic.component';
import { EntitySearchSortService } from './services/entity-search-sort.service';
import { IncludeMapperService } from './services/include-mapper.service';
import { EntitySearchService } from './services/entity-search.service';
import { EntityListDownloadComponent } from './components/entity-list-download/entity-list-download.component';
import { EntityListCsvService } from './services/entity-list-csv.service';
import { EntityListSortComponent } from './components/entity-list-sort/entity-list-sort.component';
import { EntityListAlertIconComponent } from './components/entity-list/entity-list-alert-icon.component';

@NgModule({
    imports: [
        CustomCommonModule.forChild(),
        FilterModule,
        DisplayHelperModule,
        CustomFormsModule,
        ConfigurationEntitySharedModule,
        AbsenceTypeModule
    ],
    providers: [
        EntitySearchService,
        EntitySearchSortService,
        IncludeMapperService,
        EntityListCsvService
    ],
    declarations: [
        EntityDetailsContainerComponent,
        EntityDetailsPropertyDisplayComponent,

        EntityListArchiveToggleComponent,

        EntityListComponent,
        EntityListItemComponent,
        EntityListItemDynamicComponent,
        EntityListContainerComponent,
        EntityListColumnSelectorComponent,
        EntityListDisplayToggleComponent,
        EntityListDownloadComponent,
        EntityListSortComponent,
        EntityListAlertIconComponent,

        EntityMergeContainerComponent
    ],
    exports: [
        EntityDetailsContainerComponent,
        EntityDetailsPropertyDisplayComponent,

        EntityListArchiveToggleComponent,
        EntityListDisplayToggleComponent,

        EntityListComponent,
        EntityListItemComponent,
        EntityListItemDynamicComponent,
        EntityListContainerComponent,
        EntityListColumnSelectorComponent,
        EntityListDownloadComponent,
        EntityListSortComponent,

        EntityMergeContainerComponent
    ]
})
export class EntitySharedModule { }
