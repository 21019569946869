import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { IErrorContext } from '../interceptors/error-handler.interceptor';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { TokenUser } from '../../../models/authentication/authentication.model';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LocationStrategy } from '@angular/common';

@Injectable()
export class ErrorService {

    private currentUser?: TokenUser;

    constructor(
        private route: ActivatedRoute,
        private location: LocationStrategy,
        private authService: AuthenticationService
    ) {
        this.authService.user$.pipe(
            map(i => this.currentUser = i)
        ).subscribe();
    }

    getClientContext(error: Error): IErrorContext {

        const shared = this.getShared();
        const status = error.name;
        const message = error.message || error.toString();

        return {
            ...shared,
            status,
            message,
            clientError: error
        };
    }

    getServerContext(error: HttpErrorResponse, httpRequest: HttpRequest<any>): IErrorContext {
        const shared = this.getShared();
        const status = error.status.toString();
        const message = error.message || error.toString();

        return {
            ...shared,
            status,
            message,
            requestUrl: httpRequest.urlWithParams,
            requestBody: httpRequest.body ? httpRequest.body.toString() : '',
            requestMethod: httpRequest.method
        };
    }

    isHttpError(error: any): HttpErrorResponse | undefined {
        if (error instanceof HttpErrorResponse) { return error; }
        if (this.isPromiseError(error)) { return error.rejection as HttpErrorResponse; }

        return undefined;
    }

    private getShared() {
        const fullUrl = this.location.path();
        const route = fullUrl.split('?')[0];
        const routeParameters = this.route.snapshot.params;
        const routeQueryParameters = this.route.snapshot.queryParams;
        const user = this.currentUser;

        return {
            route,
            routeParameters,
            routeQueryParameters,
            fullUrl,
            environment: environment.envType,
            user: user
        };
    }

    private isPromiseError(error: any) {
        return error && (error as any).rejection && (error as any).rejection instanceof HttpErrorResponse;
    }
}
