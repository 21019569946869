<div class="modal-body">
    <div class="container-fluid" *cpLoading="loading; error: error">
        <div class="row">
            <div class="col-11">
                <h2 class="text-primary mt-2 mb-2" [class.text-danger]="error">{{title}}</h2>
            </div>
            <div class="col-1">
                <button *ngIf="!hideDismiss" type="button" class="close mt-2" aria-label="Close" (click)="dismiss()">
                    <fa-icon icon="times" class="text-dark"></fa-icon>
                </button>
            </div>
        </div>

        <p [class.text-danger]="error">{{message}}</p>

        <hr *ngIf="!hideConfirm || !hideDismiss" />
        <button *ngIf="!hideConfirm" type="button" class="btn btn-primary btn-block btn-lg mt-4" [class.btn-danger]="error" (click)="close()">Confirm</button>
        <button *ngIf="!hideDismiss" type="button" class="btn btn-input btn-block btn-lg mt-2 mb-3" (click)="dismiss()">Cancel</button>
    </div>

</div>