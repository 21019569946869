import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Experience } from '../../../../models/experience/experience.model';
import { ConfigurationService } from '../../../core-configuration/services/configuration.service';
import { BaseSubscriber } from '../../../common/observables/base-subscriber';
import { takeUntil, map } from 'rxjs/operators';

@Component({
    selector: 'cp-experience-property-display-list',
    templateUrl: 'experience-property-display-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ExperiencePropertyDisplayListComponent extends BaseSubscriber implements OnInit {


    @Input() experienceIds: number[] = [];
    @Input() cssClass: any = 'd-block';

    loaded = false;

    private allExperiences: Experience[];

    get experiences(): Experience[] {
        return this.allExperiences.filter(config => this.experienceIds.includes(config.id));
    }

    constructor(
        private configService: ConfigurationService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.configService.getExperiences().pipe(
            map(i => this.handleConfig(i)),
            takeUntil(this.ngUnsubscribe)
        ).subscribe();
    }

    private handleConfig(configExperiences: Experience[]) {
        this.allExperiences = configExperiences;
        this.loaded = true;
        this.changeDetectorRef.markForCheck();
    }
}
