import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IEntityListItemDynamicComponent } from '../../../entity-shared/components/entity-list/entity-list-item-dynamic.component';

@Component({
    selector: 'cp-color-display-dynamic',
    template: `<cp-badge [color]="dynamicValue" [label]="dynamicValue"></cp-badge>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ColorDisplayDynamicComponent implements IEntityListItemDynamicComponent {
    @Input() dynamicValue: string;
}
