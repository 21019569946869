/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../display-helper/components/error/error.component.ngfactory";
import * as i5 from "../../../display-helper/components/error/error.component";
import * as i6 from "./progress.modal";
var styles_ProgressModalComponent = [];
var RenderType_ProgressModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProgressModalComponent, data: {} });
export { RenderType_ProgressModalComponent as RenderType_ProgressModalComponent };
export function View_ProgressModalComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [["class", "text-primary mt-2 mb-2 flex-grow-1"]], [[2, "text-danger", null]], null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close ml-1"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "fa-icon", [["class", "text-dark ng-fa-icon"], ["icon", "times"]], [[8, "innerHTML", 1]], null, null, i1.View_FaIconComponent_0, i1.RenderType_FaIconComponent)), i0.ɵdid(7, 573440, null, 0, i2.FaIconComponent, [i3.DomSanitizer, i2.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "cp-error", [], null, null, null, i4.View_ErrorComponent_0, i4.RenderType_ErrorComponent)), i0.ɵdid(9, 573440, null, 0, i5.ErrorComponent, [], { error: [0, "error"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 3, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "div", [["class", "spinner-border spinner-border-sm mr-1 text-primary"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "progress mb-1 flex-grow-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "div", [["class", "progress-bar progress-bar-striped progress-bar-animated"]], [[4, "width", "%"]], null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-input btn-block btn-lg mt-2 mb-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "times"; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.error; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = i0.ɵnov(_v, 7).renderedIconHTML; _ck(_v, 6, 0, currVal_2); var currVal_5 = _co.progress; _ck(_v, 13, 0, currVal_5); }); }
export function View_ProgressModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-progress-modal", [], null, null, null, View_ProgressModalComponent_0, RenderType_ProgressModalComponent)), i0.ɵdid(1, 638976, null, 0, i6.ProgressModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgressModalComponentNgFactory = i0.ɵccf("cp-progress-modal", i6.ProgressModalComponent, View_ProgressModalComponent_Host_0, { title: "title", message: "message", progress: "progress", error: "error" }, { close: "close", dismiss: "dismiss" }, []);
export { ProgressModalComponentNgFactory as ProgressModalComponentNgFactory };
