var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiHttpClientService } from '../../../api/services/api-http-client.service';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
var MappingHttpService = /** @class */ (function (_super) {
    __extends(MappingHttpService, _super);
    function MappingHttpService(httpClient, apiRequestHelperService) {
        var _this = _super.call(this, 'mappings', httpClient, apiRequestHelperService) || this;
        _this.httpClient = httpClient;
        _this.apiRequestHelperService = apiRequestHelperService;
        return _this;
    }
    MappingHttpService.prototype.mappingMatch = function (dataType, matchTo, options) {
        var url = this.generateMappingMatchUrl(['mappings', 'matches'], dataType, matchTo, options);
        var newParmas = this.generateMappingMatchParams(matchTo);
        return this.httpClient.get(url, {
            params: newParmas
        });
    };
    MappingHttpService.prototype.mappingMatchSearch = function (dataType, matchTo, options) {
        var url = this.generateMappingMatchUrl(['mappings', 'matches', 'search'], dataType, matchTo, options);
        var newParmas = this.generateMappingMatchParams(matchTo);
        return this.httpClient.get(url, {
            params: newParmas
        });
    };
    MappingHttpService.prototype.generateMappingMatchUrl = function (initialPath, dataType, matchTo, options) {
        var matchToPath = matchTo.entities.map(function (i) { return [i.entityType, i.id]; });
        var path = initialPath.concat([dataType]);
        matchToPath.forEach(function (m) { return path.push(m[0], m[1]); });
        var url = this.generateUrl({
            path: path,
            overrideBasePath: true,
            // this has a type restriction because this service generally doesn't use options from another entity
            // but this matching call is an exception.
            options: options
        });
        return url;
    };
    MappingHttpService.prototype.generateMappingMatchParams = function (matchTo) {
        var newParmas = new HttpParams();
        if (matchTo.ignoreIds.length > 0) {
            newParmas = newParmas.set('disableMappingIds', matchTo.ignoreIds.join(','));
        }
        return newParmas;
    };
    return MappingHttpService;
}(ApiHttpClientService));
export { MappingHttpService };
