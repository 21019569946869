var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { ConfigurationService } from '../../../core-configuration/services/configuration.service';
import { BaseSubscriber } from '../../../common/observables/base-subscriber';
import { takeUntil, map } from 'rxjs/operators';
var ExperiencePropertyDisplayListComponent = /** @class */ (function (_super) {
    __extends(ExperiencePropertyDisplayListComponent, _super);
    function ExperiencePropertyDisplayListComponent(configService, changeDetectorRef) {
        var _this = _super.call(this) || this;
        _this.configService = configService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.experienceIds = [];
        _this.cssClass = 'd-block';
        _this.loaded = false;
        return _this;
    }
    Object.defineProperty(ExperiencePropertyDisplayListComponent.prototype, "experiences", {
        get: function () {
            var _this = this;
            return this.allExperiences.filter(function (config) { return _this.experienceIds.includes(config.id); });
        },
        enumerable: true,
        configurable: true
    });
    ExperiencePropertyDisplayListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.configService.getExperiences().pipe(map(function (i) { return _this.handleConfig(i); }), takeUntil(this.ngUnsubscribe)).subscribe();
    };
    ExperiencePropertyDisplayListComponent.prototype.handleConfig = function (configExperiences) {
        this.allExperiences = configExperiences;
        this.loaded = true;
        this.changeDetectorRef.markForCheck();
    };
    return ExperiencePropertyDisplayListComponent;
}(BaseSubscriber));
export { ExperiencePropertyDisplayListComponent };
