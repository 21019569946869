var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { combineLatest, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
var RouteHelper = /** @class */ (function () {
    function RouteHelper() {
    }
    // THIS DOESNT WORK, DO NOT USE
    // combines all route params as well as query params
    RouteHelper.prototype.combineAllRouteParams = function (route) {
        var routeParamsChildObs = this.recursiveParamsChild(route);
        var routeParamsParentObs = this.recursiveParamsParent(route);
        return combineLatest(routeParamsChildObs, routeParamsParentObs, route.queryParams).pipe(tap(function (i) { return console.log('is this messing it up?'); }), map(function (results) { return (__assign({}, results[0], results[1], results[2])); }));
    };
    RouteHelper.prototype.recursiveParamsParent = function (route, params) {
        var combined = combineLatest(route.params, params || of({})).pipe(map(function (results) { return (__assign({}, results[0], results[1])); }));
        if (route.parent === null) {
            return combined;
        }
        return this.recursiveParamsParent(route.parent, combined);
    };
    RouteHelper.prototype.recursiveParamsChild = function (route, params) {
        if (!route.firstChild) {
            return params || of({});
        }
        var combined = combineLatest(route.firstChild.params, params || of({})).pipe(map(function (results) { return (__assign({}, results[0], results[1])); }));
        return this.recursiveParamsChild(route.firstChild, combined);
    };
    return RouteHelper;
}());
export { RouteHelper };
