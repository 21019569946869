import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    private redirecting = false;

    constructor(
        private service: AuthenticationService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            // ignore the main response, we only care if it errors
        }, (err: any) => {

            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    if (this.redirecting) { return; }
                    this.redirecting = true;

                    this.service.logout({ triggerUpdate: false });
                    this.router.navigate(['/login'], { queryParams: { error: 'unauthorized' } }).then(
                        () => this.redirecting = false,
                        () => this.redirecting = false
                    );
                }
            }
        }));
    }
}
