import { Injectable, Component } from '@angular/core';

// this only works for the main component on the main router-outlet (set in the layout module)
@Injectable()
export class ActivatedRouteComponentService {

    private component: any;

    setComponent(component: Component) {
        this.component = component;
    }

    getComponent() {
        return this.component;
    }
}
