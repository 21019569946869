/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./mini-profiler.component";
var styles_MiniProfilerComponent = [];
var RenderType_MiniProfilerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MiniProfilerComponent, data: {} });
export { RenderType_MiniProfilerComponent as RenderType_MiniProfilerComponent };
export function View_MiniProfilerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { elementRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["elementRef", 1]], null, 0, "div", [["style.display", "none"]], null, null, null, null, null))], null, null); }
export function View_MiniProfilerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-mini-profiler", [], null, null, null, View_MiniProfilerComponent_0, RenderType_MiniProfilerComponent)), i0.ɵdid(1, 4243456, null, 0, i1.MiniProfilerComponent, [], null, null)], null, null); }
var MiniProfilerComponentNgFactory = i0.ɵccf("cp-mini-profiler", i1.MiniProfilerComponent, View_MiniProfilerComponent_Host_0, { token: "token" }, {}, []);
export { MiniProfilerComponentNgFactory as MiniProfilerComponentNgFactory };
