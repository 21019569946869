import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseQueryService } from '../services/response-query.service';
import { tap } from 'rxjs/operators';
import { ReportResponse } from '../../../models/report/report.model';

@Injectable()
export class ResponseQueryInterceptor implements HttpInterceptor {

    constructor(
        private service: ResponseQueryService
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            tap(response => {
            if (response instanceof HttpResponse) {
                if (!response.ok || !response.body) { return; }

                this.handleResponse(req, response);
                this.handleSpecialReportResponse(req, response);
            }
        }));
    }

    private handleResponse(request: HttpRequest<any>, response: HttpResponse<any>) {
        if (!response.body.query) { return; }
        this.service.trigger(request, response.body.query);
    }

    private handleSpecialReportResponse(request: HttpRequest<any>, response: HttpResponse<any>) {
        const responseBody = response.body as ReportResponse;
        if (!responseBody) { return; }
        if (!responseBody.query) { return; }

        this.service.triggerReportResponse(request, responseBody.query);
    }
}
