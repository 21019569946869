import { Injectable } from '@angular/core';
import { ConfigurationHttpService } from './http/configuration.http.service';
import { Country, Configuration, Settings } from '../../../models/shared/configuration.model';
import { Observable } from 'rxjs';
import { ApiCacheSimpleService } from '../../api/services/api-cache-simple.service';
import { ResponseModel } from '../../../models/api/response.model';
import { EntityPropertiesConfig, EntityPropertyConfig } from '../../../models/filters/filter.model';
import { map } from 'rxjs/operators';
import { Experience } from '../../../models/experience/experience.model';
import { AbsenceType } from '../../../models/absence-type/absence-type.model';
import { Role } from '../../../models/user/role.model';
import { EntityType, NonEntityType } from '../../../models/entity/entity.type';

const CONFIG_CACHE = 1800000; // 30 mins
export const CONFIG_CACHE_KEY = 'configuration';

@Injectable()
export class ConfigurationService {

    constructor(
        private httpService: ConfigurationHttpService,
        private simpleCacheService: ApiCacheSimpleService) {
    }

    get(): Observable<Configuration> {
        return this.simpleCacheService.get<Configuration>(CONFIG_CACHE_KEY, this.httpService.get(), CONFIG_CACHE)!.pipe(
            map((resp: ResponseModel<Configuration>) => resp.data)
        );
    }

    getSettings(): Observable<Settings> {
        return this.simpleCacheService.get<Configuration>(CONFIG_CACHE_KEY, this.httpService.get(), CONFIG_CACHE)!.pipe(
            map((resp: ResponseModel<Configuration>) => resp.data.settings)
        );
    }

    getCountries(): Observable<Country[]> {
        return this.simpleCacheService.get<Country[]>('configuration-countries', this.httpService.getCountries(), CONFIG_CACHE)!.pipe(
            map((resp: ResponseModel<Country[]>) => resp.data)
        );
    }

    getExtendedProperties(type: EntityType | NonEntityType): Observable<EntityPropertyConfig[]> {
        return this.simpleCacheService.get<Configuration>(CONFIG_CACHE_KEY, this.httpService.get(), CONFIG_CACHE)!.pipe(
            map((resp: ResponseModel<Configuration>) => resp.data.types[type]),
            map(entityPropertiesConfig => {
                return entityPropertiesConfig ? Object.keys(entityPropertiesConfig)
                    .filter(key => key.startsWith('properties.'))
                    .map(key => entityPropertiesConfig[key]) : [];
            })
        );
    }

    getFilterProperties(): Observable<EntityPropertiesConfig> {
        return this.simpleCacheService.get<Configuration>(CONFIG_CACHE_KEY, this.httpService.get(), CONFIG_CACHE)!.pipe(
            map((resp: ResponseModel<Configuration>) => resp.data.types)
        );
    }

    getExperiences(): Observable<Experience[]> {
        return this.simpleCacheService.get<Configuration>(CONFIG_CACHE_KEY, this.httpService.get(), CONFIG_CACHE)!.pipe(
            map((resp: ResponseModel<Configuration>) => resp.data.experiences)
        );
    }

    getAbsentTypes(): Observable<AbsenceType[]> {
        return this.simpleCacheService.get<Configuration>(CONFIG_CACHE_KEY, this.httpService.get(), CONFIG_CACHE)!.pipe(
            map((resp: ResponseModel<Configuration>) => resp.data.absenceTypes)
        );
    }

    getRoles(): Observable<Role[]> {
        return this.simpleCacheService.get<Configuration>(CONFIG_CACHE_KEY, this.httpService.get(), CONFIG_CACHE)!.pipe(
            map((resp: ResponseModel<Configuration>) => resp.data.roles)
        );
    }
}
