import { Injectable } from '@angular/core';
import { ResponseStudentSubEntityInclude } from '../../../../models/api/response.model';
import { ApiHttpClientService } from '../../../api/services/api-http-client.service';
import { HttpClient } from '@angular/common/http';
import { StudentCourse } from '../../../../models/student/student-course.model';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { StudentSubEntityIncludeOption } from '../../../../models/shared/include.model';

@Injectable()
export class StudentCourseHttpService extends ApiHttpClientService<StudentCourse, StudentSubEntityIncludeOption, ResponseStudentSubEntityInclude> {

    protected baseEntityPath = ['students', 'courses'];

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super('studentCourses', httpClient, apiRequestHelperService);
    }
}
