import { Injectable } from '@angular/core';
import { FilterOperator, FilterPropertyType } from '../../../models/filters/filter.model';
import { InternalCondition } from './filter-query-helper.service';
import { FilterPropertyExtended } from './filter-config-helper.service';
import { ALL_FILTER_TYPES, ALL_FILTER_TYPE_PLURALS } from '../../../models/entity/entity.type';

@Injectable()
export class FilterConditionHelperService {

    private textFilterOptions: FilterOperator[] = ['contains', 'eq', 'startsWith', 'endsWith', 'any'];
    private comparableFilterOptions: FilterOperator[] = ['eq', 'gt', 'gte', 'lt', 'lte', 'any'];
    private dateFilterOptions: FilterOperator[] = [...this.comparableFilterOptions, 'range'];
    private filterTypeDates: FilterPropertyType[] = ['dateTime', 'date'];

    private entityTypeIdArray = [
        ...ALL_FILTER_TYPES.map(i => i + 'Id'),
        ...ALL_FILTER_TYPES.map(i => i + 'Ids'),
        ...ALL_FILTER_TYPES.map(i => i + '.id'),
        ...ALL_FILTER_TYPE_PLURALS.map(i => i + '.id'),
    ];

    getFilterOptionsForType(property: FilterPropertyExtended): FilterOperator[] {

        // search url means that a typeahead is used, limited values uses a multiselect
        if (property.searchUrl || (!!property.values && property.values.length > 0)) {
            return ['eq', 'any'];
        }

        // gets hit for entities that don't have a search property replacing their entity property
        if (this.isEntityType(property.key)) {
            return ['any'];
        }

        switch (property.type) {
            case 'string':
            case 'multilineText':
            case 'emailAddress':
            case 'phoneNumber':
            case 'url':
                return this.textFilterOptions;
            case 'int16':
            case 'int32':
            case 'double':
                return this.comparableFilterOptions;
            case 'date':
            case 'dateTime':
                return this.dateFilterOptions;
            case 'boolean':
                return ['eq'];
            default:
                console.log(`%cNEED TO CATER FOR TYPE: ${property.type} with key: ${property.key}`, 'color:red');
                return ['eq']; // should never hit
        }
    }

    createEmptyCondition(property: FilterPropertyExtended): InternalCondition {
        return {
            operator: this.getFilterOptionsForType(property)[0],
            values: [null as any]
        };
    }

    getOperatorName(operator: FilterOperator, filterPropertyType: FilterPropertyType): string {
        const isDate = this.filterTypeDates.includes(filterPropertyType);

        switch (operator) {
            case 'eq' : return 'equal to';
            case 'any': return 'any value'; // todo, change!
            case 'gt' : return (isDate ? 'after' : 'greater than');
            case 'gte': return (isDate ? 'after or on' : 'greater than or equal to');
            case 'lt' : return (isDate ? 'before' : 'less than');
            case 'lte': return (isDate ? 'before or on' : 'less than or equal to');
            case 'startsWith': return 'starting with';
            case 'endsWith': return 'ending with';
            case 'contains': return 'containing';
            case 'range': return 'between';

            // should never be hit unless we add a new operator and forget about the frontend.
            default: return (operator as string).split(/(?=[A-Z])/).join(' ');
        }
    }

    private isEntityType(key: string): boolean {
        return key === 'id' || this.entityTypeIdArray.includes(key);
    }
}
