/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../icon/icon.component.ngfactory";
import * as i2 from "../icon/icon.component";
import * as i3 from "../../directives/highlights/highlights.directive";
import * as i4 from "@angular/common";
import * as i5 from "./name-title.component";
import * as i6 from "../../../core-configuration/helpers/moment.helper";
var styles_NameTitleComponent = [];
var RenderType_NameTitleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NameTitleComponent, data: {} });
export { RenderType_NameTitleComponent as RenderType_NameTitleComponent };
function View_NameTitleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "cp-icon", [["hlOn", "name"], ["icon", "archive"]], [[8, "className", 0]], null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(1, 49152, null, 0, i2.IconComponent, [], { icon: [0, "icon"], hideIconIf: [1, "hideIconIf"], iconTitle: [2, "iconTitle"] }, null), i0.ɵdid(2, 16384, null, 0, i3.HighlightsDirective, [], { hlOn: [0, "hlOn"], highlights: [1, "highlights"] }, null), (_l()(), i0.ɵted(3, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "archive"; var currVal_2 = !_v.parent.context.$implicit.archived; var currVal_3 = "Archived"; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "name"; var currVal_5 = _co.highlights; _ck(_v, 2, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).elementClass; _ck(_v, 0, 0, currVal_0); var currVal_6 = _v.parent.context.$implicit.name; _ck(_v, 3, 0, currVal_6); }); }
function View_NameTitleComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "cp-icon", [["hlOn", "name"], ["icon", "clock"]], [[8, "className", 0]], null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(1, 49152, null, 0, i2.IconComponent, [], { icon: [0, "icon"], iconTitle: [1, "iconTitle"] }, null), i0.ɵdid(2, 16384, null, 0, i3.HighlightsDirective, [], { hlOn: [0, "hlOn"], highlights: [1, "highlights"] }, null), (_l()(), i0.ɵted(3, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "clock"; var currVal_2 = "Unavailable"; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = "name"; var currVal_4 = _co.highlights; _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).elementClass; _ck(_v, 0, 0, currVal_0); var currVal_5 = _v.parent.context.$implicit.name; _ck(_v, 3, 0, currVal_5); }); }
function View_NameTitleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NameTitleComponent_2)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NameTitleComponent_3)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isUnavailable; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isUnavailable; _ck(_v, 4, 0, currVal_1); }, null); }
function View_NameTitleComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["hlOn", "name"]], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i3.HighlightsDirective, [], { hlOn: [0, "hlOn"], highlights: [1, "highlights"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "name"; var currVal_2 = _co.highlights; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).elementClass; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.value; _ck(_v, 2, 0, currVal_3); }); }
export function View_NameTitleComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NameTitleComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NameTitleComponent_4)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isNameLike; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isString; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_NameTitleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-name-title", [], null, null, null, View_NameTitleComponent_0, RenderType_NameTitleComponent)), i0.ɵdid(1, 49152, null, 0, i5.NameTitleComponent, [i6.MomentHelper], null, null)], null, null); }
var NameTitleComponentNgFactory = i0.ɵccf("cp-name-title", i5.NameTitleComponent, View_NameTitleComponent_Host_0, { value: "value", highlights: "highlights" }, {}, []);
export { NameTitleComponentNgFactory as NameTitleComponentNgFactory };
