import { Injectable } from '@angular/core';
import { IncludeEntityMetadataCore } from '../../../models/shared/metadata.model';
import { dotPropertyTransform } from '../../display-helper/pipes/dot-property.pipe';
import { EntityMetadataService } from '../../core-configuration/services/metadata/entity-metadata.service';
import { AllResponseIncludes, ResponseModelSingle, ResponseModelList } from '../../../models/api/response.model';
import { AllIncludeTypes } from '../../../models/shared/include.model';
import { Entity, MetadataEntity, MetadataType } from '../../../models/entity/entity.type';

@Injectable()
export class IncludeMapperService {

    constructor(
        private metadataService: EntityMetadataService
    ) {

    }

    forceConstructor<TEntity extends MetadataEntity>(type: MetadataType, entity: TEntity): TEntity {
        // this is necessary for any "getter" property types that might exist on
        // these entities. Responses from the API will never have these properties
        // as they are not true classes (they are just simple json objects)
        // p.s. I'm not sure if this is the best way to do this... o.O
        const newEntity = this.metadataService.createEntity(type);

        return Object.assign(newEntity, entity);
    }


    handleSingle<TEntity extends MetadataEntity, TResponseIncludes extends AllResponseIncludes>(
        type: MetadataType,
        response: ResponseModelSingle<TEntity, TResponseIncludes>
    ) {
        response.data = this.forceConstructor(type, response.data);
        this.mapMetadataInclude(type, [response.data], response.include);
        this.mapMetadataIncludeMany(type, [response.data], response.include);
    }

    handleList<TEntity extends MetadataEntity, TResponseIncludes extends AllResponseIncludes>(
        type: MetadataType,
        response: ResponseModelList<TEntity, TResponseIncludes>
    ) {
        response.data = response.data.map(i => this.forceConstructor(type, i));
        this.mapMetadataInclude(type, response.data, response.include);
        this.mapMetadataIncludeMany(type, response.data, response.include);
    }

    mapMetadataInclude<TEntity extends MetadataEntity, TResponseIncludes extends AllResponseIncludes>(
        type: MetadataType,
        list: TEntity[],
        include: TResponseIncludes
    ) {
        const meta = this.metadataService.get(type);

        if (!meta.classMetadata.include) { return; }

        meta.classMetadata.include.forEach(includeMeta => {
            list.forEach((responseItem) => {
                const entity = (responseItem as Entity);
                if (!entity.include) { entity.include = {}; }

                const key = includeMeta.includeKey;

                const responseInclude = include[key];
                // console.log(includeMeta, response, responseItem, responseInclude);
                if (!responseInclude) { return; }

                // find the entity
                const find = responseInclude
                    // only handles one to one relationships.
                    .find(this.masterSlaveEqualityPredicate(includeMeta, entity));


                // it's necessary to check if something actually exists, as some entities
                // (I'm looking at you StudyPlan and StudyBlocks) can be nullable
                if (find) {
                    // create a class of the entity - also this is awful
                    entity.include[includeMeta.type] = this.forceConstructor(includeMeta.type, find);
                }
            });
        });
    }

    mapMetadataIncludeMany<TEntity extends MetadataEntity, TResponseIncludes extends AllResponseIncludes>(
        type: MetadataType,
        list: TEntity[],
        include: TResponseIncludes
    ) {
        const meta = this.metadataService.get(type);

        if (!meta.classMetadata.includeMany) { return; }

        meta.classMetadata.includeMany
            // does a poor sort to order the includes that require something to be after those that don't
            .sort((a, b) => a.options ? a.options.requiresInclude ? -1 : 0 : 0)
            .map(includeMeta => {

            list.map(responseItem => {
                const entity = (responseItem as Entity);
                if (!entity.includeMany) { entity.includeMany = {}; }

                const key = includeMeta.includeKey;

                const responseInclude = include[key];
                if (!responseInclude) { return; }

                let filter: any[];
                if (includeMeta.options && includeMeta.options.predicateManyOverride) {
                    filter = includeMeta.options.predicateManyOverride(responseItem, responseInclude);
                } else {
                    // many to one "filters" to a collection
                    filter = responseInclude
                        .filter(this.masterSlaveManyPredicate(includeMeta, entity));
                }

                // note: this is pluralized here
                entity.includeMany[key] = filter.map((f: any) => this.forceConstructor( includeMeta.type, f));
            });
        });
    }

    private masterSlaveEqualityPredicate<TEntity extends MetadataEntity>(
        includeMeta: IncludeEntityMetadataCore<TEntity, MetadataEntity>,
        responseItem: Entity
    )  {

        return (includeEntity: AllIncludeTypes) => {
            // anything that has a dot must go through the ENTITY INCLUDE *NOTE* - this is not the response include
            // it requires the "required include" to have already been processed.
            if (!includeMeta.propertyMaster.includes('.')) {
                return responseItem[includeMeta.propertyMaster] === includeEntity[includeMeta.propertySlave];
            }

            // it's possible for includeMaster to be undefined here
            // if the parent entity doesn't exist. But this should be fine.
            const includeMasterValue = dotPropertyTransform(responseItem.include, includeMeta.propertyMaster);

            return includeMasterValue === includeEntity[includeMeta.propertySlave];
        };
    }

    private masterSlaveManyPredicate<TEntity extends MetadataEntity>(includeMeta: IncludeEntityMetadataCore<TEntity, MetadataEntity>, responseItem: Entity)  {

        return (includeEntity: AllIncludeTypes) => {
            // anything that has a dot must go through the ENTITY INCLUDE *NOTE* - this is not the response include
            // it requires the "required include" to have already been processed.
            let masterValue: any;

            if (!includeMeta.propertyMaster.includes('.')) {
                masterValue = responseItem[includeMeta.propertyMaster];

            } else {
                masterValue = dotPropertyTransform(responseItem.include, includeMeta.propertyMaster);
                this.checkIncludeMasterValue(masterValue, includeMeta, responseItem.include);
            }

            const slaveValue = includeEntity[includeMeta.propertySlave];
            const masterValueIsArray = Array.isArray(masterValue);
            const slaveValueIsArray = Array.isArray(slaveValue);
            if (masterValueIsArray && slaveValueIsArray) {
                throw new Error('Many to Many Multi-include Not Supported');
            }

            if (masterValueIsArray) {
                return masterValue.includes(slaveValue);
            }

            if (slaveValueIsArray) {
                return slaveValue.includes(masterValue);
            }

            return masterValue === slaveValue;
        };
    }

    private checkIncludeMasterValue<TEntity extends MetadataEntity>(includeMasterValue: any, includeMeta: IncludeEntityMetadataCore<TEntity, MetadataEntity>, responseInclude: any) {
        const isNullable = !!includeMeta.options && !!includeMeta.options.requiresIncludeIsNullable;

        if (!includeMasterValue && !isNullable) {
            console.log('erroring', includeMasterValue, includeMeta, responseInclude);
            // this will not work beyond one level deep
            // which I don't think is a problem, at least for now
            throw new Error(`
                includeMaster value - in MetadataInclude logic - is undefined.
                This include requires another include entity, the required include entities
                must be processed before the include entities that require it.
            `);
        }
    }
}
