var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CrudEntityServiceBase } from '../../entity-shared/base/entity-service.base';
import { SavedSearchHttpService } from './http/saved-search.http.service';
var SavedSearchService = /** @class */ (function (_super) {
    __extends(SavedSearchService, _super);
    function SavedSearchService(httpService) {
        var _this = _super.call(this, httpService) || this;
        _this.httpService = httpService;
        return _this;
    }
    return SavedSearchService;
}(CrudEntityServiceBase));
export { SavedSearchService };
