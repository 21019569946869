var Highlights = /** @class */ (function () {
    function Highlights() {
    }
    return Highlights;
}());
export { Highlights };
var HighlightsDirective = /** @class */ (function () {
    function HighlightsDirective() {
        this.classes = [];
    }
    Object.defineProperty(HighlightsDirective.prototype, "elementClass", {
        get: function () {
            var highlights = this.hasHighlight() ? this.highlights[this.hlOn] : [];
            return highlights.concat(this.classes).join(' ');
        },
        set: function (val) {
            this.classes = val.split(' ');
        },
        enumerable: true,
        configurable: true
    });
    HighlightsDirective.prototype.hasHighlight = function () {
        return this.highlights && !!this.hlOn && this.highlights[this.hlOn] !== undefined;
    };
    return HighlightsDirective;
}());
export { HighlightsDirective };
// hl="{ name: 'text-success' }" hlOn="name"
