var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { toPlural } from '../../entity-shared/helper/entity-shared.helper';
import { DESCRIBABLE_TYPE_METADATA, ALL_FILTER_TYPES } from '../../../models/entity/entity.type';
var FilterConfigInnerService = /** @class */ (function () {
    function FilterConfigInnerService() {
        this.entityTypeIdArray = ALL_FILTER_TYPES.map(function (i) { return i + 'Id'; }).concat(ALL_FILTER_TYPES.map(function (i) { return i + 'Ids'; }));
    }
    FilterConfigInnerService.prototype.getBaseEntityProperties = function (config, type) {
        var _this = this;
        var entityConfig = config[type];
        if (!entityConfig) {
            return [];
        }
        return Object.keys(entityConfig)
            .map(function (key) { return entityConfig[key]; })
            .filter(function (filterProperty) { return !filterProperty.archived; })
            .map(function (filterProperty) { return _this.extendFilterProperty(filterProperty); });
    };
    FilterConfigInnerService.prototype.getPropertyPathByPath = function (config, entityType, path) {
        var baseProperties = this.getBaseEntityProperties(config, entityType);
        return this.traversePropertyConfig(config, baseProperties, path, { suppressError: true });
    };
    FilterConfigInnerService.prototype.propertyPathToFilterPath = function (propertyPath) {
        return propertyPath.map(function (pp) { return ({
            key: pp.key,
            entityIdKey: pp.entityIdKey,
            without: pp.without
        }); });
    };
    FilterConfigInnerService.prototype.traversePropertyConfig = function (config, filterProperties, path, options) {
        if (options === void 0) { options = { suppressError: false }; }
        return this.traversePropertyConfigRecursive(config, filterProperties, path, options);
    };
    FilterConfigInnerService.prototype.traversePropertyConfigRecursive = function (config, filterProperties, path, options, propertyPath) {
        if (propertyPath === void 0) { propertyPath = []; }
        if (path.length === 0) {
            if (!options.suppressError) {
                throw new Error('keyPath must have a length');
            }
            return undefined;
        }
        var pathItem = path[propertyPath.length];
        if (!pathItem) {
            if (!options.suppressError) {
                throw new Error('Unexpected undefined value in currentProperty');
            }
            return undefined;
        }
        var filterProperty = filterProperties.find(function (i) { return i.key === pathItem.key; });
        if (!filterProperty) {
            // console.log(`%cUnexpected undefined value in filterProperty`, 'color:red', pathItem, path, propertyPath);
            if (!options.suppressError) {
                throw new Error('Unexpected undefined value in filterProperty');
            }
            return undefined;
        }
        // if you have an Id path property, you need to switch the property to the entity property
        // for example, if this is a Student search, and the property coming in is "planId"
        // we need to replace this path segment with the "path" property, and set the planId to the entityId key/property
        var filterTypeIfEntity = this.isEntityIdProperty(pathItem.key);
        if (filterTypeIfEntity) {
            pathItem.key = filterTypeIfEntity;
            return this.traversePropertyConfigRecursive(config, filterProperties, path, options, propertyPath);
        }
        // set extended path
        var newPropertyPath = {
            key: pathItem.key,
            without: pathItem.without,
            property: filterProperty
        };
        propertyPath.push(newPropertyPath);
        if (!this.isEntityType(filterProperty.type)) {
            return propertyPath;
        }
        // find entity properties and keys
        var nextEntityType = filterProperty.type;
        var nextEntityProperties = this.getBaseEntityProperties(config, nextEntityType);
        var idArray = [nextEntityType + 'Id', nextEntityType + 'Ids'];
        var existsInCurrentEntityProperties = filterProperties.find(function (i) { return idArray.includes(i.key); });
        if (existsInCurrentEntityProperties) {
            newPropertyPath.entityIdKey = existsInCurrentEntityProperties.key;
            newPropertyPath.entityIdProperty = existsInCurrentEntityProperties;
        }
        else {
            var existsInNextEntityProperties = nextEntityProperties.find(function (i) { return i.key === 'id'; });
            if (existsInNextEntityProperties) {
                newPropertyPath.entityIdKey = filterProperty.key + ".id";
                newPropertyPath.entityIdProperty = existsInNextEntityProperties;
            }
        }
        // if it's an entity type and the next chain item is 'id', we need to snip it here
        var nextPath = path[propertyPath.length];
        if (nextPath && nextPath.key === 'id') {
            return propertyPath;
        }
        // if this is true, then we're at the end of the chain
        if (path.length === propertyPath.length) {
            return propertyPath;
        }
        return this.traversePropertyConfigRecursive(config, nextEntityProperties, path, options, propertyPath);
    };
    FilterConfigInnerService.prototype.isEntityIdProperty = function (key) {
        if (this.entityTypeIdArray.includes(key)) {
            var entityType = key.slice(0, key.lastIndexOf('Id'));
            // also have to cater for pluralization here
            return key.endsWith('Ids') ? toPlural(entityType) : entityType;
        }
        return undefined;
    };
    FilterConfigInnerService.prototype.extendFilterProperty = function (filterProperty) {
        return __assign({}, filterProperty, { isEntity: this.isEntityType(filterProperty.type) });
    };
    FilterConfigInnerService.prototype.isEntityType = function (value) {
        return DESCRIBABLE_TYPE_METADATA[value] && !!DESCRIBABLE_TYPE_METADATA[value].filterable;
    };
    return FilterConfigInnerService;
}());
export { FilterConfigInnerService };
