import { OnInit, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { BaseSubscriber } from '../../../common/observables/base-subscriber';
import { SimpleNamedEntity } from '../../../../models/shared/simple-entity.model';
import { ConfigurationService } from '../../../core-configuration/services/configuration.service';
import { Observable } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { ConfigurationDyanamicListItemType } from '../../../display-helper/components/dynamic/dynamic.base';
import { IEntityListItemDynamicComponent } from '../../../entity-shared/components/entity-list/entity-list-item-dynamic.component';

const EMPTY_NAME: SimpleNamedEntity = {
    id: -1,
    name: '' // don't use a name here, attended absent type should just be empty
};

export class ConfigurationEntityListItemBase
    extends BaseSubscriber implements OnInit, OnChanges, IEntityListItemDynamicComponent {
    baseEntity?: any;
    overrideTabIndex?: number | undefined;

    @Input() dynamicValue: number[] = [];

    items: SimpleNamedEntity[] = [];
    loaded = false;
    configItems: SimpleNamedEntity[] = [];

    constructor(
        protected entityType: ConfigurationDyanamicListItemType,
        protected configService: ConfigurationService,
        protected changeDetectionRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {

        const obs = this.getConfigObs();

        obs.pipe(
            map(all => {
                this.configItems = all;
                this.loaded = true;
                this.handleItems();
            }),
            takeUntil(this.ngUnsubscribe)
        ).subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.item) { this.handleItems(); }
    }

    private handleItems() {
        if (!this.loaded) { return; }
        if (!this.dynamicValue === undefined) {
            this.items = [];
            this.changeDetectionRef.markForCheck();
            return;
        }
        this.dynamicValue = this.dynamicValue instanceof Array ? this.dynamicValue : [this.dynamicValue];

        this.items = this.dynamicValue.map(i =>
            this.configItems.find(c => c.id === i) || EMPTY_NAME
        );

        this.changeDetectionRef.markForCheck();
    }

    private getConfigObs(): Observable<SimpleNamedEntity[]> {
        switch (this.entityType) {
            case 'absenceType': return this.configService.getAbsentTypes();
            case 'experience': return this.configService.getExperiences();
            case 'role': return this.configService.getRoles();
            default: throw new Error('entityType not configured for dynamic list generation: ' + this.entityType);
        }
    }
}
