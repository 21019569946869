import { NgModule } from '@angular/core';
import { CustomCommonModule } from '../common/common.module';
import { CustomFormsModule } from '../form/form.module';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { EntitySharedModule } from '../entity-shared/entity-shared.module';
import { FilterModule } from '../filter/filter.module';
import { MappingService } from './services/mapping.service';
import { MappingHttpService } from './services/http/mapping.http.service';
import { MappingToggleListComponent } from './components/mapping-toggle/mapping-toggle-list.component';
import { MappingMatchingService } from './services/mapping-matching.service';
import { MappingListItemComponent } from './components/mapping-list-item/mapping-list-item.component';
import { MappingResultsComponent } from './components/mapping/mapping-results.component';
import { MappingFormComponent } from './components/mapping/mapping-form.component';
import { TypeaheadPositionMatchComponent } from './components/typeahead/typeahead-position-match.component';

@NgModule({
    imports: [
        CustomCommonModule.forChild(),
        CustomFormsModule,
        FilterModule,
        DisplayHelperModule,
        EntitySharedModule
    ],
    providers: [

        MappingService,
        MappingHttpService,

        MappingMatchingService
    ],
    declarations: [

        MappingToggleListComponent,
        MappingListItemComponent,

        MappingResultsComponent,
        MappingFormComponent,

        TypeaheadPositionMatchComponent
    ],
    exports: [

        MappingToggleListComponent,
        MappingListItemComponent,

        MappingResultsComponent,
        MappingFormComponent,

        TypeaheadPositionMatchComponent
    ],
})
export class MappingModule { }
