var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { MinutesPipe } from '../../modules/display-helper/pipes/minutes.pipe';
import { AbsenceTypeEntityListItemComponent } from '../../modules/configuration-entity-shared/components/absence-type-entity-list-item/absence-type-entity-list-item.component';
/* NOTE: Timehseets are the only Core Type that isn't an Entity
   However, it can still be logged, and therefore inherits the ILoggedEntity interface. */
var Timesheet = /** @class */ (function () {
    function Timesheet() {
    }
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], Timesheet.prototype, "id", void 0);
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], Timesheet.prototype, "placementId", void 0);
    __decorate([
        CpProperty({ type: 'date' }),
        __metadata("design:type", Object)
    ], Timesheet.prototype, "date", void 0);
    __decorate([
        CpProperty({ label: 'Hours', type: 'int32', defaultListVisible: true, pipe: { pipe: new MinutesPipe() } }),
        __metadata("design:type", Number)
    ], Timesheet.prototype, "minutes", void 0);
    __decorate([
        CpProperty({ label: 'Absence Type', type: 'int32', listHeaderAlign: 'left', defaultListVisible: true, dynamicComponent: AbsenceTypeEntityListItemComponent }),
        __metadata("design:type", Number)
    ], Timesheet.prototype, "absenceTypeId", void 0);
    __decorate([
        CpProperty({ type: 'date' }),
        __metadata("design:type", Object)
    ], Timesheet.prototype, "added", void 0);
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], Timesheet.prototype, "addedUserId", void 0);
    __decorate([
        CpProperty({ type: 'date' }),
        __metadata("design:type", Object)
    ], Timesheet.prototype, "lastUpdated", void 0);
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], Timesheet.prototype, "lastUpdatedUserId", void 0);
    Timesheet = __decorate([
        CpClass({
            metadataType: 'timesheet',
            create: newTimesheet
        })
    ], Timesheet);
    return Timesheet;
}());
export { Timesheet };
export function newTimesheet() { return new Timesheet(); }
