var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TimesheetHttpService } from './http/timesheet.http.service';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
import { tap } from 'rxjs/operators';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
var TimesheetService = /** @class */ (function (_super) {
    __extends(TimesheetService, _super);
    function TimesheetService(httpService, apiCacheService, includeMapperService) {
        var _this = _super.call(this, 'timesheet', httpService, apiCacheService, includeMapperService) || this;
        _this.httpService = httpService;
        _this.apiCacheService = apiCacheService;
        _this.includeMapperService = includeMapperService;
        _this.includeOptions = [];
        return _this;
    }
    TimesheetService.prototype.onAfterCreate = function (entity) {
        this.apiCacheService.clearCacheByEntityId('placements', entity.placementId);
    };
    TimesheetService.prototype.onAfterUpdate = function (entity) {
        this.apiCacheService.clearCacheByEntityId('placements', entity.placementId);
    };
    TimesheetService.prototype.deleteById = function (id) {
        return this.httpService.delete([id]);
    };
    TimesheetService.prototype.bulk = function (timesheets) {
        var _this = this;
        return this.httpService.bulk(timesheets).pipe(tap(function (i) {
            lodashHelper.uniq(timesheets.map(function (t) { return t.placementId; }))
                .map(function (placementId) {
                _this.apiCacheService.clearCacheByType('timesheets');
                _this.apiCacheService.clearCacheByEntityId('placements', placementId);
            });
        }));
    };
    return TimesheetService;
}(EntityServiceMetadataBase));
export { TimesheetService };
