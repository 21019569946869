import { Component, OnInit } from '@angular/core';
import { IEntityListItemDynamicComponent } from '../../../entity-shared/components/entity-list/entity-list-item-dynamic.component';
import { MinutesSummary } from './minutes-summary.component';
import { Placement } from '../../../../models/placement/placement.model';

// eeeee, this is really cross cutting concerns here.
// this control won't work with anything other than a Placement
// but the module structure is all messed up again.
// I going to have to restructure all of these modules again...

export interface MinutesSummaryDynamic {
    attended: number;
    absent: number;
}

@Component({
    selector: 'cp-minutes-summary-dynamic',
    template: `<cp-minutes-summary *ngIf="summary" [value]="summary"></cp-minutes-summary>`
})

export class MinutesSummaryDynamicComponent implements OnInit, IEntityListItemDynamicComponent {

    dynamicValue: MinutesSummaryDynamic;
    baseEntity?: Placement;

    get summary(): MinutesSummary | undefined {
        if (!this.dynamicValue) { return undefined; }
        return {
            attended: this.dynamicValue.attended,
            absent: this.dynamicValue.absent,
            expected: this.baseEntity!.minutes
        };
    }

    constructor() { }

    ngOnInit() {
        if (!this.baseEntity) { throw new Error('baseEntity is required for this dynamic component'); }
    }
}
