var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MappingHttpService } from './http/mapping.http.service';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { tap, map } from 'rxjs/operators';
import { CrudEntityServiceBase } from '../../entity-shared/base/entity-service.base';
var MappingService = /** @class */ (function (_super) {
    __extends(MappingService, _super);
    function MappingService(httpService, includeMapperService) {
        var _this = _super.call(this, httpService) || this;
        _this.httpService = httpService;
        _this.includeMapperService = includeMapperService;
        return _this;
    }
    MappingService.prototype.getMatches = function (dataType, matchto, options) {
        var _this = this;
        return this.httpService.mappingMatch(dataType, matchto, options).pipe(tap(function (response) { return _this.includeMapperService.handleList(dataType, response); }));
    };
    MappingService.prototype.getMatchesSearch = function (dataType, matchto, options) {
        return this.httpService.mappingMatchSearch(dataType, matchto, options);
    };
    MappingService.prototype.getPositionsForStudentMatchesSearch = function (query, studentId, ignoreIds, additionalFilters, take) {
        if (additionalFilters === void 0) { additionalFilters = []; }
        if (take === void 0) { take = 10; }
        var matchTo = this.createMatchTo(studentId, ignoreIds);
        return this.getMatchesSearch('position', matchTo, { filters: additionalFilters, take: take, q: query }).pipe(map(function (i) { return i.data; }));
    };
    MappingService.prototype.createMatchTo = function (studentId, ignoreIds) {
        var matchTo = {
            entities: [{
                    entityType: 'student',
                    id: studentId
                }],
            ignoreIds: ignoreIds
        };
        return matchTo;
    };
    return MappingService;
}(CrudEntityServiceBase));
export { MappingService };
