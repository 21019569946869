import { ExtendedProperties } from '../extended-properties/extended-property.model';
import { CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { NameLike } from '../../modules/display-helper/components/title/name-title.component';
import { PersonLike } from '../../modules/display-helper/components/title/person-title.component';
import * as moment from 'moment';
import { DateRange } from '../shared/date-range.model';
import { environment } from '../../../environments/environment';
import { AllIncludeOptions } from '../shared/include.model';
import { EntityType, NonEntityType } from './entity.type';

export interface IProvisionable {
    provisioningKey?: string;
    provisioned?: boolean;
    provisionedClientId?: string;
}


export abstract class EntityBase {

    @CpProperty({ type: 'int32', hideFromList: true })
    id: number;

    properties: ExtendedProperties;

    // surely, this can be better...
    include?: { [key in (EntityType | NonEntityType)]?: any };
    includeMany?: { [key in (AllIncludeOptions)]?: any[] };


    // override this in parent class if you so wish
    // this is used with the csv download for the main column
    toString(): string {
        return entityToString(this);
    }

    constructor() {
        this.properties = {};
    }
}

export function entityToString(entity: any): string {
    if (nameGuard(entity)) {
        return entity.name;
    }

    if (personGuard(entity)) {
        return `${entity.lastName}, ${entity.firstName}`;
    }

    if (dateGuard(entity)) {
        return `${entity.start.format(environment.momentDateFormat)} > ${entity.end.format(environment.momentDateFormat)}`;
    }

    return entity.id ? entity.id.toString() : 'Unknown Entity';
}

export function nameGuard(tbd: any): tbd is NameLike {
    return tbd.name !== undefined;
}

export function personGuard(tbd: any): tbd is PersonLike {
    return tbd.firstName && tbd.lastName;
}

export function dateGuard(tbd: any): tbd is DateRange {
    return tbd.start && tbd.end && moment.isMoment(tbd.start) && moment.isMoment(tbd.end);
}
