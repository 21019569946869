import { Injectable } from '@angular/core';
import { ResponseFilter, ResponseFilterCondition } from '../../../models/api/response.model';
import { toPluralForUrl } from '../../entity-shared/helper/entity-shared.helper';
import { SearchFilterCondition, EntityPropertiesConfig } from '../../../models/filters/filter.model';
import { FilterQueryHelperService, PREFIX_SEPERATOR, QueryFilterKey, InternalFilter, InternalCondition } from './filter-query-helper.service';
import { FilterPathHelperService } from './filter-path-helper.service';
import { FilterConfigInnerService } from './filter-config-inner.service';
import { EntityType, NonEntityType } from '../../../models/entity/entity.type';

@Injectable()
export class ResponseFilterService {

    constructor(
        private filterQueryHelper: FilterQueryHelperService,
        private filterPathHelper: FilterPathHelperService,
        private configInnerHelper: FilterConfigInnerService
    ) { }

    createSearchRouteArray(filter: ResponseFilter): any[] {
        return this.baseRouteOnEntityType(filter.type);
    }

    // This method is an artifact from the initial filter logic. It's possible that we can
    // remove either "SearchCondition" or "ResponseCondition" from everything.
    //
    // be aware. You lose information when converting between these filter types. Specifically you
    // lose information on the 'Name' property, mainly used to populate input fields
    responseConditionToSearchCondition(condition: ResponseFilterCondition): SearchFilterCondition {
        const conditionValues = (condition.values as any[]).map(v => v.value ? v.value : v);

        return {
            operator: condition.operator,
            path: condition.path,
            values: conditionValues
        };
    }

    responseFilterToQueryParams(queryKey: QueryFilterKey, filter: ResponseFilter): Object {
        const obj = {};

        filter.conditions.map(condition => {
            // the queryKey is always just the path in a response filter
            const key = queryKey.prefix ? queryKey.prefix + PREFIX_SEPERATOR + condition.path : condition.path;
            const value = this.filterQueryHelper.createQueryValueStringFromCondition(condition);
            this.filterQueryHelper.appendToQueryObject(obj, key, value);
        });

        return obj;
    }

    responseFilterToInternalFilter(config: EntityPropertiesConfig, filter: ResponseFilter): InternalFilter[] {
        const toReturn: InternalFilter[] = [];

        filter.conditions.map(condition => {
            const filterPath = this.filterPathHelper.stringToFilterPath(condition.path);
            const propertyPath = this.configInnerHelper.getPropertyPathByPath(config, filter.type, filterPath);
            if (!propertyPath) {
                return;
            }

            const final = propertyPath[propertyPath.length - 1];

            const internalCondition: InternalCondition = {
                operator: condition.operator,
                values: condition.values
            };

            const internal: InternalFilter = {
                condition: internalCondition,
                path: propertyPath,
                property: final.property,
                entityIdProperty: final.entityIdProperty
            };

            toReturn.push(internal);
        });

        return toReturn;
    }

    // this probably shouldn't be here
    private baseRouteOnEntityType(type: EntityType | NonEntityType): any[] {

        switch (type) {
            case 'planBlock': return ['/blocks'];
            default: return ['/' + toPluralForUrl(type)];
        }
    }
}
