import { NgModule } from '@angular/core';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { ErrorHandlerService } from './services/error-handler.service';
import { ErrorService } from './services/error.service';
import { LoggingService } from './services/logging.service';
import { SentryLoggingService } from './services/sentry-logging.service';
import { RoutingHistoryService } from './services/routing-history.service';
import { environment } from '../../../environments/environment';
import { RouterModule } from '@angular/router';
import { AuthenticationModule } from '../authentication/authentication.module';
import { CommonModule } from '@angular/common';
import { ApiErrorInterceptor } from './interceptors/api-error.interceptor';

const loggingService = environment.useSentry ?
    { provide: LoggingService, useClass: SentryLoggingService }
    : LoggingService;


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AuthenticationModule.forChild()
    ],
    providers: [
        ApiErrorInterceptor,
        ErrorHandlerInterceptor,

        ErrorHandlerService,
        ErrorService,
        RoutingHistoryService,

        loggingService
    ],
    declarations: [],
    exports: []
})
export class ErrorLoggingModule { }
