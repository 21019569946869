export const arrayHelper = {

    addOrReplace<T>(array: T[], item: T, predicate: (item: T, index: number) => boolean): number {
        const findIndex = array.findIndex(predicate);
        if (findIndex === -1) {
            array.push(item);
        } else {
            array[findIndex] = item;
        }

        return findIndex;
    }
};
