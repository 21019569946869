import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    title: string;
    message: string;

    constructor(private service: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.checkAuthentication(state);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.checkAuthentication(state);
    }

    private checkAuthentication(state: RouterStateSnapshot): boolean {
        const authenticated = this.service.isAuthenticated();

        if (authenticated) { return true; }

        console.log('%cUser is not authenticated', 'color:grey');

        this.service.performInitialRedirect(state.url);

        return false;
    }
}
