<div class="modal-body">
    <div class="container-fluid">
        <div class="d-flex align-items-center">
            <h2 class="text-primary mt-2 mb-2 flex-grow-1" [class.text-danger]="error">
                {{ title }}
            </h2>

            <button type="button" class="close ml-1" aria-label="Close" (click)="onDismiss()">
                <fa-icon icon="times" class="text-dark"></fa-icon>
            </button>
        </div>

        <cp-error [error]="error"></cp-error>

        <div class="d-flex">
            <div class="spinner-border spinner-border-sm mr-1 text-primary"></div>
            <div class="progress mb-1 flex-grow-1">
                <div class="progress-bar progress-bar-striped progress-bar-animated" [style.width.%]="progress"></div>
            </div>
        </div>

        <hr />
        <button type="button" class="btn btn-input btn-block btn-lg mt-2 mb-3" (click)="onDismiss()">Cancel</button>
    </div>
</div>