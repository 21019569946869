var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ConfigurationService } from '../../../core-configuration/services/configuration.service';
import { BaseSubscriber } from '../../../common/observables/base-subscriber';
import { takeUntil, map } from 'rxjs/operators';
var AbsenceSummaryListComponent = /** @class */ (function (_super) {
    __extends(AbsenceSummaryListComponent, _super);
    function AbsenceSummaryListComponent(configService, changeDetectorRef) {
        var _this = _super.call(this) || this;
        _this.configService = configService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.loaded = false;
        return _this;
    }
    AbsenceSummaryListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.configService.getAbsentTypes().pipe(map(function (i) {
            _this.absenceTypes = i;
            _this.generateViewModel();
            _this.loaded = true;
            _this.changeDetectorRef.markForCheck();
        }), takeUntil(this.ngUnsubscribe)).subscribe();
    };
    AbsenceSummaryListComponent.prototype.ngOnChanges = function (changes) {
        if (changes.dynamicValue) {
            this.generateViewModel();
        }
    };
    AbsenceSummaryListComponent.prototype.generateViewModel = function () {
        var _this = this;
        if (!this.dynamicValue || !this.absenceTypes) {
            this.viewModel = [];
            return;
        }
        this.viewModel = this.dynamicValue
            .filter(function (i) { return _this.absenceTypes.map(function (a) { return a.id; }).includes(i.absenceTypeId); })
            .map(function (i) { return ({
            name: _this.absenceTypes.find(function (a) { return a.id === i.absenceTypeId; }).name,
            minutes: i.minutes
        }); });
    };
    return AbsenceSummaryListComponent;
}(BaseSubscriber));
export { AbsenceSummaryListComponent };
