import { BaseSubscriber } from '../../../common/observables/base-subscriber';
import { FilterPropertyPath } from '../../services/filter-config-inner.service';
import { MetadataType, ALL_FILTER_TYPES } from '../../../../models/entity/entity.type';


export abstract class FilterPathBase extends BaseSubscriber {


    isEntityType(propertyPath: FilterPropertyPath) {
        return ALL_FILTER_TYPES.includes(propertyPath.property.type as MetadataType) || propertyPath.entityIdKey;
    }

    isIdKey(key: string): boolean {
        return key === 'id';
    }

}
