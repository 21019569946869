var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LocationStrategy } from '@angular/common';
var ErrorService = /** @class */ (function () {
    function ErrorService(route, location, authService) {
        var _this = this;
        this.route = route;
        this.location = location;
        this.authService = authService;
        this.authService.user$.pipe(map(function (i) { return _this.currentUser = i; })).subscribe();
    }
    ErrorService.prototype.getClientContext = function (error) {
        var shared = this.getShared();
        var status = error.name;
        var message = error.message || error.toString();
        return __assign({}, shared, { status: status,
            message: message, clientError: error });
    };
    ErrorService.prototype.getServerContext = function (error, httpRequest) {
        var shared = this.getShared();
        var status = error.status.toString();
        var message = error.message || error.toString();
        return __assign({}, shared, { status: status,
            message: message, requestUrl: httpRequest.urlWithParams, requestBody: httpRequest.body ? httpRequest.body.toString() : '', requestMethod: httpRequest.method });
    };
    ErrorService.prototype.isHttpError = function (error) {
        if (error instanceof HttpErrorResponse) {
            return error;
        }
        if (this.isPromiseError(error)) {
            return error.rejection;
        }
        return undefined;
    };
    ErrorService.prototype.getShared = function () {
        var fullUrl = this.location.path();
        var route = fullUrl.split('?')[0];
        var routeParameters = this.route.snapshot.params;
        var routeQueryParameters = this.route.snapshot.queryParams;
        var user = this.currentUser;
        return {
            route: route,
            routeParameters: routeParameters,
            routeQueryParameters: routeQueryParameters,
            fullUrl: fullUrl,
            environment: environment.envType,
            user: user
        };
    };
    ErrorService.prototype.isPromiseError = function (error) {
        return error && error.rejection && error.rejection instanceof HttpErrorResponse;
    };
    return ErrorService;
}());
export { ErrorService };
