import { Injectable } from '@angular/core';
import { MatchToEntity, MappingResult } from '../../../models/placement/mapping.model';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import * as moment from 'moment';
import { EntityType } from '../../../models/entity/entity.type';

// note: careful with terminolgy here... whether or not a mapping passes or fails
// is determined by a variety of things
// most notably, what entity it was "matched" to
export interface MatchingRuleViewModel {
    id: number;
    passedIds: number[];
    failedIds: number[];
}

@Injectable()
export class MappingMatchingService {

    constructor() { }

    // REMEMBER YOUR TRAINING LUKE *spooky obi-wan voice* ...use the filters
    // FILTERS are required, however, they can have empty conditions.
    // If they have empty conditions, then they do nothing
    // if the left filter has a condition but doesn't filter the left entity - then it's irrelivant
    // if the left filter has a condition and filters the left then we must proceed
    // if the right filter has a condition...
    //      if the right filter matches then it passes this point
    //      if the right filter doesn't match then it FAILS
    //      if the right filter passes, then we look at the right and left paths (property mapping)
    // if the paths exists...
    //      match the values, if they match then it passes, if they don't then it FAILS
    filterToRelevantResults(matchToEntity: MatchToEntity, results: MappingResult[]): MappingResult[] {

        const relevantResults = results.filter(result => {

            const matchToEntityIsLeft = result.mapping.leftFilter.type === matchToEntity.entityType;
            // this line is obviously unnecessary but for the sake of my brain and english, I'm using it.
            // (it's unncessary because you can just use !matchToEntityIsLeft)
            const matchToEntityIsRight = result.mapping.rightFilter.type === matchToEntity.entityType;

            // if the left type has a filter AND a condition, but doesn't match, then it's irrelivant
            const leftFilterIsRelevant = result.mapping.leftFilter.conditions.length > 0;
            const leftMatch = result.leftValues.find(i => i.id === matchToEntity.id);
            const matchesLeftFilter = !!leftMatch && leftMatch.filter;
            if (
                matchToEntityIsLeft &&
                leftFilterIsRelevant &&
                !matchesLeftFilter
            ) {
                return false;
            }

            // if it has a left filter, but it isn't the type of the left, then it's always relevant
            if (
                matchToEntityIsRight &&
                leftFilterIsRelevant
            ) {
                return true;
            }

            // if it has a right filter, then it's always relvivant
            const rightFilterIsRelevant = result.mapping.rightFilter.conditions.length > 0;
            if (rightFilterIsRelevant) {
                return true;
            }

            // if it gets to this point, it has to check properties, but only if it's not a filter type.
            if (result.mapping.type === 'filters') {
                // I don't think this should ever be able to be hit...
                console.log(`%cthis was hit, I don't think it should be...`, 'color:red', result, results);
                return false;
            }

            // Property mapping always flow left to right
            // if the matchTo entity type isn't the left type, you have to cater for it.
            if (matchToEntityIsRight) { return true; }

            // if it is the left type, it has to have a value.
            if (leftMatch && (!leftMatch.values || leftMatch.values.length === 0)) { return false; }

            // if the matchToType is the left type, we only care if it actually has a value.
            return leftMatch && leftMatch.values && leftMatch.values.length > 0;
        });

        return relevantResults;
    }

    determineMatches(
        matchToEntity: MatchToEntity,
        matchedEntityType: EntityType,
        matchedEntityIds: number[],
        results: MappingResult[]
    ): MatchingRuleViewModel[] {

        const allMappingIds = results.map(i => i.mapping.id);

        const toReturn: MatchingRuleViewModel[] = matchedEntityIds.map(entityId => {
            const allPassedIds = this.getAllMappingMatchIds(matchToEntity, entityId, matchedEntityType, results);
            return {
                id: entityId,
                passedIds: allPassedIds,
                failedIds: allMappingIds.filter(mappingId => !allPassedIds.includes(mappingId))
            };
        });

        return toReturn;
    }


    // if the left filter has a condition but doesn't filter the left entity - then it's irrelivant
    // if the left filter has a condition and filters the left then we must proceed
    // if the right filter has a condition...
    //      if the right filter matches then it passes this point
    //      if the right filter doesn't match then it FAILS
    //      if the right filter passes, then we look at the right and left paths (property mapping)
    // if the paths exists...
    //      match the values, if they match then it passes, if they don't then it FAILS

    getAllMappingMatchIds(
        matchToEntity: MatchToEntity,
        matchedEntityId: number,
        matchedEntityType: EntityType,
        results: MappingResult[]
    ): number[] {

        const passedIds: number[] = results.map(result => {
            const matchToEntityIsLeft = result.mapping.leftFilter.type === matchToEntity.entityType;
            const leftFilterIsRelevant = result.mapping.leftFilter.conditions.length > 0;

            // If this is hit, then it's actually irrelevant, all other cases need to continue to the next check.
            // I only care about the left filter if it's not the matchedToEntityType
            if (leftFilterIsRelevant) {
                const leftFilterMatchValue = matchToEntityIsLeft ?
                    result.leftValues.find(i => i.id === matchToEntity.id) :
                    result.leftValues.find(i => i.id === matchedEntityId);

                const matchesLeftFilter = !!leftFilterMatchValue && leftFilterMatchValue.filter;

                // this is counter-intuitive - if the left filter DOESN'T match, then it is simple not relevant.
                // the left filter
                if (!matchesLeftFilter) {
                    return result.mapping.id;
                }
            }

            // if it has a right filter, then it's always relvevant
            // this means that if this exists and fails then the mapping has failed
            // if it passes, then continue to the next check
            const rightFilterIsRelevant = result.mapping.rightFilter.conditions.length > 0;

            if (rightFilterIsRelevant) {
                const rightFilterMatchValue = matchToEntityIsLeft ?
                    result.rightValues.find(i => i.id === matchedEntityId) :
                    result.rightValues.find(i => i.id === matchToEntity.id);

                const matchesRightFilter = !!rightFilterMatchValue && rightFilterMatchValue.filter;
                if (!matchesRightFilter) {
                    return undefined;
                }
             }

             const propertyMatchIsRelevant = result.mapping.leftPath && result.mapping.rightPath;

             // if it's not relevant at this point, then it's passed.
             if (!propertyMatchIsRelevant) {
                 return result.mapping.id;
             }

             // if it's relevant and passes... well, then it passes.
             if (this.isPropertyMatch(result, matchToEntity, matchedEntityId, matchedEntityType)) {
                 return result.mapping.id;
             }

             // property match was relevant and didn't pass
             return undefined;
        })
        .filter(i => i !== undefined)
        .map(i => i!);

        return passedIds;
    }

    private isPropertyMatch(result: MappingResult, matchToEntity: MatchToEntity, matchedEntityId: number, matchedEntityType: EntityType): boolean {
        const isLeft = result.mapping.leftFilter.type === matchToEntity.entityType;
        // if it gets to this point, we have to check the property paths
        const matchToArray = isLeft ? result.leftValues : result.rightValues;
        const findForMatchTo = matchToArray.find(i => i.id === matchToEntity.id);
        const matchToValues = findForMatchTo ? (findForMatchTo.values || []) : [];
        //
        const otherArray = isLeft ? result.rightValues : result.leftValues;
        const findForOther = otherArray.find(i => i.id === matchedEntityId);
        const otherValues = findForOther ? (findForOther.values || []) : [];
        const intersectionArray = lodashHelper.intersectionBy(matchToValues, otherValues, this.intersectByIteratee);

        // none, all
        if (result.mapping.type === 'leftNone') { return intersectionArray.length === 0; }
        if (result.mapping.type === 'leftAll') { return intersectionArray.length === matchToValues.length; }

        // leftAnyOrLeftEmpty
        if (matchToValues.length === 0 && otherValues.length === 0) { return true; }
        if (!isLeft && otherValues.length === 0) { return true; }
        if (isLeft && matchToValues.length === 0) { return true; }
        return intersectionArray.length > 0;
    }

    // this assumes only three possible types,
    // a basic "SameValueZero" value, a moment,
    // or an object with a "value" property (called a ValueType in the API)
    private intersectByIteratee(x: any) {
        if (typeof(x) !== 'object') {
            return x;
        }

        if (moment.isMoment(x)) {
            // retuns the unix timestamp (milliseconds from epoch)
            return (x as moment.Moment).valueOf();
        }

        // should always have a value, but if it doesn't, I can't do much else...
        return x.value ? x.value : x;
    }
}

