import { Injectable, NgZone } from '@angular/core';
import { IErrorContext } from '../interceptors/error-handler.interceptor';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlerService {

    constructor(
        private router: Router,
        private zone: NgZone
    ) { }

    handleClientError(context: IErrorContext) {
        this.handleRedirect(context);
    }

    handleServerError(context: IErrorContext) {
        // remember that there is an api error interceptor
        // which allows for certain types of http error to be "ignored"
        // this service only handles "global" errors.
        this.handleRedirect(context);
    }

    private handleRedirect(context: IErrorContext) {
        const errorPageParams: Partial<IErrorContext> = {
            message: context.message,
            status: context.status,
            fullUrl: context.fullUrl
        };

        if (NgZone.isInAngularZone()) {
            this.router.navigate(['/error', errorPageParams], { skipLocationChange: true, replaceUrl: true });
        } else {
            this.zone.run(() => {
                this.router.navigate(['/error', errorPageParams], { skipLocationChange: true, replaceUrl: true });
            });
        }
    }
}
