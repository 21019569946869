var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CpProperty, CpClass } from '../../modules/core-configuration/decorators/metadata.decorator';
import { MinutesPipe } from '../../modules/display-helper/pipes/minutes.pipe';
import { AbsenceSummaryListComponent } from '../../modules/absence-type/components/absence-summary/absence-summary-list.component';
import { MinutesSummaryDynamicComponent } from '../../modules/display-helper/components/minutes/minutes-summary-dynamic.component';
export var ALL_SUMMARY_TYPES = ['placementSummary', 'providerSummary'];
export var ALL_SUMMARY_TYPES_PLURAL = ['placementSummaries', 'providerSummaries'];
var SummaryBase = /** @class */ (function () {
    function SummaryBase() {
    }
    __decorate([
        CpProperty({ label: 'History Count', type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], SummaryBase.prototype, "changeCount", void 0);
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], SummaryBase.prototype, "noteCount", void 0);
    return SummaryBase;
}());
export { SummaryBase };
var ProviderSummary = /** @class */ (function (_super) {
    __extends(ProviderSummary, _super);
    function ProviderSummary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], ProviderSummary.prototype, "providerId", void 0);
    __decorate([
        CpProperty({ type: 'int32' }),
        __metadata("design:type", Number)
    ], ProviderSummary.prototype, "contactCount", void 0);
    __decorate([
        CpProperty({ type: 'int32' }),
        __metadata("design:type", Number)
    ], ProviderSummary.prototype, "positionCount", void 0);
    __decorate([
        CpProperty({ type: 'int32' }),
        __metadata("design:type", Number)
    ], ProviderSummary.prototype, "siteCount", void 0);
    ProviderSummary = __decorate([
        CpClass({
            create: newProviderSummary,
            metadataType: 'providerSummary'
        })
    ], ProviderSummary);
    return ProviderSummary;
}(SummaryBase));
export { ProviderSummary };
// todo: change this when fixed in back end
var AbsenceSummary = /** @class */ (function () {
    function AbsenceSummary() {
    }
    return AbsenceSummary;
}());
export { AbsenceSummary };
var PlacementSummary = /** @class */ (function (_super) {
    __extends(PlacementSummary, _super);
    function PlacementSummary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PlacementSummary.prototype, "hoursSummary", {
        get: function () {
            return {
                attended: this.timesheetMinutesAttended,
                absent: this.timesheetMinutesAbsent
            };
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        CpProperty({ type: 'dynamic', dynamicComponent: MinutesSummaryDynamicComponent }),
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], PlacementSummary.prototype, "hoursSummary", null);
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], PlacementSummary.prototype, "placementId", void 0);
    __decorate([
        CpProperty({ label: 'Attended Hours', type: 'int32', pipe: { pipe: new MinutesPipe() } }),
        __metadata("design:type", Number)
    ], PlacementSummary.prototype, "timesheetMinutesAttended", void 0);
    __decorate([
        CpProperty({ label: 'Absent Hours', type: 'dynamic', dynamicComponent: AbsenceSummaryListComponent }),
        __metadata("design:type", AbsenceSummary)
    ], PlacementSummary.prototype, "timesheetAbsences", void 0);
    __decorate([
        CpProperty({ label: 'Absent Hours Total', type: 'int32', pipe: { pipe: new MinutesPipe() } }),
        __metadata("design:type", Number)
    ], PlacementSummary.prototype, "timesheetMinutesAbsent", void 0);
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], PlacementSummary.prototype, "timesheetCount", void 0);
    __decorate([
        CpProperty({ label: 'Timesheet Max Date', type: 'date' }),
        __metadata("design:type", Object)
    ], PlacementSummary.prototype, "timesheetDateMin", void 0);
    __decorate([
        CpProperty({ label: 'Timesheet Min Date', type: 'date' }),
        __metadata("design:type", Object)
    ], PlacementSummary.prototype, "timesheetDateMax", void 0);
    PlacementSummary = __decorate([
        CpClass({
            create: newPlacementSummary,
            metadataType: 'placementSummary'
        })
    ], PlacementSummary);
    return PlacementSummary;
}(SummaryBase));
export { PlacementSummary };
export function newProviderSummary() { return new ProviderSummary(); }
export function newPlacementSummary() { return new PlacementSummary(); }
