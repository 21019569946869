import { Injectable } from '@angular/core';
import { CrudEntityServiceBase } from '../../entity-shared/base/entity-service.base';
import { ResponseNoInclude } from '../../../models/api/response.model';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { SavedSearchHttpService } from './http/saved-search.http.service';
import { SavedSearch } from '../../../models/saved-search/saved-search.model';
import { NoIncludeOption } from '../../../models/shared/include.model';

@Injectable()
export class SavedSearchService
    extends CrudEntityServiceBase<SavedSearch, NoIncludeOption, ResponseNoInclude>
    implements IUpdatableService<SavedSearch> {

    constructor(
        protected httpService: SavedSearchHttpService
    ) {
        super(httpService);
     }
}
