var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Address } from '../shared/address.model';
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IncludeEntityMetadata } from '../shared/metadata.model';
var logInclude = new IncludeEntityMetadata('entity', 'entities', 'id', 'id');
var studentInclude = new IncludeEntityMetadata('student', 'students', 'studentId', 'id');
var StudentAddress = /** @class */ (function (_super) {
    __extends(StudentAddress, _super);
    function StudentAddress() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StudentAddress.prototype.toString = function () {
        return "" + this.address + (this.postcode ? ', ' + this.postcode : '');
    };
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], StudentAddress.prototype, "studentId", void 0);
    StudentAddress = __decorate([
        CpClass({
            include: [studentInclude, logInclude],
            metadataType: 'studentAddress',
            defaultSort: [{ key: 'studentId' }],
            create: newStudentAddress
        })
    ], StudentAddress);
    return StudentAddress;
}(Address));
export { StudentAddress };
export function newStudentAddress() { return new StudentAddress(); }
