import { PROPERTY_METADATA_DECORATOR_KEY, CLASS_METADATA_DECORATOR_KEY } from '../../decorators/metadata.decorator';
var MetadataService = /** @class */ (function () {
    function MetadataService(x) {
        this._propertyMetadata = Reflect.getMetadata(PROPERTY_METADATA_DECORATOR_KEY, x);
        this._classMetadata = Reflect.getOwnMetadata(CLASS_METADATA_DECORATOR_KEY, x);
        if (!this._classMetadata) {
            throw new Error('class metadata is required for list descriptor service: ' + x.name);
        }
        var proto = Reflect.getPrototypeOf(x);
        var childProperties = Reflect.getMetadata(PROPERTY_METADATA_DECORATOR_KEY, proto);
        do {
            this._propertyMetadata = Object.assign({}, childProperties, this._propertyMetadata);
            proto = Reflect.getPrototypeOf(proto);
            childProperties = Reflect.getMetadata(PROPERTY_METADATA_DECORATOR_KEY, proto);
        } while (childProperties !== undefined);
        // console.log('metadata', x.name, this._classMetadata, this._propertyMetadata);
    }
    Object.defineProperty(MetadataService.prototype, "propertyMetadata", {
        get: function () {
            return this._propertyMetadata;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetadataService.prototype, "classMetadata", {
        get: function () {
            return this._classMetadata;
        },
        enumerable: true,
        configurable: true
    });
    return MetadataService;
}());
export { MetadataService };
