import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertResult, Alert } from '../../../../models/alert/alert.model';
import { ResponseModel } from '../../../../models/api/response.model';
import { HttpClient } from '@angular/common/http';
import { ApiHttpClientService } from '../../../api/services/api-http-client.service';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { RequestSearchOptions } from '../../../../models/api/request-list-options.model';


@Injectable()
export class AlertHttpService extends ApiHttpClientService<Alert, any, any> {

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super('alerts', httpClient, apiRequestHelperService);
    }

    results(options: RequestSearchOptions): Observable<ResponseModel<AlertResult[]>> {
        const url = this.generateUrl({ path: ['results'], options: options });

        return this.httpClient.get<ResponseModel<AlertResult[]>>(url);
    }
}
