import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ReportService } from './services/report.service';
import { ReportHttpService } from './services/http/report.http.service';
import { ReportParameterDirective } from './directives/report-parameter.directive';
import { ReportTableComponent } from './components/report-table/report-table.component';
import { ReportColumnSelectorComponent } from './components/report-column-selector/report-column-selector.component';
import { ReportSessionService } from './services/report-session.service';
import { ReportValuePipe } from './pipes/report-value.pipe';
import { ReportCsvDropdownComponent } from './components/report-csv-dropdown/report-csv-dropdown.component';
import { ReportParameterSimpleComponent } from './components/report-parameter/report-parameter-simple.component';


@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        ReportService,
        ReportSessionService,

        ReportHttpService
    ],
    declarations: [
        ReportParameterDirective,

        ReportValuePipe,

        ReportTableComponent,
        ReportColumnSelectorComponent,
        ReportCsvDropdownComponent,
        ReportParameterSimpleComponent
    ],
    exports: [
        ReportParameterDirective,

        ReportTableComponent,
        ReportCsvDropdownComponent,
        ReportParameterSimpleComponent
    ]
})
export class ReportModule { }
