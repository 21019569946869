import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseStudentInclude, ResponseModel, ResponseModelBulk, ResponseStudentPlanUpdate } from '../../../../models/api/response.model';
import { Student, StudentResult } from '../../../../models/student/student.model';
import { RequestSearchOptions } from '../../../../models/api/request-list-options.model';
import { ApiHttpClientService } from '../../../api/services/api-http-client.service';
import { HttpClient } from '@angular/common/http';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
import { StudentIncludeOption } from '../../../../models/shared/include.model';

@Injectable()
export class StudentHttpService extends ApiHttpClientService<Student, StudentIncludeOption, ResponseStudentInclude> {

    constructor(
        protected httpClient: HttpClient,
        protected apiRequestHelperService: ApiRequestHelperService
    ) {
        super('students', httpClient, apiRequestHelperService);
    }

    search(options: RequestSearchOptions): Observable<ResponseModel<StudentResult[]>> {
        const url = this.generateUrl({ options: options, path: ['search'] });

        return this.httpClient.get<ResponseModel<StudentResult[]>>(url);
    }

    addToPlan(planId: number, studentIds: number[]): Observable<ResponseStudentPlanUpdate> {
        const url =  this.generateUrl({ path: ['plans', 'add', planId]});

        return this.httpClient.post<ResponseStudentPlanUpdate>(url, { studentIds: studentIds });
    }

    removeFromPlan(planId: number, studentIds: number[]): Observable<ResponseStudentPlanUpdate> {
        const url =  this.generateUrl({ path: ['plans', 'remove', planId]});

        return this.httpClient.post<ResponseStudentPlanUpdate>(url, { studentIds: studentIds });
    }

    bulk(students: Partial<Student>[]): Observable<ResponseModelBulk<Student>> {
        const url =  this.generateUrl({ path: ['bulk'] });

        return this.httpClient.post<ResponseModelBulk<Student>>(url, students);
    }
}
