var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { ConfigurationService } from '../../../core-configuration/services/configuration.service';
import { ConfigurationEntityListItemBase } from '../entity-list-item/configuration-entity-list-item.base';
var AbsenceTypeEntityListItemComponent = /** @class */ (function (_super) {
    __extends(AbsenceTypeEntityListItemComponent, _super);
    function AbsenceTypeEntityListItemComponent(configService, changeDetectionRef) {
        var _this = _super.call(this, 'absenceType', configService, changeDetectionRef) || this;
        _this.configService = configService;
        _this.changeDetectionRef = changeDetectionRef;
        return _this;
    }
    return AbsenceTypeEntityListItemComponent;
}(ConfigurationEntityListItemBase));
export { AbsenceTypeEntityListItemComponent };
