/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../error/error.component.ngfactory";
import * as i2 from "../error/error.component";
import * as i3 from "./loader-error.component";
var styles_LoaderErrorComponent = [];
var RenderType_LoaderErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoaderErrorComponent, data: {} });
export { RenderType_LoaderErrorComponent as RenderType_LoaderErrorComponent };
export function View_LoaderErrorComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-error", [], null, null, null, i1.View_ErrorComponent_0, i1.RenderType_ErrorComponent)), i0.ɵdid(1, 573440, null, 0, i2.ErrorComponent, [], { error: [0, "error"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoaderErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-loader-error", [], null, null, null, View_LoaderErrorComponent_0, RenderType_LoaderErrorComponent)), i0.ɵdid(1, 49152, null, 0, i3.LoaderErrorComponent, [], null, null)], null, null); }
var LoaderErrorComponentNgFactory = i0.ɵccf("cp-loader-error", i3.LoaderErrorComponent, View_LoaderErrorComponent_Host_0, { error: "error" }, {}, []);
export { LoaderErrorComponentNgFactory as LoaderErrorComponentNgFactory };
