/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../display-helper/pipes/minutes.pipe";
import * as i3 from "../../../display-helper/directives/loader/loader.directive";
import * as i4 from "./absence-summary-list.component";
import * as i5 from "../../../core-configuration/services/configuration.service";
var styles_AbsenceSummaryListComponent = [];
var RenderType_AbsenceSummaryListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AbsenceSummaryListComponent, data: {} });
export { RenderType_AbsenceSummaryListComponent as RenderType_AbsenceSummaryListComponent };
function View_AbsenceSummaryListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "d-flex align-items-end"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "mr-3 badge badge-outline-secondary"]], [[2, "mt-1", null]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "ml-auto"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵppd(5, 1)], null, function (_ck, _v) { var currVal_0 = !_v.context.first; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.minutes)); _ck(_v, 4, 0, currVal_2); }); }
function View_AbsenceSummaryListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AbsenceSummaryListComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewModel; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AbsenceSummaryListComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.MinutesPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AbsenceSummaryListComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.LoaderDirective, [i0.TemplateRef, i0.ViewContainerRef, i0.ComponentFactoryResolver], { cpLoadingSmall: [0, "cpLoadingSmall"], cpLoading: [1, "cpLoading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = !_co.loaded; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AbsenceSummaryListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-absence-summary-list", [], null, null, null, View_AbsenceSummaryListComponent_0, RenderType_AbsenceSummaryListComponent)), i0.ɵdid(1, 770048, null, 0, i4.AbsenceSummaryListComponent, [i5.ConfigurationService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AbsenceSummaryListComponentNgFactory = i0.ɵccf("cp-absence-summary-list", i4.AbsenceSummaryListComponent, View_AbsenceSummaryListComponent_Host_0, { dynamicValue: "dynamicValue" }, {}, []);
export { AbsenceSummaryListComponentNgFactory as AbsenceSummaryListComponentNgFactory };
