import { Component, Input, OnInit, ChangeDetectionStrategy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ResponseModelError } from '../../../../models/api/response.model';

@Component({
    selector: 'cp-progress-modal',
    templateUrl: 'progress.modal.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProgressModalComponent implements OnInit, OnChanges {
    @Input() title: string;
    @Input() message: string;

    @Input() progress = 0;
    @Input() error: ResponseModelError;

    @Output() close = new EventEmitter();
    @Output() dismiss = new EventEmitter();

    constructor() {

    }

    ngOnInit() {
        if (!this.title) {
            this.title = this.error ? 'Error' : 'Progress';
        }
        if (!this.message) {
            this.message = this.error ? 'An unexpected error occurred' : '';
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.progress && this.progress >= 100) {
            this.close.emit();
        }
    }

    onDismiss() {
        this.dismiss.emit();
    }
}
