/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./property-display.component";
var styles_PropertyDisplayComponent = [];
var RenderType_PropertyDisplayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PropertyDisplayComponent, data: {} });
export { RenderType_PropertyDisplayComponent as RenderType_PropertyDisplayComponent };
export function View_PropertyDisplayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "row d-flex align-items-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-2 property-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "small", [["class", "text-muted"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "col-sm-10"]], null, null, null, null, null)), i0.ɵncd(null, 1)], null, null); }
export function View_PropertyDisplayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-property-display", [["class", "d-block property-display"]], [[2, "property-display-margin", null], [2, "border-light", null], [2, "border-bottom", null]], null, null, View_PropertyDisplayComponent_0, RenderType_PropertyDisplayComponent)), i0.ɵdid(1, 114688, null, 0, i1.PropertyDisplayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).margin; var currVal_1 = i0.ɵnov(_v, 1).borderColor; var currVal_2 = i0.ɵnov(_v, 1).borderBottom; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var PropertyDisplayComponentNgFactory = i0.ɵccf("cp-property-display", i1.PropertyDisplayComponent, View_PropertyDisplayComponent_Host_0, { removeMargin: "removeMargin", removeBorder: "removeBorder" }, {}, ["[label]", "[property]"]);
export { PropertyDisplayComponentNgFactory as PropertyDisplayComponentNgFactory };
