import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModalModule, NgbActiveModal, NgbTypeaheadModule, NgbCollapseModule, NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from './modals/confirm/confirm.modal';
import { ClickOutsideModule } from 'ng-click-outside';
import { ExtendedPropertiesDetailsComponent } from './components/extended-properties/extended-properties-details.component';
import { PaginationEntityComponent } from './components/pagination/pagination-entity.component';
import { ActivatedRouteComponentService } from './services/activated-route-component.service';
import { MomentModule } from 'angular2-moment';
import { BulkSummaryModalComponent } from './modals/bulk-summary/bulk-summary.modal';
import { QuerySearchComponent } from './components/query-search/query-search.component';
import { QueryDateRangeComponent } from './components/query-date-range/query-date-range.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PaginationLocalComponent } from './components/pagination/pagination-local.component';
import { AddressComponent } from './components/address/address.component';
import { CustomFormsModule } from '../form/form.module';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { CoreConfigurationModule } from '../core-configuration/core-configuration.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { ResponseSortService } from './services/response-sort-service';
import { ProgressModalComponent } from './modals/progress/progress.modal';

@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    entryComponents: [
        ConfirmModalComponent,
        BulkSummaryModalComponent,
        ProgressModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        CoreConfigurationModule,

        MomentModule,

        AuthenticationModule.forChild(),
        DisplayHelperModule,
        CustomFormsModule,

        NgbModalModule,
        NgbTypeaheadModule,
        NgbCollapseModule,
        NgbDatepickerModule,
        NgbTooltipModule,

        ClickOutsideModule
    ],
    declarations: [

        ConfirmModalComponent,
        BulkSummaryModalComponent,
        ProgressModalComponent,

        PaginationEntityComponent,
        PaginationLocalComponent,
        PaginationComponent,

        QuerySearchComponent,

        QueryDateRangeComponent,

        ExtendedPropertiesDetailsComponent,
        AddressComponent
    ],
    providers: [],
    exports: [
        // modules
        CommonModule,
        FormsModule,
        RouterModule,

        ClickOutsideModule,
        MomentModule,

        NgbModalModule,
        NgbTypeaheadModule,
        NgbCollapseModule,
        NgbDatepickerModule,
        NgbTooltipModule,

        // components

        ExtendedPropertiesDetailsComponent,

        ConfirmModalComponent,
        BulkSummaryModalComponent,
        ProgressModalComponent,

        PaginationEntityComponent,
        PaginationLocalComponent,
        PaginationComponent,

        QueryDateRangeComponent,

        QuerySearchComponent,

        ExtendedPropertiesDetailsComponent,
        AddressComponent
    ]
})
// has to include the "custom" prefix as "common" is already taken by angular
export class CustomCommonModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CustomCommonModule,
            providers: [
                NgbActiveModal,
                ActivatedRouteComponentService,
                ResponseSortService
            ]
        };
    }

    static forChild(): ModuleWithProviders {
        return {
            ngModule: CustomCommonModule
        };
    }
}
