import { ALL_DATE_INTERVAL_VALUES } from '../../../models/filters/filter.model';
import { INTERVAL_PATTERN_LITERAL } from '../../common/constants/pattern.constants';
var IntervalHelper = /** @class */ (function () {
    function IntervalHelper() {
    }
    Object.defineProperty(IntervalHelper.prototype, "today", {
        get: function () {
            return '0day';
        },
        enumerable: true,
        configurable: true
    });
    IntervalHelper.prototype.stringOrResultToInterval = function (str) {
        if (!str) {
            return undefined;
        }
        if (typeof (str) === 'object') {
            if (!str.value) {
                return undefined;
            }
            str = str.value;
        }
        // handle intervals
        var intervalRegex = str.match(INTERVAL_PATTERN_LITERAL);
        if (intervalRegex !== null && ALL_DATE_INTERVAL_VALUES.includes(intervalRegex[2])) {
            return {
                number: +intervalRegex[1],
                interval: intervalRegex[2]
            };
        }
        return undefined;
    };
    IntervalHelper.prototype.intervalToString = function (interval) {
        return "" + interval.number + interval.interval;
    };
    IntervalHelper.prototype.updateStringIntervalValue = function (value, interval) {
        var intervalObj = this.stringOrResultToInterval(value);
        if (!intervalObj) {
            return;
        }
        intervalObj.interval = interval;
        return this.intervalToString(intervalObj);
    };
    return IntervalHelper;
}());
export { IntervalHelper };
