/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./minutes-summary.component.ngfactory";
import * as i2 from "./minutes-summary.component";
import * as i3 from "@angular/common";
import * as i4 from "./minutes-summary-dynamic.component";
var styles_MinutesSummaryDynamicComponent = [];
var RenderType_MinutesSummaryDynamicComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MinutesSummaryDynamicComponent, data: {} });
export { RenderType_MinutesSummaryDynamicComponent as RenderType_MinutesSummaryDynamicComponent };
function View_MinutesSummaryDynamicComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-minutes-summary", [], null, null, null, i1.View_MinutesSummaryComponent_0, i1.RenderType_MinutesSummaryComponent)), i0.ɵdid(1, 49152, null, 0, i2.MinutesSummaryComponent, [], { value: [0, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.summary; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MinutesSummaryDynamicComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MinutesSummaryDynamicComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.summary; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MinutesSummaryDynamicComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-minutes-summary-dynamic", [], null, null, null, View_MinutesSummaryDynamicComponent_0, RenderType_MinutesSummaryDynamicComponent)), i0.ɵdid(1, 114688, null, 0, i4.MinutesSummaryDynamicComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MinutesSummaryDynamicComponentNgFactory = i0.ɵccf("cp-minutes-summary-dynamic", i4.MinutesSummaryDynamicComponent, View_MinutesSummaryDynamicComponent_Host_0, {}, {}, []);
export { MinutesSummaryDynamicComponentNgFactory as MinutesSummaryDynamicComponentNgFactory };
