import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './guards/authentication.guard';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';
import { AuthenticationHttpService } from './services/http/authentication.http.service';
import { ProfileNameComponent } from './components/profile-name/profile-name.component';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { IfAuthedDirective } from './directives/if-authed/if-authed.directive';
import { CoreConfigurationModule } from '../core-configuration/core-configuration.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,

        CoreConfigurationModule,
    ],
    providers: [],
    declarations: [
        LogoutButtonComponent,
        ProfileNameComponent,

        IfAuthedDirective
    ],
    exports: [
        LogoutButtonComponent,
        ProfileNameComponent,

        IfAuthedDirective
    ]
})
export class AuthenticationModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AuthenticationModule,
            providers: [
                AuthGuard,
                AuthenticationInterceptor,
                AuthorizationInterceptor,

                AuthenticationHttpService
            ]
        };
    }

    static forChild(): ModuleWithProviders {
        return {
            ngModule: AuthenticationModule
        };
    }
}
