import { minutesToHoursDisplay } from '../../pipes/minutes.pipe';
var MinutesSummaryComponent = /** @class */ (function () {
    function MinutesSummaryComponent() {
    }
    Object.defineProperty(MinutesSummaryComponent.prototype, "total", {
        get: function () {
            var timesheetTotal = this.value.attended + this.value.absent;
            if (timesheetTotal > this.value.expected) {
                return timesheetTotal;
            }
            return this.value.expected || 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MinutesSummaryComponent.prototype, "unaccounted", {
        get: function () {
            if (this.total > this.value.expected) {
                return 0;
            }
            return this.value.expected - this.value.attended - this.value.absent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MinutesSummaryComponent.prototype, "absentWidth", {
        get: function () {
            if (this.total === 0) {
                return 0;
            }
            return this.value.absent / this.total * 100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MinutesSummaryComponent.prototype, "attendedWidth", {
        get: function () {
            if (this.total === 0) {
                return 0;
            }
            var max = this.value.expected && this.value.attended > this.value.expected ?
                this.value.expected : this.value.attended;
            return max / this.total * 100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MinutesSummaryComponent.prototype, "unaccountedWidth", {
        get: function () {
            if (this.unaccounted === 0) {
                return 0;
            }
            return this.unaccounted / this.total * 100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MinutesSummaryComponent.prototype, "title", {
        get: function () {
            var array = [
                "Expected hours: " + (minutesToHoursDisplay(this.value.expected) || 0),
                "Attended hours: " + (minutesToHoursDisplay(this.value.attended) || 0),
                "Absent hours: " + (minutesToHoursDisplay(this.value.absent) || 0),
                "Unaccounted hours: " + (minutesToHoursDisplay(this.unaccounted) || 0)
            ];
            return array.join('\r\n');
        },
        enumerable: true,
        configurable: true
    });
    return MinutesSummaryComponent;
}());
export { MinutesSummaryComponent };
