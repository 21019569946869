import { Injectable } from '@angular/core';
import { ContactHttpService } from './http/contact.http.service';
import { Contact } from '../../../models/provider/contact.model';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { ResponseProviderSubEntityInclude } from '../../../models/api/response.model';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { IListService, IIdListService } from '../../entity-shared/base/entity-search.base';
import { IMergeService } from '../../entity-shared/components/entity-merge/entity-merge.base.component';
import { ProviderBaseService } from './provider-base.service';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { ProviderSubEntityIncludeOption } from '../../../models/shared/include.model';


@Injectable()
export class ContactService
    extends
    ProviderBaseService<Contact, ProviderSubEntityIncludeOption, ResponseProviderSubEntityInclude>
    implements
    IUpdatableService<Contact>,
    IListService<Contact, ProviderSubEntityIncludeOption, ResponseProviderSubEntityInclude>,
    IIdListService,
    IMergeService<Contact> {

    protected includeOptions: ProviderSubEntityIncludeOption[] = ['providers', 'sites'];

    constructor(
        protected httpService: ContactHttpService,
        protected apiCacheService: ApiCacheService,
        protected includeMapperService: IncludeMapperService
    ) {
        super('contact', httpService, apiCacheService, includeMapperService);
    }

    protected createEntity = () => new Contact();

    protected onAfterCreate(entity: Contact): RequestFilter[] | void {
        this.apiCacheService.clearCacheByEntityId('providers', entity.providerId);
        return [];
    }
    protected onAfterUpdate(entity: Contact): void {
        // have to just clear provider cache here because of the
        // 'joining table' means that both site and contact needs to be updated.
        // the above now happens in the base class :/

        // archivable entities need to clear master types just to be safe
        this.apiCacheService.clearCacheByType('providers');
        // needs to happen because of site.contactId array cache
        this.apiCacheService.clearCacheByType(this.typePlural);
        // contacts update can change results for a site call, so sites have to be cleared too
        this.apiCacheService.clearCacheByType('sites');
    }
}
