import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IProvisionable, EntityBase } from '../entity/entity.model';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { EntityLog } from '../shared/logged.model';
import { Moment } from 'moment';

const logInclude = new IncludeEntityMetadata<StudentCourse, EntityLog>('entity', 'entities', 'id', 'id');

@CpClass({
    include: [logInclude],
    metadataType: 'studentCourse',
    defaultSort: [{ key: 'start', desending: true }],
    create: newStudentCourse
})
export class StudentCourse extends EntityBase implements IProvisionable {
    @CpProperty({ type: 'int32', hideFromList: true })
    studentId: number;

    @CpProperty({ type: 'string' })
    name: string;

    @CpProperty({ type: 'boolean' })
    current: boolean;

    @CpProperty({ type: 'date' })
    start: Moment;

    @CpProperty({ type: 'date' })
    end: Moment;

    provisioningKey?: string;
    provisioned?: boolean;
    provisionedClientId?: string;
}

export function newStudentCourse() { return new StudentCourse(); }
