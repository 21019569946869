import { NgModule } from '@angular/core';
import { FilterInputComponent } from './components/filter-input/filter-input.component';
import { FilterConditionLabelComponent } from './components/filter-condition/filter-condition-label.component';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { CustomFormsModule } from '../form/form.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreConfigurationModule } from '../core-configuration/core-configuration.module';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { FilterConfigHelperService } from './services/filter-config-helper.service';
import { FilterDropdownItems } from './components/filter-dropdown/filter-dropdown-items.component';
import { FilterPathControllerComponent } from './components/filter-path/filter-path-controller.component';
import { FilterDropdownSearchButtonComponent } from './components/filter-dropdown/filter-dropdown-search-button.component';
import { FilterSearchFormComponent } from './components/filter-search/filter-search-form.component';
import { FilterConditionComponent } from './components/filter-condition/filter-condition.component';
import { FilterPathConditionComponent } from './components/filter-path-condition/filter-path-condition.component';
import { FilterConditionHelperService } from './services/filter-condition-helper.service';
import { FilterQueryHelperService } from './services/filter-query-helper.service';
import { FilterConfigInnerService } from './services/filter-config-inner.service';
import { EntityFilterListService } from './services/entity-filter-list.service';
import { FilterPathHelperService } from './services/filter-path-helper.service';
import { ResponseFilterService } from './services/response-filter.service';
import { CustomCommonModule } from '../common/common.module';
import { ResponseFilterCacheService } from './services/response-filter-cache.service';
import { FilterComparisionService } from './services/filter-comparision.service';
import { FilterPathDisplayComponent } from './components/filter-path/filter-path-display.component';
import { FilterDisplayComponent } from './components/filter-display/filter-display.component';
import { FilterConditionDisplayComponent } from './components/filter-condition/filter-condition-display.component';
import { FilterDisplayDynamic } from './components/filter-display/filter-display-dynamic.component';

@NgModule({
    entryComponents: [
        FilterDisplayDynamic
    ],
    imports: [
        CommonModule,
        RouterModule,

        CustomFormsModule,
        CustomCommonModule.forChild(),
        DisplayHelperModule,
        CoreConfigurationModule
    ],
    providers: [
        FilterConfigInnerService,
        FilterConfigHelperService,
        FilterConditionHelperService,
        FilterQueryHelperService,
        EntityFilterListService,
        ResponseFilterService,
        FilterPathHelperService,
        ResponseFilterCacheService,
        FilterComparisionService
    ],
    declarations: [
        FilterInputComponent,
        FilterConditionLabelComponent,

        FilterDropdownComponent,
        FilterConditionComponent,
        FilterPathControllerComponent,
        FilterDropdownItems,
        FilterDropdownSearchButtonComponent,
        FilterSearchFormComponent,
        FilterPathConditionComponent,
        FilterPathDisplayComponent,
        FilterDisplayComponent,
        FilterDisplayDynamic,
        FilterConditionDisplayComponent
    ],
    exports: [
        FilterInputComponent,
        FilterConditionLabelComponent,

        FilterDropdownComponent,
        FilterConditionComponent,
        FilterPathControllerComponent,
        FilterDropdownSearchButtonComponent,
        FilterSearchFormComponent,
        FilterPathDisplayComponent,
        FilterDisplayComponent,
        FilterDisplayDynamic,
        FilterConditionDisplayComponent
    ]
})
export class FilterModule { }
