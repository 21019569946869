import { Injectable } from '@angular/core';
import { ResponseQuery } from '../../../models/api/response.model';
import { BehaviorSubject } from 'rxjs';
import { HttpRequest } from '@angular/common/http';
import { ResponseFiltersQuery } from '../../../models/report/report.model';

export interface ResponseQueryEvent {
    request: HttpRequest<any>;
    responseQuery: ResponseQuery;
}

export interface ResponseReportQueryEvent {
    request: HttpRequest<any>;
    responseFiltersQuery: ResponseFiltersQuery;
}

@Injectable()
export class ResponseQueryService {

    private _queryResponse: ResponseQueryEvent;
    private _queryResponseSource = new BehaviorSubject<ResponseQueryEvent>(this._queryResponse);
    response$ = this._queryResponseSource.asObservable();

    private _reportResponse: ResponseReportQueryEvent;
    private _reportResponseSource = new BehaviorSubject<ResponseReportQueryEvent>(this._reportResponse);
    reportResponse$ = this._reportResponseSource.asObservable();

    constructor() { }

    trigger(request: HttpRequest<any>, query: ResponseQuery) {
        this._queryResponse = {
            request: request,
            responseQuery: query
        };
        this._queryResponseSource.next(this._queryResponse);
    }

    triggerReportResponse(request: HttpRequest<any>, responseFiltersQuery: ResponseFiltersQuery) {
        this._reportResponse = {
            request: request,
            responseFiltersQuery: responseFiltersQuery
        };
        this._reportResponseSource.next(this._reportResponse);
    }
}
