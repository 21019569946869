var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EMPTY } from 'rxjs';
import { map, expand, concatAll, toArray } from 'rxjs/operators';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
var EntityServiceBase = /** @class */ (function () {
    function EntityServiceBase(httpService) {
        this.httpService = httpService;
    }
    EntityServiceBase.prototype.get = function (id) {
        return this.httpService.get([id], []).pipe(map(function (i) { return i.data; }));
    };
    EntityServiceBase.prototype.listInclude = function (options) {
        return this.httpService.list(options);
    };
    // be careful using this function, it will continuously call the API based on the
    // options until all pages are hit
    // at it also bypasses the cache -- for now
    EntityServiceBase.prototype.listIncludeGetAllStream = function (options) {
        var _this = this;
        return this.getWithNext(options).pipe(expand(function (_a) {
            var next = _a.next;
            return next ? _this.getWithNext(next) : EMPTY;
        }), map(function (_a) {
            var response = _a.response;
            return response;
        }));
    };
    EntityServiceBase.prototype.listIncludeGetAll = function (options) {
        return this.listIncludeGetAllStream(options).pipe(map(function (response) { return response.data; }), concatAll(), toArray());
    };
    EntityServiceBase.prototype.getWithNext = function (options) {
        var _this = this;
        return this.listInclude(options).pipe(map(function (response) { return ({
            response: response,
            next: _this.nextListResponse(response, options)
        }); }));
    };
    EntityServiceBase.prototype.nextListResponse = function (response, options) {
        var nextOptions;
        var skipTake = response.query.skip + response.query.take;
        if (skipTake < response.total) {
            nextOptions = lodashHelper.cloneDeep(options);
            nextOptions.skip = skipTake;
        }
        return nextOptions;
    };
    return EntityServiceBase;
}());
export { EntityServiceBase };
var CrudEntityServiceBase = /** @class */ (function (_super) {
    __extends(CrudEntityServiceBase, _super);
    function CrudEntityServiceBase(httpService) {
        var _this = _super.call(this, httpService) || this;
        _this.httpService = httpService;
        return _this;
    }
    CrudEntityServiceBase.prototype.create = function (entity) {
        return this.httpService.create(entity).pipe(map(function (i) { return i.data; }));
    };
    CrudEntityServiceBase.prototype.update = function (entity) {
        return this.httpService.update(entity).pipe(map(function (i) { return i.data; }));
    };
    CrudEntityServiceBase.prototype.updatePartial = function (entity) {
        return this.httpService.update(entity).pipe(map(function (i) { return i.data; }));
    };
    CrudEntityServiceBase.prototype.delete = function (entity) {
        return this.httpService.delete([entity.id]);
    };
    return CrudEntityServiceBase;
}(EntityServiceBase));
export { CrudEntityServiceBase };
