import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IIncludeEntity, ProviderSubEntityIncludeType } from '../shared/include.model';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { Provider } from './provider.model';
import { Site } from './site.model';
import { Position } from './position.model';
import { EntityLog } from '../shared/logged.model';
import { UserEntity } from '../user/user-entity.model';

const providerInclude = new IncludeEntityMetadata<Contact, Provider>('provider', 'providers', 'providerId', 'id');
const logInclude = new IncludeEntityMetadata<Contact, EntityLog>('entity', 'entities', 'id', 'id');

const sitesInclude = new IncludeEntityMetadata<Contact, Site>('site', 'sites', 'siteIds', 'id');
const positionsInclude = new IncludeEntityMetadata<Contact, Position>('position', 'positions', 'id', 'contactIds');

@CpClass<Contact>({
    include: [providerInclude, logInclude],
    includeMany: [sitesInclude, positionsInclude],
    metadataType: 'contact',
    defaultSort: [{ key: 'lastName' }, { key: 'firstName' }],
    create: newContact
})
export class Contact extends UserEntity implements IIncludeEntity<ProviderSubEntityIncludeType> {

    @CpProperty({ type: 'int32' })
    providerId: number;

    @CpProperty({ type: 'int32' })
    siteIds: number[];

    constructor() {
        super();

        this.siteIds = [];
    }
}

export function newContact() { return new Contact(); }
