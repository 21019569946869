import * as moment from 'moment';
import { Position } from '../provider/position.model';
import { Block } from '../plan/block.model';
import { Student } from '../student/student.model';
import { Plan } from '../plan/plan.model';
import { Site } from '../provider/site.model';
import { Provider } from '../provider/provider.model';
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IIncludeEntity, PlacementIncludeType } from '../shared/include.model';
import { IncludeEntityMetadata, IncludeEntityMetadataCore } from '../shared/metadata.model';
import { Experience } from '../experience/experience.model';
import { PlacementSummary } from '../summary/summary.model';
import { MinutesPipe } from '../../modules/display-helper/pipes/minutes.pipe';
import { ExperienceEntityListItemComponent } from '../../modules/configuration-entity-shared/components/experience-entity-list-item/experience-entity-list-item.component';
import { Contact } from '../provider/contact.model';
import { EntityBase } from '../entity/entity.model';
import { EntityLog } from '../shared/logged.model';
import { Overlap } from '../shared/break.model';
import { DateRangeOverlaps } from '../../modules/date-range-display-dynamic/components/date-range/date-range-breaks-display.component';
import { DateRangeBreaksDisplayDynamicComponent } from '../../modules/date-range-display-dynamic/components/date-range/date-range-breaks-display-dynamic.component';

// these are the simple ones
const placementSummaryInclude = new IncludeEntityMetadata<Placement, PlacementSummary>('placementSummary', 'placementSummaries', 'id', 'placementId');
const blockInclude = new IncludeEntityMetadata<Placement, Block>('planBlock', 'planBlocks', 'planBlockId', 'id');
const studentInclude = new IncludeEntityMetadata<Placement, Student>('student', 'students', 'studentId', 'id');
const positionInclude = new IncludeEntityMetadata<Placement, Position>('position', 'positions', 'positionId', 'id');
const logInclude = new IncludeEntityMetadata<Placement, EntityLog>('entity', 'entities', 'id', 'id');

// singles that aren't shown
const experienceInclude = new IncludeEntityMetadata<Placement, Experience>('experience', 'experiences', 'experienceId', 'id', { hideFromList: true });

// these require a bit more logic
const planInclude = new IncludeEntityMetadataCore<Placement, Plan>('plan', 'plans', 'planBlock.planId', 'id', {
    requiresInclude: ['planBlocks'],
    requiresIncludeSortPrefix: 'planBlock'
});
const providerInclude = new IncludeEntityMetadataCore<Placement, Provider>('provider', 'providers', 'position.providerId', 'id', {
    requiresInclude: ['positions'],
    requiresIncludeSortPrefix: 'position'
});
const siteInclude = new IncludeEntityMetadataCore<Placement, Site>('site', 'sites', 'position.siteId', 'id', {
    requiresInclude: ['positions'],
    requiresIncludeSortPrefix: 'position',
    requiresIncludeIsNullable: true
});

// require even more thought.
const contactsInclude = new IncludeEntityMetadataCore<Placement, Contact>('contact', 'contacts', 'position.contactIds', 'id', {
    requiresInclude: ['positions'],
    requiresIncludeSortPrefix: 'position',
    requiresIncludeIsNullable: true
});
const placementOverlapsInclude = new IncludeEntityMetadata<Placement, Placement>('placement', 'placementOverlaps', 'studentId', 'studentId', {
    predicateManyOverride: placementOverlapsPredicate,
    hideFromList: true
});

// Note: can't use lambda functions in functions used in Metadata objects
export function placementOverlapsPredicate(master: Placement, slaves: Placement[]): Placement[] {

    const filteredArray: Placement[] = [];

    for (let i = 0; i < slaves.length; i++) {
        const slave = slaves[i];
        if (slave.studentId !== master.studentId) { continue; }
        if (slave.id === master.id) { continue; }

        if (
            slave.start.isBefore(master.end, 'days')
            && slave.end.isSameOrAfter(master.start, 'days')
            && (slave.start.isAfter(master.start, 'days') || (slave.start.isSame(master.start, 'days') && slave.end.isBefore(master.end, 'days')))) {

            filteredArray.push(slave);
        }
    }

    return filteredArray;
}

@CpClass({
    include: [
        placementSummaryInclude,
        blockInclude,
        studentInclude,
        positionInclude,
        planInclude,
        providerInclude,
        siteInclude,
        experienceInclude,
        logInclude
    ],
    includeMany: [
        contactsInclude,
        placementOverlapsInclude
    ],
    metadataType: 'placement',
    defaultSort: [{ key: 'start', desending: true }, { key: 'experienceId' }],
    create: newPlacement
})
export class Placement extends EntityBase implements IIncludeEntity<PlacementIncludeType> {

    @CpProperty({ type: 'dynamic', dynamicComponent: DateRangeBreaksDisplayDynamicComponent })
    get dates(): DateRangeOverlaps {
        return {
            start: this.start,
            end: this.end,
            overlaps: this.overlaps
        };
    }

    @CpProperty({ label: 'Experience', type: 'int32', listHeaderAlign: 'left', dynamicComponent: ExperienceEntityListItemComponent })
    experienceId: number;

    @CpProperty({ label: 'Experiences', type: 'int32', listHeaderAlign: 'left', defaultListVisible: true, dynamicComponent: ExperienceEntityListItemComponent })
    get experienceIds(): number[] {
        return [this.experienceId, ...this.overlaps.map(o => o.experienceId)];
    }

    @CpProperty({ type: 'int32', hideFromList: true })
    studentId: number;

    @CpProperty({ type: 'int32', hideFromList: true })
    positionId?: number;

    @CpProperty({ label: 'Block Id', type: 'int32', hideFromList: true })
    planBlockId?: number;

    @CpProperty({ type: 'date' })
    start: moment.Moment;

    @CpProperty({ type: 'date' })
    end: moment.Moment;

    @CpProperty({ label: 'Expected Hours', type: 'int32', pipe: { pipe: new MinutesPipe() } })
    minutes: number;

    private get overlaps(): Overlap[] {
        if (!this.includeMany || !this.includeMany.placementOverlaps) {
            return [];
        }

        return (this.includeMany.placementOverlaps as Placement[])
            .sort((a, b) => {
                if (a.start.isSame(b.start, 'days')) { return 0; }
                if (a.start.isAfter(b.start, 'days')) { return 1; }
                return -1;
            })
            .map(i => ({
                experienceId: i.experienceId,
                placementId: i.id,
                start: i.start,
                end: i.end,
                minutes: i.minutes
            }));
    }
}

export function newPlacement() { return new Placement(); }
