import { Injectable } from '@angular/core';
import { TimesheetHttpService } from './http/timesheet.http.service';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { Timesheet } from '../../../models/placement/timesheet.model';
import { ResponseTimesheetInclude, ResponseModelBulk } from '../../../models/api/response.model';
import { Observable } from 'rxjs';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { IListService, IIdListService } from '../../entity-shared/base/entity-search.base';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
import { tap } from 'rxjs/operators';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import { TimesheetIncludeOption } from '../../../models/shared/include.model';

@Injectable()
export class TimesheetService
    extends EntityServiceMetadataBase<Timesheet, TimesheetIncludeOption, ResponseTimesheetInclude>
    implements
    IUpdatableService<Timesheet>,
    IListService<Timesheet, TimesheetIncludeOption, ResponseTimesheetInclude>,
    IIdListService {

    protected includeOptions: TimesheetIncludeOption[] = [];

    constructor(
        protected httpService: TimesheetHttpService,
        protected apiCacheService: ApiCacheService,
        protected includeMapperService: IncludeMapperService
    ) {
        super('timesheet', httpService, apiCacheService, includeMapperService);
    }

    protected onAfterCreate(entity: Timesheet): void | RequestFilter[] {
        this.apiCacheService.clearCacheByEntityId('placements', entity.placementId);
    }
    protected onAfterUpdate(entity: Timesheet): void {
        this.apiCacheService.clearCacheByEntityId('placements', entity.placementId);
    }

    deleteById(id: number) {
        return this.httpService.delete([id]);
    }

    bulk(timesheets: Timesheet[]): Observable<ResponseModelBulk<Timesheet>> {
        return this.httpService.bulk(timesheets).pipe(
            tap(i => {
                lodashHelper.uniq(timesheets.map(t => t.placementId))
                .map(placementId => {
                    this.apiCacheService.clearCacheByType('timesheets');
                    this.apiCacheService.clearCacheByEntityId('placements', placementId);
                });
            })
        );
    }
}
