import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { EntityBase } from '../entity/entity.model';
import { ResponseFilter, ResponseSort } from '../api/response.model';
import { ArchiveDisplayDynamicComponent } from '../../modules/display-helper/components/archive/archive-display-dynamic.component';

@CpClass({
    create: newSavedSearch,
    metadataType: 'savedSearch'
})
export class SavedSearch extends EntityBase {

    constructor() {
        super();
        this.filter = new ResponseFilter();
    }

    @CpProperty({ type: 'string' })
    name: string;

    @CpProperty({ type: 'string' })
    description: string;

    @CpProperty({ type: 'boolean', dynamicComponent: ArchiveDisplayDynamicComponent })
    archived: boolean;

    @CpProperty({ type: 'boolean' })
    shared: boolean;

    @CpProperty({ type: 'dynamic', hideFromList: true })
    filter: ResponseFilter;

    @CpProperty({ type: 'dynamic', hideFromList: true })
    sort: ResponseSort[] = [];

    @CpProperty({ type: 'string' })
    q: string;

    @CpProperty({ type: 'string' })
    columns: string[] = [];
}

export function newSavedSearch() { return new SavedSearch(); }
