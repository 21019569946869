import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Experience } from '../../../../models/experience/experience.model';

@Component({
    selector: 'cp-experience-badge',
    template: `<cp-badge [color]="experience.color" [label]="experience.name"></cp-badge>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ExperienceBadgeComponent {
    @Input() experience: Experience;
}
