<cp-bootstrap-alert *ngIf="array.length > 0" type="danger" class="d-block my-2" [small]="small">
    <div *ngFor="let error of array; let lastError = last; let first = first" class="mb-2" [class.mb-0]="lastError">
        <p class="mb-0">{{ error.message }} <a href="javascript:;" (click)="showMore = !showMore">{{ showMore ? 'Hide' : 'Show more' }}</a></p>
        <cp-json-to-property-display class="bg-white p-2 mt-2" [item]="error" [ngbCollapse]="!showMore" [class.d-block]="showMore"></cp-json-to-property-display>
    </div>
</cp-bootstrap-alert>

<cp-bootstrap-alert *ngIf="showRaw" type="danger" class="d-block my-2" [small]="small">
    <p class="mb-0">An Unexpected Error Occurred</p>
    <cp-json-to-property-display [item]="error"></cp-json-to-property-display>
</cp-bootstrap-alert>