/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../display-helper/components/badge/badge.component.ngfactory";
import * as i2 from "../../../display-helper/components/badge/badge.component";
import * as i3 from "../../../display-helper/services/display-helper.service";
import * as i4 from "./experience-badge.component";
var styles_ExperienceBadgeComponent = [];
var RenderType_ExperienceBadgeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ExperienceBadgeComponent, data: {} });
export { RenderType_ExperienceBadgeComponent as RenderType_ExperienceBadgeComponent };
export function View_ExperienceBadgeComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-badge", [], null, null, null, i1.View_BadgeComponent_0, i1.RenderType_BadgeComponent)), i0.ɵdid(1, 49152, null, 0, i2.BadgeComponent, [i3.DisplayHelperService], { color: [0, "color"], label: [1, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.experience.color; var currVal_1 = _co.experience.name; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ExperienceBadgeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-experience-badge", [], null, null, null, View_ExperienceBadgeComponent_0, RenderType_ExperienceBadgeComponent)), i0.ɵdid(1, 49152, null, 0, i4.ExperienceBadgeComponent, [], null, null)], null, null); }
var ExperienceBadgeComponentNgFactory = i0.ɵccf("cp-experience-badge", i4.ExperienceBadgeComponent, View_ExperienceBadgeComponent_Host_0, { experience: "experience" }, {}, []);
export { ExperienceBadgeComponentNgFactory as ExperienceBadgeComponentNgFactory };
