import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TimesheetService } from './services/timesheet.service';
import { TimesheetHttpService } from './services/http/timesheet.http.service';
import { TimesheetFormComponent } from './components/timesheet/timesheet-form.component';
import { TimesheetResultsComponent } from './components/timesheet/timesheet-results.component';

@NgModule({
    entryComponents: [],

    imports: [
        SharedModule
    ],
    providers: [
        TimesheetService,

        TimesheetHttpService
    ],
    declarations: [
        TimesheetFormComponent,

        TimesheetResultsComponent
    ],
    exports: [
        TimesheetFormComponent,
        TimesheetResultsComponent
    ],
})
export class TimesheetModule { }
