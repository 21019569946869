import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

declare var MiniProfiler: any;

@Injectable()
export class MiniProfilerInterceptor implements HttpInterceptor {

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const handled = next.handle(req);

        if (typeof MiniProfiler === 'undefined') {
            return handled;
        }

        return handled.pipe(tap(evt => this.handleEvent(evt), err => this.handleEvent(err)));
    }

    private handleEvent(evt: any): void {

        if (
            evt &&
            evt.headers &&
            evt.headers instanceof HttpHeaders
        ) {
            this.makeMiniProfilerRequests(evt.headers);
        }
    }

    private makeMiniProfilerRequests(headers: HttpHeaders) {
        const miniProfilerHeaders = headers.getAll('x-miniprofiler-ids');

        if (!miniProfilerHeaders) {
            return;
        }

        miniProfilerHeaders.forEach(miniProfilerIdHeaderValue => {
            const ids = JSON.parse(miniProfilerIdHeaderValue) as string[];
            MiniProfiler.fetchResults(ids);
        });
    }
}
