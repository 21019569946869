import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgbDatepickerHelper } from './datepicker.helper';

export const NGB_DATE_FORMAT = 'L';

@Injectable()
export class MomentLocaleParserFormatter extends NgbDateParserFormatter {


    constructor(private helper: NgbDatepickerHelper) {
        super();
    }

    parse(value: string): NgbDateStruct {
        const parsed = moment.utc(value, NGB_DATE_FORMAT);

        // note to lint punkasses: i had to do it.
        if (!parsed.isValid()) { return (null as any); }

        return this.helper.convertFromMoment(parsed);
    }

    format(date: NgbDateStruct): string {
        if (!date) { return ''; }

        return this.helper.convertToMoment(date).format(NGB_DATE_FORMAT);
    }
}
