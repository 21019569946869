import { Pipe, PipeTransform } from '@angular/core';
import { ExtendedPropertyAllowedBaseType } from '../../../models/extended-properties/extended-property.model';
import { NameValueResult } from '../../../models/shared/search.model';
import { PropertyDisplayItemOptions } from '../components/property-display/property-display-item.component';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { BooleanPipe } from './boolean.pipe';
import { DateRange } from '../../../models/shared/date-range.model';

@Pipe({name: 'propertyDisplayValue'})
export class PropertyDisplayValuePipe implements PipeTransform {
    transform(singleValue: ExtendedPropertyAllowedBaseType | NameValueResult | DateRange | undefined, options: PropertyDisplayItemOptions) {
        if (singleValue === undefined || singleValue === null) {
            return '';
        }

        // datetime is currently limited to display only
        if (options.type === 'dateTime') {
            return this.handleDateTime(singleValue as (moment.Moment));
        }

        if (options.type === 'date') {
            return this.handleDateRange(singleValue as any)
                || this.handleDate(singleValue as (moment.Moment))
                || this.handleNameValue(singleValue);
        }

        if (options.type === 'boolean') {
            return new BooleanPipe().transform(singleValue as boolean);
        }

        // this daterange one is a hack
        if (options.type === 'dateRange') {
            return this.handleDateRange(singleValue as (string |  moment.Moment | DateRange)) || '';
        }

        // could have issues with some types (like a moment type, equality compare won't work)
        const find = (options.arrayOptions || []).find(i => i === singleValue);

        // if there's a pipe, use that first
        if (options.pipe) {
            return options.pipe.pipe.transform(singleValue, options.pipe.args || []);
        }

        if (find) { return find; }

        // else, it could be a name-value or just a value...
        return this.handleNameValue(singleValue);
    }

    handleDate(singleValue: moment.Moment) {
        if (singleValue === undefined || singleValue === null) {
            return undefined;
        }

        if (moment.isMoment(singleValue)) { return singleValue.format(environment.momentDateFormat); }

        return undefined;
    }

    handleDateTime(singleValue: moment.Moment) {
        if (singleValue === undefined || singleValue === null) {
            return undefined;
        }

        if (moment.isMoment(singleValue)) { return singleValue.format(environment.momentDateTimeFormat); }

        return undefined;
    }

    private handleNameValue(singleValue: any) {
        const namedValue = (singleValue as NameValueResult);
        if (namedValue.value) {
            return namedValue.name ? namedValue.name : namedValue.value;
        }

        return singleValue;
    }

    private handleDateRange(singleValue: string |  moment.Moment | DateRange) {
        if (typeof(singleValue) === 'string' && singleValue.includes('|')) {
            return singleValue.replace('|', ' - ');
        }

        const dateRange = singleValue as DateRange;
        if (typeof(dateRange) === 'object' && moment.isMoment(dateRange.start) && moment.isMoment(dateRange.end)) {
            return `${dateRange.start.format(environment.momentDateFormat)} - ${dateRange.end.format(environment.momentDateFormat)}`;
        }

        return undefined;
    }
}
