import { NgModule } from '@angular/core';
import { CustomCommonModule } from '../common/common.module';
import { FilterModule } from '../filter/filter.module';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { CustomFormsModule } from '../form/form.module';
import { AbsenceTypeEntityListItemComponent } from './components/absence-type-entity-list-item/absence-type-entity-list-item.component';
import { ExperienceEntityListItemComponent } from './components/experience-entity-list-item/experience-entity-list-item.component';
import { RoleEntityListItemComponent } from './components/role-entity-list-item/role-entity-list-item.component';

@NgModule({
    entryComponents: [
        AbsenceTypeEntityListItemComponent,
        ExperienceEntityListItemComponent,
        RoleEntityListItemComponent
    ],
    imports: [
        CustomCommonModule.forChild(),
        FilterModule,
        DisplayHelperModule,
        CustomFormsModule
    ],
    providers: [],
    declarations: [
        AbsenceTypeEntityListItemComponent,
        ExperienceEntityListItemComponent,
        RoleEntityListItemComponent
    ],
    exports: [
        AbsenceTypeEntityListItemComponent,
        ExperienceEntityListItemComponent,
        RoleEntityListItemComponent
    ]
})
export class ConfigurationEntitySharedModule { }
