import { AllIncludeOptions } from '../../../models/shared/include.model';
import { Entity, MetadataType } from '../../../models/entity/entity.type';

// import { environment } from '../../../../environments/environment';

const NG_ON_ONIT_NAME = 'ngOnInit';
const defaultOptions: RequiredOnInitOptions = { strict: false };
export interface RequiredOnInitOptions {
    strict: boolean;
}

export function CpRequiredOnInit(options: RequiredOnInitOptions = defaultOptions): PropertyDecorator {
    return overrideInit((value: any, target: any, property: string) => existsCheck(value, target, property, options));
}

export function CpRequiresInclude(includeTypes: MetadataType[]): PropertyDecorator  {
    return overrideInit((value: any, target: any, property: string) => {
        existsCheck(value, target, property);
        includeCheck(value, target, property, includeTypes);
    });
}

export function CpRequiresIncludeMany(includeTypes: AllIncludeOptions[]): PropertyDecorator {
    return overrideInit((value: any, target: any, property: string) => {
        existsCheck(value, target, property);
        includeManyCheck(value, target, property, includeTypes);
    });
}

function existsCheck(value: any, target: any, property: string, options: RequiredOnInitOptions = defaultOptions): void {

    if (options.strict && value !== undefined) {
        return;
    }

    if (!value) {
        throw new Error(`${property} is required for component ${target.constructor.name}.`);
    }
}

function includeCheck(value: any, target: any, property: string, includeTypes: MetadataType[]): void {
    includeTypes.map(includeType => {
        if (!(value as Entity).include || !value.include[includeType]) {
            throw new Error(`${includeType} is required as include (single) for this entity with property '${property}' in component ${target.constructor.name}.`);
        }
    });
}

function includeManyCheck(value: any, target: any, property: string, includeTypes: AllIncludeOptions[]): void {
    includeTypes.map(includePluralType => {
        if (!(value as Entity).includeMany || !value.includeMany[includePluralType]) {
            throw new Error(`${includePluralType} is required as IncludeMany (multiple) for this entity with propertyName '${property}' in component ${target.constructor.name}.`);
        }
    });
}

function overrideInit(valueCheck: (value: any, target: any, property: string) => void) {

    return (target: any, property: string) => {

        const original: Function | null = target[NG_ON_ONIT_NAME];

        Object.defineProperty(target, NG_ON_ONIT_NAME, {
            value: function() {
                valueCheck(this[property], target, property);

                if (original) { original.call(this); }
            }
        });
    };
}
