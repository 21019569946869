import { OnInit } from '@angular/core';
var PropertyDisplayComponent = /** @class */ (function () {
    function PropertyDisplayComponent() {
        this.removeMargin = false;
        this.removeBorder = false;
    }
    Object.defineProperty(PropertyDisplayComponent.prototype, "margin", {
        get: function () {
            return !this.removeMargin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyDisplayComponent.prototype, "borderColor", {
        get: function () {
            return !this.removeBorder;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyDisplayComponent.prototype, "borderBottom", {
        get: function () {
            return !this.removeBorder;
        },
        enumerable: true,
        configurable: true
    });
    PropertyDisplayComponent.prototype.ngOnInit = function () { };
    return PropertyDisplayComponent;
}());
export { PropertyDisplayComponent };
