import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { RequestFilter } from '../../../models/api/request-filter.model';

export const POSITION_AVAILABILITY_STATUS_KEY = 'status';
export type PositionAvailabilityStatus = 'available' | 'unavailable' | 'all';
export const ALL_POSITION_AVAILABILITIES: PositionAvailabilityStatus[] = ['available', 'unavailable', 'all'];

@Injectable()
export class PositionAvailabilityStatusService {

    defaultStatus: PositionAvailabilityStatus = 'available';

    private availableFilter: RequestFilter;
    private unavailableFilter: RequestFilter;

    constructor() {
        this.availableFilter = new RequestFilter('positions', 'availableDates', 'eq', ['0day']);
        this.unavailableFilter = new RequestFilter('positions', '!availableDates', 'eq', ['0day']);
    }

    hasNewStatus(params: Params, currentStatus: PositionAvailabilityStatus): PositionAvailabilityStatus | undefined {
        const queryStatus = params[POSITION_AVAILABILITY_STATUS_KEY];

        if (queryStatus && ALL_POSITION_AVAILABILITIES.includes(queryStatus) && queryStatus !== currentStatus) {
            return queryStatus;
        }

        if (!queryStatus && currentStatus !== 'available') {
            return 'available';
        }

        return;
    }

    createRequestFilter(status: PositionAvailabilityStatus): RequestFilter | undefined {

        if (status === 'available') {
            return this.availableFilter;
        }

        if (status === 'unavailable') {
            return this.unavailableFilter;
        }

        return;
    }
}
