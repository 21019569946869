import { Injectable } from '@angular/core';
import { EntityPropertiesConfig, EntityPropertyConfig } from '../../../models/filters/filter.model';
import { FilterPropertyExtended, FilterPath } from './filter-config-helper.service';
import { toPlural } from '../../entity-shared/helper/entity-shared.helper';
import { MetadataType, DESCRIBABLE_TYPE_METADATA, ALL_FILTER_TYPES } from '../../../models/entity/entity.type';

export interface FilterPropertyPath extends FilterPath {
    property: FilterPropertyExtended;
    entityIdProperty?: FilterPropertyExtended;
}

interface TraverseConfigOptions {
    suppressError: boolean;
}

@Injectable()
export class FilterConfigInnerService {

    private entityTypeIdArray = [
        ...ALL_FILTER_TYPES.map(i => i + 'Id'),
        ...ALL_FILTER_TYPES.map(i => i + 'Ids')
    ];

    constructor() { }

    getBaseEntityProperties(config: EntityPropertiesConfig, type: MetadataType): FilterPropertyExtended[] {
        const entityConfig = config[type];

        if (!entityConfig) { return []; }

        return Object.keys(entityConfig)
            .map(key => entityConfig[key])
            .filter(filterProperty => !filterProperty.archived)
            .map(filterProperty => this.extendFilterProperty(filterProperty));
    }

    getPropertyPathByPath(config: EntityPropertiesConfig, entityType: MetadataType, path: FilterPath[]): FilterPropertyPath[] | undefined {
        const baseProperties = this.getBaseEntityProperties(config, entityType);
        return this.traversePropertyConfig(config, baseProperties, path, { suppressError: true });
    }

    propertyPathToFilterPath(propertyPath: FilterPropertyPath[]): FilterPath[] {
        return propertyPath.map(pp => ({
            key: pp.key,
            entityIdKey: pp.entityIdKey,
            without: pp.without
        }));
    }

    traversePropertyConfig(
        config: EntityPropertiesConfig,
        filterProperties: FilterPropertyExtended[],
        path: FilterPath[],
        options: TraverseConfigOptions = { suppressError: false }
    ): FilterPropertyPath[] | undefined  {
        return this.traversePropertyConfigRecursive(config, filterProperties, path, options);
    }

    private traversePropertyConfigRecursive(
        config: EntityPropertiesConfig,
        filterProperties: FilterPropertyExtended[],
        path: FilterPath[],
        options: TraverseConfigOptions,
        propertyPath: FilterPropertyPath[] = []
    ): FilterPropertyPath[] | undefined {
        if (path.length === 0) {
            if (!options.suppressError) { throw new Error('keyPath must have a length'); }
            return undefined;
        }

        const pathItem = path[propertyPath.length];
        if (!pathItem) {
            if (!options.suppressError) { throw new Error('Unexpected undefined value in currentProperty'); }
            return undefined;
        }

        const filterProperty = filterProperties.find(i => i.key === pathItem.key);
        if (!filterProperty) {
            // console.log(`%cUnexpected undefined value in filterProperty`, 'color:red', pathItem, path, propertyPath);
            if (!options.suppressError) { throw new Error('Unexpected undefined value in filterProperty'); }
            return undefined;
        }

        // if you have an Id path property, you need to switch the property to the entity property
        // for example, if this is a Student search, and the property coming in is "planId"
        // we need to replace this path segment with the "path" property, and set the planId to the entityId key/property
        const filterTypeIfEntity = this.isEntityIdProperty(pathItem.key);
        if (filterTypeIfEntity) {
            pathItem.key = filterTypeIfEntity;
            return this.traversePropertyConfigRecursive(config, filterProperties, path, options, propertyPath);
        }

        // set extended path
        const newPropertyPath: FilterPropertyPath = {
            key: pathItem.key,
            without: pathItem.without,
            property: filterProperty
        };
        propertyPath.push(newPropertyPath);

        if (!this.isEntityType(filterProperty.type)) {
            return propertyPath;
        }

        // find entity properties and keys
        const nextEntityType = filterProperty.type as MetadataType;
        const nextEntityProperties = this.getBaseEntityProperties(config, nextEntityType);

        const idArray = [nextEntityType + 'Id', nextEntityType + 'Ids'];
        const existsInCurrentEntityProperties = filterProperties.find(i => idArray.includes(i.key));
        if (existsInCurrentEntityProperties) {
            newPropertyPath.entityIdKey = existsInCurrentEntityProperties.key;
            newPropertyPath.entityIdProperty = existsInCurrentEntityProperties;
        } else {
            const existsInNextEntityProperties = nextEntityProperties.find(i => i.key === 'id');
            if (existsInNextEntityProperties) {
                newPropertyPath.entityIdKey = `${filterProperty.key}.id`;
                newPropertyPath.entityIdProperty = existsInNextEntityProperties;
            }
        }

        // if it's an entity type and the next chain item is 'id', we need to snip it here
        const nextPath = path[propertyPath.length];
        if (nextPath && nextPath.key === 'id') {
            return propertyPath;
        }

        // if this is true, then we're at the end of the chain
        if (path.length === propertyPath.length) {
            return propertyPath;
        }

        return this.traversePropertyConfigRecursive(config, nextEntityProperties, path, options, propertyPath);
    }

    private isEntityIdProperty(key: string): string | undefined {
        if (this.entityTypeIdArray.includes(key)) {
            const entityType = key.slice(0, key.lastIndexOf('Id')) as MetadataType;

            // also have to cater for pluralization here
            return key.endsWith('Ids') ? toPlural(entityType) : entityType;
        }

        return undefined;
    }

    private extendFilterProperty(filterProperty: EntityPropertyConfig): FilterPropertyExtended {
        return {
            ...filterProperty,
            isEntity: this.isEntityType(filterProperty.type as string)
        };
    }

    private isEntityType(value: string): boolean {
        return DESCRIBABLE_TYPE_METADATA[value] && !!DESCRIBABLE_TYPE_METADATA[value as MetadataType].filterable;
    }
}
