import { Injectable } from '@angular/core';
import { FilterPath } from './filter-config-helper.service';

@Injectable()
export class FilterPathHelperService {

    constructor(
    ) { }

    stringToFilterPath(key: string): FilterPath[] {
        const propertyKeys = key.split('.');
        if (propertyKeys.length > 1 && propertyKeys[propertyKeys.length - 2].endsWith('properties')) {
            const finalKeyString = propertyKeys.pop();
            const porpertyKeyString = propertyKeys.pop();
            propertyKeys.push(`${porpertyKeyString}.${finalKeyString}`);
        }

        const paths = propertyKeys.map(i => {
            const without = i.startsWith('!');
            const path = without ? i.slice(1) : i;
            return {
                without: without,
                key: path
            };
        });

        return paths;
    }
}
