import { Injectable } from '@angular/core';
import { ProviderHttpService } from './http/provider.http.service';
import { Provider } from '../../../models/provider/provider.model';
import { ResponseProviderInclude } from '../../../models/api/response.model';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { IUpdatableService } from '../../form/components/form/entity-form.base';
import { IMergeService } from '../../entity-shared/components/entity-merge/entity-merge.base.component';
import { ProviderBaseService } from './provider-base.service';
import { RequestFilter } from '../../../models/api/request-filter.model';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { ProviderIncludeOption } from '../../../models/shared/include.model';

@Injectable()
export class ProviderService
    extends ProviderBaseService<Provider, ProviderIncludeOption, ResponseProviderInclude>
    implements IUpdatableService<Provider>, IMergeService<Provider> {

    protected includeOptions: ProviderIncludeOption[] = ['contacts', 'positions', 'sites', 'providerSummaries'];

    constructor(
        protected httpService: ProviderHttpService,
        protected apiCacheService: ApiCacheService,
        protected includeMapperService: IncludeMapperService
    ) {
        super('provider', httpService, apiCacheService, includeMapperService);
    }

    protected createEntity = () => new Provider();

    protected onAfterCreate(): RequestFilter[] | undefined {
        return;
    }

    protected onAfterUpdate(entity: Provider): void {
        this.apiCacheService.clearCacheByType('providers');
        return;
    }
}
