import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
    OnDestroy
} from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthUserType } from '../../../../models/authentication/authentication.model';

@Directive({ selector: '[ifAuthed]' })
export class IfAuthedDirective implements OnInit, OnDestroy {
    constructor(
        private templateRef: TemplateRef<any>,
        private service: AuthenticationService,
        private viewContainer: ViewContainerRef
    ) {}

    private condition: boolean;
    private isAuthenticated: boolean;
    private isCorrectRole: boolean;
    private sub: Subscription;
    private allowedUserTypes: AuthUserType[];

    @Input()
    set ifAuthed(condition: boolean) {
        this.condition = condition;
    }

    @Input('ifAuthedForUserType')
    set forUserType(val: AuthUserType | AuthUserType[]) {
        if (!(val instanceof Array)) {
            val = [val];
        }

        this.allowedUserTypes = val;
    }

    ngOnInit() {
        this.sub = this.service.user$.pipe(
            map(user => {
                const newValue = this.service.isAuthenticated();
                const userType = this.service.getUserType();

                let newCorrectRoleValue = true;

                if (this.allowedUserTypes && this.allowedUserTypes.length > 0) {
                    newCorrectRoleValue = userType ? this.allowedUserTypes.includes(userType) : false;
                }

                if (this.isAuthenticated === newValue && this.isCorrectRole === newCorrectRoleValue) {
                    return;
                }

                this.isAuthenticated = newValue;
                this.isCorrectRole = newCorrectRoleValue;
                if (
                    (this.isAuthenticated && this.condition && this.isCorrectRole) ||
                    (!this.isAuthenticated && !this.condition)
                ) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            })
        ).subscribe();
    }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
