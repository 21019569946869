import { ComponentRef, ComponentFactory, ComponentFactoryResolver, ViewContainerRef, TemplateRef } from '@angular/core';
import { LoaderComponent } from '../../../display-helper/components/loader/loader.component';
import { LoaderErrorComponent } from '../../components/loader/loader-error.component';
var LoaderDirective = /** @class */ (function () {
    function LoaderDirective(templateRef, vcRef, componentFactoryResolver) {
        this.templateRef = templateRef;
        this.vcRef = vcRef;
        this.componentFactoryResolver = componentFactoryResolver;
        this.cpLoadingSmall = false;
        this.cpLoadingDisableFadeIn = false;
        // Create resolver for loading component
        this.loaderFactory = this.componentFactoryResolver.resolveComponentFactory(LoaderComponent);
        this.loaderErrorFactory = this.componentFactoryResolver.resolveComponentFactory(LoaderErrorComponent);
    }
    Object.defineProperty(LoaderDirective.prototype, "cpLoading", {
        get: function () { return this._loading; },
        set: function (loading) {
            this.vcRef.clear();
            this._loading = loading;
            this.handleTemplate(loading);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaderDirective.prototype, "cpLoadingError", {
        get: function () { return this._error; },
        set: function (error) {
            this.vcRef.clear();
            this._error = error;
            this.handleTemplate(this.cpLoading);
        },
        enumerable: true,
        configurable: true
    });
    LoaderDirective.prototype.handleTemplate = function (loading) {
        if (this.cpLoadingError) {
            this.loaderErrorComponent = this.vcRef.createComponent(this.loaderErrorFactory);
            this.loaderErrorComponent.instance.error = this.cpLoadingError;
        }
        else if (loading) {
            // create and embed an instance of the loading component
            this.loaderComponent = this.vcRef.createComponent(this.loaderFactory);
            this.loaderComponent.instance.small = this.cpLoadingSmall;
            this.loaderComponent.instance.disableFadeIn = this.cpLoadingDisableFadeIn;
        }
        else {
            // embed the contents of the host template
            this.vcRef.createEmbeddedView(this.templateRef);
        }
    };
    return LoaderDirective;
}());
export { LoaderDirective };
