import { Injectable, InjectionToken, Type, Injector } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { Breadcrumb, BreadcrumbService } from './breadcrumb.service';
import { filter, catchError, map, } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';


export type BreadcrumbRouteDataArray = (Breadcrumb | BreadcrumbServiceProvider<any>)[];
export interface BreadcrumbRouteData { breadcrumbs: BreadcrumbRouteDataArray; }

export interface BreadcrumbConfig {
    breadcrumb: (Breadcrumb | BreadcrumbServiceProvider<any>);
    routeSnapshot: ActivatedRouteSnapshot;
}

export interface BreadcrumbServiceProvider<TService> {
    service: Type<TService> | InjectionToken<TService>;
    action: (service: TService, data: ActivatedRouteSnapshot) => Observable<Breadcrumb>;
}

export function breadcrumbArrayTypeGuard(toCheck: any): toCheck is BreadcrumbRouteDataArray {
    // must exist, must be an array
    if (!toCheck || !Array.isArray(toCheck)) { return false; }

    return toCheck.every(breadcrumb => {
        // can't be undefined
        if (!breadcrumb) { return false; }

        // this means it's a simple breadcrumb
        if (breadcrumbTypeGuard(breadcrumb)) { return true; }

        // this isn't really a thorough check
        if (breadcrumb.service && breadcrumb.action && typeof(breadcrumb.action) === 'function' ) {
            return true;
        }

        return false;
    });
}

export function breadcrumbTypeGuard(toCheck: any): toCheck is Breadcrumb {
    if (!toCheck) { return false; }

    return toCheck.text && Array.isArray(toCheck.route);
}

@Injectable()
export class BreadcrumbBuilderService {

    private initialized = false;
    private previousRoutePath: string[][] = [];

    constructor(
        private injector: Injector,
        private router: Router,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService
    ) {}

    initialize() {
        // console.log('initializing breadcrumb');
        if (this.initialized) {
            return;
        }

        this.router.events.pipe(
            filter(i => i instanceof NavigationEnd),
            map(() => this.handleRoute())
        ).subscribe();

        this.initialized = true;
    }

    private handleRoute() {

        const breadcrumbConfigs = this.buildBreadcrumbsFromRoute(this.route.root.snapshot, []);

        // dont' do anything if the route is the same
        const routePath = breadcrumbConfigs.map(crumbs => crumbs.routeSnapshot.url.map(u => u.path));
        if (lodashHelper.isEqual(this.previousRoutePath, routePath)) {
            return;
        }
        this.previousRoutePath = routePath;

        const obsArray: Observable<Breadcrumb>[] = breadcrumbConfigs.map(breadcrumbConfig => {
            const breadcrumb = breadcrumbConfig.breadcrumb;

            if (breadcrumbTypeGuard(breadcrumb)) {
                return of(breadcrumb);
            }

            const service = this.injector.get(breadcrumb.service);
            return breadcrumb.action(service, breadcrumbConfig.routeSnapshot);
        });

        if (!obsArray || obsArray.length === 0) {
            this.breadcrumbService.setCrumbs([]);
            return;
        }

        forkJoin(obsArray).pipe(
            // consider logging. todo
            // catch and replace strat
            catchError(err => of([]))
        ).toPromise().then(i => {
            this.breadcrumbService.setCrumbs(i);
        });
    }

    private buildBreadcrumbsFromRoute(route: ActivatedRouteSnapshot, chain: BreadcrumbConfig[]): BreadcrumbConfig[] {

        chain = [...chain, ...this.pushRouteBreadcrumbs(route)];

        if (route.firstChild) {
            return this.buildBreadcrumbsFromRoute(route.firstChild, chain);
        }

        return chain;
    }

    private pushRouteBreadcrumbs(route: ActivatedRouteSnapshot): BreadcrumbConfig[] {
        if (!route.routeConfig || !route.routeConfig.data) { return []; }

        const dataArray = route.routeConfig.data.breadcrumbs;
        if (breadcrumbArrayTypeGuard(dataArray)) {
            return dataArray.map(i => ({
                breadcrumb: i,
                routeSnapshot: route
            }));
        }

        return [];
    }
}
