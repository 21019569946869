import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntitySearchSortService } from './entity-search-sort.service';
import { EntityListDescriptorService } from '../../core-configuration/services/metadata/entity-list-descriptor.service';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import { FilterQueryHelperService } from '../../filter/services/filter-query-helper.service';
import { EntityListCsvService } from './entity-list-csv.service';
import { MetadataType } from '../../../models/entity/entity.type';

@Injectable()
export class EntitySearchService {
    constructor(
        public route: ActivatedRoute,
        public filterQueryHelperService: FilterQueryHelperService,
        public sortService: EntitySearchSortService,
        public listDescriptorService: EntityListDescriptorService,
        public csvDownloadService: EntityListCsvService
    ) {}

    metadataObs(type: MetadataType) {
        return this.listDescriptorService.change$.pipe(
            map(metaConfig => {
                if (metaConfig[type] === undefined) {
                    return [];
                }

                const vis = metaConfig[type].filter(meta => meta.visible);

                const visTypes = vis
                    .filter(meta => meta.includeKey !== '')
                    .map(meta => meta.includeKey);

                const requiredTypes = lodashHelper.flatten(
                    vis
                        .filter(meta => meta.requiresInclude !== undefined)
                        .map(meta => meta.requiresInclude!)
                );

                return [...visTypes, ...requiredTypes];
            }),
            map(i => lodashHelper.uniq(i)),
            distinctUntilChanged((x, y) => lodashHelper.isEqual(x, y))
        );
    }
}
