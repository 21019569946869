import { Injectable } from '@angular/core';
import { Configuration, Country } from '../../../../models/shared/configuration.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ResponseModel } from '../../../../models/api/response.model';

export interface IConfigurationHttpService {
    get(): Observable<ResponseModel<Configuration>>;
    getCountries(): Observable<ResponseModel<Country[]>>;
}

@Injectable()
export class ConfigurationHttpService implements IConfigurationHttpService {

    private baseUrl: string;

    get(): Observable<ResponseModel<Configuration>> {
        return this.httpClient.get<ResponseModel<Configuration>>(`${this.baseUrl}/configuration`);
    }

    getCountries(): Observable<ResponseModel<Country[]>> {
        return this.httpClient.get<ResponseModel<Country[]>>(`${this.baseUrl}/countries`);
    }

    constructor(private httpClient: HttpClient) {
        this.baseUrl = environment.apiBaseUrl + '/' + environment.apiVersion;
    }
}
