var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PlanHttpService } from './http/plan.http.service';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
var PlanService = /** @class */ (function (_super) {
    __extends(PlanService, _super);
    function PlanService(httpService, apiCacheService, includeMapperService) {
        var _this = _super.call(this, 'plan', httpService, apiCacheService, includeMapperService) || this;
        _this.httpService = httpService;
        _this.apiCacheService = apiCacheService;
        _this.includeMapperService = includeMapperService;
        _this.includeOptions = ['planBlocks', 'experiences'];
        return _this;
    }
    PlanService.prototype.onAfterCreate = function (entity) {
        return;
    };
    PlanService.prototype.onAfterUpdate = function (entity) {
        return;
    };
    return PlanService;
}(EntityServiceMetadataBase));
export { PlanService };
