import { MergeApiHttpClientService } from '../../api/services/api-http-client.service';
import { AllResponseIncludes, ResponseModelMerge } from '../../../models/api/response.model';
import { Observable } from 'rxjs';
import { ApiCacheService } from '../../api/services/api-cache.service';
import { tap } from 'rxjs/operators';
import { EntityServiceMetadataBase } from '../../entity-shared/base/entity-service-metadata.base';
import { IncludeMapperService } from '../../entity-shared/services/include-mapper.service';
import { AllIncludeOptions } from '../../../models/shared/include.model';
import { Entity, MetadataType } from '../../../models/entity/entity.type';

export abstract class ProviderBaseService<
    TEntity extends Entity,
    TIncludeOptions extends AllIncludeOptions,
    TResponseIncludes extends AllResponseIncludes>
    extends EntityServiceMetadataBase<TEntity, TIncludeOptions, TResponseIncludes> {

    constructor(
        protected type: MetadataType,
        protected httpService: MergeApiHttpClientService<TEntity, TIncludeOptions, TResponseIncludes>,
        protected apiCacheService: ApiCacheService,
        protected includeMapperService: IncludeMapperService
    ) {
        super(type, httpService, apiCacheService, includeMapperService);
    }

    merge(deleteId: number, targetId: number, options: { dry: boolean }): Observable<ResponseModelMerge<TEntity>> {
        return options.dry ?
            this.httpService.dryMerge(deleteId, targetId) :
            this.httpService.merge(deleteId, targetId).pipe(
                tap(() => {
                    this.apiCacheService.clearCacheByType('providers');
                    this.apiCacheService.clearCacheByType('positions');
                    this.apiCacheService.clearCacheByType('contacts');
                    this.apiCacheService.clearCacheByType('sites');
                })
            );
    }
}
