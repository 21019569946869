import { NgModule } from '@angular/core';
import { CustomCommonModule } from '../common/common.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { CustomFormsModule } from '../form/form.module';
import { FilterModule } from '../filter/filter.module';
import { ExperienceModule } from '../experience/experience.module';
import { AbsenceTypeModule } from '../absence-type/absence-type.module';
import { EntitySharedModule } from '../entity-shared/entity-shared.module';
import { ChangesModule } from '../changes/changes.module';
import { MappingModule } from '../mapping/mapping.module';
import { DateRangeDisplayDynamicModule } from '../date-range-display-dynamic/date-range-display-dynamic.module';
import { SavedSearchModule } from '../saved-search/saved-search.module';
import { EntitySearchModule } from '../entity-search/entity-search.module';

@NgModule({
    declarations: [

    ],
    providers: [

    ],
    imports: [
        AuthenticationModule.forChild(),
        CustomCommonModule.forChild(),
        CustomFormsModule,
        DisplayHelperModule,
        FilterModule,
        ExperienceModule,
        AbsenceTypeModule,
        MappingModule,
        EntitySharedModule,
        EntitySearchModule,
        ChangesModule,
        SavedSearchModule,
        DateRangeDisplayDynamicModule
    ],
    exports: [
        AuthenticationModule,
        CustomCommonModule,
        CustomFormsModule,
        DisplayHelperModule,
        FilterModule,
        ExperienceModule,
        AbsenceTypeModule,
        MappingModule,
        EntitySharedModule,
        EntitySearchModule,
        ChangesModule,
        SavedSearchModule,
        DateRangeDisplayDynamicModule
    ]
})
export class SharedModule {}
