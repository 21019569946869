import { RequestIdEntity } from '../../../models/api/request.model';
import { AllResponseIncludes, ResponseModelList } from '../../../models/api/response.model';
import { ApiHttpClientService, ApiGetHttpClientService } from '../../api/services/api-http-client.service';
import { Observable, EMPTY } from 'rxjs';
import { map, expand, concatAll, toArray } from 'rxjs/operators';
import { RequestListOptions } from '../../../models/api/request-list-options.model';
import { lodashHelper } from '../../core-configuration/helpers/lodash.helper';
import { AllIncludeOptions } from '../../../models/shared/include.model';

export abstract class EntityServiceBase<
    TEntity extends RequestIdEntity,
    TIncludeOptions extends AllIncludeOptions,
    TResponseIncludes extends AllResponseIncludes> {

    constructor(
        protected httpService: ApiGetHttpClientService<TEntity, TIncludeOptions, TResponseIncludes>
    ) { }

    get(id: number): Observable<TEntity> {
        return this.httpService.get([id], []).pipe(
            map(i => i.data)
        );
    }

    listInclude(options: RequestListOptions<TIncludeOptions>): Observable<ResponseModelList<TEntity, TResponseIncludes>> {
        return this.httpService.list(options);
    }

    // be careful using this function, it will continuously call the API based on the
    // options until all pages are hit
    // at it also bypasses the cache -- for now
    listIncludeGetAllStream(options: RequestListOptions<TIncludeOptions>): Observable<ResponseModelList<TEntity, TResponseIncludes>> {
        return this.getWithNext(options).pipe(
            expand(({ next }) => next ? this.getWithNext(next) : EMPTY),
            map(({ response }) => response)
        );
    }

    listIncludeGetAll(options: RequestListOptions<TIncludeOptions>): Observable<TEntity[]> {
        return this.listIncludeGetAllStream(options).pipe(
            map(response => response.data),
            concatAll(),
            toArray()
        );
    }

    protected getWithNext(options: RequestListOptions<TIncludeOptions>) {
        return this.listInclude(options).pipe(
            map(response => ({
                response: response,
                next: this.nextListResponse(response, options)
            }))
        );
    }

    private nextListResponse(response: ResponseModelList<TEntity, TResponseIncludes>, options: RequestListOptions<TIncludeOptions>): RequestListOptions<TIncludeOptions> | undefined  {
        let nextOptions: RequestListOptions<TIncludeOptions> | undefined;
        const skipTake = response.query.skip + response.query.take;
        if (skipTake < response.total) {
            nextOptions = lodashHelper.cloneDeep(options);
            nextOptions.skip = skipTake;
        }

        return nextOptions;
    }
}

export abstract class CrudEntityServiceBase<
    TEntity extends RequestIdEntity,
    TInlcudeOptions extends AllIncludeOptions,
    TResponseIncludes extends AllResponseIncludes>
    extends EntityServiceBase<TEntity, TInlcudeOptions, TResponseIncludes> {

    constructor(
        protected httpService: ApiHttpClientService<TEntity, TInlcudeOptions, TResponseIncludes>
    ) {
        super(httpService);
    }

    create(entity: TEntity): Observable<TEntity> {
        return this.httpService.create(entity).pipe(
            map(i => i.data)
        );
    }

    update(entity: TEntity): Observable<TEntity> {
        return this.httpService.update(entity).pipe(
            map(i => i.data)
        );
    }

    updatePartial(entity: Partial<TEntity>): Observable<TEntity> {
        return this.httpService.update(entity).pipe(
            map(i => i.data)
        );
    }

    delete(entity: TEntity): Observable<Object> {
        return this.httpService.delete([entity.id]);
    }
}
