import { Observable ,  Subject ,  throwError, of } from 'rxjs';
import { ResponseModel } from '../../../models/api/response.model';
import { tap, catchError, finalize } from 'rxjs/operators';


interface ICacheContent {
    expiry: number;
    value: any;
}

/**
 * Cache Service is an observables based in-memory cache implementation
 * Keeps track of in-flight observables and sets a default expiry for cached values
 * @export
 * @class CacheService
 */
export class ApiCacheSimpleService {
    private cache: Map<string, ICacheContent> = new Map<string, ICacheContent>();
    private inFlightObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();
    readonly DEFAULT_MAX_AGE: number = 300000; // 5 mins

    /**
     * Gets the value from cache if the key is provided.
     * If no value exists in cache, then check if the same call exists
     * in flight, if so return the subject. If not create a new
     * Subject inFlightObservable and return the source observable.
     */
    get<T>(key: string, fallback?: Observable<ResponseModel<T>>, maxAge?: number): Observable<ResponseModel<T>> | Subject<ResponseModel<T>> {

        if (this.hasValidCachedValue(key)) {

            // console.log(`%cGetting from cache ${key}`, 'color: green');

            return of(this.cache.get(key)!.value);
        }

        if (!maxAge) {
            maxAge = this.DEFAULT_MAX_AGE;
        }

        if (this.inFlightObservables.has(key)) {
            return this.inFlightObservables.get(key)!;

        } else if (fallback && fallback instanceof Observable) {
            this.inFlightObservables.set(key, new Subject());
            console.log(`%c Calling api for ${key}`, 'color: purple');

            const fallbackHandle: Observable<ResponseModel<T>> = fallback.pipe(
                tap((value) => { this.set(key, value, maxAge); }),
                catchError(error => {
                    console.log('error in caching stream', error);
                    // see comment in api-cache.service.ts
                    this.handleInFlightOnError(key, error);
                    return throwError(error); // Observable.of(i);
                }),
                finalize(() => {
                    console.log('on complete was actually called, usually occurs when a http request is cancelled');
                    this.handleInFlightOnCancel(key, fallbackHandle);
                })
            );

            return fallbackHandle;

        } else {
            return throwError('Requested key is not available in Cache');
        }

    }

    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     */
    set(key: string, value: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
        this.cache.set(key, { value: value, expiry: Date.now() + maxAge });
        this.notifyInFlightObservers(key, value);
    }

    /**
     * Checks if the a key exists in cache
     */
    has(key: string): boolean {
        return this.cache.has(key);
    }

    remove(key: string) {
        if (this.hasValidCachedValue(key)) {
            this.cache.delete(key);
        }
    }

    /**
     * Publishes the value to all observers of the given
     * in progress observables if observers exist.
     */
    private notifyInFlightObservers(key: string, value: any): void {
        if (!this.inFlightObservables.has(key)) { return; }

        const inFlight = this.inFlightObservables.get(key)!;
        const observersCount = inFlight.observers.length;

        if (observersCount) {
            console.log(`%cNotifying ${inFlight.observers.length} flight subscribers for ${key}`, 'color: blue', value);
            inFlight.next(value);
        }

        inFlight.complete();

        this.inFlightObservables.delete(key);
    }

    private handleInFlightOnError(key: string, error: any) {
        if (!this.inFlightObservables.has(key)) { return; }

        const inFlight = this.inFlightObservables.get(key)!;
        inFlight.error(error);

        this.inFlightObservables.delete(key);
    }

    private handleInFlightOnCancel<T>(key: string, fallback: Observable<ResponseModel<T>>) {
        if (!this.inFlightObservables.has(key)) { return; }

        // this works... but i'm not sure if it's the best way
        fallback.toPromise();
    }

    /**
     * Checks if the key exists and   has not expired.
     */
    private hasValidCachedValue(key: string): boolean {
        if (this.cache.has(key)) {
            if (this.cache.get(key)!.expiry < Date.now()) {
                this.cache.delete(key);
                return false;
            }
            return true;
        } else {
            return false;
        }
    }
}
