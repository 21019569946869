import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ConfigurationService } from '../../../core-configuration/services/configuration.service';
import { ConfigurationEntityListItemBase } from '../entity-list-item/configuration-entity-list-item.base';

@Component({
    selector: 'cp-absence-type-entity-list-item',
    templateUrl: 'absence-type-entity-list-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AbsenceTypeEntityListItemComponent extends ConfigurationEntityListItemBase {
    constructor(
        protected configService: ConfigurationService,
        protected changeDetectionRef: ChangeDetectorRef
    ) {
        super('absenceType', configService, changeDetectionRef);
    }
}
