import { PipeTransform } from '@angular/core';
export function minutesToHoursDisplay(minutes) {
    if (!minutes) {
        return '';
    }
    var hours = minutes / 60;
    return "" + Math.round(hours * 100) / 100;
}
var MinutesPipe = /** @class */ (function () {
    function MinutesPipe() {
    }
    MinutesPipe.prototype.transform = function (minutes) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return minutesToHoursDisplay(minutes);
    };
    return MinutesPipe;
}());
export { MinutesPipe };
