var BootstrapAlertComponent = /** @class */ (function () {
    function BootstrapAlertComponent() {
        this.iconRight = false;
        this.small = false;
    }
    Object.defineProperty(BootstrapAlertComponent.prototype, "faIcon", {
        get: function () {
            if (this.icon) {
                return this.icon;
            }
            switch (this.type) {
                case 'primary': return 'thumbs-up';
                case 'success': return 'thumbs-up';
                case 'danger': return 'exclamation-triangle';
                case 'warning': return 'bell';
                case 'info':
                default: return 'info-circle';
            }
        },
        enumerable: true,
        configurable: true
    });
    return BootstrapAlertComponent;
}());
export { BootstrapAlertComponent };
