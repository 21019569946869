import { Moment } from 'moment';
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { ILoggedType } from '../shared/logged.model';
import { MinutesPipe } from '../../modules/display-helper/pipes/minutes.pipe';
import { AbsenceTypeEntityListItemComponent } from '../../modules/configuration-entity-shared/components/absence-type-entity-list-item/absence-type-entity-list-item.component';

export type TimesheetType = 'attended' | 'absent' | 'sick' | 'leave' | 'multiple';

/* NOTE: Timehseets are the only Core Type that isn't an Entity
   However, it can still be logged, and therefore inherits the ILoggedEntity interface. */

@CpClass({
    metadataType: 'timesheet',
    create: newTimesheet
})
export class Timesheet implements ILoggedType {

    @CpProperty({ type: 'int32', hideFromList: true })
    id: number;

    @CpProperty({ type: 'int32', hideFromList: true })
    placementId: number;

    @CpProperty({ type: 'date' })
    date: Moment;

    @CpProperty({ label: 'Hours', type: 'int32', defaultListVisible: true, pipe: { pipe: new MinutesPipe() }})
    minutes: number;

    @CpProperty({ label: 'Absence Type', type: 'int32', listHeaderAlign: 'left', defaultListVisible: true, dynamicComponent: AbsenceTypeEntityListItemComponent })
    absenceTypeId?: number;

    @CpProperty({ type: 'date' })
    added?: Moment;

    @CpProperty({ type: 'int32', hideFromList: true })
    addedUserId?: number;

    @CpProperty({ type: 'date' })
    lastUpdated?: Moment;

    @CpProperty({ type: 'int32', hideFromList: true })
    lastUpdatedUserId?: number;
}

export function newTimesheet() { return new Timesheet(); }
