var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiHttpClientService } from '../../../api/services/api-http-client.service';
import { HttpClient } from '@angular/common/http';
import { ApiRequestHelperService } from '../../../api/services/api-request-helper.service';
var StudentHttpService = /** @class */ (function (_super) {
    __extends(StudentHttpService, _super);
    function StudentHttpService(httpClient, apiRequestHelperService) {
        var _this = _super.call(this, 'students', httpClient, apiRequestHelperService) || this;
        _this.httpClient = httpClient;
        _this.apiRequestHelperService = apiRequestHelperService;
        return _this;
    }
    StudentHttpService.prototype.search = function (options) {
        var url = this.generateUrl({ options: options, path: ['search'] });
        return this.httpClient.get(url);
    };
    StudentHttpService.prototype.addToPlan = function (planId, studentIds) {
        var url = this.generateUrl({ path: ['plans', 'add', planId] });
        return this.httpClient.post(url, { studentIds: studentIds });
    };
    StudentHttpService.prototype.removeFromPlan = function (planId, studentIds) {
        var url = this.generateUrl({ path: ['plans', 'remove', planId] });
        return this.httpClient.post(url, { studentIds: studentIds });
    };
    StudentHttpService.prototype.bulk = function (students) {
        var url = this.generateUrl({ path: ['bulk'] });
        return this.httpClient.post(url, students);
    };
    return StudentHttpService;
}(ApiHttpClientService));
export { StudentHttpService };
