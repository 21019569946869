import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

export type AlertType = 'info' | 'danger' | 'warning' | 'success' | 'primary';

@Component({
    selector: 'cp-bootstrap-alert',
    templateUrl: 'bootstrap-alert.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class BootstrapAlertComponent {

    @Input() type: AlertType;
    @Input() icon: string;
    @Input() iconRight = false;
    @Input() small = false;

    get faIcon(): string {
        if (this.icon) { return this.icon; }

        switch (this.type) {
            case 'primary' : return 'thumbs-up';
            case 'success' : return 'thumbs-up';
            case 'danger' : return 'exclamation-triangle';
            case 'warning' : return 'bell';
            case 'info' :
            default: return 'info-circle';
        }
    }
}
