import { OnInit, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { map } from 'rxjs/operators';
var IfAuthedDirective = /** @class */ (function () {
    function IfAuthedDirective(templateRef, service, viewContainer) {
        this.templateRef = templateRef;
        this.service = service;
        this.viewContainer = viewContainer;
    }
    Object.defineProperty(IfAuthedDirective.prototype, "ifAuthed", {
        set: function (condition) {
            this.condition = condition;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IfAuthedDirective.prototype, "forUserType", {
        set: function (val) {
            if (!(val instanceof Array)) {
                val = [val];
            }
            this.allowedUserTypes = val;
        },
        enumerable: true,
        configurable: true
    });
    IfAuthedDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.sub = this.service.user$.pipe(map(function (user) {
            var newValue = _this.service.isAuthenticated();
            var userType = _this.service.getUserType();
            var newCorrectRoleValue = true;
            if (_this.allowedUserTypes && _this.allowedUserTypes.length > 0) {
                newCorrectRoleValue = userType ? _this.allowedUserTypes.includes(userType) : false;
            }
            if (_this.isAuthenticated === newValue && _this.isCorrectRole === newCorrectRoleValue) {
                return;
            }
            _this.isAuthenticated = newValue;
            _this.isCorrectRole = newCorrectRoleValue;
            if ((_this.isAuthenticated && _this.condition && _this.isCorrectRole) ||
                (!_this.isAuthenticated && !_this.condition)) {
                _this.viewContainer.createEmbeddedView(_this.templateRef);
            }
            else {
                _this.viewContainer.clear();
            }
        })).subscribe();
    };
    IfAuthedDirective.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    };
    return IfAuthedDirective;
}());
export { IfAuthedDirective };
