var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IncludeEntityMetadata, IncludeEntityMetadataCore } from '../shared/metadata.model';
import { EntityBase } from '../entity/entity.model';
var logInclude = new IncludeEntityMetadata('entity', 'entities', 'id', 'id');
var planInclude = new IncludeEntityMetadata('plan', 'plans', 'planIds', 'id');
var addressInclude = new IncludeEntityMetadata('studentAddress', 'studentAddresses', 'id', 'studentId');
var courseInclude = new IncludeEntityMetadataCore('studentCourse', 'studentCourses', 'id', 'studentId');
var currentCourseInclude = new IncludeEntityMetadataCore('studentCourse', 'studentCurrentCourses', 'id', 'studentId');
var Student = /** @class */ (function (_super) {
    __extends(Student, _super);
    function Student() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        CpProperty({ type: 'string', defaultListVisible: true, showInDetails: true }),
        __metadata("design:type", String)
    ], Student.prototype, "externalId", void 0);
    __decorate([
        CpProperty({ type: 'string' }),
        __metadata("design:type", String)
    ], Student.prototype, "firstName", void 0);
    __decorate([
        CpProperty({ type: 'string' }),
        __metadata("design:type", String)
    ], Student.prototype, "lastName", void 0);
    __decorate([
        CpProperty({ type: 'emailAddress', showInDetails: true }),
        __metadata("design:type", String)
    ], Student.prototype, "email", void 0);
    __decorate([
        CpProperty({ type: 'phoneNumber', showInDetails: true }),
        __metadata("design:type", String)
    ], Student.prototype, "phoneNumber", void 0);
    __decorate([
        CpProperty({ type: 'int32' }),
        __metadata("design:type", Array)
    ], Student.prototype, "planIds", void 0);
    Student = __decorate([
        CpClass({
            include: [logInclude],
            includeMany: [planInclude, addressInclude, courseInclude, currentCourseInclude],
            metadataType: 'student',
            defaultSort: [{ key: 'lastName' }, { key: 'firstName' }],
            create: newStudent
        })
    ], Student);
    return Student;
}(EntityBase));
export { Student };
export function newStudent() { return new Student(); }
