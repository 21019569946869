import { Injectable } from '@angular/core';
import { ReportHttpService } from './http/report.http.service';
import { Report, ReportResponse, ReportRequestRunQuery, ReportRequestDownloadQuery, ReportRequestQuery } from '../../../models/report/report.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ReportSessionService } from './report-session.service';

@Injectable()
export class ReportService {

    constructor(
        private httpService: ReportHttpService,
        private reportSessionService: ReportSessionService
    ) { }

    list(): Observable<Report[]> {
        return this.httpService.list({}).pipe(
            map(i => i.data)
        );
    }

    get(key: string): Observable<Report> {
        return this.httpService.get([key], []).pipe(
            map(i => i.data)
        );
    }

    run(request: ReportRequestRunQuery): Observable<ReportResponse> {

        this.populateTablesParam(request);

        return this.httpService.run(request).pipe(
            tap(i => this.reportSessionService.initialize(i))
        );
    }

    download(request: ReportRequestDownloadQuery): Observable<Blob> {
        const store = this.reportSessionService.getReportStore(request.key);
        request.tableColumns = store.tables
            .map((table, index) => {
                const allVisible = table.columns.every(c => c.visible);
                if (allVisible) {
                    return undefined as any;
                }

                return {
                    index: index,
                    columns: table.columns.filter(c => c.visible).map(c => c.name)
                };
            })
            .filter(i => i !== undefined);

        this.populateTablesParam(request);

        return this.httpService.download(request);
    }

    private populateTablesParam(request: ReportRequestQuery) {
        const store = this.reportSessionService.getReportStore(request.key, { allowUndefined: true });

        if (store) {
            request.tables = store.tables
                .map((table, index) => ({
                    name: table.name,
                    visible: table.visible,
                    index: index
                }));
        }
    }
}
