/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../property-display/property-display.component.ngfactory";
import * as i2 from "../property-display/property-display.component";
import * as i3 from "@angular/common";
import * as i4 from "./json-to-property-display.component";
var styles_JsonToPropertyDisplayComponent = [];
var RenderType_JsonToPropertyDisplayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_JsonToPropertyDisplayComponent, data: {} });
export { RenderType_JsonToPropertyDisplayComponent as RenderType_JsonToPropertyDisplayComponent };
function View_JsonToPropertyDisplayComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["property", ""]], null, null, null, null, null))], null, null); }
function View_JsonToPropertyDisplayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "cp-property-display", [["class", "mb-1 pb-1 d-block property-display"]], [[2, "property-display-margin", null], [2, "border-light", null], [2, "border-bottom", null]], null, null, i1.View_PropertyDisplayComponent_0, i1.RenderType_PropertyDisplayComponent)), i0.ɵdid(1, 114688, null, 0, i2.PropertyDisplayComponent, [], { removeMargin: [0, "removeMargin"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "span", [["label", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, 1, 2, null, View_JsonToPropertyDisplayComponent_2)), i0.ɵdid(5, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(6, { $implicit: 0 })], function (_ck, _v) { var currVal_3 = true; _ck(_v, 1, 0, currVal_3); var currVal_5 = _ck(_v, 6, 0, _v.context.$implicit.value); var currVal_6 = i0.ɵnov(_v.parent, 3); _ck(_v, 5, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).margin; var currVal_1 = i0.ɵnov(_v, 1).borderColor; var currVal_2 = i0.ɵnov(_v, 1).borderBottom; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.key; _ck(_v, 3, 0, currVal_4); }); }
function View_JsonToPropertyDisplayComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["property", ""]], null, null, null, null, null))], null, null); }
function View_JsonToPropertyDisplayComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "cp-property-display", [["class", "mb-1 pb-1 d-block property-display"]], [[2, "property-display-margin", null], [2, "border-light", null], [2, "border-bottom", null]], null, null, i1.View_PropertyDisplayComponent_0, i1.RenderType_PropertyDisplayComponent)), i0.ɵdid(1, 114688, null, 0, i2.PropertyDisplayComponent, [], { removeMargin: [0, "removeMargin"], removeBorder: [1, "removeBorder"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "span", [["label", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, 1, 2, null, View_JsonToPropertyDisplayComponent_6)), i0.ɵdid(5, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(6, { $implicit: 0 })], function (_ck, _v) { var currVal_3 = true; var currVal_4 = true; _ck(_v, 1, 0, currVal_3, currVal_4); var currVal_6 = _ck(_v, 6, 0, _v.context.$implicit.value); var currVal_7 = i0.ɵnov(_v.parent.parent.parent, 3); _ck(_v, 5, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).margin; var currVal_1 = i0.ɵnov(_v, 1).borderColor; var currVal_2 = i0.ɵnov(_v, 1).borderBottom; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _v.context.$implicit.key; _ck(_v, 3, 0, currVal_5); }); }
function View_JsonToPropertyDisplayComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_JsonToPropertyDisplayComponent_5)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i3.KeyValuePipe, [i0.KeyValueDiffers]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_v.parent.context.$implicit)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_JsonToPropertyDisplayComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_JsonToPropertyDisplayComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_JsonToPropertyDisplayComponent_9)), i0.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { $implicit: 0 })], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); var currVal_1 = i0.ɵnov(_v.parent.parent.parent, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_JsonToPropertyDisplayComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_JsonToPropertyDisplayComponent_8)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_JsonToPropertyDisplayComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_JsonToPropertyDisplayComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_JsonToPropertyDisplayComponent_4)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_JsonToPropertyDisplayComponent_7)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_JsonToPropertyDisplayComponent_10)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isObject(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isArray(_v.context.$implicit); _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.isArray(_v.context.$implicit) && !_co.isObject(_v.context.$implicit)); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_JsonToPropertyDisplayComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_JsonToPropertyDisplayComponent_1)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i3.KeyValuePipe, [i0.KeyValueDiffers]), (_l()(), i0.ɵand(0, [["template", 2]], null, 0, null, View_JsonToPropertyDisplayComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.item)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_JsonToPropertyDisplayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-json-to-property-display", [], null, null, null, View_JsonToPropertyDisplayComponent_0, RenderType_JsonToPropertyDisplayComponent)), i0.ɵdid(1, 49152, null, 0, i4.JsonToPropertyDisplayComponent, [], null, null)], null, null); }
var JsonToPropertyDisplayComponentNgFactory = i0.ɵccf("cp-json-to-property-display", i4.JsonToPropertyDisplayComponent, View_JsonToPropertyDisplayComponent_Host_0, { item: "item" }, {}, []);
export { JsonToPropertyDisplayComponentNgFactory as JsonToPropertyDisplayComponentNgFactory };
