import { NgModule } from '@angular/core';
import { AlertHttpService } from './services/http/alert.http.service';
import { AlertService } from './services/alert.service';
import { AlertResultListComponent } from './components/alert-result/alert-result-list.component';
import { AlertListComponent } from './components/alert/alert-list.component';
import { AlertDetailsComponent } from './components/alert/alert-details.component';
import { AlertFormComponent } from './components/alert/alert-form.component';
import { EntityAlertResultsComponent } from './components/alert-result/entity-alert-results.component';
import { AlertResultItemComponent } from './components/alert-result/alert-result-item.component';
import { CustomCommonModule } from '../common/common.module';
import { CustomFormsModule } from '../form/form.module';
import { DisplayHelperModule } from '../display-helper/display-helper.module';
import { EntitySharedModule } from '../entity-shared/entity-shared.module';
import { FilterModule } from '../filter/filter.module';

@NgModule({
    imports: [
        CustomCommonModule.forChild(),
        CustomFormsModule,
        DisplayHelperModule,
        EntitySharedModule,
        FilterModule
    ],
    declarations: [
        AlertListComponent,
        AlertResultListComponent,
        AlertDetailsComponent,
        AlertFormComponent,
        EntityAlertResultsComponent,
        AlertResultItemComponent
    ],
    providers: [
        AlertService,
        AlertHttpService
    ],
    exports: [
        AlertListComponent,
        AlertResultListComponent,
        AlertDetailsComponent,
        AlertFormComponent,
        EntityAlertResultsComponent
    ]
})
export class AlertModule { }
