/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./filter-display.component.ngfactory";
import * as i2 from "./filter-display.component";
import * as i3 from "../../../core-configuration/services/configuration.service";
import * as i4 from "../../services/filter-config-inner.service";
import * as i5 from "../../services/response-filter.service";
import * as i6 from "@angular/common";
import * as i7 from "./filter-display-dynamic.component";
var styles_FilterDisplayDynamic = [];
var RenderType_FilterDisplayDynamic = i0.ɵcrt({ encapsulation: 2, styles: styles_FilterDisplayDynamic, data: {} });
export { RenderType_FilterDisplayDynamic as RenderType_FilterDisplayDynamic };
function View_FilterDisplayDynamic_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-filter-display", [], null, null, null, i1.View_FilterDisplayComponent_0, i1.RenderType_FilterDisplayComponent)), i0.ɵdid(1, 245760, null, 0, i2.FilterDisplayComponent, [i3.ConfigurationService, i4.FilterConfigInnerService, i5.ResponseFilterService, i0.ChangeDetectorRef], { filter: [0, "filter"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dynamicValue; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FilterDisplayDynamic_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterDisplayDynamic_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dynamicValue; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FilterDisplayDynamic_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-filter-display-dynamic", [], null, null, null, View_FilterDisplayDynamic_0, RenderType_FilterDisplayDynamic)), i0.ɵdid(1, 49152, null, 0, i7.FilterDisplayDynamic, [], null, null)], null, null); }
var FilterDisplayDynamicNgFactory = i0.ɵccf("cp-filter-display-dynamic", i7.FilterDisplayDynamic, View_FilterDisplayDynamic_Host_0, { dynamicValue: "dynamicValue" }, {}, []);
export { FilterDisplayDynamicNgFactory as FilterDisplayDynamicNgFactory };
