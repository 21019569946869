import * as moment from 'moment';
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IIncludeEntity, PositionIncludeType } from '../shared/include.model';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { Provider } from './provider.model';
import { Site } from './site.model';
import { ExperienceEntityListItemComponent } from '../../modules/configuration-entity-shared/components/experience-entity-list-item/experience-entity-list-item.component';
import { Contact } from './contact.model';
import { Placement } from '../placement/placement.model';
import { EntityBase } from '../entity/entity.model';
import { SearchResult } from '../shared/search.model';
import { EntityLog } from '../shared/logged.model';

const providerInclude = new IncludeEntityMetadata<Position, Provider>('provider', 'providers', 'providerId', 'id');
const siteInclude = new IncludeEntityMetadata<Position, Site>('site', 'sites', 'siteId', 'id');
const logInclude = new IncludeEntityMetadata<Position, EntityLog>('entity', 'entities', 'id', 'id');

const contactsInclude = new IncludeEntityMetadata<Position, Contact>('contact', 'contacts', 'contactIds', 'id');
const placementsInclude = new IncludeEntityMetadata<Position, Placement>('placement', 'placements', 'id', 'positionId', { hideFromList: true });

@CpClass({
    include: [providerInclude, siteInclude, logInclude],
    includeMany: [contactsInclude, placementsInclude],
    metadataType: 'position',
    create: newPosition
})
export class Position extends EntityBase implements IIncludeEntity<PositionIncludeType> {

    @CpProperty({ type: 'int32' })
    providerId: number;

    @CpProperty({ type: 'int32' })
    siteId: number;

    @CpProperty({ type: 'int32' })
    contactIds: number[];

    @CpProperty({ type: 'string' })
    name: string;

    @CpProperty({ type: 'multilineText' })
    description: string;

    @CpProperty({ label: 'Experiences', listHeaderAlign: 'left', type: 'int32', dynamicComponent: ExperienceEntityListItemComponent })
    experienceIds: number[];

    @CpProperty({ type: 'int32', showInDetails: true })
    capacity?: number;

    @CpProperty({ type: 'date' })
    availableFrom: moment.Moment;

    @CpProperty({ type: 'date' })
    availableTo?: moment.Moment;

    constructor() {
        super();
        this.contactIds = [];
        this.experienceIds = [];
    }
}


export function newPosition() { return new Position(); }

export interface CapacityResult {
    id: number;
    capacity?: number;
    placementCount: number;
}

export interface PositionResult extends SearchResult {
    positionName: string;
    siteName: string;
    providerName: string;
    capacity?: number;
}

export interface PositionMatchResult extends PositionResult {
    placementCount: number;
}
