var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { EntityBase } from '../entity/entity.model';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { ResponseFilter } from '../api/response.model';
import { FilterDisplayDynamic } from '../../modules/filter/components/filter-display/filter-display-dynamic.component';
var logInclude = new IncludeEntityMetadata('entity', 'entities', 'id', 'id');
var blockInclude = new IncludeEntityMetadata('planBlock', 'planBlocks', 'id', 'planId', { hideFromList: true });
var Plan = /** @class */ (function (_super) {
    __extends(Plan, _super);
    function Plan() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        CpProperty({ label: 'Name', type: 'string', showInDetails: true }),
        __metadata("design:type", String)
    ], Plan.prototype, "name", void 0);
    __decorate([
        CpProperty({ label: 'Description', type: 'multilineText', showInDetails: true }),
        __metadata("design:type", String)
    ], Plan.prototype, "description", void 0);
    __decorate([
        CpProperty({ type: 'dynamic', dynamicComponent: FilterDisplayDynamic }),
        __metadata("design:type", ResponseFilter)
    ], Plan.prototype, "studentFilter", void 0);
    __decorate([
        CpProperty({ type: 'date', hideFromList: true }),
        __metadata("design:type", Object)
    ], Plan.prototype, "start", void 0);
    __decorate([
        CpProperty({ type: 'date', hideFromList: true }),
        __metadata("design:type", Object)
    ], Plan.prototype, "end", void 0);
    Plan = __decorate([
        CpClass({
            include: [logInclude],
            includeMany: [blockInclude],
            defaultSort: [{ key: 'name', desending: true }],
            metadataType: 'plan',
            create: newPlan
        })
    ], Plan);
    return Plan;
}(EntityBase));
export { Plan };
export function newPlan() { return new Plan(); }
