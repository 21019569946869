var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
var EntityBase = /** @class */ (function () {
    function EntityBase() {
        this.properties = {};
    }
    // override this in parent class if you so wish
    // this is used with the csv download for the main column
    EntityBase.prototype.toString = function () {
        return entityToString(this);
    };
    __decorate([
        CpProperty({ type: 'int32', hideFromList: true }),
        __metadata("design:type", Number)
    ], EntityBase.prototype, "id", void 0);
    return EntityBase;
}());
export { EntityBase };
export function entityToString(entity) {
    if (nameGuard(entity)) {
        return entity.name;
    }
    if (personGuard(entity)) {
        return entity.lastName + ", " + entity.firstName;
    }
    if (dateGuard(entity)) {
        return entity.start.format(environment.momentDateFormat) + " > " + entity.end.format(environment.momentDateFormat);
    }
    return entity.id ? entity.id.toString() : 'Unknown Entity';
}
export function nameGuard(tbd) {
    return tbd.name !== undefined;
}
export function personGuard(tbd) {
    return tbd.firstName && tbd.lastName;
}
export function dateGuard(tbd) {
    return tbd.start && tbd.end && moment.isMoment(tbd.start) && moment.isMoment(tbd.end);
}
