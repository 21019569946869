/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./bootstrap-alert.component";
var styles_BootstrapAlertComponent = [];
var RenderType_BootstrapAlertComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BootstrapAlertComponent, data: {} });
export { RenderType_BootstrapAlertComponent as RenderType_BootstrapAlertComponent };
function View_BootstrapAlertComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "flex-shrink-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"], ["size", "lg"]], [[8, "innerHTML", 1]], null, null, i1.View_FaIconComponent_0, i1.RenderType_FaIconComponent)), i0.ɵdid(2, 573440, null, 0, i2.FaIconComponent, [i3.DomSanitizer, i2.FaIconService], { iconProp: [0, "iconProp"], size: [1, "size"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.faIcon; var currVal_2 = "lg"; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0); }); }
function View_BootstrapAlertComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "flex-shrink-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"], ["size", "lg"]], [[8, "innerHTML", 1]], null, null, i1.View_FaIconComponent_0, i1.RenderType_FaIconComponent)), i0.ɵdid(2, 573440, null, 0, i2.FaIconComponent, [i3.DomSanitizer, i2.FaIconService], { iconProp: [0, "iconProp"], size: [1, "size"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.faIcon; var currVal_2 = "lg"; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0); }); }
export function View_BootstrapAlertComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], [[8, "className", 0], [2, "alert-sm", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BootstrapAlertComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "flex-grow-1 alert-body alert-text mt-0"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BootstrapAlertComponent_2)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.iconRight; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.iconRight; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "alert alert-", _co.type, ""); var currVal_1 = _co.small; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_BootstrapAlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-bootstrap-alert", [], null, null, null, View_BootstrapAlertComponent_0, RenderType_BootstrapAlertComponent)), i0.ɵdid(1, 49152, null, 0, i5.BootstrapAlertComponent, [], null, null)], null, null); }
var BootstrapAlertComponentNgFactory = i0.ɵccf("cp-bootstrap-alert", i5.BootstrapAlertComponent, View_BootstrapAlertComponent_Host_0, { type: "type", icon: "icon", iconRight: "iconRight", small: "small" }, {}, ["*"]);
export { BootstrapAlertComponentNgFactory as BootstrapAlertComponentNgFactory };
