import { Component, Input } from '@angular/core';
import { IEntityListItemDynamicComponent } from '../../../entity-shared/components/entity-list/entity-list-item-dynamic.component';

@Component({
    selector: 'cp-archive-display-dynamic',
    templateUrl: 'archive-display-dynamic.component.html'
})

export class ArchiveDisplayDynamicComponent implements IEntityListItemDynamicComponent {
    @Input() dynamicValue: boolean;
}
