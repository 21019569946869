import { CpClass, CpProperty } from '../../modules/core-configuration/decorators/metadata.decorator';
import { IIncludeEntity, PlanIncludeType } from '../shared/include.model';
import { EntityBase, IProvisionable } from '../entity/entity.model';
import { IncludeEntityMetadata } from '../shared/metadata.model';
import { EntityLog } from '../shared/logged.model';
import { ResponseFilter } from '../api/response.model';
import { Block } from './block.model';
import { Moment } from 'moment';
import { FilterDisplayDynamic } from '../../modules/filter/components/filter-display/filter-display-dynamic.component';

const logInclude = new IncludeEntityMetadata<Plan, EntityLog>('entity', 'entities', 'id', 'id');
const blockInclude = new IncludeEntityMetadata<Plan, Block>('planBlock', 'planBlocks', 'id', 'planId', { hideFromList: true });

@CpClass({
    include: [logInclude],
    includeMany: [blockInclude],
    defaultSort: [{ key: 'name', desending: true }],
    metadataType: 'plan',
    create: newPlan
})
export class Plan extends EntityBase implements IIncludeEntity<PlanIncludeType>, IProvisionable {

    @CpProperty({ label: 'Name', type: 'string', showInDetails: true })
    name: string;

    @CpProperty({ label: 'Description', type: 'multilineText', showInDetails: true })
    description: string;

    // hidden from lists for now, will reenable when i have a filter display type
    @CpProperty({ type: 'dynamic', dynamicComponent: FilterDisplayDynamic })
    studentFilter: ResponseFilter;

    // These two properties don't actually exist on the object
    // but I need them here to appear on the sort menu...
    @CpProperty({ type: 'date', hideFromList: true })
    start: Moment;

    @CpProperty({ type: 'date', hideFromList: true })
    end: Moment;

    provisioningKey?: string;
    provisioned?: boolean;
    provisionedClientId?: string;
}


export function newPlan() { return new Plan(); }
