/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./date-range-breaks-display.component.ngfactory";
import * as i2 from "./date-range-breaks-display.component";
import * as i3 from "../../../core-configuration/helpers/moment.helper";
import * as i4 from "@angular/common";
import * as i5 from "./date-range-breaks-display-dynamic.component";
var styles_DateRangeBreaksDisplayDynamicComponent = [];
var RenderType_DateRangeBreaksDisplayDynamicComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DateRangeBreaksDisplayDynamicComponent, data: {} });
export { RenderType_DateRangeBreaksDisplayDynamicComponent as RenderType_DateRangeBreaksDisplayDynamicComponent };
function View_DateRangeBreaksDisplayDynamicComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-date-range-breaks-display", [], null, null, null, i1.View_DateRangeBreaksDisplayComponent_0, i1.RenderType_DateRangeBreaksDisplayComponent)), i0.ɵdid(1, 573440, null, 0, i2.DateRangeBreaksDisplayComponent, [i3.MomentHelper], { start: [0, "start"], end: [1, "end"], overlaps: [2, "overlaps"], removeMarginBottom: [3, "removeMarginBottom"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dynamicValue.start; var currVal_1 = _co.dynamicValue.end; var currVal_2 = _co.dynamicValue.overlaps; var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_DateRangeBreaksDisplayDynamicComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DateRangeBreaksDisplayDynamicComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dynamicValue; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DateRangeBreaksDisplayDynamicComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-date-range-breaks-display-dynamic", [], null, null, null, View_DateRangeBreaksDisplayDynamicComponent_0, RenderType_DateRangeBreaksDisplayDynamicComponent)), i0.ɵdid(1, 49152, null, 0, i5.DateRangeBreaksDisplayDynamicComponent, [], null, null)], null, null); }
var DateRangeBreaksDisplayDynamicComponentNgFactory = i0.ɵccf("cp-date-range-breaks-display-dynamic", i5.DateRangeBreaksDisplayDynamicComponent, View_DateRangeBreaksDisplayDynamicComponent_Host_0, { dynamicValue: "dynamicValue" }, {}, []);
export { DateRangeBreaksDisplayDynamicComponentNgFactory as DateRangeBreaksDisplayDynamicComponentNgFactory };
