var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { SimpleChanges, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { CpRequiredOnInit } from '../../../core-configuration/decorators/required-on-init.decorator';
import { MomentHelper } from '../../../core-configuration/helpers/moment.helper';
var DateRangeBreaksDisplayComponent = /** @class */ (function () {
    function DateRangeBreaksDisplayComponent(momentHelper) {
        this.momentHelper = momentHelper;
        this.overlaps = [];
        this.removeMarginBottom = false;
    }
    DateRangeBreaksDisplayComponent.prototype.ngOnChanges = function (changes) {
        if (!this.overlaps) {
            this.ranges = [];
        }
        else {
            this.ranges = this.overlaps.sort(this.momentHelper.dateSort);
        }
    };
    __decorate([
        CpRequiredOnInit(),
        __metadata("design:type", Object)
    ], DateRangeBreaksDisplayComponent.prototype, "start", void 0);
    __decorate([
        CpRequiredOnInit(),
        __metadata("design:type", Object)
    ], DateRangeBreaksDisplayComponent.prototype, "end", void 0);
    return DateRangeBreaksDisplayComponent;
}());
export { DateRangeBreaksDisplayComponent };
