import { Injectable, NgZone } from '@angular/core';
import { ILoggingService } from './logging.service';
import { IErrorContext } from '../interceptors/error-handler.interceptor';
import { BrowserClient, Hub, User, Severity } from '@sentry/browser';
import { environment } from '../../../../environments/environment';
import { RoutingHistoryService } from './routing-history.service';

// useful docs: https://docs.sentry.io/enriching-error-data/context/?platform=javascript

@Injectable()
export class SentryLoggingService implements ILoggingService {

    private client: BrowserClient;

    constructor(
        private zone: NgZone,
        private routerHistoryService: RoutingHistoryService
    ) {
        this.client = new BrowserClient({
            dsn: environment.sentryDsn,
            release: environment.commitRef
        });
    }

    logError(error: IErrorContext): void {
        this.handleZones(error);

        // if (error.clientError) {
        //     console.error(error.clientError, error);
        // } else {
        //     console.error(error);
        // }
    }

    private handleZones(error: IErrorContext) {
        if (NgZone.isInAngularZone()) {
            this.notifySentry(error);
        } else {
            this.zone.run(() => {
                this.notifySentry(error);
            });
        }

    }

    private notifySentry(error: IErrorContext) {
        const hub = new Hub(this.client);

        hub.configureScope(scope => {
            if (error.user) {
                const sentryUser: User = {
                    username: `${error.user.given_name} ${error.user.family_name}`
                };

                // this requires either email, id, or username
                scope.setUser( { ...sentryUser, ...error.user });
            }

            // Tags for major error data groups
            scope.setTag('status', error.status);
            scope.setTag('route', error.route);
            scope.setTag('environment', error.environment);
            scope.setTag('errorOrigin', error.clientError ? 'client' : 'server');

            // extra info for anything extra that could be useful
            scope.setExtra('fullUrl', error.fullUrl);
            scope.setExtra('routeParameters', error.routeParameters);
            scope.setExtra('routeQueryParameters', error.routeQueryParameters);
            scope.setExtra('message', error.message);

            scope.setExtra('routeHistory', this.routerHistoryService.getHistory());

            if (!error.clientError) {
                scope.setExtra('requestUrl', error.requestUrl || 'none');
                scope.setExtra('requestBody', error.requestBody || 'none');
                scope.setExtra('requestMethod', error.requestMethod || 'none');
            }

            if (error.clientError) {
                hub.captureException(error.clientError);
            } else {
                const message = `Http Error Response ${error.status} - ${error.requestUrl}`;

                hub.captureMessage(message, Severity.Error);
            }
        });
    }
}
