<ng-container *ngIf="isNameLike; let nameLike">
    <cp-icon
        *ngIf="!isUnavailable"
        icon="archive"
        [hideIconIf]="!nameLike.archived"
        [iconTitle]="'Archived'"
        [hl]="highlights"
        hlOn="name">
        {{ nameLike.name }}
    </cp-icon>
    <cp-icon
        *ngIf="isUnavailable"
        icon="clock"
        [iconTitle]="'Unavailable'"
        [hl]="highlights"
        hlOn="name">
        {{ nameLike.name }}
    </cp-icon>
</ng-container>
<span *ngIf="isString" [hl]="highlights" hlOn="name">{{ value }}</span>
