import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ResponseQueryService } from './response-query.service';
import { map } from 'rxjs/operators';
import { ResponseSort } from '../../../models/api/response.model';
import { MetadataType } from '../../../models/entity/entity.type';

export interface ResponseSortEvent {
    path: ResponseSort[];
    type: MetadataType;
}

export type ResponseDefaultSort = {
    [key in MetadataType]?: ResponseSort[];
};

@Injectable()
export class ResponseSortService {

    private sort: ResponseSortEvent;
    private sortSource = new BehaviorSubject<ResponseSortEvent>(this.sort);
    sort$ = this.sortSource.asObservable();

    private defaults: ResponseDefaultSort = {};

    constructor(
        private queryService: ResponseQueryService
    ) {
        this.queryService.response$.pipe(
            map(response => {
                if (!response) { return; }
                // sort will never be zero, id will always exist...
                if (!response.responseQuery.sort || response.responseQuery.sort.length === 0) { return; }

                const requestUrl = new URL(response.request.urlWithParams);

                const userQueriedOnIdLast = this.userQueriedOnIdLast(requestUrl.searchParams);
                // create a new array
                const responseSort = [...response.responseQuery.sort];

                // sets the entity type
                const type = response.responseQuery.filter.type;

                let toEmit: ResponseSortEvent | undefined;

                if (userQueriedOnIdLast) {
                    // if the user queried on Id, and it's the last value,
                    // send it on up!
                    toEmit = { type: type, path: responseSort };
                } else if (responseSort[0].path === 'id') {
                    // if the response only sorts on ID, and it wasn't requested by the user
                    // do nothing, do not emit nada
                    // toEmit will be undefined
                } else if (responseSort[responseSort.length - 1].path === 'id') {
                    // this should always be hit unless the "user-queried-on-id-last"
                    // as the API always adds ID to a sort filter
                    // however, we need to strip the last 'id' value
                    const defaultForType = responseSort.slice(0, responseSort.length - 1);
                    toEmit = { type: type, path: defaultForType };
                } else {
                    // this will be hit if the user defines a sort that does not end with id
                    toEmit = { type: type, path: responseSort };
                }

                if (toEmit) {
                    // set default if there was no 'sort' in the request
                    if (!requestUrl.searchParams.get('sort')) {
                        this.defaults[type] = toEmit.path;
                    }

                    this.sortSource.next(toEmit);
                }
            })
        ).subscribe();
    }

    getDefault(type: MetadataType): ResponseSort[] | undefined {
        return this.defaults[type];
    }

    // setDefault should be removed out of here
    setDefault(type: MetadataType, sort: ResponseSort[]) {
        this.defaults[type] = sort;
    }

    // this is a special case to cater for when a user does a sort which ends with an
    // ID sort.
    // This is necessary as the API will always tack on "id" to the sort if it is not
    // present in the original query.
    // todo: remove magic string 'sort'sort
    private userQueriedOnIdLast(params: URLSearchParams): boolean {
        const sort = params.get('sort');
        if (!sort) { return false; }

        return sort.endsWith('id');
    }
}
