<div class="modal-body" [class.modal-failed]="showFailedStyles">
    <div class="container-fluid">
        <h2 class="text-primary mt-2 mb-2" [class.text-danger]="showFailedStyles">{{ title }}</h2>

        <cp-loader *ngIf="!completed"></cp-loader>

        <div class="alert alert-danger" *ngIf="error">
            An Unexpected Error Occurred.
        </div>

        <div *ngIf="completed && !error">
            <div class="d-flex">
                <div>Total {{ total }}</div>
            </div>
        </div>

        <div *ngIf="errorItems.length > 0">
            <hr />
            <h3>Error Summary</h3>

            <div *ngFor="let item of errorItems" class="mt-1">
                <div><strong>{{ errorItemHeader(item.model) }}</strong></div>
                <div *ngFor="let error of item.errors" class="row">
                    {{ error.message }}
                </div>
            </div>
        </div>

        <div *ngIf="completed">
            <hr />
            <button type="button" 
                class="btn btn-primary btn-block btn-lg mt-4" 
                [class.btn-danger]="showFailedStyles"
                (click)="close('Confirm Button')">
                OK
            </button>
        </div>
    </div>
</div>