import { Injectable } from '@angular/core';
import { ChangesHttpService } from './http/changes.http.service';
import { ChangesResponse } from '../../../models/changes/changes.model';
import { Observable } from 'rxjs';
import { RequestListOptions } from '../../../models/api/request-list-options.model';
import { ChangesIncludeOption } from '../../../models/shared/include.model';
import { EntityType, Entity } from '../../../models/entity/entity.type';

@Injectable()
export class ChangesService {

    constructor(
        private httpService: ChangesHttpService
    ) { }

    // CAREFUL with this service, NOTICE that this doesn't just call down into the "get" of the inherited base class.
    get(type: EntityType, id: number, options: RequestListOptions<ChangesIncludeOption>): Observable<ChangesResponse<Entity>> {
        return this.httpService.getChanges(type, id, options);
    }
}
