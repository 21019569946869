<cp-property-display *ngFor="let i of item | keyvalue" [removeMargin]="true" class="mb-1 pb-1">
    <span label>{{ i.key }}</span>
    <div property *ngTemplateOutlet="template; context: { $implicit: i.value }"></div>
</cp-property-display>

<ng-template #template let-value>
    <ng-container *ngIf="isObject(value)">
        <cp-property-display *ngFor="let i of value | keyvalue" [removeMargin]="true" [removeBorder]="true" class="mb-1 pb-1">
            <span label>{{ i.key }}</span>
            <div property *ngTemplateOutlet="template; context: { $implicit: i.value }"></div>
        </cp-property-display>
    </ng-container>

    <ng-container *ngIf="isArray(value)">
        <div *ngFor="let i of value">
            <ng-container *ngTemplateOutlet="template; context: { $implicit: i }"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="!isArray(value) && !isObject(value)">{{ value }}</ng-container>
</ng-template>