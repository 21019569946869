/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./loader.component";
var styles_LoaderComponent = [];
var RenderType_LoaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
export function View_LoaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], [[2, "fade-in", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "spinner-border text-primary"]], [[2, "spinner-border-sm", null], [2, "m-2", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.disableFadeIn; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.small; var currVal_2 = !_co.small; _ck(_v, 1, 0, currVal_1, currVal_2); }); }
export function View_LoaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cp-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i0.ɵdid(1, 49152, null, 0, i1.LoaderComponent, [], null, null)], null, null); }
var LoaderComponentNgFactory = i0.ɵccf("cp-loader", i1.LoaderComponent, View_LoaderComponent_Host_0, { small: "small", disableFadeIn: "disableFadeIn" }, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
